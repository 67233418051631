// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
    .background-white{ 
      color: white; 
    } 
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-analysis/applications.component.ts"],"names":[],"mappings":";IACI;MACE,YAAY;IACd","sourcesContent":[" \n    .background-white{ \n      color: white; \n    } \n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
