import { Injectable } from '@angular/core';
import auth from '../../auth-config.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, Observer, throwError } from "rxjs";
import { ComplianceResponsible, RuleDomains, Rules, SubRules, ValidationFrequency } from 'src/app/interfaces/RuleBook/RuleDomains';
import { EstimatedCosts, EstimatedCostTypes } from 'src/app/interfaces/Maintenance/InitialBaseline/EstimatedCostTypes';

@Injectable({
  providedIn: 'root'
})
export class RuleBookService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394";
  constructor(private http: HttpClient) { }

  private createHeader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getRuleBook(organizationId: string, showPredefined: boolean) {
    var Url = this.url + `/api/RuleBook/rule-book/${organizationId}/${showPredefined}`;
    return this.http.get<RuleDomains[]>(Url);
  }

  getFrequencies() {
    var Url = this.url + "/api/RuleBook/validation-frequency";
    return this.http.get<ValidationFrequency[]>(Url);
  }

  isRuleSubmitted(organizationId: string) {
    var Url = this.url + `/api/RuleBook/rule-submit/${organizationId}`;
    return this.http.get<boolean>(Url);
  }

  getComplianceResponsible() {
    var Url = this.url + "/api/RuleBook/compliance-responsible";
    return this.http.get<ComplianceResponsible[]>(Url);
  }

  submitRules(rules: any, organizationId: string) {
    var Url = this.url + `/api/RuleBook/rules/${organizationId}`;
    var data = JSON.stringify(rules);
    return this.http.post<any>(Url, data, this.createHeader());
  }

  deleteSubRule(subRuleId: number, organizationId: string, isCustom: boolean) {
    var Url = this.url + `/api/RuleBook/sub-rule?subRuleId=${subRuleId}&organizationId=${organizationId}&isCustom=${isCustom}`;
    return this.http.delete(Url).subscribe();
  }

  addSubRule(subRule: any, organizationId: string) {
    var Url = this.url + `/api/RuleBook/sub-rule/${organizationId}`;
    var data = JSON.stringify(subRule);
    return this.http.post<any>(Url, data, this.createHeader());
  }

  updateSubRule(subRule: any) {
    var Url = this.url + `/api/RuleBook/sub-rule`;
    var data = JSON.stringify(subRule);
    return this.http.put(Url, data, this.createHeader());
  }

  updatePredefinedSubRule(subRule: any) {
    var Url = this.url + `/api/RuleBook/predefined-sub-rule`;
    var data = JSON.stringify(subRule);
    return this.http.put(Url, data, this.createHeader());
  }

  updateRule(rule: any) {
    var Url = this.url + `/api/RuleBook/rule`;
    var data = JSON.stringify(rule);
    return this.http.put(Url, data, this.createHeader());
  }

  AddRule(rule: any, ruleDomain: number) {
    var Url = this.url + `/api/RuleBook/rule?ruleDomainId=${ruleDomain}`;
    var data = JSON.stringify(rule);
    return this.http.post(Url, data, this.createHeader());
  }

  addPredefinedSubRule(subRule: any) {
    var Url = this.url + `/api/RuleBook/sub-rule`;
    var data = JSON.stringify(subRule);
    return this.http.post(Url, data, this.createHeader());
  }

  deleteRule(ruleId: number) {
    var Url = this.url + `/api/RuleBook/rule?ruleId=${ruleId}`;
    return this.http.delete(Url).subscribe();
  }

  deletePredefinedSubRule(subRuleId: number) {
    var Url = this.url + `/api/RuleBook/predefined-sub-rule?subRuleId=${subRuleId}`;
    return this.http.delete(Url).subscribe();
  }

  getMyRuleBook(organizationId: string) {
    var Url = this.url + `/api/MyRuleBook/my-rule-book/${organizationId}`;
    return this.http.get<Rules[]>(Url);
  }

  establishBaseline(subRule: any) {
    var Url = this.url + `/api/MyRuleBook/initial-baseline`;
    var data = JSON.stringify(subRule);
    return this.http.post<SubRules>(Url, data, this.createHeader());
  }

  addCost(cost: EstimatedCosts) {
    var Url = this.url + `/api/MyRuleBook/estimated-costs`;
    var data = JSON.stringify(cost);
    return this.http.post<number>(Url, data, this.createHeader());
  }

  updateCost(cost: EstimatedCosts) {
    var Url = this.url + `/api/MyRuleBook/estimated-costs`;
    var data = JSON.stringify(cost);
    return this.http.put(Url, data, this.createHeader());
  }

  deleteCost(estimatedCostId: number, isStandard: boolean, actionId:number) {
    var Url = this.url + `/api/MyRuleBook/estimated-costs?estimatedCostId=${estimatedCostId}&isStandard=${isStandard}&actionId=${actionId}`;
    return this.http.delete(Url);
  }
}