import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ChipListComponent, ClickEventArgs } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectComponent, TaggingEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { AdministrationService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'manage-permissions-layout',
  styleUrls: ['manage-permissions-layout.css'],
  templateUrl: 'manage-permissions-layout.html',
})
export class ManagePermissionsLayoutComponent implements OnInit {
  constructor(private service: AdministrationService) { }
  ngOnInit(): void {
    this.getRoles();
  }
  private roles = [];
  private scopes = [];
  private permissions = [];
  private nonIncludedPermissions = [];
  private nonIncludedScopes = [];
  private selectedRole;
  private scopePlaceholder = "Start searching here..."
  public fields: Object = { text: 'scopeName', value: 'scopeId' };
  public p_fields: Object = { text: 'name', value: 'id' };
  @ViewChild('scopeCat')
  public scopeCat: MultiSelectComponent;

  @ViewChild('scopeChipsList')
  public scopeChipsList: ChipListComponent;

  @ViewChildren(MultiSelectComponent) multiselects: QueryList<MultiSelectComponent>;
  @ViewChildren(ChipListComponent) templates: QueryList<ChipListComponent>;

  getRoles() {
    this.service.getAllRoles().subscribe(data => {
      this.roles = data;
    })
  }

  getScopesByRole(roleId: number) {
    this.selectedRole = roleId;
    this.service.getScopesByRole(roleId).subscribe(data => {
      this.scopes = data;
    })

    this.service.getNonIncludedScopesByRole(roleId).subscribe(data => {
      this.nonIncludedScopes = data;
    })
    this.getPermissionsByRole(roleId);
  }

  getPermissionsByRole(roleId: number) {
    this.service.getRoleParametersWithPermissions(roleId).subscribe(data => {
      this.permissions = data;
    })

    this.service.getNonIncludedRoleParametersWithPermissions(roleId).subscribe(data => {
      this.nonIncludedPermissions = data;
    })
  }

  public linkScopeToRole = (e: TaggingEventArgs) => {
    let model = {
      scopeId: 0,
      scopeName: e.itemData["scopeName"],
    }
    this.service.linkScopesToRole(Number(e.itemData["scopeId"]), this.selectedRole).subscribe(x => {
      var chip = {
        text: model.scopeName,
        value: Number(x),
        cssClass: 'custom-chips permissions-chips-choice'
      }
      model.scopeId = Number(x);
      this.scopes.push(model);
      this.scopeChipsList.add(chip);
      this.scopeCat.value = [];
      this.nonIncludedScopes.splice(this.nonIncludedScopes.findIndex(x => x.id == x), 1);
    });
  }

  deleteScopeLink(e: ClickEventArgs) {
    if (e.text) {
      if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {
        let index = e.data["value"];
        if (index) {
          var model = {
            scopeId: Number(index),
            scopeName: e.data["text"]
          }
          this.service.deleteScopesToRoleLink(this.selectedRole, Number(index)).subscribe();
          this.scopes.splice(this.scopes.findIndex(x => x.scopeId == index), 1);
          this.nonIncludedScopes.push(model);
        }
      }
    }
  }

  public linkPermissionToRole = (e: TaggingEventArgs, permissionTypeId) => {
    let model = {
      id: 0,
      name: e.itemData["name"],
    }
    this.service.linkPermissionToRole(e.itemData["id"], this.selectedRole, permissionTypeId).subscribe(x => {
      var chip = {
        text: model.name,
        value: e.itemData["id"],
        cssClass: 'custom-chips permissions-chips-choice'
      }
      model.id = e.itemData["id"];
      this.permissions.filter(x=>x.id == permissionTypeId)[0].values.push(model);

      var chipListName = 'permissionsChipsList' + permissionTypeId + '';
      var currentMultiselectListName = 'permissionsCat' + permissionTypeId + '';
      var currentChip = this.templates.filter(x => x.element.id == chipListName);
      var currentMultiselect = this.multiselects.filter(x => x.element.id == currentMultiselectListName);
      currentChip[currentChip.length - 1].add(chip)
      this.nonIncludedPermissions.filter(x=>x.id == permissionTypeId)[0].values
      .splice(this.nonIncludedPermissions.filter(x=>x.id == permissionTypeId)[0].values.findIndex(x => x.id == e.itemData["id"]), 1);
      currentMultiselect[0].value = [];
    });
  }

  deletePermissionLink(e: ClickEventArgs, permissionTypeId: number) {
    if (e.text) {
      if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {
        let index = e.data["value"];
        if (index) {
          var model = {
            id: index,
            name: e.data["text"]
          }
          this.service.deletePermissionToRoleLink(this.selectedRole, index, permissionTypeId).subscribe();
          this.permissions.filter(x=>x.id == permissionTypeId)[0].splice(this.permissions.findIndex(x => x.id == index), 1);
          this.nonIncludedPermissions.filter(x=>x.id == permissionTypeId)[0].values.push(model);
        }
      }
    }
  }
}
