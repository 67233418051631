import { Component, OnInit, ViewChild } from "@angular/core";
import { Router} from "@angular/router";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
@Component({
    selector: 'gdpr-full-assessment',
    styleUrls: ['gdpr-full-risk.component.css'],
    templateUrl: 'gdpr-full-risk.component.html',
})
export class GdprFullAssessmentComponent implements OnInit{
    constructor(private router: Router){

    }
    ngOnInit(): void {
       
    }
public index = 1;
@ViewChild('fullRiskTab') tabGroup:TabComponent;
public headerText: Object = [{ text: "Top-5" },
{ text: "Show All" }, {text: "ANALYSIS"}];

onTabSelect(args:any){    
    if(args.selectedIndex === 2){
        this.router.navigate(['/bp-details/id/security/riskanalysis/0'])
    }
    if(args.selectedIndex === 0){
        this.router.navigate(['/bp-details/id/security/entry-security'])
    }
}
}