import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { GridComponent, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { EmitType } from "@syncfusion/ej2-base";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { createSpinner, hideSpinner, showSpinner } from "@syncfusion/ej2-angular-popups";

@Component({
    selector: 'transfer-impact-assessment',
    templateUrl: 'transfer-impact-assessment.html',
    styleUrls: ['transfer-impact-assessment.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`]
})
export class TransferImpactAssessmentComponent implements OnInit, AfterViewInit {
    ngOnInit(): void {
        createSpinner({
            target: document.getElementById('container')
        });
        this.getTiaLoadTypes();
        this.getModelData();
    }
    constructor(private service: DataTransferService) { }
    ngAfterViewInit(): void {
        this.getStatusesList();
    }
    public modelData = [];
    public tiaLoadTypes = [];
    public documentStatuses = [];
    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChild('data_transfers_grid')
    private tiaGrid: GridComponent;

    @ViewChild('warningToast')
    public position: ToastPositionModel = { X: 'Right', Y: 'Bottom' };

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];

    public getStatusesList() {
        this.service.getDocumentStatusList().subscribe(data => {
            this.documentStatuses = data;
        })
    }

    public getTiaLoadTypes() {
        this.service.getTiaLoadTypes().subscribe(data => {
            this.tiaLoadTypes = data;
        })
    }

    public beforeDataBound(): void {
        let fn = () => {
            this.tiaGrid.showSpinner();
            this.tiaGrid.off('toolbar-refresh', fn);
        };
        this.tiaGrid.on('toolbar-refresh', fn);
    }

    public queryCellInfoEvent: EmitType<QueryCellInfoEventArgs> = (args: QueryCellInfoEventArgs) => {
        if (args.data["tiaNeeded"] == false) {
            if (args.column.field == 'transferType')
                args.colSpan = 2;
        }
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (index == "0") {
            args.cell.classList.add('background-grey');
        }
        if (count % 2 == 0) {
            args.cell.classList.add('background-grey');
        }
    }
    documentStatusesChanged(id, recipientId, versionNo) {
        this.service.addEditDocumentStatus(recipientId, id, versionNo).subscribe();
    }

    fileChange(event, recipient, versionNo) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('tia', file, file.name.split(" ")[0]);
            formData.append('recipient', recipient);
            formData.append('versionNo', versionNo);
            this.service.uploadTia(formData).pipe(catchError(err => {
                this.toasts[0].content = "File is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            }))
                .subscribe(res => {
                    console.log('HTTP response', res)
                    this.toasts[1].content = "File is successfully saved";
                    this.toastObj.show(this.toasts[1]);
                    this.modelData.filter(x => x.recipientId == recipient)[0].tiaExists = true;
                    this.tiaGrid.dataSource = [];
                    this.tiaGrid.dataSource = this.modelData;
                });
        }
    }
    generateTia(recipientId, fileFormat, versionNo) {
        showSpinner(document.getElementById('container'));
        document.querySelector('button').disabled = true;
        this.service.generateTia(recipientId, fileFormat, versionNo).subscribe((data: any) => {
            if (fileFormat === 'Word') {
                this.service.saveAsWordFile(data, "TIA_" + recipientId + "_" + versionNo);
            }
            if (fileFormat === 'Pdf') {
                this.service.saveAsPdfFile(data, "TIA_" + recipientId + "_" + versionNo);
            }
            hideSpinner(document.getElementById('container'));
        },
            ((error) => {
                hideSpinner(document.getElementById('container'));
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(error);
            }));
    }

    getModelData() {
        this.service.getTransferImpactAssessmentData().subscribe(modelData => {
            this.modelData = modelData;
        })
    }
}