import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentationCategory } from "src/app/interfaces/DocumentationCategory";
import { DocumentationCategoryProgress } from "src/app/interfaces/DocumentationCategoryProgress";

import auth from '../../auth-config.json';

@Injectable({
  providedIn: 'root'
})
export class GeneralInfoService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394"
  constructor(private http: HttpClient) { }

  private createHedader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  ifCategoryIsFinal(bpId: string, categoryId: string, versionNo: number): Observable<boolean> {
    var Url = this.url + "/api/DocumentationCategory/CategoryFinalTypeExists?businessProcessId=" + bpId + "&documentationCategoryId=" + categoryId
      + "&versionNo=" + versionNo + ""
    return this.http.get<boolean>(Url);
  }

  getAllCategoriesList(): Observable<DocumentationCategory[]> {
    var Url = this.url + "/api/DocumentationCategory/GetAll"
    return this.http.get<DocumentationCategory[]>(Url);
  }

  getAllCategoriesListByBusinessProcess(bpId: string, versionNo: number): Observable<DocumentationCategory[]> {
    var Url = this.url + "/api/DocumentationCategory/GetAllByBp?businessProcessId=" + bpId + "&versionNo=" + versionNo + ""
    return this.http.get<DocumentationCategory[]>(Url);
  }
  getCategoryById(categoryId: string, bpId: string, versionNo: number): Observable<DocumentationCategory[]> {
    var Url = this.url + "/api/DocumentationCategory/Get?documentationCategoryId=" +
      categoryId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get<DocumentationCategory[]>(Url);
  }

  saveCategoryWithType(type: number, categoryId: string, bpId: string, versionNo: number, category: DocumentationCategory[]) {
    var fullUrl = this.url + "/api/DocumentationCategory/SaveCategoryWithType?type=" +
      type + "&documentationCategoryId=" + categoryId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.post(fullUrl, JSON.stringify(category), this.createHedader());
  }

  saveCategory(category: DocumentationCategory[]) {
    var fullUrl = this.url + "/api/DocumentationCategory/SaveCategory";
    return this.http.post(fullUrl, JSON.stringify(category), this.createHedader());
  }

  updateCategory(categoryId: string, progress: number) {
    var fullUrl = this.url + "/api/DocumentationCategory/UpdateCategoryProgress?documentationCategoryId=" + categoryId + "&progress=" + progress + "";
    return this.http.put(fullUrl, this.createHedader());
  }

  addUpdateCategory(categoryProgress: DocumentationCategoryProgress) {
    var fullUrl = this.url + "/api/DocumentationCategory/AddUpdateCategoryProgress"
    return this.http.post(fullUrl, JSON.stringify(categoryProgress), this.createHedader());
  }
}

