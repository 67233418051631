import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GroupData } from "src/app/enums/groups";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
  selector: 'data-processors',
  templateUrl: 'data-processors.component.html',
  styleUrls: ['data-processors.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataProcessorsComponent implements OnInit {
  dataProcessors = [];
  public placeholder = 'Select dataprocessor'
  versionNo: any;
  bpId: string;
  ngOnInit(): void {
    this.bpId = sessionStorage.getItem("bpId")
    var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
    this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
    this.getDataProcessorsList();
  }

  constructor(private riskService: RiskService, private authService: MsalService) {

  }

  getDataProcessorsList() {
    var tenant = sessionStorage.getItem("customerId");
    if (sessionStorage.getItem("dataProcessorsData")) {
      this.dataProcessors = JSON.parse(sessionStorage.getItem("dataProcessorsData"))
    }
    else {
      this.riskService.getDataProcessorsList(this.bpId, this.versionNo, tenant).subscribe(res => {

        if (res.length > 0) {
          sessionStorage.removeItem("dataProcessorsData");
          sessionStorage.setItem("dataProcessorsData", JSON.stringify(res));
        }
        else res = null;
        this.dataProcessors = res;
      })
    }
  }
}