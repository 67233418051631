import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'decisionMakingFilter',
    pure: false
})
export class DecisionMakingPipe implements PipeTransform {
    transform(items: any[], item: string): any {
        if (!items || !item) {
            return items;
        }
        var result = items.filter(u => u.fieldId === item);
        return result;
    }
}