import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'report-preview-readonly',
    templateUrl: 'report-preview-readonly.html',
    styleUrls: ['report-preview-readonly.css']
})
export class ReportPreviewReadonlyComponent implements AfterViewChecked, OnInit {
    controlTrigger: any[];
    triggerName: any;
    constructor(private service: DocMaintenanceService) { }
    public reportData: any;
    public qualityList: any;
    @Input() controlId: any;
    @Input() category: any;
    @Input() refresh: boolean;
    @Output() showLog = new EventEmitter<number>();
    oldControlId: any;
    isFirstTime: boolean;
    currentControl:any;
    qualityName:string;
    refreshPreview = false;
    ngOnInit(): void {
        this.isFirstTime = true;
        this.getQualityList();
    }
    ngAfterViewChecked(): void {
        if(this.controlId!==undefined){
            if(this.controlId!==this.oldControlId){           
                this.getControl();
                this.getControlReportData();
                this.oldControlId = this.controlId;
            }
            if(this.refresh===true){ 
                this.getControl();
                this.getControlReportData();          
                this.refresh = false;
            }
        }
    }

    getControlTriggers() {
        this.service.getControlEventTriggerList().subscribe(data => {
            this.controlTrigger = data;
        })
    }

    showMaintenanceLog() {
        this.showLog.emit(this.controlId);
    }

    getQualityList() {
        this.service.getControlEventGetQualityList().subscribe(x => {
            this.qualityList = x;
        })
    }
    getControlReportData() {
        this.service.showReportPreview(this.controlId).subscribe(x => {
            this.reportData = x;
            this.qualityName = this.reportData.qualityOfDocumentation.documantationQuality;
            this.triggerName = this.reportData.trigger.triggerTitle;
        })
    }

    getControl() {
        this.service.getControlEventById(this.controlId).subscribe(_data => {
            this.currentControl = _data;
        })
    }
}