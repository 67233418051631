import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { bottomSpace } from "@syncfusion/ej2/kanban";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";
import { LegalBasisComponent } from "../../legal-basis.component";

@Component({
    selector: 'legal-basis-for-processing',
    styleUrls: ['legal-basis-for-processing.css'],
    templateUrl: 'legal-basis-for-processing.html',
})
export class LegalBasisProcessingComponent implements OnInit, AfterViewChecked {
    @Input() fieldId: string;
    @Input() model: any;
    currentFieldId;
    lbProcessing: any[];
    isFirstRun: boolean;
    constructor(private service: LegalBasisService, private parent: LegalBasisComponent) { }
    ngOnInit(): void {
        this.getLegalBasisList();
        this.isFirstRun = true;
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.model !== undefined) {
                if (this.lbProcessing !== undefined) {
                    for (var x = 0; x < this.lbProcessing.length; x++) {
                        this.RevertAdd(this.lbProcessing[x].id)
                    }
                    if (this.model.length > 0) {
                        for (var i = 0; i < this.model.length; i++) {
                            document.getElementById('lbProcessingAddBtn_' + this.model[i].id + '').style.display = "none";
                            document.getElementById('lbProcessingModule_' + this.model[i].id + '').closest('div').style.background = "#DBEEF7";
                        }
                    }
                    this.isFirstRun = false;
                }
            }
        }
    }

    getLegalBasisList() {
        this.service.getAllLegalBasisForProcessing().subscribe(x => {
            this.lbProcessing = x;
        })
    }
    AddLegalBasis(item) {
        document.getElementById('lbProcessingAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('lbProcessingModule_' + item.id + '').closest('div').style.background = "#DBEEF7";
        this.service.LinkLegalBasisForProcessingToField(this.fieldId, item.id).subscribe();
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        var chipListName = 'lbChips_' + this.fieldId + '';
        var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
        currentChip[0].add(chip);
        this.parent.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel === 3)
            .map(x => x.documentationCategoryFields)
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(x => x.documentationCategoryFieldId == this.fieldId)[0].legalBasisProcessing.push(
                { id: item.id, name: item.name })
        this.parent.recalculateProgress();
    }

    RevertAdd(id) {
        document.getElementById('lbProcessingAddBtn_' + id + '').style.display = "block";
        document.getElementById('lbProcessingModule_' + id + '').closest('div').style.background = "#EDEFF3";
    }
}