import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'maintenance-log',
    templateUrl: 'maintenance-log.html',
    styleUrls: ['maintenance-log.css']
})
export class MaintenanceLogComponent implements OnInit, AfterViewChecked {
    public logData = [];
    public isFirstRun: boolean;
    public prevControlId;
    @Input() controlId: any;
    @Input() refresh: boolean;
    ngOnInit(): void {
        this.isFirstRun = true;
        this.refresh = false;
    }

    constructor(private service: DocMaintenanceService) { }
    ngAfterViewChecked(): void {
        if (this.controlId !== undefined) {
            if (this.isFirstRun === true && this.controlId !== this.prevControlId) {
                this.getReport();
                this.isFirstRun = false;
                this.prevControlId = this.controlId;
            }
            if (this.refresh === true) {
                this.getReport();
                this.refresh = false;
            }
        }
    }

    getReport() {
        this.service.getMaintenanceLog(this.controlId).subscribe(data => {
            this.logData = data;
            this.logData.forEach(x => {
                x.logDate = new Date(x.logDate).toISOString().slice(0, 10)
            })
        })
    }
}

