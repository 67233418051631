import { Component, OnInit } from "@angular/core";
import { Router} from "@angular/router";
@Component({
    selector: 'gdpr-assessment',
    styleUrls: ['gdpr-risk-assessment.component.css'],
    templateUrl: 'gdpr-risk-assessment.component.html',
})
export class GdprAssessmentComponent implements OnInit{


ngOnInit(): void {

}
constructor(private router: Router){

}
public index = 6;
public headerText: Object = [{ text: "Top-5" },
{ text: "Show All" }, {text: "ANALYSIS"}];

onTabSelect(args:any){
    if(args.selectedIndex === 2){
        this.router.navigate(['/bp-details/id/security/riskanalysis/0'])
    }
    if(args.selectedIndex === 1){
        this.router.navigate(['/bp-details/id/security/gdpr-full'])
    }
}
}


