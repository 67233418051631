import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { AdministrationService } from "src/app/shared/services/admin.service";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { L10n } from "@syncfusion/ej2-base";
import { createSpinner, hideSpinner, setSpinner, showSpinner } from "@syncfusion/ej2-angular-popups";
@Component({
    selector: 'model-import-grid',
    styleUrls: ['model_import_grid.css'],
    templateUrl: 'model_import_grid.html',
    encapsulation: ViewEncapsulation.None
})
export class ModelImportGridComponent implements OnInit, AfterViewInit {
    constructor(private authService: MsalService, private adminService: AdministrationService) {

    }
    public toolbar: ToolbarItems[] | object;
    private loadInProgress:boolean;
    public editSettings: Object;
    public isInitialLoad: boolean = false;
    ngAfterViewInit(): void {
        this.toolbar = [
            { text: 'Models currently used', id: 'importGridHeader' },
            'Delete'
        ]
        this.editSettings = { allowDeleting: false, mode: 'Normal' };
    }
    public modelData = [];
    public currentModel;

    @ViewChild('warningToast')
    public position: ToastPositionModel = { X: 'Center', Y: 'Top' };

    @ViewChild('existing_model_grid')
    public modelImportGrid: GridComponent;

    @ViewChild('fileInput', null) fileInput: ElementRef;


    @ViewChild('toasttype')
    private toastObj: ToastComponent;
    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];
    @Input() customerId;
    ngOnInit(): void {
        createSpinner({
            target: document.getElementById('GridWrapper')
        });
        this.getModelData(0);
        this.currentModel = null;
        this.loadInProgress = false;
    }

    public load(args: any): void {
        this.isInitialLoad = true;
    }
    public beforeDataBound(e): void {
        let fn = () => {
            this.modelImportGrid.showSpinner();
            this.modelImportGrid.off('toolbar-refresh', fn);
            if (!this.isInitialLoad && e.result.length == 0) {
                this.modelImportGrid.hideSpinner();            //hide spinner 
            }
            this.isInitialLoad = false;
        };
        this.modelImportGrid.on('toolbar-refresh', fn);
    }
    RemoveElementFromArray(element: string, arrayElements: any) {
        arrayElements.forEach((value, index) => {
            if (value.id == element) arrayElements.splice(index, 1);
        });
    }

    getModelData(version: number) {
        this.adminService.getDummyCustomer().subscribe(data => {
            if (data !== null) {
                this.adminService.getModelData(data.customerID, version).subscribe(data => {
                    if (version == 0) {
                        this.modelData = data;
                        this.modelImportGrid.hideSpinner();
                    }
                    else {
                        let filteredModel = this.modelData.filter(x => x.displayId == data[0].displayId);
                        if (filteredModel !== null && filteredModel.length > 0) {
                            this.RemoveElementFromArray(filteredModel[0].id, this.modelData);
                        }
                        this.modelData.push(data[0]);
                        this.currentModel = data[0];
                        this.modelImportGrid.dataSource = [];
                        this.modelImportGrid.dataSource = this.modelData;
                    }
                });
            }
        })
    }

    actionBegin(args: any): void {
        if (args.requestType === 'delete') {
            this.adminService.deleteXml(args.data[0].customerId, args.data[0].versionNo).subscribe();
            sessionStorage.removeItem("group");
            sessionStorage.removeItem("role");
            sessionStorage.removeItem("current_group");
            sessionStorage.removeItem("bpListData");
            sessionStorage.removeItem("threatData")
            sessionStorage.removeItem("appData")
            sessionStorage.removeItem("bpId")
            sessionStorage.removeItem("dpia")
            sessionStorage.removeItem("sbmtInfo")
            sessionStorage.removeItem("legalBasisApps")
        }
    }

    submitModel(model: NgForm) {
        showSpinner(document.getElementById('GridWrapper'));
        var data = model.value;
        data.id = this.currentModel.id;
        data.customerId = this.currentModel.customerId;
        data.versionNo = this.currentModel.versionNo;
        this.adminService.updateXml(data).subscribe(data => {
            this.getModelData(0);
            this.currentModel = model.value;
            hideSpinner(document.getElementById('GridWrapper'));
        });
    }
    DataBound(args) {
        if (this.currentModel !== null) {
            let displayId = this.currentModel.displayId;
            let index = this.modelImportGrid.getRowIndexByPrimaryKey(displayId);
            this.modelImportGrid.selectRow(index);
        }
        sessionStorage.removeItem("group");
        sessionStorage.removeItem("role");
        sessionStorage.removeItem("current_group");
        sessionStorage.removeItem("bpListData");
        sessionStorage.removeItem("threatData")
        sessionStorage.removeItem("appData")
        sessionStorage.removeItem("bpId")
        sessionStorage.removeItem("dpia")
        sessionStorage.removeItem("sbmtInfo")
        sessionStorage.removeItem("legalBasisApps")
    }

    rowSelected(e) {
        this.editSettings = { allowDeleting: true, mode: 'Normal' };
        let id = e.data.businessProcessId;
        this.currentModel = this.modelData.filter(x => x.businessProcessId == id)[0];
    }
    rowDeselected(e) {
        this.editSettings = { allowDeleting: false, mode: 'Normal' };
        this.currentModel = null;
    }

    fileChange(event) {
        this.loadInProgress = true;
        this.modelImportGrid.clearSelection();
        showSpinner(document.getElementById('GridWrapper'));
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('uploadFiles', file, file.name);
            formData.append('customerId', '7CCD8F33-73D1-4398-AEED-36F8ED82F931');
            this.adminService.uploadXml(formData, this.customerId).pipe(catchError(err => {
                hideSpinner(document.getElementById('GridWrapper'));
                this.toasts[0].content = "File is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                this.fileInput.nativeElement.value = '';
                this.loadInProgress = false;
                return throwError(err);
            }))
                .subscribe(res => {
                    hideSpinner(document.getElementById('GridWrapper'));
                    console.log('HTTP response', res)
                    this.toasts[1].content = "File is successfully saved";
                    this.toastObj.show(this.toasts[1]);
                    this.getModelData(Number(res));
                    this.fileInput.nativeElement.value = '';
                    this.loadInProgress = false;
                });
        }
    }
}