// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-info-main-wrapper {
    background: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/direct-menu-pages/business-functions/business-functions.component.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.basic-info-main-wrapper {\r\n    background: white;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
