import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DataTransfersLayoutComponent } from "./data-transfers-layout.component";

@Component({
    selector: 'data-transfers-grid',
    styleUrls: ['data-transfers-grid.css'],
    templateUrl: 'data-transfers-grid.html',
})
export class DataTransfersGridComponent implements OnInit {
    category: string;
    public modelData = [];
    constructor(private service: DataTransferService, 
        private router: Router,
        private menu: MainLayoutComponent,
        private parent: DataTransfersLayoutComponent) { }
    ngOnInit(): void {
        this.getModelData();
        this.menu.openClick(true);
    }

    getModelData(){
        // this.service.getModelData('7ccd8f33-73d1-4398-aeed-36f8ed82f931', 20, sessionStorage.getItem("bpId")).
        // subscribe(_data=>{
        //     this.modelData = _data;

        var modelData = [{
            recepientId: "3139BAC6-3D4A-449E-BF20-52594AC9DB77",
            recepientName: "ServiceNow",
            transferType:"Transfer by transmission (transfers to processors)",
            countryName: "USA",
            legalBasis: "Based on appropriate safeguards",
            tiaNeeded: true,
            flagUrl: "usa.svg",
            isProcessor: true,
            isDisclosure: false,
        },
    
        {
            recepientId: "20F27A59-9032-44C6-8F56-6F55B09BD684",
            recepientName: "Visma",
            transferType:"Transfer by disclosure",
            countryName: "Canada",
            legalBasis: "Based on appropriate safeguards",
            tiaNeeded: true,
            flagUrl: "ca.svg",
            isProcessor: true,
            isDisclosure: false,
        }
    ]

        this.modelData = modelData;
    }


    navigateToDetails(recepientId){
        this.router.navigate(['/bp-details/id/data-transfers-layout/'+
        this.parent.categoryId+'/data-transfers-form/'+recepientId+''])
    }
}