import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { RightsOfDataSubjectsComponent } from "../../rights-of-data-subjects.component";
import { RightsOfDataSubjectsService } from "src/app/shared/services/rightsOfDataSubjectsService";

@Component({
    selector: 'erasure-negative',
    styleUrls: ['erasure-negative.css'],
    templateUrl: 'erasure-negative.component.html',
})
export class ErasureNegativeComponent implements OnInit, AfterViewChecked {
    @Input() field: any;
    @Input() model: any;
    @Input() versionNo: number;
    @Input() type: number;
    negativeErasures: any[];
    erasures: any[];
    isFirstRun: boolean;
    bpId: string;

    constructor(private service: RightsOfDataSubjectsService, private parent: RightsOfDataSubjectsComponent) { }
    ngOnInit(): void {
        this.getErasureList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId");
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.negativeErasures !== undefined) {
                if (this.model !== undefined && this.model.length > 0) {
                    this.erasures = this.negativeErasures.filter(x => x.responseType == this.type);
                    for (var i = 0; i < this.model.length; i++) {
                        if (document.getElementById('erasureAddBtn_' + this.model[i].id + '') !== null)
                            document.getElementById('erasureAddBtn_' + this.model[i].id + '').style.display = "none";
                        document.getElementById('removeErasureBtn_' + this.model[i].id + '')
                            document.getElementById('removeErasureBtn_' + this.model[i].id + '').style.display = "block";
                    }
                }
                if (this.model === undefined || this.model.length == 0) {
                    this.erasures = this.negativeErasures.filter(x => x.responseType == this.type);
                }
                this.isFirstRun = false;
            }
        }
    }

    getErasureList() {
        this.service.getErasureModels().subscribe(x => {
            this.negativeErasures = x;
        })
    }
    AddErasure(item) {
        document.getElementById('erasureAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('removeErasureBtn_' + item.id + '').style.display = "block";
        this.service.enableErasure(this.field, this.versionNo, item);
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        this.parent.erasureChips.add(chip);
    }

    DisableErasure(id) {
        this.parent.disableErasure(id, this.field);
        document.getElementById('removeErasureBtn_' + id + '').style.display = "none";
        document.getElementById('erasureAddBtn_' + id + '').style.display = "block";
        this.parent.erasureModelData
        .splice(this.parent.erasureModelData.findIndex(item => item.id === id), 1);
        this.model.splice(this.model.findIndex(x => x.id == id), 1);
    }
}