import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DataTransfersFormComponent } from "../../data-transfers-form.component";

@Component({
    selector: 'safeguards',
    styleUrls: ['safeguards.component.css'],
    templateUrl: 'safeguards.component.html',
})
export class SafeguardsComponent implements OnInit, AfterViewChecked {
    @Input() recipientId: string;
    @Input() model: any;
    safeguards: any[];
    isFirstRun: boolean;
    bpId: string;
    versionNo: any;
    constructor(private service: DataTransferService, private parent: DataTransfersFormComponent) { }
    ngOnInit(): void {
        this.getSafeguardsList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.model !== undefined) {
                if (this.safeguards !== undefined) {
                    if (this.model.safeguards.length > 0) {
                        for (var i = 0; i < this.model.safeguards.length; i++) {
                            document.getElementById('safeGuardAddBtn_' + this.model.safeguards[i].id + '').style.display = "none";
                            document.getElementById('safeguardModule_' + this.model.safeguards[i].id + '').closest('div').style.background = "#DBEEF7";
                        }
                    }
                    this.isFirstRun = false;
                }
            }
        }
    }

    getSafeguardsList() {
        this.service.getSafeguards().subscribe(x => {
            this.safeguards = x;
        })
    }
    AddSafeguard(item) {
        document.getElementById('safeGuardAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('safeguardModule_' + item.id + '').closest('div').style.background = "#DBEEF7";
        this.service.linkSafeguard(this.recipientId, item.id, this.versionNo).subscribe();
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        this.parent.safeguardChips.add(chip)
    }

    RevertAdd(id) {
        document.getElementById('safeGuardAddBtn_' + id + '').style.display = "block";
        document.getElementById('safeguardModule_' + id + '').closest('div').style.background = "#EDEFF3";
    }
}