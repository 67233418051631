// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessDeniedMainHeader {
    color: #0C2B57;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 26px;
}

.accessDeniedText {
    color: #0C2B57;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.accessDeniedLayout{
    text-align: center;
    background: white;
    padding: 15px;
    height: 85vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/errors/access-denied.css"],"names":[],"mappings":"AAEA;IACI,cAAc;IACd,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.accessDeniedMainHeader {\r\n    color: #0C2B57;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-weight: 600;\r\n    font-size: 26px;\r\n}\r\n\r\n.accessDeniedText {\r\n    color: #0C2B57;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-weight: 500;\r\n    font-size: 18px;\r\n}\r\n\r\n.accessDeniedLayout{\r\n    text-align: center;\r\n    background: white;\r\n    padding: 15px;\r\n    height: 85vh;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
