import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'callback',
    pure: false
})
export class CallbackPipe implements PipeTransform {
    transform(items: any[], item: string, modelData: any[]): any {
        if (!items || !item) {
            return items;
        }
        var result = items.filter(u => u.tabLevel === 4
            && u.parentDocumentationSubCategoryId === item);
        for (var i = 0; i < result.length; i++) {
            var matchingField = result[i].documentationCategoryFields.filter(x => x.sequence == 7)[0];
            if (matchingField.fieldValue.value == null || matchingField.fieldValue.value == "No") {
                result[i].specialCategoriesMatchCondition = true;
            }
            else {
                var matchingModelData = modelData.filter(x => x.fieldId == matchingField.documentationCategoryFieldId);
                result[i].specialCategoriesMatchCondition = matchingModelData.some(x => x.selectType == 'a' || x.selectType == 'g');
            }
        }
        return result;
    }
}