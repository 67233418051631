// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orgDDl {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #8D8EA1;
    height: 39px;
    width: 80%;
    background: #EDEFF3;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
}

.roleDDl::after {
    border: 1px solid #CED4DA;
}

.resetFunctionsBtn{
    color: white;
    font-family: 'Rubik';
    background-color: #0E384D;
    border:none;
    padding: 5px;
    font-size: 16px;
}

.resetContainer{
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    overflow: auto;
}

.orgResetTxt{
    color: #0E384D;
    font-family: 'Rubik';
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/admin/data-management/admin-functions/admin-functions.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":[".orgDDl {\r\n    font-family: 'Rubik';\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    font-size: 16px;\r\n    color: #8D8EA1;\r\n    height: 39px;\r\n    width: 80%;\r\n    background: #EDEFF3;\r\n    border: 1px solid #CED4DA;\r\n    box-sizing: border-box;\r\n    border-radius: 8px;\r\n    text-align: center;\r\n}\r\n\r\n.roleDDl::after {\r\n    border: 1px solid #CED4DA;\r\n}\r\n\r\n.resetFunctionsBtn{\r\n    color: white;\r\n    font-family: 'Rubik';\r\n    background-color: #0E384D;\r\n    border:none;\r\n    padding: 5px;\r\n    font-size: 16px;\r\n}\r\n\r\n.resetContainer{\r\n    margin-left: 15px;\r\n    margin-right: 15px;\r\n    padding: 10px;\r\n    overflow: auto;\r\n}\r\n\r\n.orgResetTxt{\r\n    color: #0E384D;\r\n    font-family: 'Rubik';\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
