// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
    .background-grey{ 
      background-color: #F9F9F9; 
    } 
    .background-green{ 
      background-color:  #A1CD63; 
      color:white;
    } 
    .background-red{ 
      background-color: #DE2F3A; 
      color:white;
    } 
    .background-yellow{ 
      background-color: #FFFD54; 
      color:white;
    } 
    .background-orange{ 
      background-color: #F4C142; 
      color:white;
    } 
    .background-white{ 
      background-color: #ffffff; 
    }
    .background-light-green{
      background-color:  #d6f5d6;
    }
    .background-light-red{
      background-color:#ffcccc;
      opacity:1;
    }

    .text-color-submitted{
      font-color:#5AA9EF;
    }
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/full-threat.component.ts"],"names":[],"mappings":";IACI;MACE,yBAAyB;IAC3B;IACA;MACE,0BAA0B;MAC1B,WAAW;IACb;IACA;MACE,yBAAyB;MACzB,WAAW;IACb;IACA;MACE,yBAAyB;MACzB,WAAW;IACb;IACA;MACE,yBAAyB;MACzB,WAAW;IACb;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,0BAA0B;IAC5B;IACA;MACE,wBAAwB;MACxB,SAAS;IACX;;IAEA;MACE,kBAAkB;IACpB","sourcesContent":[" \n    .background-grey{ \n      background-color: #F9F9F9; \n    } \n    .background-green{ \n      background-color:  #A1CD63; \n      color:white;\n    } \n    .background-red{ \n      background-color: #DE2F3A; \n      color:white;\n    } \n    .background-yellow{ \n      background-color: #FFFD54; \n      color:white;\n    } \n    .background-orange{ \n      background-color: #F4C142; \n      color:white;\n    } \n    .background-white{ \n      background-color: #ffffff; \n    }\n    .background-light-green{\n      background-color:  #d6f5d6;\n    }\n    .background-light-red{\n      background-color:#ffcccc;\n      opacity:1;\n    }\n\n    .text-color-submitted{\n      font-color:#5AA9EF;\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
