import { OnInit } from "@angular/core";
import { Component, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
    selector: 'assets',
    templateUrl: 'assets.component.html',
    styleUrls: ['assets.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetsComponent implements OnInit {
    currentAsset: number;
    constructor(private riskService: RiskService) { }
    ngOnInit(): void {
        this.currentTab = 1;
        this.currentAsset = 2;
        this.getTabs();
    }
    raSubType: number;
    tabsModel = [];
    fullInfo = [];
    currentTab: number;

    @Input("raType") raType: boolean;
    @Input("riskType") risk;
    @ViewChild('tabGroup') tabGroup;

    @ViewChild('subAsset')
    public subAsset: TabComponent;

    public headerText: Object =
        [
            { text: "PROCESSES" },
            { text: "APPLICATIONS" },
            { text: "INFRASTRUCTURE" },
            { text: "LINES OF COMMUNICATION" },
            { text: "PEOPLE" },
            { text: "EXTERNAL IT" }
        ];

    getTabs() {
        let raSubTypeId = this.raType == true ? 2 : 1;
        this.raSubType = raSubTypeId;
        if (sessionStorage.getItem("tabs"))
            this.tabsModel = JSON.parse(sessionStorage.getItem("tabs")).filter(x => x.id == raSubTypeId);
        else {
            this.riskService.getTabStructure().subscribe(res => {
                this.tabsModel = res.filter(x => x.id == raSubTypeId);
                sessionStorage.setItem("tabs", JSON.stringify(res))
            });
        }
    }

    onTabSelect(e) {
        this.currentTab = Number(e["selectedItem"].dataset.id);
    }

    setTypeSelected(assetId: number) {
        this.currentTab = 1;
        this.currentAsset = assetId;
    }
}