import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GanttService {
  private ganttData: any;

  setGanttData(data: any): void {
    this.ganttData = data;
    localStorage.setItem("gannt", JSON.stringify(data));
  }

  getGanttData(): any {
    const dataString = localStorage.getItem("gannt");
    return dataString ? JSON.parse(dataString) : null;
  }
}
