import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'coveredByDecisionMakingFilter',
    pure: false
})
export class CoveredByDecisionMakingFilterPipe implements PipeTransform {
    transform(items: any[], item: string, arr: any[]): any {
        if (!items || !item) {
            return items;
        }
        var result = items.filter(u => u.functionName === item);
        const found = result.filter(row => arr.some(value => row.selectType.includes(value)));
        if(found.length > 0){
            return "No";
        }
        else{
            return "Yes";
        }
    }
}