import { AfterViewChecked, AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { MultiSelectComponent } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { DialogComponent, TooltipComponent, createSpinner, hideSpinner, showSpinner } from "@syncfusion/ej2-angular-popups";
import { AnimationModel, FontModel, ILoadedEventArgs, ProgressTheme } from "@syncfusion/ej2-angular-progressbar";
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import * as _ from "lodash";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DocumentationCategoryField } from "src/app/interfaces/DocumentationCategoryField";
import { DocumentationSubCategory } from "src/app/interfaces/DocumentationSubCategory";
import { LegalBasisForProcessing } from "src/app/interfaces/LegalBasis/LegalBasisForProcessing";
import { ControlEvent } from "src/app/interfaces/Maintenance/ControlEvent";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";
import { ArchiveMaintenanceComponent } from "../documentation-maintenance/archive-maintenance.component";
import { DiscretionaryMaintenanceComponent } from "../documentation-maintenance/discretionary-maintenance.component";
import { ControlReportComponent } from "../documentation-maintenance/modals/control-report.component";
import { MaintenanceLogComponent } from "../documentation-maintenance/modals/maintenance-log.component";
import { WheelMaintenanceComponent } from "../documentation-maintenance/wheel-maintenance.component";
import { LegalBasisProcessingComponent } from "./modals/legal-basis-for-processing/legal-basis-for-processing";
import { LegalBasisPersonalDataComponent } from "./modals/personal-data/personal-data.component";
import { DecisionMakingImportanceComponent } from "./modals/decision-making-importance/decision-making-importance.component";
import { DecisionMakingSpecialCategoriesComponent } from "./modals/decision-making-special-category/decision-making-special-categories.component";
import { CallbackPipe } from "src/app/shared/pipes/callback";

@Component({
    selector: 'legal-basis',
    templateUrl: 'legal-basis.html',
    styleUrls: ['legal-basis.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F9F9F9; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    encapsulation: ViewEncapsulation.None
})
export class LegalBasisComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild(ArchiveMaintenanceComponent, { static: false }) childA: ArchiveMaintenanceComponent;
    @ViewChild(WheelMaintenanceComponent, { static: false }) childW: WheelMaintenanceComponent;
    @ViewChild(DiscretionaryMaintenanceComponent, { static: false }) childD: DiscretionaryMaintenanceComponent;
    @ViewChild(ControlReportComponent, { static: false }) childCR: ControlReportComponent;
    @ViewChild(MaintenanceLogComponent, { static: false }) childML: MaintenanceLogComponent;

    @ViewChildren(TooltipComponent) tooltipTemplates: QueryList<TooltipComponent>;
    @ViewChildren(ChipListComponent) templates: QueryList<ChipListComponent>;
    @ViewChildren(MultiSelectComponent) multitemplates: QueryList<MultiSelectComponent>;
    filterargs = { tabIndex: 2 };
    private tableFields;
    public personalData = [];
    categoryId;
    tabIndex;
    prevIndex;
    lastTabItem
    defaultCategoryData = [];
    filledFields: number;
    wholeFields: number;
    defaultSubCategoryData = [];
    subCategories = [];
    controlId: number;
    categoryField: any;
    showWheel: boolean = false;
    showWheelReport: boolean = false;
    typeSelected: any;
    showDiscretionary: boolean = false;
    currentEvent: ControlEvent;
    generalFrequency: any;
    fieldName: string;
    categoryType: any;
    currentDateTime: any;
    controlStarted: boolean;
    exitMode: boolean;
    exitWheelMode: boolean;
    refreshLog: boolean;
    refreshDiscretionary: boolean;
    contentTypeModel: { text: string; value: number; }[];
    lbLegislationModel: { text: string; value: number; }[];
    currentType: number;
    pdType: any;
    currentLegalBasisData: LegalBasisForProcessing[];
    bpId: string;
    versionNo: any;
    viewId: any;
    decisionMakingObjects: any = [];
    importanceFieldId: any;
    specialCategoryFieldId: any;
    objectSubCategoryId: string;
    public menuState;
    public completed: boolean = false;
    pdSubCategoryId: string;
    currentSubcategory: any;
    existingBpImportanceModelData: any[];
    existingBpSpecialCategoriesModelData: any[];
    importanceSubCategory: any;
    specialCategoriesSubCategory: any;
    constructor(private generalInfoService: GeneralInfoService, private legalBasisServise: LegalBasisService,
        private mainService: DocMaintenanceService,
        private route: ActivatedRoute,
        private menu: MainLayoutComponent,
        private dataTransferService: DataTransferService) {
    }

    ngAfterViewChecked(): void {
        if (this.menu.menuObj.element.clientWidth !== 54) {
            this.menu.openClick(true);
        }
    }

    ngAfterViewInit(): void {
        this.getControlFrequencies();
        this.getCurrentControlEvent();
        this.GetExistingDmSpecialCategoriesListsByBusinessProcess();
        this.GetExistingDmImportanceListsByBusinessProcess();
        this.SubmitFinalDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Submit as...</label>";
        this.FrequencyDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Maintenance frequency</label>"
    }

    ngOnInit(): void {
        createSpinner({
            target: document.getElementById('loadingContainer') as any
        });

        showSpinner((document as any).getElementById('loadingContainer'));
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.categoryId = id;
            }
        })
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.viewId = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].modelViewID;
        this.menu.openClick(true);
        this.getCategories();
        this.currentDateTime = new Date().toISOString().slice(0, 10);
        this.controlStarted = false;
        this.ifCategoryCompletedIsFinal();
        this.getObjectsDecisionMaker();
        this.exitWheelMode = false;
        this.exitMode = false;
        this.refreshLog = false;
        this.refreshDiscretionary = false;
        this.refresh = false;
        this.showWheel = false;
        this.showDiscretionary = false;
        this.menuState = this.menu.menuObj.element.clientWidth;
        this.contentTypeModel = [
            { text: 'Planning wheel maintenance', value: 1 },
            { text: 'Discretionary maintenance', value: 2 }
        ]
        this.lbLegislationModel = [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 2 }
        ]
    }
    public labelStyle1: FontModel;
    lines = 'None';
    public type: string = 'Linear';
    public fieldId;
    public width: string = '303px';
    public height: string = '41px';
    public trackThickness: number = 41;
    public progressThickness: number = 41;
    public min: number = 0;
    public max: number = 100;
    public value1: number = 0;
    public showProgressValue: boolean = true;
    public controlFrequency = [];

    @ViewChild('Dialog')
    public Dialog: DialogComponent;

    @ViewChild('LBPersonalDataDialog')
    public PersonalDataDialog: DialogComponent;

    @ViewChild('LBProcessingDialog')
    public LBProcessingDialog: DialogComponent;

    @ViewChild('FrequencyDialog')
    public FrequencyDialog: DialogComponent;

    @ViewChild('CRDialog')
    public ReportDialog: DialogComponent;

    @ViewChild('ArchiDialog')
    public ArchiDialog: DialogComponent;

    @ViewChild('MDialog')
    public MDialog: DialogComponent;

    @ViewChild('DMImportanceDialog')
    public DecisionMakingImportanceDialog: DialogComponent;

    @ViewChild('DMSpecialCategoriesDialog')
    public DecisionMakingSpecialCategoriesDialog: DialogComponent;

    @ViewChild('SubmitFinalDialog')
    public SubmitFinalDialog: DialogComponent;

    @ViewChild('CETypeDialog')
    public CEDialog: DialogComponent;
    public dialogHeight = '100%';
    public dialogWidth = '31%';
    public dialogCEHeight = '220';
    public dialogCEWidth = '25%';
    public dialogPosition: { X: "Right", Y: "Top" }
    public dialogCEPosition: { X: "Center", Y: "Top" }

    public a_dialogPosition: { X: "Center", Y: "Center" }
    public cat_dialogPosition: { X: "Bottom", Y: "Right" }

    public a_dialogWidth = '70%';
    public a_dialogCEHeight = '100%';


    public cr_dialogWidth = '50%';
    public cr_dialogHeight = '100%';
    pdWidth = '60%';
    public s_dialogPosition: { X: "Right", Y: "Top" }
    public c_dialogPosition: { X: "Center", Y: "Center" }
    dialogdragging = true;
    public cat_dialogWidth = '15%';
    public cat_dialogCEHeight = '30%';
    f_dialogWidth = '25%';
    showCloseIcon = true;
    public isFinal: boolean;
    public refresh: boolean = false;
    reportRefresh: boolean = false;
    existingSpecialCategoriesModelData: any[];
    existingImportanceModelData: any[];
    specialCategoriesMatchCondition: boolean = true;
    importanceMatchCondition: boolean = false;

    public ddlfields: Object = { text: 'name', value: 'id' };
    public waterMark: string = 'Select frequency item';
    public value: string = 'GDPR visual models';
    public showReport: boolean;
    public refreshReport: boolean;
    importanceIndex = 0;
    specialCategoriesIndex = 0;
    categories = [];
    fields = [];
    public animation: AnimationModel = { duration: 2000, delay: 0 };

    @ViewChild('tabGroup') tabGroup: TabComponent;
    @ViewChild('lb_grid') grid: GridComponent;
    @ViewChild(LegalBasisProcessingComponent) lbProcessing: LegalBasisProcessingComponent;
    @ViewChild(LegalBasisPersonalDataComponent) pdComponent: LegalBasisPersonalDataComponent;
    @ViewChild(DecisionMakingImportanceComponent) decisionMakingImportance: DecisionMakingImportanceComponent;
    @ViewChild(DecisionMakingSpecialCategoriesComponent) decisionMakingSpecialCategories: DecisionMakingSpecialCategoriesComponent;

    // @ViewChild('lbProcessingChips')
    // public lbProcessingChips: ChipListComponent

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChild('warningToast')
    public position: ToastPositionModel = { X: 'Right', Y: 'Bottom' };

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];

    GetExistingDmImportanceLists() {
        this.legalBasisServise.getExistingDecisionMakingImportanceList(this.importanceFieldId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.existingImportanceModelData = res;
                this.importanceMatchCondition = res.some(x => x.selectType == 'a' || x.selectType == 'c');
                if (this.importanceMatchCondition) {
                    var subcategoryId = this.defaultCategoryData["documentationSubCategories"]
                        .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
                        .filter(x => x.documentationCategoryFieldId == this.importanceFieldId)[0].documentationSubCategoryId;
                    this.defaultCategoryData["documentationSubCategories"]
                        .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = true;
                }
            }
        })
    }

    GetExistingDmSpecialCategoriesLists() {
        this.legalBasisServise.getExistingDecisionMakingSpecialCategoriesList(this.specialCategoryFieldId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.existingSpecialCategoriesModelData = res;
                this.specialCategoriesMatchCondition = res.some(x => x.selectType == 'a' || x.selectType == 'g');
            }
        })
    }

    GetExistingDmImportanceListsByBusinessProcess() {
        this.legalBasisServise.getExistingDecisionMakingImportanceListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.existingBpImportanceModelData = res;
            }
        })
    }

    GetExistingDmSpecialCategoriesListsByBusinessProcess() {
        this.legalBasisServise.getExistingDecisionMakingSpecialCategoriesListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.existingBpSpecialCategoriesModelData = res;
            }
        })
    }


    filterImportanceModel(documentationCategoryFieldId: string) {
        return this.existingBpImportanceModelData.filter(x => x.fieldId == documentationCategoryFieldId);
    }

    filterWarning(dm: any) {
        var fieldId = dm.documentationCategoryFields.filter(x => x.sequence == 7)[0].documentationCategoryFieldId;
        var b = this.existingBpSpecialCategoriesModelData.filter(x => x.fieldId == fieldId)
            .some(x => x.selectType == 'a' || x.selectType == 'g');
        return b;
    }

    filterSpecialCategoriesModel(documentationCategoryFieldId: string) {
        return this.existingBpSpecialCategoriesModelData.filter(x => x.fieldId == documentationCategoryFieldId);
    }

    callImportance(item, idx, subCategory) {
        this.importanceSubCategory = subCategory;
        this.importanceFieldId = item;
        this.importanceIndex = idx;
        this.existingImportanceModelData = this.filterImportanceModel(item);
        this.showDecisionMakingImportanceDialog();
    }

    callSpecialCategories(item, idx, subCategory) {
        this.specialCategoriesSubCategory = subCategory;
        this.specialCategoryFieldId = item;
        this.specialCategoriesIndex = idx;
        this.existingSpecialCategoriesModelData = this.filterSpecialCategoriesModel(item);
        this.showDecisionMakingSpecialCategoriesDialog();
    }

    disableImportance(args: any, fieldId: string) {
        if (args.text) {
            this.legalBasisServise.DisableDecisionMakingImportance(fieldId, this.versionNo, Number(args.data.value)).subscribe();
            let b = this.existingImportanceModelData.some(x => x.selectType == 'a' || x.selectType == 'c');
            var subcategoryId = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
                .filter(x => x.documentationCategoryFieldId == fieldId)[0].documentationSubCategoryId;
            if (b) {
                this.defaultCategoryData["documentationSubCategories"]
                    .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = true;
            }
            if (!b) {
                this.defaultCategoryData["documentationSubCategories"]
                    .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = false;
            }
        }
    }

    disableSpecialCategory(args: any, field: any) {
        if (args.text) {
            this.legalBasisServise.DisableDecisionMakingSpecialCategory(field.parentDocumentationCategoryFieldId, this.versionNo, Number(args.data.value)).subscribe();
            this.existingBpSpecialCategoriesModelData
                .splice(this.existingBpSpecialCategoriesModelData
                    .findIndex(x => x.id == Number(args.data.value) && x.fieldId == field.parentDocumentationCategoryFieldId), 1);
        }
    }

    showPDTypes(id, field, data) {
        this.pdType = id;
        this.fieldId = field;
        this.personalData = this.getFields(data, id);
        this.PersonalDataDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select personal data type</h3>'
        this.PersonalDataDialog.show();
    }

    showLegalBasisProcessing(e: any, modelData) {
        this.fieldId = e;
        this.currentLegalBasisData = modelData;
        this.LBProcessingDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select Legal basis</h3>'
        this.lbProcessing.isFirstRun = true;
        this.LBProcessingDialog.show();
    }

    setTypeSelected(type) {
        this.typeSelected = type;
    }

    createExplainFieldData(e: any, value) {
        let model = e["fieldData"];
        model["explainText"] = value;
        this.legalBasisServise.AddLegalBasisFieldData(model).subscribe();
        this.recalculateProgress();
    }

    getPersonalProcessedDataPerRow(e: any) {
        var target = (this.grid.getRowInfo(e.target));
        var _fieldId = target.rowData["documentationCategoryFieldId"];
        return this.getLegalBasisExistingValues(_fieldId);
    }

    createWhichLegislationFieldData(e: any, value) {
        let model = e["fieldData"];
        model["whichLegislation"] = value;
        this.legalBasisServise.AddLegalBasisFieldData(model).subscribe();
        this.recalculateProgress();
    }

    getLegalBasisExistingValues(id: string) {
        this.legalBasisServise.getLegalBasisForProcessingById(id).subscribe(x => {
            this.currentLegalBasisData = x;
        })
    }

    getObjectsDecisionMaker() {
        let customerId = sessionStorage.getItem("customerId");
        this.legalBasisServise.getObjectsDecisionMaker(this.versionNo, this.viewId, customerId).subscribe(data => {
            this.decisionMakingObjects = data;
        })
    }

    createOtherLegislationFieldData(e: any, value) {
        let model = e["fieldData"];
        model["otherLegislation"] = value;
        this.legalBasisServise.AddLegalBasisFieldData(model).subscribe();
        this.recalculateProgress();
    }

    createCommentFieldData(e: any, value) {
        let model = e["fieldData"];
        model["comment"] = value;
        this.legalBasisServise.AddLegalBasisFieldData(model).subscribe();
        this.recalculateProgress();
    }

    showArchive() {
        this.childA.refresh = true;
        this.ArchiDialog.header = '<label style="font-family: Rubik;font-weight: 600;' +
            'font-size: 20px; color: #0C2B57;">Maintenance archive</label>';
        this.ArchiDialog.show();
    }

    filteredSubcategories(values: DocumentationSubCategory[], type: number): DocumentationSubCategory[] {
        if (values.length > 0)
            return values.filter(x => x.tabLevel == type);
    }

    getCurrentControlEvent() {
        let bpId = sessionStorage.getItem("bpId");
        this.mainService.getCategoryMaintenanceType(bpId, this.categoryId, this.versionNo).subscribe(x => {
            if (x) {
                if (x === 1) {
                    this.showWheel = true;
                    this.showDiscretionary = false;
                    this.currentType = 1;
                }

                if (x === 2) {
                    this.showWheel = false;
                    this.showDiscretionary = true;
                    this.currentType = 2;
                }
            }
        })
    }

    currentIndex;

    customiseCell(args: QueryCellInfoEventArgs) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (index == "0") {
            args.cell.classList.add('background-grey');
        }
        if (count % 2 == 0) {
            args.cell.classList.add('background-grey');
        }
    }

    public items: ItemModel[] = [
        {
            text: 'Final'
        }];

    public itemBeforeEvent(args: MenuEventArgs) {
        args.element.getElementsByTagName('a')[0].setAttribute('target', '_blank');
    }

    ifCategoryCompletedIsFinal() {
        var bpId = sessionStorage.getItem("bpId");
        this.generalInfoService.ifCategoryIsFinal(bpId, this.categoryId, this.versionNo).subscribe(_data => {
            this.isFinal = _data;
        })
    }

    chooseCEType() {
        this.CEDialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 18px;' +
            'color: #0C2B57;">Select type of Control Event:</label>'
        this.CEDialog.show();
    }

    submitTypeSelection() {
        this.controlStarted = false;
        this.CEDialog.hide();
        if (this.typeSelected !== undefined) {
            if (this.typeSelected === 2) {
                this.showDiscretionary = true;
                this.showWheel = false;
                this.mainService.updateCategoryType(sessionStorage.getItem("bpId"), this.categoryId, 2, this.versionNo);
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
            if (this.typeSelected === 1) {
                this.showWheel = true;
                this.showDiscretionary = false;
                this.mainService.updateCategoryType(sessionStorage.getItem("bpId"), this.categoryId, 1, this.versionNo);
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
        }

        else if (this.typeSelected === null || this.typeSelected === undefined) {
            this.typeSelected = 1;
            this.showWheel = true;
            this.showDiscretionary = false;
            this.mainService.updateCategoryType(sessionStorage.getItem("bpId"), this.categoryId, 1, this.versionNo);
        }
    }


    showDialog(data: any, name: string) {
        this.categoryField = data;
        this.fieldName = name;
        this.Dialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 20px;' +
            'color: #0C2B57;">Add observations and actions</label>'
        this.Dialog.show();
    }

    showMDialog(id: number) {
        this.controlId = id;
        this.MDialog.header = '<label style="font-size: 25px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 600; margin-left:5px;' +
            'color: #0C2B57" class="logLbl">Maintenance Log</label>';
        this.childML.refresh = true;
        this.MDialog.show();
    }

    showDecisionMakingImportanceDialog() {
        this.DecisionMakingImportanceDialog.header = '<label style="font-size: 18px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 600; margin-left:5px;' +
            'color: #142A56" class="logLbl">Why is it necessary to perform?</label>';
        this.decisionMakingImportance.isFirstRun = true;
        this.DecisionMakingImportanceDialog.show();
    }

    showDecisionMakingSpecialCategoriesDialog() {
        this.DecisionMakingSpecialCategoriesDialog.header = '<label style="font-size: 18px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 600; margin-left:5px;' +
            'color: #142A56" class="logLbl">Which dispensation for processing special categories of personal is used?</label>';
        this.decisionMakingSpecialCategories.isFirstRun = true;
        this.DecisionMakingSpecialCategoriesDialog.show();
    }

    observationActionDialogClose() {
        if (this.showWheel == true) {
            this.childW.refresh = true;
        }
        if (this.showWheel == false) {
            this.childD.refresh = true;
        }
        this.categoryField = undefined;
    }

    isComplianceManager: boolean = false;
    isDataController: boolean = false;
    isAdmin: boolean = false;
    bpHeader;

    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }

    public load(args: ILoadedEventArgs): void {
        let selectedTheme: string = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = <ProgressTheme>(selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark');
        if (args.progressBar.theme === 'Material') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.labelStyle.fontFamily = 'Rubik'
            args.progressBar.labelStyle.fontWeight = '500'
            args.progressBar.progressColor = '#5AA9EF'
            args.progressBar.trackColor = '#0C2B57'
        }
        if (selectedTheme === 'Contrast') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.trackColor = '#0C2B57'
        }
    }

    isShouldRender(field: DocumentationCategoryField, fields: any[]): boolean {
        let parent;
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId).length > 0) {
                    parent = fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId)
                    break;
                }
            }
            if (parent)
                if (parent[0].shouldRender)
                    if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                        return true;
                    }
        }
        else return false;
    }

    public valueAccess = (field: string, data: Object, column: Object) => {
        if (data['fieldData'] !== null)
            return data['fieldData'];
    }

    getPdTypeData(data: DocumentationSubCategory[], type: number, parent: string): any[] {
        if (data !== null && data !== undefined && parent !== undefined) {
            return data.filter(u => u.tabLevel === type && u.parentDocumentationSubCategoryId === parent);
        }
    }

    getUsers(data: DocumentationSubCategory[], type: number): any[] {
        if (data !== null && data !== undefined) {
            return data.filter(u => u.tabLevel === type);
        }
    }

    getSupportingApplication(data: DocumentationSubCategory[], parentSubcategory: string, functionName: string) {
        if (data !== null && data !== undefined && this.decisionMakingObjects !== undefined && this.decisionMakingObjects !== null && this.decisionMakingObjects.length > 0) {
            var objectId = data.filter(x => x.documentationSubCategoryId == parentSubcategory)[0].modelConceptId;
            var application = this.decisionMakingObjects.filter(x => x.objectId == objectId && functionName == functionName)[0].applicationName;
            return application;
        }
    }

    getDecisionMakerSubCategory(data: DocumentationSubCategory[], parentSubcategory: string) {
        if (data !== null && data !== undefined) {
            var decisionSubCategory = data.filter(u => u.tabLevel === 4
                && u.parentDocumentationSubCategoryId === parentSubcategory);
            return decisionSubCategory;
        }
    }

    getFields(data, type) {
        if (data !== null && data !== undefined) {
            this.tableFields = data.filter(x => x.typeId == type);
            if (this.tableFields.length > 0)
                return this.tableFields[0].personalDataInfoValues;
            else return undefined;
        }
    }

    deletePersonalDataType(e: ClickEventArgs, type, isStandard, fieldId) {
        if (e.text) {
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {
                let index = e.data["value"];
                if (index) {
                    var dataToReturn = {
                        id: Number(index),
                        name: e.data["text"].replace("+ ", ""),
                        isStandard: isStandard,
                        personalDataType: type,
                        isEnabled: true,
                        recipientId: fieldId,
                        isCurrentlySelected: false
                    }
                    if (isStandard) {
                        this.dataTransferService.deleteLinkPersonalDataType(fieldId, Number(index), type, this.versionNo);
                    }
                    if (!isStandard) {
                        this.dataTransferService.deselectPersonalDataType(Number(index));
                    }
                    this.pdComponent.type = type;
                    if (isStandard) {
                        if (this.pdComponent.personalData !== undefined) {
                            this.pdComponent.personalData.filter(x => x.typeId == type)[0].personalDataInfoValues.push(dataToReturn);
                        }
                    }
                    if (!isStandard) {
                        if (this.pdComponent.deselectedEnabledData !== undefined) {
                            this.pdComponent.deselectedEnabledData.push(dataToReturn);
                        }
                    }
                }
            }
        }
    }


    getCategories() {
        let customerId = sessionStorage.getItem("customerId");
        this.legalBasisServise.getCategoryById(this.categoryId, this.bpId, this.versionNo, this.viewId, customerId).subscribe(res => {
            this.defaultCategoryData = res;
            let fields = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields);

            this.tableFields = this.defaultCategoryData["documentationSubCategories"].filter(x => x.tabLevel == 3)
                .map(x => x.documentationCategoryFields);

            for (var i = 0; i < fields.length; i++) {
                for (var j = 0; j < fields[i].length; j++) {
                    fields[i][j].shouldRender = this.isShouldRender(fields[i][j], fields);
                }
            }
            this.recalculateProgress()
        })
    }

    recalculateProgress() {
        let fields = this.defaultCategoryData["documentationSubCategories"].filter(x => x.tabLevel !== 3).map(x => x.documentationCategoryFields);
        let tableFieldData = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel === 3)
            .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
        let wholeRowsToCalculate = 6 * tableFieldData.length;
        let filledCounter = 0;
        let unfilledCounter = 0;
        let fieldsToRenderCounter = 0;

        for (var i = 0; i < fields.length; i++) {
            for (var j = 0; j < fields[i].length; j++) {

                if (fields[i][j].shouldRender === true) {
                    //if yes then check if field filled
                    if (fields[i][j].fieldValue.value != null && fields[i][j].fieldValue.value != '' && fields[i][j].fieldValue.value != 'undefined') {
                        filledCounter = filledCounter + 1;
                    }
                    //for the whole amount of needed fields
                    fieldsToRenderCounter = fieldsToRenderCounter + 1;
                }
            }
        }

        for (var k = 0; k < tableFieldData.length; k++) {
            unfilledCounter += tableFieldData[k].fieldData.comment == null ? 1 : 0;
            unfilledCounter += tableFieldData[k].fieldData.explainText == null ? 1 : 0;
            unfilledCounter += tableFieldData[k].fieldData.otherLegislation == null ? 1 : 0;
            unfilledCounter += tableFieldData[k].fieldData.whichLegislation == null ? 1 : 0;
            unfilledCounter += tableFieldData[k].legalBasisProcessing.length == 0 ? 1 : 0;
            unfilledCounter += tableFieldData[k].personalDataTypes
                .map(x => x.personalDataInfoValues)
                .reduce(function (a, b) { return a.concat(b); }, []).length == 0 ? 1 : 0;
        }
        this.wholeFields = fieldsToRenderCounter;
        this.filledFields = filledCounter;
        let value1 = (this.filledFields / this.wholeFields) * 100
        let value2 = (wholeRowsToCalculate - unfilledCounter) / wholeRowsToCalculate * 100;
        this.value1 = (value1 + value2) / 2;
        this.labelStyle1 = { text: 'You have completed ' + Math.round(this.value1) + '% of ' + this.max + '%' };
        hideSpinner((document as any).getElementById('loadingContainer'));
    }

    formSave() {
        if (this.defaultCategoryData["progress"].progress === 100 && this.isFinal == false)
            this.SubmitFinalDialog.show();
        else if (this.defaultCategoryData["progress"].progress !== 100 || this.isFinal == true)
            this.submitCategory(0);
        else if (this.defaultCategoryData["progress"].progress == 100 && this.isFinal)
            this.submitCategory(0)
    }


    getControlFrequencies() {
        this.mainService.getControlFrequencyList().subscribe(data => {
            this.controlFrequency = data;
        })
    }

    frequencyChanged(data: any) {
        this.generalFrequency = data;
        var d = new Date();
        var newDate = new Date(d.setMonth(d.getMonth() + data.itemData.monthsToAdd));
        document.getElementById('nextDeadline').textContent = newDate.toISOString().slice(0, 10);
    }

    submitFrequency() {
        var bp = sessionStorage.getItem("bpId");
        this.mainService.changeFrequency(bp, this.categoryId, this.generalFrequency.itemData, this.versionNo)
        this.FrequencyDialog.hide();
    }

    submitCategory(categoryType: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [0]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        this.generalInfoService.saveCategoryWithType(categoryType, this.categoryId, this.bpId, this.versionNo, fields)
            .pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        if (categoryType == 2) this.isFinal = true;
        this.recalculateProgress()
        this.updateCategoryProgress()
    }

    setCategoryTypeSelected(typeid) {
        this.categoryType = typeid;
    }

    categorySbmtChoice() {
        if (this.categoryType === undefined) {
            this.categoryType = 1;
        }
        this.SubmitFinalDialog.hide();
        if (this.categoryType === 2)
            this.FrequencyDialog.show();
        this.submitCategory(this.categoryType)
    }

    updateCategoryProgress() {
        var bpId = sessionStorage.getItem("bpId");
        let categoryProgress = this.defaultCategoryData["progress"];
        categoryProgress.documentationCategoryId = this.defaultCategoryData["documentationCategoryId"];
        categoryProgress.businessProcessId = bpId;
        categoryProgress.progress = Math.round(this.value1);
        categoryProgress.versionNo = this.versionNo;

        this.generalInfoService.addUpdateCategory(categoryProgress).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
    }

    fieldValueChanged(field, args, tabLevel, index) {
        field.fieldValue.value = args;
        this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == tabLevel)
            .map(x => x.documentationCategoryFields)[index]
            .filter(x => x.documentationCategoryFieldId == field.documentationCategoryFieldId)[0].hasChanged = true;
        this.formSaveOnTabStatic(index, tabLevel);
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress()
    }

    fieldValueChangedDecisionMaking(field, args) {
        field.fieldValue.value = args;
        var fields = [];
        fields.push(field.fieldValue);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress()
    }

    formSaveOnTabStatic(index: number, tabLevel: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == tabLevel)
            .map(x => x.documentationCategoryFields)[0]
            .filter(x => x.hasChanged == true).map(x => x.fieldValue);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
    }

    formSaveOnTab(index: number, tabLevel: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == tabLevel)
            .map(x => x.documentationCategoryFields)[index]
            .filter(x => x.hasChanged == true).map(x => x.fieldValue);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
    }

    handleChange(field, evt, index, tabLevel) {
        this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == tabLevel)
            .map(x => x.documentationCategoryFields)[index]
            .filter(x => x.documentationCategoryFieldId == field.documentationCategoryFieldId)[0].hasChanged = true;
        this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == tabLevel)
            .map(x => x.documentationCategoryFields)[index]
            .filter(x => x.documentationCategoryFieldId == field.documentationCategoryFieldId)[0].fieldValue.value = evt;
        this.fields = this.defaultCategoryData["documentationSubCategories"]
            .find(x => x.documentationSubCategoryId == field.documentationSubCategoryId).documentationCategoryFields;
        for (var i = 0; i < this.fields.length; i++) {
            this.fields[i].shouldRender = false;
        }

        for (var i = 0; i < this.fields.length; i++) {
            this.fields[i].shouldRender = this.isShouldRenderLocal(this.fields[i], this.fields);
        }
        this.formSaveOnTab(index, tabLevel);
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress()
    }

    handleChangeImportance(field, evt, idx) {
        field.fieldValue.value = evt;
        var fields = [];
        fields.push(field.fieldValue);
        this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress();

        this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.documentationSubCategoryId == field.documentationSubCategoryId)[0]
            .documentationCategoryFields.filter(x => x.parentDocumentationCategoryFieldId == field.documentationCategoryFieldId)
            .forEach(element => {
                element.shouldRender = this.isShouldRenderLocalByParent(element, field);
            });
        this.isShouldRenderLocalByParent(this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.documentationSubCategoryId == field.documentationSubCategoryId)[0]
            .documentationCategoryFields.filter(x => x.parentDocumentationCategoryFieldId == field.documentationCategoryFieldId)[0], field);
        if (evt == "Yes") {
            this.importanceIndex = idx;
            this.importanceFieldId = field.documentationCategoryFieldId;
            this.existingImportanceModelData = this.existingBpImportanceModelData
                .filter(x => x.fieldId == field.documentationCategoryFieldId);
            this.showDecisionMakingImportanceDialog();
        }
        if (evt == "No") {
            var subcategoryId = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
                .filter(x => x.documentationCategoryFieldId == field.documentationCategoryFieldId)[0].documentationSubCategoryId;
            this.defaultCategoryData["documentationSubCategories"]
                .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = false;
        }
    }

    handleChangeSpecialCategory(field, evt) {
        field.fieldValue.value = evt;
        var fields = [];
        fields.push(field.fieldValue);
        this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress();
        this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.documentationSubCategoryId == field.documentationSubCategoryId)[0]
            .documentationCategoryFields.filter(x => x.parentDocumentationCategoryFieldId == field.documentationCategoryFieldId)[0].shouldRender =
            this.isShouldRenderLocalByParent(this.defaultCategoryData["documentationSubCategories"]
                .filter(x => x.documentationSubCategoryId == field.documentationSubCategoryId)[0]
                .documentationCategoryFields.filter(x => x.parentDocumentationCategoryFieldId == field.documentationCategoryFieldId)[0], field);
        if (evt == "Yes") {
            this.specialCategoryFieldId = field.documentationCategoryFieldId;
            this.existingSpecialCategoriesModelData = this.existingBpSpecialCategoriesModelData
                .filter(x => x.fieldId == field.documentationCategoryFieldId);
            this.showDecisionMakingSpecialCategoriesDialog();
        }
        if (evt == "No") {

        }
    }

    isShouldRenderLocal(field: DocumentationCategoryField, fields): boolean {
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            var parent = fields.filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId);
            if (parent[0].shouldRender)
                if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                    return true;
                }
        }
        else return false;
    }

    isShouldRenderLocalByParent(field: DocumentationCategoryField, parent: DocumentationCategoryField): boolean {
        this.fields = this.defaultCategoryData["documentationSubCategories"]
            .map(x => x.documentationCategoryFields);
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            if (parent.shouldRender)
                if (parent.type == 2 && parent.fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                    return true;
                }
        }
        else return false;
    }

    showObservationBtn(id: number) {
        this.controlId = id;
        this.controlStarted = true;
    }

    controlEventRemoved(id) {
        if (this.controlId !== undefined) {
            if (this.controlId == id) {
                this.controlStarted = false;
            }
        }
    }

    previewReport(id: number) {
        this.controlId = id;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        })
        this.ReportDialog.show();
        this.showReport = true;
        this.childCR.refresh = true;
    }

    closePreview(exitControlMode) {
        this.ReportDialog.hide();
        if (exitControlMode === true) {
            this.controlStarted = false;
            if (this.showWheel == true) {
                this.childW.refresh = true;
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
            if (this.showWheel == false) {
                this.childD.refresh = true;
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
        }
    }

    closeAndPreview() {
        this.Dialog.hide();
        this.childCR.refresh = true;
        this.showReport = true;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        });
        if (this.showWheel == true)
            this.refresh = true;
        if (this.showWheel == false)
            this.refreshDiscretionary = true;
        this.ReportDialog.show();
    }

    closeLog() {
        this.MDialog.hide();
    }

    hideObservationBtn() {
        var addObservationActionButtons = document.getElementsByClassName("observationActionAdd") as HTMLCollectionOf<HTMLElement>;;
        for (var i = 0; i < addObservationActionButtons["length"]; i++) {
            addObservationActionButtons[i].style.display = "none";
        }
    }

    beforeRenderSF(e, field) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipsf_' + field + '');
        currentTooltip[0].content = e.target.innerText;
    }
    beforeRenderPD(e, field, type, index) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipPD_' + field + '_' + type + '_' + index + '');
        currentTooltip[0].content = e.target.innerText;
    }
    beforeRenderSFExisting(e, field) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipsfex_' + field + '');
        currentTooltip[0].content = e.target.innerText;
    }

    deleteLegalBasisForProcessing(e: ClickEventArgs, field) {
        if (e.text) {
            this.legalBasisServise.RemoveLegalBasisForProcessingFieldLink(field, e.data["value"]).subscribe();
            this.lbProcessing.RevertAdd(e.data["value"])
        }
    }
}
