import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { AdministrationService } from "src/app/shared/services/admin.service";
import { OrganizationThreatsComponent } from "../threats/threats.component";
export interface PreviousInput {
    organizationId: string,
    threatId: number,
    isCustom: boolean
}
@Component({
    selector: 'threat-rules',
    styleUrls: ['threat-rules.css'],
    templateUrl: 'threat-rules.html',
    encapsulation: ViewEncapsulation.None
})

export class ThreatRulesComponent implements OnInit {
    constructor(public service: AdministrationService, private parent: OrganizationThreatsComponent) { }
    public ruleGroups: any[];
    public weights: any[];
    public filteredRules: any[];
    @Input() threatId: number;
    @Input() organizationId: string;
    @Input() isCustom: boolean;
    @Input() threatRules: any[];
    @ViewChild('gridThreatRules')
    public grid?: GridComponent;
    public isFirstRun: boolean;
    public ddlfields: Object = { text: 'groupName', value: 'groupId' };
    public weightfields: Object = { text: 'name', value: 'id' };
    public org: string;
    public prevInput: PreviousInput;
    public currentinput: PreviousInput;
    refresh: boolean;

    ngOnInit(): void {
        this.refresh = false;
        this.org = sessionStorage.getItem("customerId");
        this.isFirstRun = true;
        this.getWeights();
    }

    getRuleGroups() {
        this.service.getRules(this.org, this.threatId, this.isCustom).subscribe(data => {
            this.ruleGroups = data;
        })
    }

    ngAfterViewChecked(): void {
        if (this.org && this.threatId && this.isCustom !== undefined) {
            this.currentinput = {
                organizationId: this.org,
                isCustom: this.isCustom,
                threatId: this.threatId
            }
            if (!this.prevInput || (this.prevInput.organizationId !== this.currentinput.organizationId &&
                this.prevInput.isCustom !== this.currentinput.isCustom &&
                this.prevInput.threatId) || this.refresh) {
                this.prevInput = this.currentinput;
                this.getRuleGroups();
                this.refresh = false;
            }
        }
    }

    getWeights() {
        this.service.getWeights().subscribe(data => {
            this.weights = data;
        })
    }

    linkExists(data: any) {
        return data.organizationId === this.org && data.threatId === this.threatId && data.isCustom === this.isCustom;
    }

    filterGrid(searchValue: string): void {
        const searchTerm = searchValue.trim().toLowerCase();
        if (searchTerm) {
            const filteredData = this.filteredRules.filter(rule =>
                rule.ruleName.toLowerCase().includes(searchTerm)
            );
            this.grid.dataSource = filteredData;
        } else {
            this.grid.dataSource = [...this.filteredRules];
        }
    }

    selectGroup(args) {
        var groupId = args.itemData.groupId;
        this.filteredRules = this.ruleGroups.filter(x => x.groupId == groupId)[0].rules;
    }

    selectWeight(args) {

    }

    linkRule(args, data) {
        if (args.checked) {
            var model = {
                threatId: this.threatId,
                rule: {
                    ruleId: data.ruleId
                },
                organizationId: this.organizationId,
                weight: {
                    id: data.threatRules.weight.id
                },
                isCustom: this.isCustom
            }
            this.service.LinkThreatRule(model).subscribe(val => {
                let cssDataClass: string
                switch (data.threatRules.weight.id) {
                    case 1: {
                        cssDataClass = "e-danger-chip";
                        break;
                    }
                    case 2: {
                        cssDataClass = "e-warning-chip";
                        break;
                    }
                    case 3: {
                        cssDataClass = "e-success-chip";
                        break;
                    }
                }
                var chip = {
                    text: data.ruleName,
                    value: val,
                    cssClass: `custom-chips ${cssDataClass}`
                }
                var chipListName = 'threatChipsList_' + this.threatId + '';
                var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
                currentChip[0].add(chip);
                this.parent.grid.refresh();
            });
        }

        if (!args.checked) {
            this.service.deleteThreatRule(data.threatRules.threatRulesId).subscribe();
        }
    }
}