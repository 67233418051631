import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import auth from '../../auth-config.json';
import { DocumentationCategory } from "src/app/interfaces/DocumentationCategory";
import { ApplicationsWithTypeData } from "src/app/interfaces/RightsOfDataSubjects/ApplicationsWithTypeData";

@Injectable({
  providedIn: 'root'
})
export class RightsOfDataSubjectsService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394";
  constructor(private http: HttpClient) { }

  private createHedader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getCategoryById(categoryId: string, bpId: string, versionNo: number, viewId: string, customerId: string): Observable<DocumentationCategory[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/Get?documentationCategoryId=" +
      categoryId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "&viewId=" + viewId +
      "&customerId=" + customerId + "";
    return this.http.get<DocumentationCategory[]>(Url);
  }
  getApplications(categoryId: string, bpId: string, viewId: string, customerId: string, versionNo: number) {
    var Url = this.url + "/api/RightsOfDataSubjects/GetApplications?documentationCategoryId=" + categoryId
      + "&businessProcessId=" + bpId
      + "&viewId=" + viewId +
      "&customerId=" + customerId + "&versionNo=" + versionNo + "";
    return this.http.get<ApplicationsWithTypeData[]>(Url);
  }

  getErasureModels(): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetErasureExplainList";
    return this.http.get<any[]>(Url);
  }

  getExistingErasureModels(fieldId: string, versionNo: number): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetExistingErasureList?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
    return this.http.get<any[]>(Url);
  }
  disableErasure(fieldId: string, versionNo: number, erasureId: number) {
    var Url = this.url + "/api/RightsOfDataSubjects/DisableErasure?fieldId="
      + fieldId + "&versionNo=" + versionNo + "&erasureId=" + erasureId + "";
    return this.http.delete(Url);
  }
  enableErasure(fieldId: string, versionNo: number, erasure: any) {
    var Url = this.url + "/api/RightsOfDataSubjects/EnableErasure?fieldId="
      + fieldId + "&versionNo=" + versionNo + "";
    return this.http.post(Url, JSON.stringify(erasure), this.createHedader()).subscribe();
  }

  getRestrictionsModels(): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetRestrictionsExplainList";
    return this.http.get<any[]>(Url);
  }

  getExistingRestrictionsModels(fieldId: string, versionNo: number): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetExistingRestrictionsList?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
    return this.http.get<any[]>(Url);
  }
  disableRestriction(fieldId: string, versionNo: number, restrictionId: number) {
    var Url = this.url + "/api/RightsOfDataSubjects/DisableRestriction?fieldId="
      + fieldId + "&versionNo=" + versionNo + "&restrictionId=" + restrictionId + "";
    return this.http.delete(Url);
  }
  enableRestriction(fieldId: string, versionNo: number, erasure: any) {
    var Url = this.url + "/api/RightsOfDataSubjects/EnableRestriction?fieldId="
      + fieldId + "&versionNo=" + versionNo + "";
    return this.http.post(Url, JSON.stringify(erasure), this.createHedader()).subscribe();
  }
  getRestrictionsToTheRightsExplainList(): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetRestrictionsToTheRightsExplainList";
    return this.http.get<any[]>(Url);
  }

  getExistingRestrictionsToTheRightsList(fieldId: string, versionNo: number): Observable<any[]> {
    var Url = this.url + "/api/RightsOfDataSubjects/GetExistingRestrictionsToTheRightsList?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
    return this.http.get<any[]>(Url);
  }
  disableRestrictionToTheRights(fieldId: string, versionNo: number, restrictionId: number) {
    var Url = this.url + "/api/RightsOfDataSubjects/DisableRestrictionToTheRights?fieldId="
      + fieldId + "&versionNo=" + versionNo + "&restrictionId=" + restrictionId + "";
    return this.http.delete(Url);
  }
  enableRestrictionToTheRights(fieldId: string, versionNo: number, erasure: any) {
    var Url = this.url + "/api/RightsOfDataSubjects/EnableRestrictionToTheRights?fieldId="
      + fieldId + "&versionNo=" + versionNo + "";
    return this.http.post(Url, JSON.stringify(erasure), this.createHedader()).subscribe();
  }
}