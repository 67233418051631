// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/admin/data-management/admin-rule-book/admin-rule-book.component.ts"],"names":[],"mappings":";IACI;MACE,yBAAyB;IAC3B;IACA;MACE,SAAS;MACT,SAAS;IACX","sourcesContent":[" \n    .background-grey{ \n      background-color: #F0F1F6; \n    } \n    .highlight{\n      padding:0;\n      width:4px;\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
