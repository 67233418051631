import { Injectable } from '@angular/core';
import auth from '../../auth-config.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, Observer, throwError } from "rxjs";
import { Organizations } from 'src/app/interfaces/Organization/Organizations';
import { OrganizationSectors } from 'src/app/interfaces/Organization/OrganizationSectors';
import { OrganizationIndustries } from 'src/app/interfaces/Organization/OrganizationIndustries';
import { OrganizationPublicServices } from 'src/app/interfaces/Organization/OrganizationPublicServices';
import { OrganizationFunctionGroups } from 'src/app/interfaces/Organization/OrganizationFunctionGroups';
import { Countries } from 'src/app/interfaces/Organization/Countries';
import { Currencies } from 'src/app/interfaces/Organization/Currencies';
import { Organization } from 'src/app/interfaces/Organization/lei/Organization';
import { EntityTypes, LicensedActivity } from 'src/app/interfaces/Organization/EntityTypes';
import { Impact, OrganizationFunctions, TimeCritical } from 'src/app/interfaces/Organization/OrganizationFunctions';
import { OrganizationFieldValues, OrganizationFields } from 'src/app/interfaces/Organization/OrganizationFields';
import { OrganizationsShortInfo } from 'src/app/interfaces/Organization/OrganizationsShortInfo';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394";
  leiUrl = "https://api.gleif.org"
  constructor(private http: HttpClient) { }

  private createHeader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getOrganizationInfo(customerId: string) {
    var Url = this.url + "/api/organizationInfo?customerId=" + customerId + "";
    return this.http.get<Organizations>(Url);
  }
  getOrganizationsList() {
    var Url = this.url + "/api/organizations";
    return this.http.get<OrganizationsShortInfo>(Url);
  }
  getOrganizationSectors() {
    var Url = this.url + "/api/organizationSectors";
    return this.http.get<OrganizationSectors[]>(Url);
  }

  getOrganizationIndustries(): Observable<OrganizationIndustries[]> {
    return new Observable((observer: Observer<OrganizationIndustries[]>) => {
      const url = this.url + '/api/organizationIndustries';

      // Simulate an asynchronous operation, such as an HTTP request
      this.http.get<OrganizationIndustries[]>(url)
        .pipe(
          catchError((error: any) => {
            console.error('HTTP request error:', error);
            observer.error(error); // Forward the error to the observer
            return throwError(error); // Continue throwing the error for further handling
          })
        )
        .subscribe((data: OrganizationIndustries[]) => {
          console.log('HTTP request successful. Data received:', data);
          observer.next(data); // Emit the received data
          observer.complete(); // Complete the observable after emitting values
        });
    });
  }

  getOrganizationPublicServices() {
    var Url = this.url + "/api/organizationPublicServices";
    return this.http.get<OrganizationPublicServices[]>(Url);
  }

  getOrganizationFunctions(): Observable<any[]> {
    var Url = this.url + `/api/organizationFunctions`;
    return this.http.get<OrganizationFunctionGroups[]>(Url);
  }

  getExistingOrganizationFunctions(organizationId: string): Observable<any[]> {
    var Url = this.url + `/api/existing-functions/${organizationId}`;
    return this.http.get<OrganizationFunctionGroups[]>(Url);
  }

  isShowFunctions(organizationId: string) {
    var Url = this.url + `/api/show-functions/${organizationId}`;
    return this.http.get<boolean>(Url);
  }

  updateOrganizationData(organization: Organizations) {
    var Url = this.url + "/api/organizationData";
    var data = JSON.stringify(organization);
    return this.http.put(Url, data, this.createHeader());
  }

  resetFunctions(organizationId: string) {
    var Url = this.url + `/api/reset-functions/${organizationId}`;
    return this.http.get(Url);
  }


  resetThreats(organizationId: string) {
    var Url = this.url + `/api/organization-threats/${organizationId}`;
    return this.http.delete(Url);
  }

  updateOrganizationLogo(organizationId: string, image: string) {
    const formData = {
      organizationId: organizationId,
      image: image
    };
    var Url = this.url + '/api/logo';
    return this.http.put(Url, formData);
  }

  updateAssetData(types: EntityTypes, organizationId: string) {
    var Url = this.url + `/api/asset/${organizationId}`;
    return this.http.put(Url, JSON.stringify(types), this.createHeader());
  }

  updateFinancialIndicatorData(types: EntityTypes, organizationId: string) {
    var Url = this.url + `/api/financial-indicator/${organizationId}`;
    return this.http.put(Url, JSON.stringify(types), this.createHeader());
  }

  saveFieldChanged(field: OrganizationFieldValues) {
    var Url = this.url + '/api/organization-field';
    var data = JSON.stringify(field);
    return this.http.post(Url, data, this.createHeader());
  }

  removeFieldValue(fieldValueId: string) {
    var Url = this.url + `/api/organization-field/${fieldValueId}`;
    return this.http.delete(Url);
  }

  deleteFunction(functionId: number, organizationId: string, isCustom: boolean) {
    var Url = this.url + `/api/function?functionId=${functionId}&organizationId=${organizationId}&isCustom=${isCustom}`;
    return this.http.delete(Url).subscribe();
  }

  addFunction(subFunction: OrganizationFunctions, organizationId: string) {
    var Url = this.url + `/api/function/${organizationId}`;
    var data = JSON.stringify(subFunction);
    return this.http.post(Url, data, this.createHeader());
  }

  updateFunction(subFunction: OrganizationFunctions) {
    var Url = this.url + `/api/function`;
    var data = JSON.stringify(subFunction);
    return this.http.put(Url, data, this.createHeader());
  }

  submitFunctions(functions: any, organizationId: string) {
    var Url = this.url + `/api/functions/${organizationId}`;
    var data = JSON.stringify(functions);
    return this.http.post<any>(Url, data, this.createHeader());
  }

  getCountries() {
    var Url = this.url + "/api/countries";
    return this.http.get<Countries[]>(Url);
  }

  getLicensedActivity(organizationId: string) {
    var Url = this.url + `/api/licensed-activities/${organizationId}`;
    return this.http.get<LicensedActivity[]>(Url);
  }

  linkLicensedActivity(activity: any, data: any) {
    var Url = this.url + "/api/licensed-activity";
    var model = {
      function: data,
      licensedActivity: activity
    }
    return this.http.post(Url, JSON.stringify(model), this.createHeader());
  }

  removeLicensedActivity(licensedActivityId: number, functionId: number, isCustom: boolean) {
    var Url = this.url + `/api/licensed-activity?licensedActivityId=${licensedActivityId}&functionId=${functionId}&isCustom=${isCustom}`;
    return this.http.delete(Url);
  }

  removeFunctionsLicencedActivities(organizationId: string) {
    var Url = this.url + `/api/licensed-activities/${organizationId}`;
    return this.http.delete(Url);
  }

  getCurrencies() {
    var Url = this.url + "/api/currencies";
    return this.http.get<Currencies[]>(Url);
  }

  getImpactOptions() {
    var Url = this.url + "/api/impact";
    return this.http.get<Impact[]>(Url);
  }

  getTimeCriticalOptions() {
    var Url = this.url + "/api/timecritical";
    return this.http.get<TimeCritical[]>(Url);
  }

  getEntityTypes() {
    var Url = this.url + "/api/entityTypes";
    return this.http.get<EntityTypes[]>(Url);
  }

  getOrganizationByLeiCode(lei: string) {
    var url = this.leiUrl + "/api/v1/lei-records/" + lei + "";
    return this.http.get<Organization>(url);
  }
}

