import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
    selector: 'bp-details-content',
    styleUrls: ['bp-details-layout.css'],
    templateUrl: 'bp-details-layout.html',
    encapsulation: ViewEncapsulation.None
})
export class DetailsLayoutComponent implements OnInit, AfterViewInit {
    bpId: string;
    versionNo: any;
    constructor(private route: ActivatedRoute, private generalInfo: GeneralInfoService, private riskService: RiskService,
        private router: Router) { }
    ngAfterViewInit(): void {
        this.GetAllSubmittedInfo();
    }
    processId;
    categories = [];
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
        this.getCategoriesList();
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.processId = id;
                var storedData;
                if (sessionStorage.getItem("bpId")) {
                    storedData = sessionStorage.getItem("bpId");
                    if (storedData !== id) {
                        sessionStorage.removeItem("threatData")
                        sessionStorage.removeItem("appData")
                        sessionStorage.removeItem("bpId")
                        sessionStorage.removeItem("dpia")
                        sessionStorage.removeItem("sbmtInfo")
                        sessionStorage.removeItem("legalBasisApps")
                        sessionStorage.setItem("bpId", id);
                    }
                }
                else {
                    sessionStorage.removeItem("threatData")
                    sessionStorage.removeItem("appData")
                    sessionStorage.removeItem("bpId")
                    sessionStorage.removeItem("dpia")
                    sessionStorage.removeItem("sbmtInfo")
                    sessionStorage.removeItem("legalBasisApps")
                    sessionStorage.setItem("bpId", id);
                }
            }
        })
        // this.GetThreatData();
    }

    GetAllSubmittedInfo() {
        if (!sessionStorage.getItem("sbmtInfo")) {
            this.bpId = sessionStorage.getItem("bpId")
            var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
            this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
            this.riskService.getAllBpSubmitInfo(sessionStorage.getItem("bpId"), this.versionNo)
                .subscribe(data => {
                    sessionStorage.setItem("sbmtInfo", JSON.stringify(data));
                })
        }
    }

    getCategoriesList() {
        // if (sessionStorage.getItem("categories")) {
        //     this.categories = JSON.parse(sessionStorage.getItem("categories"));
        // }
        // else {
            this.generalInfo.getAllCategoriesListByBusinessProcess(sessionStorage.getItem("bpId"), this.versionNo).subscribe(res => {
                try {
                    if (res !== null && res.length > 0) {
                        for (var i = 0; i < res.length; i++) {
                            switch (res[i].name) {
                                case 'General information': {
                                    res[i].routerLink = "general-info/" + res[i].documentationCategoryId + "";
                                    break;
                                }
                                case 'Data transfers': {
                                    res[i].routerLink = "data-transfers-layout/" + res[i].documentationCategoryId + "/data-transfers-grid";
                                    break;
                                }
                                case 'Security of processing': {
                                    res[i].routerLink = "security/entry-security";
                                    break;
                                }
                                case 'The legal basis for processing': {
                                    res[i].routerLink = "legal-basis/" + res[i].documentationCategoryId + "";
                                    break;
                                }
                                case 'Compliance with GDPR principles':{
                                    res[i].routerLink = "compliance-with-gdpr-principles/" + res[i].documentationCategoryId + "";
                                    break;
                                }
                                case 'The rights of data subjects': {
                                    res[i].routerLink = "rights-of-data-subjects/" + res[i].documentationCategoryId + "";
                                    break;
                                }
                                default: {
                                    res[i].routerLink = "/home"
                                    break;
                                }
                            }
                        }
                        this.categories = res;
                    }
                }
                catch (e) {

                }
            });
        //}
    }
    hideMenu() {
        document.getElementById('menu-secondary').style.display = 'none';
        document.getElementById('menu-secondary-content').className = 'col-12'
    }
    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }
}
