import { Component, ViewEncapsulation, OnInit, Input, AfterViewChecked } from "@angular/core";
import { BasicInfoComponent } from "../basic-info.component";

@Component({
    selector: 'licensed-activities',
    templateUrl: './licensed-activities.html',
    styleUrls: ['licensed-activities.css'],
    encapsulation: ViewEncapsulation.None,
})
export class LicensedActivityComponent implements OnInit, AfterViewChecked {
    constructor(private parent: BasicInfoComponent) { }

    @Input() ind: number;
    @Input() model: any;
    @Input() activities: any;
    @Input() etType: number;
    isFirstCall: boolean;
    prevEtType: number;
    prevFuncId: number;

    licensedActivities: any;
    ngOnInit(): void {
        this.isFirstCall = true;
        this.licensedActivities = this.activities;
    }

    ngAfterViewChecked(): void {
        if ((this.isFirstCall || this.etType !== this.prevEtType || this.model.functionId !== this.prevFuncId)
            && this.activities !== undefined && this.model !== undefined) {
            this.isFirstCall = false;
            this.licensedActivities = [];
            var newActivities = this.activities.map(activity => {
                return { ...activity, functionDisplayId: null };
            });
            this.licensedActivities = newActivities;

            this.prevEtType = this.etType;
            this.prevFuncId = this.model.functionId;
            if (this.activities.length > 0) {
                this.activities.forEach(element => {
                    if (document.getElementById(`chevron_${element.licensedActivityId}${this.model.functionId}`) !== undefined)
                        document.getElementById(`chevron_${element.licensedActivityId}${this.model.functionId}`).style.display = "none"
                });
                if (this.model.licensedActivities.length > 0) {
                    this.model.licensedActivities.forEach(element => {
                        if (document.getElementById(`chevron_${element.licensedActivityId}${this.model.functionId}`) !== undefined) {
                            document.getElementById(`chevron_${element.licensedActivityId}${this.model.functionId}`).style.display = "block"
                        }
                    });
                }
            }
        }
    }

    linkLA(la) {
        if (document.getElementById(`chevron_${la.licensedActivityId}${this.model.functionId}`).style.display == "block") {
            document.getElementById(`chevron_${la.licensedActivityId}${this.model.functionId}`).style.display = "none";
        }
        else {
            document.getElementById(`chevron_${la.licensedActivityId}${this.model.functionId}`).style.display = "block";
        }
        this.parent.linkLA(la, this.model, this.ind);
    }
}