import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { LegalBasisComponent } from "../../legal-basis.component";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";

@Component({
    selector: 'decision-making-special-categories',
    styleUrls: ['decision-making-special-categories.css'],
    templateUrl: 'decision-making-special-categories.html',
})
export class DecisionMakingSpecialCategoriesComponent implements OnInit, AfterViewChecked {
    @Input() field: any;
    @Input() model: any;
    @Input() versionNo: number;
    @Input() index: number;
    @Input() subCategory: any;

    dmSpecialCategories: any[];
    isFirstRun: boolean;
    bpId: string;

    constructor(private service: LegalBasisService, private parent: LegalBasisComponent) { }
    ngOnInit(): void {
        this.getSpecialCategoriesList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId");
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.dmSpecialCategories !== undefined) {
                var removeItems = document.getElementsByClassName("removeSpecialCategoriesBtn");
                for (var i = 0; i < removeItems.length; i++) {
                    removeItems[i].setAttribute('style', 'display:none');
                }
                var addItems = document.getElementsByClassName("dmSpecialCategoriesAddBtn");
                for (var i = 0; i < addItems.length; i++) {
                    addItems[i].setAttribute('style', 'display:block');
                }
                if (this.model !== undefined && this.model.length > 0) {
                    for (var i = 0; i < this.model.length; i++) {
                        document.getElementById('dmSpecialCategoriesAddBtn_' + this.model[i].id + this.index + '').style.display = "none";
                        document.getElementById('removeSpecialCategoriesBtn_' + this.model[i].id + this.index + '').style.display = "block";
                    }
                }
                this.isFirstRun = false;
            }
        }
    }

    getSpecialCategoriesList() {
        this.service.getDecisionMakingSpecialCategoriesList().subscribe(x => {
            this.dmSpecialCategories = x;
        })
    }
    addSpecialCategories(item) {
        item.fieldId = this.field;
        document.getElementById('dmSpecialCategoriesAddBtn_' + item.id + this.index + '').style.display = "none";
        document.getElementById('removeSpecialCategoriesBtn_' + item.id + this.index + '').style.display = "block";
        this.service.EnableDecisionMakingSpecialCategory(this.field, this.bpId, this.versionNo, item).subscribe();
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        var chipListName = 'specialCategoriesChips_' + this.field + this.index + '';
        var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
        currentChip[0].add(chip)
        this.model.push(item);
        this.parent.existingBpSpecialCategoriesModelData.push(item);
        this.parent.defaultCategoryData["documentationSubCategories"]
        .filter(x => x.documentationSubCategoryId == this.subCategory.documentationSubCategoryId)[0]
        .specialCategoriesMatchCondition = this.model.some(x => x.selectType == 'a' || x.selectType == 'g');
    }

    disableSpecialCategories(id) {
        this.service.DisableDecisionMakingSpecialCategory(this.field, this.versionNo, id).subscribe();
        document.getElementById('removeSpecialCategoriesBtn_' + id + this.index + '').style.display = "none";
        document.getElementById('dmSpecialCategoriesAddBtn_' + id + this.index + '').style.display = "block";
        this.parent.existingBpSpecialCategoriesModelData.splice(this.parent.existingBpSpecialCategoriesModelData.findIndex(x => x.id == id && x.fieldId == this.field), 1);
        this.model.splice(this.model.findIndex(x => x.id == id), 1);
    }
}