import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'baseline-log',
    templateUrl: 'initial-baseline-tracking-log.html',
    styleUrls: ['initial-baseline-tracking-log.css']
})
export class InitialBaselineTrackingLogComponent implements OnInit, AfterViewChecked {
    public logData = [];
    public isFirstRun: boolean;
    public prevSubRule;
    public lines = true;
    public initialPage: Object;
    @Input() controlId: any;
    @Input() subRule: number;
    @Input() isCustom: boolean;
    @Input() refresh: boolean;
    ngOnInit(): void {
        this.isFirstRun = true;
        this.refresh = false;
        this.initialPage = { pageSizes: ['5', '7'], pageCount: 4, pageSize: 5 };
    }

    constructor(private service: DocMaintenanceService) { }
    ngAfterViewChecked(): void {
        if (this.subRule !== undefined) {
            if (this.isFirstRun === true && this.subRule !== this.prevSubRule) {
                this.getReport();
                this.isFirstRun = false;
                this.prevSubRule = this.subRule;
            }
            if (this.refresh === true) {
                this.getReport();
                this.refresh = false;
            }
        }
    }

    getReport() {
        this.service.getInitialtrackingLog(this.subRule, this.isCustom).subscribe(data => {
            this.logData = data;
            this.logData.forEach(x => {
                x.date = new Date(x.date).toISOString().slice(0, 10)
            })
        })
    }
}

