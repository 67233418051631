import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlEvent } from 'src/app/interfaces/Maintenance/ControlEvent';
import { ControlEventFrequency } from 'src/app/interfaces/Maintenance/ControlEventFrequency';
import { Observation } from 'src/app/interfaces/Maintenance/Observation';
import auth from '../../auth-config.json';
import { DocumentationQuality } from 'src/app/interfaces/Maintenance/DocumentationQuality';
import { MsalService } from '@azure/msal-angular';
import { InitialBaselineTrackingLog } from 'src/app/interfaces/Maintenance/InitialBaseline/InitialBaselineTrackingLog';

@Injectable({
    providedIn: 'root'
})
export class DocMaintenanceService {

    url = auth.resources.PDHubApi.resourceUri;
    localUrl = "https://localhost:44394";
    constructor(private http: HttpClient, private authService: MsalService) { }

    private createHedader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    getAllControlEvents(bpId: string, categoryId: string, versionNo: number): Observable<ControlEvent[]> {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "GetAllControlEvents?businessProcessId=" + bpId + "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get<ControlEvent[]>(Url);
    }

    getPlannedControlEvents(bpId: string, categoryId: string, frequency: number, versionNo: number): Observable<ControlEvent[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetPlannedControlEvents?businessProcessId=" + bpId +
            "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get<ControlEvent[]>(Url);
    }

    getCurrentControlEvent(bpId: string, categoryId: string, versionNo: number): Observable<ControlEvent> {
        var Url = this.url + "/api/DocumentationMaintenance/GetCurrentControlEvent?businessProcessId=" + bpId +
            "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get<ControlEvent>(Url);
    }

    getControlEventById(controlEventId: number): Observable<ControlEvent> {
        var Url = this.url + "/api/DocumentationMaintenance/GetControlEventById?controlEventId=" + controlEventId + "";
        return this.http.get<ControlEvent>(Url);
    }

    getDiscretionaryEvents(bpId: string, categoryId: string, versionNo: number): Observable<ControlEvent[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetDiscretionaryControlEvents?businessProcessId=" + bpId +
            "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get<ControlEvent[]>(Url);
    }

    getControlFrequencyList(): Observable<ControlEventFrequency[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetFrequencyList";
        return this.http.get<ControlEventFrequency[]>(Url);
    }

    getControlEventReasonList(): Observable<any[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetReasonList";
        return this.http.get<any[]>(Url);
    }

    getControlEventTriggerList(): Observable<any[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetTriggerList";
        return this.http.get<any[]>(Url);
    }

    getControlEventGetQualityList(): Observable<DocumentationQuality[]> {
        var Url = this.url + "/api/DocumentationMaintenance/GetQualityList";
        return this.http.get<DocumentationQuality[]>(Url);
    }

    changeDocumantationQuality(controlId: number, quality: any) {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "ChangeControlQuality?controlId=" + controlId + "";
        return this.http.put(Url, JSON.stringify(quality), this.createHedader()).subscribe();
    }

    changeControlReason(controlId: number, reason: any) {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "ChangeControlReason?controlId=" + controlId + "";
        return this.http.put(Url, JSON.stringify(reason), this.createHedader()).subscribe();
    }

    changeControlTrigger(controlId: number, trigger: any) {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "UpdateControlEventTrigger?controlId=" + controlId + "";
        return this.http.post(Url, JSON.stringify(trigger), this.createHedader()).subscribe();
    }

    changeFrequency(bpId: string, categoryId: string, frequency: ControlEventFrequency, versionNo: number) {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "ChangeFrequencyAndDate?businessProcessId=" + bpId + "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.put(Url, JSON.stringify(frequency), this.createHedader()).subscribe();
    }

    getControlArchiveReport(bpId: string, categoryId: string, versionNo: number): Observable<any> {
        var Url = this.url + "/api/DocumentationMaintenance/" +
            "GetControlEventArchiveReport?businessProcessId=" + bpId + "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get<any>(Url);
    }

    getMaxVersion(customerId: string) {
        var versionUrl = this.url + "/api/xml/version/" + customerId + "";
        return this.http.get(versionUrl);
    }

    addObservation(observation: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/AddObservation?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(observation), this.createHedader());
    }

    addAction(action: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/AddAction?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(action), this.createHedader());
    }

    addNewObservation(observation: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-observation?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(observation), this.createHedader());
    }

    addInitialObservation(observation: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-observation?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(observation), this.createHedader());
    }

    addNewAction(action: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-action?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(action), this.createHedader());
    }

    addInitialAction(action: any): Observable<number> {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-action?userName=" + username + "";
        return this.http.post<number>(url, JSON.stringify(action), this.createHedader());
    }

    deleteObservation(observationId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/DeleteObservation?observationId=" + observationId + "&userName=" + username + "";
        return this.http.get(url);
    }

    deleteAction(actionId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/DeleteAction?actionId=" + actionId + "&userName=" + username + "";
        return this.http.get(url);
    }

    deleteNewObservation(observationId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-observation?observationId=" + observationId + "&userName=" + username + "";
        return this.http.delete(url);
    }

    deleteInitialObservation(observationId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-observation?observationId=" + observationId + "&userName=" + username + "";
        return this.http.delete(url);
    }

    deleteNewAction(actionId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-action?actionId=" + actionId + "&userName=" + username + "";
        return this.http.delete(url);
    }

    deleteInitialAction(actionId: number) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-action?actionId=" + actionId + "&userName=" + username + "";
        return this.http.delete(url);
    }

    addDiscretionaryControlEvent(event: any) {
        var url = this.url + "/api/DocumentationMaintenance/AddDiscretionaryControlEvent";
        return this.http.post(url, JSON.stringify(event), this.createHedader());
    }

    showReportPreview(controlId: number) {
        var url = this.url + "/api/DocumentationMaintenance/GetControlReportPreview?controlId=" + controlId + "";
        return this.http.get<any>(url);
    }

    showNewReportPreview(controlId: number) {
        var url = this.url + "/api/DocumentationMaintenance/report?controlId=" + controlId + "";
        return this.http.get<any>(url);
    }

    getMaintenanceLog(controlId: number): Observable<any[]> {
        var url = this.url + "/api/DocumentationMaintenance/GetMaintenanceLog?controlId=" + controlId + "";
        return this.http.get<any>(url);
    }

    getNewMaintenanceLog(controlId: number): Observable<any[]> {
        var url = this.url + "/api/DocumentationMaintenance/log?controlId=" + controlId + "";
        return this.http.get<any>(url);
    }
    getInitialtrackingLog(subRuleId: number, isCustom: boolean): Observable<any[]> {
        var url = this.url + `/api/DocumentationMaintenance/initial-log?subRuleId=${subRuleId}&IsCustom=${isCustom}`;
        return this.http.get<InitialBaselineTrackingLog[]>(url);
    }

    getInitialMaintenanceLog(subRule: number, isCustom: boolean): Observable<any[]> {
        var url = this.url + `/api/DocumentationMaintenance/new-log?subRuleId=${subRule}&isCustom=${isCustom}`;
        return this.http.get<any>(url);
    }

    getObservationsActionsForField(controlId: number, field: string): Observable<Observation[]> {
        var url = this.url + "/api/DocumentationMaintenance/GetObservationsByField?controlEventId=" + controlId + "&field=" + field + "";
        return this.http.get<Observation[]>(url);
    }

    getExistingObservationsActions(control: any, subRuleId: any, isCustom: any) {
        var url = this.url + `/api/DocumentationMaintenance/new-observations-actions?controlEventId=${control}&subRuleId=${subRuleId}&isCustom=${isCustom}`;
        return this.http.get<Observation[]>(url);
    }

    updateObservation(observation: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/UpdateObservation?userName=" + username + "";
        return this.http.put(url, JSON.stringify(observation), this.createHedader()).subscribe();
    }

    updateNewObservation(observation: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-observation?userName=" + username + "";
        return this.http.put(url, JSON.stringify(observation), this.createHedader()).subscribe();
    }

    updateInitialObservation(observation: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-observation?userName=" + username + "";
        return this.http.put(url, JSON.stringify(observation), this.createHedader()).subscribe();
    }

    updateAction(action: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/UpdateAction?userName=" + username + "";
        return this.http.put(url, JSON.stringify(action), this.createHedader());
    }

    updateNewAction(action: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/new-action?userName=" + username + "";
        return this.http.put(url, JSON.stringify(action), this.createHedader());
    }

    updateInitialAction(action: any) {
        var username = this.authService.instance.getAllAccounts()[0].name;
        var url = this.url + "/api/DocumentationMaintenance/initial-action?userName=" + username + "";
        return this.http.put(url, JSON.stringify(action), this.createHedader());
    }

    saveReport(controlEventId: number, reportType: number, name: string) {
        var url = this.url + "/api/DocumentationMaintenance/SaveControlEventArchiveReport?" +
            "controlEventId=" + controlEventId + "&reportType=" + reportType + "&userName=" + name + "";
        return this.http.get(url).subscribe();
    }

    getCategoryMaintenanceType(bpId: string, categoryId: string, versionNo: number) {
        var url = this.url + "/api/DocumentationMaintenance/GetCurrentMaintenanceType?businessProcessId=" +
            bpId + "&documentationCategoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.get(url);
    }

    updateCategoryType(bpId: string, categoryId: string, type: number, versionNo: number) {
        var url = this.url + "/api/DocumentationMaintenance/UpdateCategoryType?businessProcessId=" +
            bpId + "&documentationCategoryId=" + categoryId + "&type=" + type + "&versionNo=" + versionNo + "";
        return this.http.get(url).subscribe();
    }

    setControlEventDisabled(controlId: number) {
        var url = this.url + "/api/DocumentationMaintenance/SetControlEventDisabled?controlEventId=" + controlId + "";
        return this.http.get(url).subscribe();
    }

    setContentChanged(changed: boolean, controlId: number, field: string) {
        var url = this.url + "/api/DocumentationMaintenance/SetContentChanged?" +
            "changed=" + changed + "&controlId=" + controlId + "&fieldId=" + field + "";
        return this.http.get(url).subscribe();
    }
}