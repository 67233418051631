import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AccordionComponent, ExpandEventArgs } from "@syncfusion/ej2-angular-navigations";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";

@Component({
    selector: 'control-report',
    templateUrl: 'control-report.html',
    styleUrls: ['control-report.css']
})
export class ControlReportComponent implements AfterViewChecked, OnInit {
    typeSelected: number;
    controlTrigger: any[];
    constructor(private service: DocMaintenanceService,
        private authService: MsalService) { }
    public reportData: any;
    public qualityList: any;
    @Input() controlId: any;
    oldControlId: any;
    isFirstTime: boolean;
    currentControl: any;
    contentModel: any;
    @Input() refresh: boolean;
    @Input() category: any;
    @Input() showReport: boolean;
    @Output() showLog = new EventEmitter<number>();
    @Output() closeDialog = new EventEmitter<boolean>();

    @ViewChild('accordion') acrdnInstance: AccordionComponent;
    public initialLoad = true;
    public isCollapsed = false;
    public expandIndex;
    selecteditems: number[];

    ngOnInit(): void {
        this.isFirstTime = true;
        this.getQualityList();
        this.getControlTriggers();
        this.contentModel = [{ text: 'Yes', value: true }, { text: 'No', value: false }]
    }
    ngAfterViewChecked(): void {
        if (this.controlId !== undefined) {
            if (this.controlId !== this.oldControlId) {
                this.getControlReportData();
                this.getControl();
                this.oldControlId = this.controlId;
            }
            if (this.refresh === true) {
                this.getControlReportData();
                this.getControl();
                this.refresh = false;
            }
        }
    }

    getQualityList() {
        this.service.getControlEventGetQualityList().subscribe(x => {
            this.qualityList = x;
        })
    }
    getControlReportData() {
        this.service.showReportPreview(this.controlId).subscribe(x => {
            this.reportData = x;
            this.reportData.fields.forEach(element => {
                if (element.contentChanged == null) element.contentChanged = false;
            });
        })
    }

    QualityChanged(id) {
        var quality = {
            id: id, name: ''
        }
        this.service.changeDocumantationQuality(this.controlId, quality);
    }
    getControl() {
        this.service.getControlEventById(this.controlId).subscribe(_data => {
            this.currentControl = _data;
        })
    }

    getControlTriggers() {
        this.service.getControlEventTriggerList().subscribe(data => {
            this.controlTrigger = data;
        })
    }

    TriggerChanged(id) {
        var trigger = {
            id: id, title: ''
        }
        this.service.changeControlTrigger(this.controlId, trigger);
    }

    cloneObservation() {
        let action = {
            actionId: 0,
            description: null,
            actionType: "",
            priority: 1,
            registeredDate: new Date(),
            observationId: 0
        };

        let observation = {
            observationId: 0,
            observationName: "",
            controlId: this.controlId,
            documantationCategoryFieldId: null,
            actions: [action]
        };
        var generalfield = this.reportData.fields.filter(x => x.title == 'General Observation');
        if (generalfield.length === 0) {
            let field = {
                documentationCategoryFieldId: null,
                title: "General Observation",
                observations: [observation]
            };
            this.reportData.fields.push(field);
        }
        else {
            this.reportData.fields.filter(x => x.title == 'General Observation')[0].
                observations.push(observation);
        }
    }

    setTypeSelected(type) {
        this.typeSelected = type;
    }

    previewSave() {
        var wrapper = document.getElementById('reportWrapper');
        if (this.typeSelected === undefined) {
            this.typeSelected = 1;
        }

        var userName = this.authService.instance.getAllAccounts()[0].name;
        this.service.saveReport(this.controlId, this.typeSelected, userName)
        // setTimeout(() => {
        //     this.service.saveReport(this.controlId, this.typeSelected, userName)
        // }, 2000);
        if (this.typeSelected == 1)
            this.closeDialog.emit(false);
        setTimeout(() => {
            if (this.typeSelected == 2)
                wrapper.style.opacity = '0.5'
            this.closeDialog.emit(true);
            wrapper.style.opacity = '1'
        }, 2000);
        // if (this.typeSelected == 1)
        //     this.closeDialog.emit(false);
        // if (this.typeSelected == 2)
        //     this.closeDialog.emit(true);
    }

    showMaintenanceLog() {
        this.showLog.emit(this.controlId);
    }


    // getCategoryById(){
    //     this.generalInfoService.getCategoryById(this.categoryId, bpId).subscribe(res => {

    //     }
    // }

    addActionToObservation(fieldId: string, observationId: number) {
        let action = {
            actionId: 0,
            registeredDate: new Date(),
            actionType: "Planned",
            priority: 1,
            description: null,
            observationId: observationId
        }
        this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].
            observations.filter(y => y.observationId == observationId)[0].actions.push(action);
    }

    newObservation(id) {
        let action = {
            actionId: 0,
            description: null,
            actionType: "",
            priority: 0,
            registeredDate: new Date(),
            observationId: 0
        };

        let observation = {
            observationId: 0,
            observationName: "",
            controlId: this.controlId,
            documantationCategoryFieldId: null,
            actions: [action]
        };
        this.reportData.fields.filter(x => x.documentationCategoryFieldId == id)[0].
            observations.push(observation);
    }

    saveObservation(fieldId, obId, data: any, index) {
        if (this.controlId !== undefined) {
            if (obId === 0 || obId === undefined) {
                let observation = {
                    id: 0,
                    name: data.target.value,
                    controlId: this.controlId,
                    documentationCategoryFieldId: fieldId,
                };
                this.service.addObservation(observation).subscribe(x => {
                    this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].
                        observations[index].observationId = x;
                    this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].
                        observations[index].observationName = data.target.value;
                });
            }

            if (obId !== 0) {
                let observation = {
                    id: obId,
                    name: data.target.value,
                    controlId: this.controlId,
                    documentationCategoryFieldId: fieldId,
                };
                this.service.updateObservation(observation);
            }
        }
    }

    saveAction(fieldId, observationId, actionId, data: any, index) {
        if (observationId == 0) return;
        else if (observationId !== 0 && (actionId === 0 || actionId === undefined)) {

            let action = {
                id: 0,
                registeredDate: new Date(),
                actionType: "Planned",
                priority: 1,
                description: data.target.value,
                observationId: observationId
            }
            this.service.addAction(action).subscribe(x => {
                this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].observations
                    .filter(x => x.observationId == observationId)[0].actions[index].actionId = x;
                this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].observations
                    .filter(x => x.observationId == observationId)[0].actions[index].description = data.target.value;
                this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].observations
                    .filter(x => x.observationId == observationId)[0].actions[index].priority = 1;
            });
        }
        else if (observationId !== 0 && (actionId !== 0 && actionId !== undefined)) {

            let action = {
                id: actionId,
                actionType: "Planned",
                registeredDate: new Date(),
                priority: 1,
                description: data.target.value,
                observationId: observationId
            }
            this.service.updateAction(action).subscribe();
        }
    }

    deleteObservation(id: number, fieldId: string, index: number) {
        if (id !== 0 && id !== undefined)
            this.service.deleteObservation(id).subscribe();
        if (index !== -1) this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].
            observations.splice(index, 1);
    }

    deleteAction(id: number, obIndx: number, fieldId: string, index: number) {
        if (id !== 0 && id !== undefined)
            this.service.deleteAction(id).subscribe();
        if (index !== -1) this.reportData.fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].
            observations[obIndx].actions.splice(index, 1);
    }

    setContentChanged(changed, field) {
        this.service.setContentChanged(changed, this.controlId, field);
    }

    public expanding(e: ExpandEventArgs) {
        if (e.isExpanded && !this.initialLoad && !this.isCollapsed) {
            e.cancel = true;
            this.expandIndex = this.acrdnInstance.items.indexOf(e.item);
            this.isCollapsed = true;
        }
    }
    public expanded(e: ExpandEventArgs) {
        if (!e.isExpanded && !this.initialLoad && this.isCollapsed) {
            this.acrdnInstance.expandItem(true, this.expandIndex);
            this.isCollapsed = false;
        }
    }

    public created(): void {
        this.initialLoad = false;
    }
}