import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { BPGridRow } from "src/app/interfaces/BPGridRow";

import auth from '../../auth-config.json';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BPService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394"
  constructor(private http: HttpClient, private router: Router) { }

  getDPIARequired(bpId: string) {
    var customerUrl = this.url + "/api/documentationcategory/isdpiarequired?businessProcessId=" + bpId + "";
    return this.http.get<boolean>(customerUrl);
  }

  getCustomerId(tenantId: string) {
    var customerUrl = this.url + "/api/customer/customerId/" + tenantId + "";
    return this.http.get<string>(customerUrl).pipe(mergeMap(_data => {
      sessionStorage.removeItem("customerId")
      sessionStorage.setItem("customerId", _data);
      return _data;
    }))
  }

  getAllBusinessProcesses(tenantId: string): Observable<BPGridRow[]> {
    var customerUrl = this.url + "/api/customer/customerId/" + tenantId + "";
    return this.http.get<string>(customerUrl).pipe(mergeMap(_data => {
      sessionStorage.removeItem("customerId")
      sessionStorage.setItem("customerId", _data);
      var bpUrl = this.url + "/api/BusinessProcess/getall?customerId=" + _data + "";
      return this.http.get<BPGridRow[]>(bpUrl)
    }));
  }
}
