// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lbProcessingMainContainer {
    background: white;
    overflow: auto;
    padding: 5px;
}


.lbProcessingHeader {
    font-family: 'Rubik', sans-serif;
    color: #0C2B57;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
}

.lbProcessingText {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #142A56;
    width: 80%;
    float: left;
}

.lbProcessingModule {
    background: #EDEFF3;
    overflow: auto;
    margin-bottom: 1px;
    padding: 15px;
}

.lbProcessingAddBtn {
    width: 10%;
    height: 20px;
    float: right;
    background: #63A5DA;
    border:1px solid #63A5DA;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/legal-basis/modals/legal-basis-for-processing/legal-basis-for-processing.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;;AAGA;IACI,gCAAgC;IAChC,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,UAAU;IACV,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;IACZ,gCAAgC;IAChC,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.lbProcessingMainContainer {\r\n    background: white;\r\n    overflow: auto;\r\n    padding: 5px;\r\n}\r\n\r\n\r\n.lbProcessingHeader {\r\n    font-family: 'Rubik', sans-serif;\r\n    color: #0C2B57;\r\n    font-weight: 600;\r\n    font-size: 19px;\r\n    text-align: center;\r\n}\r\n\r\n.lbProcessingText {\r\n    font-family: 'Rubik';\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    color: #142A56;\r\n    width: 80%;\r\n    float: left;\r\n}\r\n\r\n.lbProcessingModule {\r\n    background: #EDEFF3;\r\n    overflow: auto;\r\n    margin-bottom: 1px;\r\n    padding: 15px;\r\n}\r\n\r\n.lbProcessingAddBtn {\r\n    width: 10%;\r\n    height: 20px;\r\n    float: right;\r\n    background: #63A5DA;\r\n    border:1px solid #63A5DA;\r\n    color: white;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-size: 12px;\r\n    font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
