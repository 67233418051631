// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 

    .background-green{ 
      background-color:  #92BB50; 
    } 
    .background-red{ 
      background-color: #DD2122; 
    } 
    .background-yellow{ 
      background-color: #EDDA00; 
    } 
    .background-orange{ 
      background-color: #F3B10D; 
    } 
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-assessment/grids/gdpr-assessment-heatmap.component.ts"],"names":[],"mappings":";;IAEI;MACE,0BAA0B;IAC5B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B","sourcesContent":[" \n\n    .background-green{ \n      background-color:  #92BB50; \n    } \n    .background-red{ \n      background-color: #DD2122; \n    } \n    .background-yellow{ \n      background-color: #EDDA00; \n    } \n    .background-orange{ \n      background-color: #F3B10D; \n    } \n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
