// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restrictionsMainContainer {
    background: white;
    overflow: auto;
    padding: 5px;
}


.restrictionsHeader {
    font-family: 'Rubik', sans-serif;
    color: #0C2B57;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
}

.restrictionsText {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #142A56;
    width: 80%;
    float: left;
}

.restrictionsModule {
    background: #EDEFF3;
    overflow: auto;
    margin-bottom: 1px;
    padding: 15px;
}

.restrictionsAddBtn {
    width: 10%;
    height: 20px;
    float: right;
    background: #63A5DA;
    border:1px solid #63A5DA;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.removeRestrictionsBtn{
    height: 20px;
    float: right;
    background: #D1D2D9;
    border: 1px solid #D1D2D9;
    color: #63A5DA;
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 600;
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/rights-of-data-subjects/modals/restrictions/restrictions.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;;AAGA;IACI,gCAAgC;IAChC,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,UAAU;IACV,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;IACZ,gCAAgC;IAChC,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.restrictionsMainContainer {\r\n    background: white;\r\n    overflow: auto;\r\n    padding: 5px;\r\n}\r\n\r\n\r\n.restrictionsHeader {\r\n    font-family: 'Rubik', sans-serif;\r\n    color: #0C2B57;\r\n    font-weight: 600;\r\n    font-size: 19px;\r\n    text-align: center;\r\n}\r\n\r\n.restrictionsText {\r\n    font-family: 'Rubik';\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    color: #142A56;\r\n    width: 80%;\r\n    float: left;\r\n}\r\n\r\n.restrictionsModule {\r\n    background: #EDEFF3;\r\n    overflow: auto;\r\n    margin-bottom: 1px;\r\n    padding: 15px;\r\n}\r\n\r\n.restrictionsAddBtn {\r\n    width: 10%;\r\n    height: 20px;\r\n    float: right;\r\n    background: #63A5DA;\r\n    border:1px solid #63A5DA;\r\n    color: white;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-size: 12px;\r\n    font-weight: 600;\r\n}\r\n\r\n.removeRestrictionsBtn{\r\n    height: 20px;\r\n    float: right;\r\n    background: #D1D2D9;\r\n    border: 1px solid #D1D2D9;\r\n    color: #63A5DA;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-size: 12px;\r\n    font-weight: 600;\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
