import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { DataTransfersModelData } from "src/app/interfaces/DataTransfers/DataTransfersModelData";
import { LegalBasis } from "src/app/interfaces/DataTransfers/LegalBasis";
import { ModelDataList } from "src/app/interfaces/DataTransfers/ModelDataList";
import { PersonalDataInfo } from "src/app/interfaces/DataTransfers/PersonalDataInfo";
import { PersonalDataType } from "src/app/interfaces/DataTransfers/PersonalDataType";
import { Prerequisites } from "src/app/interfaces/DataTransfers/Prerequisites";
import { RecipientCategory } from "src/app/interfaces/DataTransfers/RecipientCategory";
import { RecipientData } from "src/app/interfaces/DataTransfers/RecipientData";
import { ThirdCountryInfo } from "src/app/interfaces/DataTransfers/ThirdCountryInfo";
import { map, catchError, take } from 'rxjs/operators';
import auth from '../../auth-config.json';
import FileSaver from 'file-saver'
import { TIADocumentStatus } from "src/app/interfaces/DataTransfers/TIADocumentStatus";
import { TiaLoadTypes } from "src/app/interfaces/DataTransfers/TiaLoadTypes";
@Injectable({
    providedIn: 'root'
})
export class DataTransferService {
    url = auth.resources.PDHubApi.resourceUri;
    localUrl = "https://localhost:44394"
    constructor(private http: HttpClient, private authService: MsalService) { }
    WORD_EXTENSION = '.docx';
    PDF_EXTENSION = '.pdf';
    private createHedader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    getRecepientsList(customerId: string, versionNo: number, bpId: string) {
        var Url = this.url + "/api/DataTransfers/GetRecipientsList?customerId=" + customerId + "&versionNo=" + versionNo + "&businessProcessId=" + bpId + "";
        return this.http.get<RecipientData[]>(Url);
    }

    getThirdCountryData(customerId: string, versionNo: number, bpId: string, recepientId: string) {
        var Url = this.url + "/api/DataTransfers/GetThirdCountryInfo?customerId=" + customerId
            + "&versionNo=" + versionNo + "&businessProcessId=" + bpId + "&recipientId=" + recepientId + "";
        return this.http.get<ThirdCountryInfo[]>(Url);
    }

    getModelData(customerId: string, versionNo: number, bpId: string) {
        var Url = this.url + "/api/DataTransfers/GetModelDataList?customerId=" + customerId
            + "&versionNo=" + versionNo + "&businessProcessId=" + bpId + "";
        return this.http.get<ModelDataList[]>(Url);
    }

    getDTModelData(recipientId: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetRecipientData?recipientId=" + recipientId + "&versionNo=" + versionNo + "";
        return this.http.get<DataTransfersModelData>(Url);
    }

    getSafeguards() {
        var Url = this.url + "/api/DataTransfers/GetSafeguardsList";
        return this.http.get<any[]>(Url);
    }

    getDerogations() {
        var Url = this.url + "/api/DataTransfers/GetDerogationsList";
        return this.http.get<any[]>(Url);
    }

    getPersonalDataList() {
        var Url = this.url + "/api/DataTransfers/GetPersonalDataTypeData";
        return this.http.get<PersonalDataType[]>(Url);
    }

    getCustomPersonalDataList(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetCustomPersonalDataByRecipient?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<PersonalDataInfo[]>(Url);
    }

    getDisabledCustomPersonalDataList(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetDisabledCustomPersonalDataByRecipient?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<PersonalDataInfo[]>(Url);
    }

    getDeselectedEnabledCustomPersonalDataList(recipient: string, type: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetDeselectedEnabledCustomPersonalDataByRecipient?recipientId="
            + recipient + "&typeId=" + type + "&versionNo=" + versionNo + "";
        return this.http.get<PersonalDataInfo[]>(Url);
    }

    getPersonalDataListByRecipient(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetPersonalDataByRecipient?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<PersonalDataType[]>(Url);
    }

    linkDerogation(recipientId: string, derogationId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/AddDerogation?recipientId=" + recipientId +
            "&derogationId=" + derogationId + "&versionNo=" + versionNo + "";
        return this.http.get(Url);
    }

    //LEGAL BASIS
    linkLegalBasis(recipientId: string, legalBasisId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/LinkLegalBasis?recipientId=" +
            recipientId + "&legalBasisId=" + legalBasisId + "&versionNo=" + versionNo + "";
        return this.http.get(Url);
    }

    getLegalBasisByRecipient(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetLegalBasisByRecipient?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<LegalBasis>(Url);
    }

    getLegalBasisList() {
        var Url = this.url + "/api/DataTransfers/GetLegalBasisList";
        return this.http.get<LegalBasis[]>(Url);
    }
    //LEGAL BASIS

    //Recipient Category
    linkRecipientCategory(recipientId: string, categoryId: number, isCustom: boolean, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/LinkRecipientCategory?recipientId=" + recipientId +
            "&categoryId=" + categoryId + "&isCustom=" + isCustom + "&versionNo=" + versionNo + "";
        return this.http.get(Url);
    }

    linkCustomCategory(recipientId: string, customerId: string, model: RecipientCategory, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/LinkCustomCategory?recipientId=" + recipientId
            + "&customerId=" + customerId + "&versionNo=" + versionNo + "";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    getRecipientCategoryByRecipient(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetRecipientCategoryByRecipient?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<RecipientCategory>(Url);
    }

    getRecipientCategoryList(recipient: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetRecipientCategoryList?recipientId=" + recipient + "&versionNo=" + versionNo + "";
        return this.http.get<RecipientCategory[]>(Url);
    }
    //Recipient Category
    //PREREQUISITES
    getPrerequisitesList(recipientId: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetPrerequisitesByRecipient?recipientId=" + recipientId + "&versionNo=" + versionNo + "";
        return this.http.get<Prerequisites[]>(Url);
    }
    addPrerequisiteLink(recipientId: string, model: Prerequisites, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/AddPrerequisites?recipientId=" + recipientId + "&versionNo=" + versionNo + "";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }
    //PREREQUISITES
    linkSafeguard(recipientId: string, safeguardId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/AddSafeguard?recipientId=" + recipientId + "&safeguardId=" + safeguardId + "&versionNo=" + versionNo + "";
        return this.http.get(Url);
    }

    linkPersonalDataType(recipientId: string, dataTypeId: number, typeId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/AddPersonalDataLink?recipientId=" + recipientId
            + "&dataTypeId=" + dataTypeId + "&typeId=" + typeId + "&versionNo=" + versionNo + "";
        return this.http.get(Url).subscribe();
    }

    deleteLinkPersonalDataType(recipientId: string, dataTypeId: number, typeId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/DeletePersonalDataLink?recipientId=" + recipientId + "&dataTypeId=" + dataTypeId
            + "&typeId=" + typeId + "&versionNo=" + versionNo + "";
        return this.http.get(Url).subscribe();
    }

    disableLinkPersonalDataType(dataTypeId: number) {
        var Url = this.url + "/api/DataTransfers/DisablePersonalDataLink?dataTypeId=" + dataTypeId + "";
        return this.http.get(Url).subscribe();
    }

    deselectPersonalDataType(id: number) {
        var Url = this.url + "/api/DataTransfers/DeselectCustomPersonalData?id=" + id + "";
        return this.http.get(Url).subscribe();
    }


    enableLinkPersonalDataType(dataTypeId: number) {
        var Url = this.url + "/api/DataTransfers/EnablePersonalDataLink?dataTypeId=" + dataTypeId + "";
        return this.http.get(Url).subscribe();
    }

    addCustomPersonalData(model: any) {
        var Url = this.url + "/api/DataTransfers/AddCustomPersonalData";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    addExplainDate(model: any) {
        var Url = this.url + "/api/DataTransfers/AddExplainDate";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    updateExplainDate(model: any) {
        var Url = this.url + "/api/DataTransfers/UpdateExplainDate";
        return this.http.put(Url, JSON.stringify(model), this.createHedader());
    }
    deleteSafeguardLink(recipientId: string, safeguardId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/DeleteSafeguardLink?recipientId=" + recipientId + "&safeguardId=" + safeguardId + "&versionNo=" + versionNo + "";
        return this.http.delete(Url).subscribe();
    }
    deleteDerogationLink(recipientId: string, derogationId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/DeleteDerogationLink?recipientId=" + recipientId + "&derogationId=" + derogationId + "&versionNo=" + versionNo + "";
        return this.http.delete(Url).subscribe();
    }

    //Categories
    deleteCategoryLink(recipientId: string, categoryId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/DeleteCategoryDataLink?recipientId=" + recipientId + "&categoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.delete(Url).subscribe();
    }

    deleteCustomCategoryLink(recipientId: string, categoryId: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/DeleteCustomCategoryWithLink?recipientId=" + recipientId + "&categoryId=" + categoryId + "&versionNo=" + versionNo + "";
        return this.http.delete(Url).subscribe();
    }

    generateTia(recipientId: string, type: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GenerateTIA?recipientId=" + recipientId + "&type=" + type + "&versionNo=" + versionNo + "";
        var file = this.http.get(Url, { responseType: 'blob' })
            .pipe(map((resp: Blob) => {
                return resp;
            }));
        return file;
    }

    saveAsWordFile(buffer: any, fileName: string): void {
        FileSaver.saveAs(buffer, fileName + this.WORD_EXTENSION);
    }
    saveAsPdfFile(buffer: any, fileName: string): void {
        FileSaver.saveAs(buffer, fileName + this.PDF_EXTENSION);
    }
    uploadTia(formData: FormData) {
        let Url = this.url + "/api/DataTransfers/UploadTia";
        return this.http.post(Url, formData);
    }

    tiaExists(recipientId: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/TiaExists?recipientId=" + recipientId + "&versionNo=" + versionNo + "";
        var data = this.http.get<boolean>(Url)
            .pipe(take(1)).toPromise();
        return data;
    }

    getDocumentStatusList() {
        var Url = this.url + "/api/DataTransfers/GetTiaUploadTypesList";
        return this.http.get<TIADocumentStatus[]>(Url);
    }
    getDocumentStatusByRecipient(recepientId: string, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/GetTiaUploadTypeByRecipient?recipientId=" + recepientId + "&versionNo=" + versionNo + "";
        return this.http.get<TIADocumentStatus>(Url);
    }

    addEditDocumentStatus(recepientId: string, uploadType: number, versionNo: number) {
        var Url = this.url + "/api/DataTransfers/AddEditRecipientTiaUploadType?recipientId=" +
            recepientId + "&uploadType=" + uploadType + "&versionNo=" + versionNo + "";
        return this.http.get(Url);
    }
    getTransferImpactAssessmentData() {
        var Url = this.url + "/api/DataTransfers/GetTransferImpactAssessmentData";
        return this.http.get<ModelDataList[]>(Url);
    }

    getTiaLoadTypes() {
        var Url = this.url + "/api/DataTransfers/GetTIALoadTypes";
        return this.http.get<TiaLoadTypes[]>(Url);
    }
}

