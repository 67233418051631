import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { BPGridRow } from "src/app/interfaces/BPGridRow";

import auth from '../../auth-config.json';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { BusinessCountData } from "src/app/interfaces/Dashboard/BusinessCountData";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394"
  constructor(private http: HttpClient, private router: Router) { }

  GetBusinessCountData(customerId: string) {
    var Url = this.url + "/api/dashboard/GetBusinessCountData?customerId=" + customerId + "";
    return this.http.get<BusinessCountData>(Url);
  }
}
