import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'new-wheel-maintenance',
    templateUrl: 'wheel-maintenance.html',
    styleUrls: ['wheel-maintenance.css'],
    encapsulation: ViewEncapsulation.None
})
export class NewWheelMaintenanceComponent implements OnInit, AfterViewChecked {
    @Output() controlStarted = new EventEmitter<number>();
    @Output() showReportStarted = new EventEmitter<number>();
    @Output() controlEventRemoved = new EventEmitter<number>();
    @Input() subRule: string;
    @Input() show: boolean;
    // public editSettings: Object;
    public isFirstRun: boolean;
    public controlsEvents = [];
    public controlFrequency = [];
    public dataManager: any;
    public reportPreview: any;
    @ViewChild('Dialog')
    public Dialog: DialogComponent;
    public dialogHeight = '100';
    public dialogWidth = '20';
    showCloseIcon = true;
    public prevId: any;
    public ddlfields: Object = { text: 'name', value: 'id' };
    public selectedIndex;

    @ViewChild('wheel_grid') grid: GridComponent;
    constructor(private service: DocMaintenanceService, private authService: MsalService) { }
    ngAfterViewChecked(): void {
        if (this.isFirstRun === true) {
            if (this.subRule !== undefined) {
                if (this.subRule[0]["actualControlEvent"] !== undefined) {
                    this.subRule[0]["actualControlEvent"].dueDate = new Date(this.subRule[0]["actualControlEvent"].dueDate).toISOString().slice(0, 10)
                    this.controlsEvents.push(this.subRule[0]["actualControlEvent"]);
                    this.isFirstRun = false;
                }
                else {
                    this.isFirstRun = false;
                }
            }
        }
    }


    ngOnInit(): void {
        this.prevId = 0;
        this.isFirstRun = true;
        this.getControlFrequencies();
    }

    dataBound(args) {
        if (this.selectedIndex !== null && this.selectedIndex !== undefined) {
            this.grid.selectRow(this.selectedIndex);
        }
        if (this.prevId !== 0)
            document.getElementById("dcStartControlBtn_" + this.prevId)
                .style.display = "none";
    }

    showBtn() {
        document.getElementById("dcStartControlBtn_" + this.prevId)
            .style.display = "block";
    }

    dismissControlEvent(id, displayId) {
        this.service.setControlEventDisabled(id);
        this.grid.deleteRecord('displayId', displayId);
        if (this.prevId == id) {
            this.prevId = 0;
            this.controlEventRemoved.emit(id)
        }
    }

    getControlFrequencies() {
        this.service.getControlFrequencyList().subscribe(data => {
            this.controlFrequency = data;
        })
    }

    dialogShow() {
        this.Dialog.show;
    }
}


