import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'permissionsFilter',
    pure: false
})
export class PermissionsPipe implements PipeTransform {
    transform(items: any[], item: number): any {
        if (!items || !item) {
            return items;
        }
        var result = items.filter(u => u.id === item).map(x=>x.values);
        return result[0];
    }
}