import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'rightsDecisionMakingFilter',
    pure: false
})
export class RightsDecisionMakingFilter implements PipeTransform {
    transform(items: any[], item: string): any {
        if (!items || !item) {
            return items;
        }
        var result = items.filter(u=>u.parentDocumentationSubCategoryId === item);
        return result;
    }
}