import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { ColumnModel, CommandModel, EditEventArgs, EditSettings, EditSettingsModel, GridComponent, IEditCell, QueryCellInfoEventArgs, RowDataBoundEventArgs, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { ToolbarClickEventArgs } from "@syncfusion/ej2-filemanager";
import { AdminThreats } from "src/app/interfaces/Administration/threats/AdminThreats";
import { AdministrationService } from "src/app/shared/services/admin.service";
import { ThreatRulesComponent } from "../threat-rules/threat-rules.component";
import { Query } from "@syncfusion/ej2/data";

const localede = {
    "de": {
        grid: {
            Add: 'Add threat'
        }
    }
};

setCulture("de");
L10n.load(localede);

@Component({
    selector: 'admin-threats',
    styleUrls: ['threats.css'],
    templateUrl: 'threats.html',
    encapsulation: ViewEncapsulation.None
})
export class OrganizationThreatsComponent implements OnInit {
    constructor(private adminService: AdministrationService, private cdr: ChangeDetectorRef) { }
    public assetsColumns: any[];
    public effectColumns: any[];
    public customAttributes;
    public threatLevels;
    public categories;
    public selectedCategories: string[] = [];
    public selectedThreatLevels: string[] = [];
    public selectedThreatLevel: string = '';
    public ddlfields: Object = { text: 'levelName', value: 'levelId' };
    public catfields: Object = { text: 'categoryName', value: 'categoryId' };
    public riskfields: Object = { text: 'riskTypeName', value: 'riskTypeId' };
    public actorfields: Object = { text: 'actorName', value: 'actorId' };
    public intentionfields: Object = { text: 'intentionName', value: 'intentionId' };
    public toolbar: ToolbarItems[] | object;
    public editSettings: EditSettingsModel;
    public adminThreats: any[] = [];
    public actors: any[] = [];
    public intentions: any[] = [];
    public riskTypes: any[] = [];
    public org: string;
    public currentCustom;
    public isSubmitted: boolean;
    @ViewChild('gridAdminThreats')
    public grid?: GridComponent;
    public commands?: CommandModel[];
    public dialogHeight = '100%';
    public currentThreat;
    public currentRules;
    public mode: string;
    public checkWaterMark: string = 'All';
    public popHeight: string = '350px';
    public requiredRules?: object;

    @ViewChild('threatChipsList')
    public threatChips: ChipListComponent
    @ViewChildren(ChipListComponent) templates: QueryList<ChipListComponent>;
    @ViewChild(ThreatRulesComponent) threatRules: ThreatRulesComponent;

    @ViewChild('integritytemplate', { static: true }) public integrityTemplate!: TemplateRef<any>;
    @ViewChild('confidentialitytemplate', { static: true }) public confidentialitytemplate!: TemplateRef<any>;
    @ViewChild('availabilitytemplate', { static: true }) public availabilitytemplate!: TemplateRef<any>;

    @ViewChild('internalOrganisationTemplate', { static: true }) public internalOrganisationTemplate!: TemplateRef<any>;
    @ViewChild('internalEmployeesTemplate', { static: true }) public internalEmployeesTemplate!: TemplateRef<any>;
    @ViewChild('internallyHostedApplicationsTemplate', { static: true }) public internallyHostedApplicationsTemplate!: TemplateRef<any>;
    @ViewChild('internallyHostedITInfrastructureTemplate', { static: true }) public internallyHostedITInfrastructureTemplate!: TemplateRef<any>;
    @ViewChild('iCTServiceProvidersOrganisationTemplate', { static: true }) public iCTServiceProvidersOrganisationTemplate!: TemplateRef<any>;
    @ViewChild('iCTServiceProvidersEmployeesTemplate', { static: true }) public iCTServiceProvidersEmployeesTemplate!: TemplateRef<any>;
    @ViewChild('externallyHostedApplicationsTemplate', { static: true }) public externallyHostedApplicationsTemplate!: TemplateRef<any>;
    @ViewChild('externallyHostedITInfrastructureTemplate', { static: true }) public externallyHostedITInfrastructureTemplate!: TemplateRef<any>;

    pageSettings: Object;
    public pageSizes: number[] = [3, 5, 10];

    @ViewChild('ThreatRuleDialog')
    public ThreatRuleDialog: DialogComponent;
    public intentionParams?: IEditCell;
    public actorParams?: IEditCell;
    public threatLevelParams?: IEditCell;
    public risktypeParams?: IEditCell;
    public categoryParams?: IEditCell;
    getEffectLabel(effectValue: number): string {
        return effectValue === 1 ? 'X' : 'Y';
    }
    ngOnInit(): void {
        this.pageSettings = { pageSizes: true, pageCount: 4 };

        this.mode = 'CheckBox';
        this.requiredRules = { required: [true, "Field is Required"] };
        this.isSubmitted = false;
        this.org = sessionStorage.getItem("customerId");
        this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-delete e-icons' }},
        { type: 'Save', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-update e-icons' }}];
        this.getSubmitInfo();
        this.getAdminThreats();
        this.getCategories();
        this.getRiskTypes();
        this.getActors();
        this.getThreatLevels();
        this.getIntentions();
        this.customAttributes = { class: 'orientationcss' };

        this.assetsColumns = [
            {
                field: 'internalOrganisation',
                headerText: 'Internal organisation',
                width: 100,
                customAttributes: this.customAttributes,
                template: this.internalOrganisationTemplate,
                allowEditing: true,
                editType: 'booleanedit',
            },
            {
                field: 'internalEmployees',
                width: 100,
                headerText: 'Internal employees',
                customAttributes: this.customAttributes,
                template: this.internalEmployeesTemplate,
                allowEditing: true,
                editType: 'booleanedit',
            },
            {
                field: 'internallyHostedApplications',
                width: 100,
                headerText: 'Internally hosted applications',
                customAttributes: this.customAttributes,
                template: this.internallyHostedApplicationsTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'internallyHostedITInfrastructure',
                width: 100,
                headerText: 'Internally hosted IT infrastructure',
                customAttributes: this.customAttributes,
                template: this.internallyHostedITInfrastructureTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'iCTServiceProvidersOrganisation',
                width: 100,
                headerText: 'ICT service providers organisation',
                customAttributes: this.customAttributes,
                template: this.iCTServiceProvidersOrganisationTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'iCTServiceProvidersEmployees',
                width: 100,
                headerText: 'ICT service providers employees',
                customAttributes: this.customAttributes,
                template: this.iCTServiceProvidersEmployeesTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'externallyHostedApplications',
                width: 100,
                headerText: 'Externally hosted applications',
                customAttributes: this.customAttributes,
                template: this.externallyHostedApplicationsTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'externallyHostedITInfrastructure',
                width: 100,
                headerText: 'Externally hosted IT infrastructure',
                customAttributes: this.customAttributes,
                template: this.externallyHostedITInfrastructureTemplate,
                editType: 'booleanedit'
            }
        ];

        this.effectColumns = [
            {
                field: 'confidentiality',
                width: 100,
                headerText: 'Confidentiality',
                customAttributes: this.customAttributes,
                template: this.confidentialitytemplate,
                editType: 'booleanedit',
            },
            {
                field: 'integrity',
                width: 100,
                headerText: 'Integrity',
                customAttributes: this.customAttributes,
                template: this.integrityTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'availability',
                width: 100,
                headerText: 'Availability',
                customAttributes: this.customAttributes,
                template: this.availabilitytemplate,
                editType: 'booleanedit',
            }
        ];

        this.toolbar = ['Add'];
        this.toolbar = [
            'Add',
            { text: 'Submit catalogue', id: 'submitCatalogueBtn' },
        ]
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Bottom', mode: 'Normal' };
        if (document.getElementById('submitCatalogueBtn')) {
            document.getElementById('submitCatalogueBtn').style.display = 'none';
        }
        this.intentionParams = {
            params: {
                query: new Query(),
                dataSource: this.intentions,
                fields: { value: 'intentionId', text: 'intentionName' },
                allowFiltering: true
            }
        }
        this.actorParams = {
            params: {
                query: new Query(),
                dataSource: this.actors,
                fields: { value: 'actorId', text: 'actorName' },
                allowFiltering: true
            }
        }
        this.categoryParams = {
            params: {
                query: new Query(),
                dataSource: this.categories,
                fields: { value: 'categoryId', text: 'categoryName' },
                allowFiltering: true
            }
        }
        this.threatLevelParams = {
            params: {
                query: new Query(),
                dataSource: this.threatLevels,
                fields: { value: 'levelId', text: 'levelName' },
                allowFiltering: true
            }
        }
        this.risktypeParams = {
            params: {
                query: new Query(),
                dataSource: this.riskTypes,
                fields: { value: 'riskTypeId', text: 'riskTypeName' },
                allowFiltering: true
            }
        }
    }

    created() {
        let toolbar = ((this.grid as GridComponent).element as HTMLElement).querySelector('.e-toolbar');
        (this.grid as GridComponent).element.appendChild(toolbar as HTMLElement);
    }

    public rowDataBound(args: RowDataBoundEventArgs) {
        args.rowHeight = 40;
    }

    clickHandler(args: ToolbarClickEventArgs): void {
        if (args.item.id === 'submitCatalogueBtn' && !this.isSubmitted) {
            this.submitThreats();
        }
    }

    showDialog(threatId, isCustom, threatRules) {
        this.currentThreat = threatId;
        this.currentCustom = isCustom;
        this.currentRules = threatRules;
        this.ThreatRuleDialog.header = '<label style="font-size: 30px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 50; margin-left:5px;' +
            'color: #0E384D" class="logLbl">Select relevant rules</label>';
        this.ThreatRuleDialog.show();
    }

    actionBegin(args: any): void {
        if (args.requestType === 'save' && args.action === 'add') {
            args.data.isCustom = true;
            var relatedCategory = this.categories.filter(x => x.categoryName === args.data.category.categoryName)[0];
            args.data.category = relatedCategory;
            var intention = this.intentions.filter(x => x.intentionName === args.data.intention.intentionName)[0];
            args.data.intention = intention;
            var riskType = this.riskTypes.filter(x => x.riskTypeName === args.data.riskType.riskTypeName)[0];
            var threatLevel = this.threatLevels.filter(x => x.levelName === args.data.threatLevel.levelName)[0];
            var actor = this.actors.filter(x => x.actorName === args.data.actor.actorName)[0];
            args.data.actor = actor;
            args.data.threatLevel = threatLevel;
            args.data.riskType = riskType;
            this.adminService.AddCustomOrganizationAdminThreat(args.data, this.org).subscribe(resp => {
                args.data.threatId = resp;
                this.grid.refresh();
            })
        }

        if (args.requestType === 'save' && args.action === 'edit') {
            var relatedCategory = this.categories.filter(x => x.categoryName === args.data.category.categoryName)[0];
            args.data.category = relatedCategory;
            var intention = this.intentions.filter(x => x.intentionName === args.data.intention.intentionName)[0];
            args.data.intention = intention;
            var riskType = this.riskTypes.filter(x => x.riskTypeName === args.data.riskType.riskTypeName)[0];
            var threatLevel = this.threatLevels.filter(x => x.levelName === args.data.threatLevel.levelName)[0];
            var actor = this.actors.filter(x => x.actorName === args.data.actor.actorName)[0];
            args.data.actor = actor;
            args.data.threatLevel = threatLevel;
            args.data.riskType = riskType;
            this.adminService.UpdateOrganizationAdminThreat(args.data, this.org).subscribe(resp => {
                args.data.threatId = resp;
                this.grid.refresh();
            })
        }

        if (args.requestType === 'delete') {
            this.adminService.deleteOrganizationAdminThreat(args.data[0].threatId, this.org, args.data[0].isCustom)
                .subscribe();
        }
    }

    submitThreats() {
        this.adminService.AddOrganizationAdminThreats(this.adminThreats, this.org).subscribe(
            () => { },
            error => {
                console.error('Error submitting threats:', error);
            })
    }

    public setHeaderHeight() {
        const textWidth: number = (document.querySelector(".orientationcss > div") as HTMLElement).scrollWidth;
        const headerCell: NodeList = document.querySelectorAll(".e-headercell");
        for (let i: number = 0; i < headerCell.length; i++) {
            ((headerCell as any).item(i)).style.height = 50 + 'px';
        }

        let toolbar = ((this.grid as GridComponent).element as HTMLElement).querySelector('.e-toolbar');
        (this.grid as GridComponent).element.appendChild(toolbar as HTMLElement);
    }
    previousCategory: string = '';
    categoryStartIndex: number = 0;

    previousColumn2: string = '';
    column2StartIndex: number = 0;

    queryCellInfo(args: QueryCellInfoEventArgs) {
        const currentRowData = args.data as AdminThreats;
        const currentIndex = this.adminThreats.findIndex(item => item === currentRowData);

        // Merging for category.categoryName
        if (args.column.field === 'category.categoryName') {
            if (currentIndex === 0 || this.adminThreats[currentIndex - 1].category.categoryName !== currentRowData.category.categoryName) {
                let rowSpanCount = 1;
                for (let i = currentIndex + 1; i < this.adminThreats.length; i++) {
                    if ((this.adminThreats[i] as AdminThreats).category.categoryName === currentRowData.category.categoryName) {
                        rowSpanCount++;
                    } else {
                        break;
                    }
                }
                args.cell.setAttribute('rowSpan', rowSpanCount.toString());
            } else {
                (args.cell as HTMLElement).style.display = 'none';
            }
        }

        // Merging for threatLevel.levelName
        if (args.column.field === 'threatLevel.levelName') {
            if (currentIndex === 0 || this.adminThreats[currentIndex - 1].threatLevel.levelName !== currentRowData.threatLevel.levelName) {
                let rowSpanCount = 1;
                for (let i = currentIndex + 1; i < this.adminThreats.length; i++) {
                    if ((this.adminThreats[i] as AdminThreats).threatLevel.levelName === currentRowData.threatLevel.levelName) {
                        rowSpanCount++;
                    } else {
                        break;
                    }
                }
                args.cell.setAttribute('rowSpan', rowSpanCount.toString());
            } else {
                (args.cell as HTMLElement).style.display = 'none';
            }
        }
    }

    actionComplete(args: any) {
        if (args.requestType === 'paging') {
            this.grid.refresh();
        }
    }

    filterCategory(category: string, event: any): void {
        if (event.checked) {
            this.selectedCategories.push(category);
        } else {
            const index = this.selectedCategories.indexOf(category);
            if (index > -1) {
                this.selectedCategories.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    shareCheckedList(item: any[]) {
        console.log(item);
    }
    shareIndividualCheckedList(item: {}) {
        console.log(item);
    }

    filterThreatLevel(event: any): void {
        let tl = event.itemData.levelName;
        if (event.name === "select") {
            this.selectedThreatLevels.push(tl);
        }
        if (event.name === "removing") {
            const index = this.selectedThreatLevels.indexOf(tl);
            if (index > -1) {
                this.selectedThreatLevels.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    // Assuming this.selectedCategories and this.selectedThreatLevels are arrays of selected values.
    applyFilters() {
        const selectedCategories = this.selectedCategories;
        const selectedThreatLevels = this.selectedThreatLevels.map(level => level);

        const filteredData = this.adminThreats.filter(item => {
            const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(item.category.categoryName);
            const threatLevelMatch = selectedThreatLevels.length === 0 || selectedThreatLevels.includes(item.threatLevel.levelName);
            return categoryMatch && threatLevelMatch;
        });

        this.grid.dataSource = filteredData;
        this.grid.refresh();
    }
    getSubmitInfo() {
        this.adminService.threatSubmitInfo(this.org).subscribe(item => {
            this.isSubmitted = item;
            if (this.isSubmitted) {
                document.getElementById('submitCatalogueBtn').style.display = 'none';
            }
        },
            error => {
                console.error('Error fetching threats submit info:', error);
            })
    }

    getAdminThreats() {
        this.adminService.getOrganizationAdminThreats(this.org).subscribe(item => {
            this.adminThreats = item;
        },
            error => {
                console.error('Error fetching threats:', error);
            })
    }

    getCategories() {
        this.adminService.getCategories().subscribe(item => {
            this.categories = item;
        },
            error => {
                console.error('Error fetching categories:', error);
            })
    }


    getRiskTypes() {
        this.adminService.getRiskTypes().subscribe(item => {
            this.riskTypes = item;
        },
            error => {
                console.error('Error fetching risk types:', error);
            })
    }

    getActors() {
        this.adminService.getActors().subscribe(item => {
            this.actors = item;
        },
            error => {
                console.error('Error fetching actors:', error);
            })
    }

    getThreatLevels() {
        this.adminService.getThreatLevels().subscribe(item => {
            this.threatLevels = item;
        },
            error => {
                console.error('Error fetching threat levels:', error);
            })
    }

    getIntentions() {
        this.adminService.getIntentions().subscribe(item => {
            this.intentions = item;
        },
            error => {
                console.error('Error fetching intentions:', error);
            })
    }

    deleteThreatLink(e: ClickEventArgs) {
        if (e.text) {
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {
                let index = e.data["value"];
                if (index) {
                    this.adminService.deleteThreatRule(index).subscribe();
                }
                this.threatRules.refresh = true;
            }
        }
    }
}


