import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { GridLine, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { ReportPreviewReadonlyComponent } from "./modals/report-preview-readonly";

@Component({
    selector: 'archive-maintenance',
    templateUrl: 'archive-maintenance.html',
    styleUrls: ['archive-maintenance.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F7F8FA; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`]
})
export class ArchiveMaintenanceComponent implements OnInit, OnChanges, AfterViewChecked {
    @ViewChild(ReportPreviewReadonlyComponent, { static: false }) childCRP: ReportPreviewReadonlyComponent;
    public reportData = [];
    public lines: GridLine;
    @Input() categoryId: any;
    @Input() category: any;
    @Output() showLog = new EventEmitter<number>();
    controlId: number;
    showWheelReport: boolean;
    @Input() refresh: boolean;

    @ViewChild('RetrieveDialog')
    public ArchiDialog: DialogComponent;


    public a_dialogPosition: { X: "Center", Y: "Center" }

    public a_dialogWidth = '70%';
    public a_dialogCEHeight = '100%';
    bpId: string;
    versionNo: any;
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.getReport();
        this.lines = 'None';
    }

    constructor(private service: DocMaintenanceService) { }
    ngAfterViewChecked(): void {
        if (this.refresh === true) {
            this.getReport();
            this.bpId = sessionStorage.getItem("bpId")
            this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
            this.refresh = false;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.getReport();
    }

    getReport() {
        if (this.categoryId !== undefined)
            this.service.getControlArchiveReport(this.bpId, this.categoryId, this.versionNo).subscribe(data => {
                this.reportData = data;
                this.reportData.forEach(x => {
                    x.submittionDate = new Date(x.submittionDate).toISOString().slice(0, 10)
                })
            })
    }

    getControl(id) {
        this.service.getControlEventById(id).subscribe(_data => {
            this.ArchiDialog.header = '<label style="font-family: Rubik;font-weight: 600;' +
                'font-size: 20px; color: #0C2B57;">' + _data.displayId + ' Control report</label>';
            this.ArchiDialog.show();
        })
    }

    retrieveReport(id: number) {
        this.getControl(id);
        this.controlId = id;
        this.childCRP.refresh = true;
    }

    customiseCell(args: QueryCellInfoEventArgs) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (index == "0") {
            args.cell.classList.add('background-grey');
        }
        if (count % 2 == 0) {
            args.cell.classList.add('background-grey');
        }
    }

    showMLog(id) {
        this.showLog.emit(id);
    }
}


