import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { OrganizationService } from "src/app/shared/services/organization.service";

@Component({
    selector: 'admin-functions',
    templateUrl: './admin-functions.html',
    styleUrls: ['admin-functions.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AdminFunctionsComponent implements OnInit {
    constructor(private organizationservice: OrganizationService) { }
    ngOnInit(): void {
        this.getOrganizationsList();
    }
    private organizations: any = [];
    isOrgSet = false;
    currentOrgId:string;
    getOrganizationsList() {
        this.organizationservice.getOrganizationsList().subscribe(data => {
            this.organizations = data;
        })
    }
    enableReset(id){
        this.currentOrgId = id;
        this.isOrgSet = true;
    }
    reset(){
        this.organizationservice.resetFunctions(this.currentOrgId).subscribe();
        this.isOrgSet = false;
    }

    resetThreats(){
        this.organizationservice.resetThreats(this.currentOrgId).subscribe();
        this.isOrgSet = false;
    }
}