import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import { StandardControlDomainModel } from "src/app/interfaces/RiskAnalysis/StandardControlDomainModel";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
    selector: 'standard-controls',
    templateUrl: 'standard-controls.component.html',
    styleUrls: ['standard-controls.component.css']
})


export class StandardControlComponent implements OnInit {
    @Input() threat: any;
    @Input() uniqueCombination: string;

    public tabIndex;
    public control: number;
    public controlsList = [];
    public domain: Observable<StandardControlDomainModel[]>;
    public domain_cis: Observable<StandardControlDomainModel[]>;
    constructor(private riskService: RiskService) { }

    ngOnInit(): void {
        this.getControlsList();
        this.tabIndex = 1;
    }
    public headerText: Object = [{ text: '<img src="/assets/icons/Security/isoLogo.svg">' },
    { text: '<img src="/assets/icons/Security/cisLogo.svg">' }];

    showContent(controlId: number, domainId: number) {
        this.control = controlId;

        if (controlId === 1) {
            this.domain_cis = undefined;
            this.domain =
                this.controlsList.
                    filter(x => x.standardControlId == controlId)[0].domains.filter(x => x.domainId == domainId)[0];
            document.getElementById('domainContainer_' + this.uniqueCombination + '_'+controlId+ '').style.display = "none";
        }
        if (controlId === 2) {
            this.domain = undefined;
            this.domain_cis =
                this.controlsList.
                    filter(x => x.standardControlId == controlId)[0].domains.filter(x => x.domainId == domainId)[0];
            document.getElementById('domainContainer_' + this.uniqueCombination + '_'+controlId+ '').style.display = "none";
        }
    }
    showdomainsList(controlId: number) {
        document.getElementById('domainContainer_' + this.uniqueCombination + '_'+controlId+'').style.display = "block";
        this.domain = undefined;
        this.domain_cis = undefined;
    }

    getControlsList() {
        if (sessionStorage.getItem("standardControls"))
            this.controlsList = JSON.parse(sessionStorage.getItem("standardControls"));

        else {
            this.riskService.getStandardControlsList().subscribe(data => {
                this.controlsList = data;
                if (data)
                    sessionStorage.setItem("standardControls", JSON.stringify(data));
            })
        }
    }


    onTabSelect(args: any) {
        this.tabIndex = args.selectedIndex + 1;
        this.domain = undefined;
        this.domain_cis = undefined;

        var container = document.getElementById('domainContainer_' + this.uniqueCombination+"_"+this.tabIndex + '');
        if(container!==undefined && container!==null)
        container.style.display = "block";
    }
}