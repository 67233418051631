import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AutoComplete, DropDownList } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent, GridLine, IEditCell, QueryCellInfoEventArgs, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { hideSpinner, showSpinner } from "@syncfusion/ej2-angular-popups";
import { L10n } from "@syncfusion/ej2-base";
import { DataManager, Query } from "@syncfusion/ej2/data";
import { hiddenMerge } from "@syncfusion/ej2/spreadsheet";
import { ActionPlanService } from "src/app/shared/services/actionPlanService";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

L10n.load({
    'en-US': {
        'grid': {
            'Add': 'Add'
        }
    }
});
@Component({
    selector: 'app-action-plan',
    templateUrl: 'actionPlan.html',
    styleUrls: ['actionPlan.css'],
    encapsulation: ViewEncapsulation.None,
    styles: [` 
    .background-grey{ 
      background-color: #F9F9F9; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`]
})
export class ActionPlanComponent implements OnInit {
    constructor(private actionPlanService: ActionPlanService, private maintenanceService: DocMaintenanceService) { }

    public toolbar: ToolbarItems[] | object;
    public lines: GridLine;
    public role;
    public formatoptions = { type: 'dateTime', format: 'dd/MM/yyyy' }
    public priorityParams: IEditCell;
    public statusParams: IEditCell;
    public requiredrules: Object;
    public isInitialLoad: boolean = false;
    public actionsList = [];
    public editSettings: Object;
    public pageSettings: Object;
    public defaultObservationId;
    public pageSizes: number[] = [3, 5, 10];
    public status = [];
    @ViewChild('my_action_grid') grid: GridComponent;

    public priorityElem: HTMLElement;
    public priorityObj: DropDownList;

    public statusElem: HTMLElement;
    public statusObj: DropDownList;

    public priority: { [key: string]: Object }[] = [
        { name: '1', id: '1' },
        { name: '2', id: '2' },
        { name: '3', id: '3' }
    ];

    ngOnInit(): void {
        this.role = sessionStorage.getItem("role");
        this.requiredrules = { required: true };
        this.lines = 'None';
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
        this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[1], pageSizes: this.pageSizes };

        this.priorityParams = {
            create: () => {
                this.priorityElem = document.createElement('input');
                return this.priorityElem;
            },
            read: () => {
                return this.priorityObj.text;
            },
            destroy: () => {
                this.priorityObj.destroy();
            },
            write: (args) => {
                this.priorityObj = new DropDownList({
                    dataSource: this.priority,
                    fields: { value: 'id', text: 'name' },
                    value: args.requestType === 'add' ? '1' : (args.rowData as any).priority,
                    floatLabelType: 'Never'
                });
                this.priorityObj.appendTo(this.priorityElem);
            }
        };

        this.statusParams = {
            create: () => {
                this.statusElem = document.createElement('input');
                return this.statusElem;
            },
            read: () => {
                return this.statusObj.text;
            },
            destroy: () => {
                this.statusObj.destroy();
            },
            write: (args) => {
                this.statusObj = new DropDownList({
                    dataSource: this.status,
                    fields: { value: 'id', text: 'name' },
                    value: args.requestType === 'add' ? this.status[0].id : args.rowData.status == null ? this.status[0].id : (args.rowData as any).status.id,
                    floatLabelType: 'Never'
                });
                this.statusObj.appendTo(this.statusElem);
            }
        };
    }
    getStatuses() {
        this.actionPlanService.getActionStatuses().subscribe(data => {
            this.status = data;
        })
    }

    customiseCell(args: QueryCellInfoEventArgs) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (index == "0") {
            args.cell.classList.add('background-grey');
        }
        if (count % 2 == 0) {
            args.cell.classList.add('background-grey');
        }
    }
    public load(): void {
        this.isInitialLoad = true;
    }
    public beforeDataBound(e): void {
        let fn = () => {
            this.grid.showSpinner();
            this.grid.off('toolbar-refresh', fn);
            if (!this.isInitialLoad && e.result.length == 0) {
                this.grid.hideSpinner();
            }
            this.isInitialLoad = false;
        };
        this.grid.on('toolbar-refresh', fn);
    }

    getPlans() {
        this.actionPlanService.getActionPlans().subscribe(data => {
            this.actionsList = data;
        })
    }

    getDefaultObservationId() {
        this.actionPlanService.getDefaultObservationId().subscribe(data => {
            this.defaultObservationId = data;
        })
    }
    rowSelected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    }
    rowDeselected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    }
}