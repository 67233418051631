import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnModel, FilterSettingsModel, GridComponent } from "@syncfusion/ej2-angular-grids";
import { ItemModel } from "@syncfusion/ej2-angular-navigations";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { ActionDetails } from "src/app/interfaces/ActionPlan/ActionDetails";
import { InitialBaselineAction } from "src/app/interfaces/ActionPlan/SubRuleActions";
import { ActionDetailsFields } from "src/app/interfaces/Maintenance/InitialBaseline/InitialBaselineObservation";
import { ActionPlanService } from "src/app/shared/services/actionPlanService";
import { GanttService } from "src/app/shared/services/ganntService";

@Component({
    selector: 'initial-action-plan',
    templateUrl: 'initial-action-plan.html',
    styleUrls: ['initial-action-plan.css'],
    encapsulation: ViewEncapsulation.None
})
export class InitialActionPlanComponent implements OnInit, AfterViewInit {
    constructor(
        private actionPlanService: ActionPlanService,
        private router: Router,
        private ganttDataService: GanttService,
        @Inject(ViewContainerRef) private viewContainerRef?: ViewContainerRef) { }

    public actionPlanData: any[] = [];
    public originalActionPlanData: any[] = [];

    public actionDetails: any;
    public actionColumns: any[] = [];
    public actionStatuses: any = [];
    public dimensions: any = [];
    public ddlfields: Object = { text: 'name', value: 'id' };
    public dialogWidth: '30%';
    public detailsHeight = '100%';
    public detailsWidth = '35%';
    public dialogdragging = 'true';
    public dialogPosition: { X: "Center", Y: "Top" }
    public selectedStatuses: string[] = [];
    public selectedDimension: string = '';
    public actionId;
    public subRuleId;
    public startDate;
    public endDate;
    public isCustom;
    public initialBaselineId;
    public filterOptions?: FilterSettingsModel;
    @ViewChild('initialActionGrid')
    public grid?: GridComponent;
    public currentAction: InitialBaselineAction;
    // public actionColumns: ColumnModel[] = [];
    // @ViewChild('idTemplate', stat) public idtemplate: any;
    // @ViewChild('discTemplate') public descriptionTemplate: any;
    @ViewChild('idTemplate', { static: true }) public idTemplate!: TemplateRef<any>;
    @ViewChild('descTemplate', { static: true }) public descTemplate!: TemplateRef<any>;
    @ViewChild('dateDialog')
    public dateDialog: DialogComponent;

    @ViewChild('detailDialog')
    public detailDialog: DialogComponent;

    @ViewChild('detailReadonlyDialog')
    public detailRedonlyDialog: DialogComponent;

    @ViewChild('cancelDialog')
    public cancelDialog: DialogComponent;

    ngOnInit(): void {
        this.getInitialActionPlan();
        this.getActionStatuses();
        this.getDimensions();
        this.filterOptions = {
            type: 'Menu'
        };
    }

    // public actionColumns: ColumnModel[] = [
    //     {
    //         field: 'initialBaselines',
    //         headerText: 'ID',
    //         width: 100,
    //         template: this.idtemplate
    //     },
    //     {
    //         field: 'initialBaselines',
    //         headerText: 'Description',
    //         width: 200,
    //         template: this.descriptionTemplate
    //     }
    // ];
    ngAfterViewInit(): void {
        // this.idtemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        // this.idtemplate.elementRef.nativeElement.propName = 'template';

        // this.descriptionTemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        // this.descriptionTemplate.elementRef.nativeElement.propName = 'template';

        this.setupGridColumns();
    }
    public items: ItemModel[] = [
        {
            text: 'Active'
        },
        {
            text: 'Cancel'
        }];


    public field: Object = { text: 'text', id: 'id' };

    private setupGridColumns(): void {
        this.actionColumns = [
            {
                field: 'subRuleName',
                headerText: 'Sub-rules',
                width: 300,
                textAlign: 'Center'
            },
            {
                headerText: 'Proposed actions',
                columns: [
                    {
                        field: 'initialBaselines',
                        headerText: 'ID',
                        width: 100,
                        template: this.idTemplate
                    },
                    {
                        field: 'initialBaselines',
                        headerText: 'Description',
                        width: 200,
                        template: this.descTemplate
                    }
                ]
            },
            {
                field: 'currentComplianceLevel',
                headerText: 'Current compliance level',
                width: 150,
                textAlign: 'Left'
            },
            {
                field: 'targetComplianceLevel',
                headerText: 'Target compliance level',
                width: 150,
                textAlign: 'Left'
            },
            {
                field: 'dimension',
                headerText: 'Dimension',
                width: 200,
                textAlign: 'Left'
            },
            {
                headerText: 'Estimated compliance effect',
                width: 100,
                textAlign: 'Left'
            },
            {
                headerText: 'Project phase',
                width: 100,
                textAlign: 'Left'
            },
            {
                headerText: 'Operations phase',
                width: 100,
                textAlign: 'Left'
            },
            {
                headerText: 'Status',
                width: 100,
                textAlign: 'Left'
            }
        ];
    }

    filterDimension(event: any): void {
        this.selectedDimension = event.itemData.name;

        // Apply the filter
        this.applyDimensionFilter();
    }

    applyDimensionFilter(): void {
        if (this.selectedDimension === 'All') {
            this.grid.dataSource = this.actionPlanData;
        } else {
            const filteredData = this.actionPlanData.map(item => {
                const filteredInitialBaselines = item.initialBaselines.filter(init =>
                    init.initialBaselineName === this.selectedDimension
                );

                return {
                    ...item,
                    initialBaselines: filteredInitialBaselines
                };
            }).filter(item => item.initialBaselines.length > 0);

            if (filteredData.length > 0) {
                this.grid.dataSource = filteredData;
            } else {
                this.grid.dataSource = [];
            }
        }

        // Refresh the grid to display the updated data source
        this.grid.refresh();
    }

    filterStatus(statusName, event: any): void {
        const isChecked = event.checked;

        if (isChecked) {
            this.selectedStatuses.push(statusName);
        } else {
            const index = this.selectedStatuses.indexOf(statusName);
            if (index > -1) {
                this.selectedStatuses.splice(index, 1);
            }
        }
        this.applyStatusFilter();
    }

    applyStatusFilter(): void {
        if (this.selectedStatuses.length == 0) {
            this.grid.dataSource = this.actionPlanData;
            this.grid.refresh();
            return;
        }
        const filteredData = this.actionPlanData.map(item => {
            const filteredInitialBaselines = item.initialBaselines.map(init => {
                const filteredActions = init.initialBaselineActions.filter(action =>
                    this.selectedStatuses.includes(action.status.statusName)
                );

                return {
                    ...init,
                    initialBaselineActions: filteredActions
                };
            }).filter(init => init.initialBaselineActions.length > 0);

            return {
                ...item,
                initialBaselines: filteredInitialBaselines
            };
        }).filter(item => item.initialBaselines.length > 0);
        this.grid.dataSource = filteredData;
        this.grid.refresh();
    }

    isShouldRender(field: ActionDetailsFields, fields: any): boolean {
        if (field.parentFieldId == null)
            return true;
        if (field.parentFieldId != null) {
            var parent = fields.filter(x => x.actionDetailsFieldId == field.parentFieldId);
            if (parent[0].shouldRender)
                if (parent[0].type == 2 && parent[0].actionDetailsValue.value == field.parentFieldTriggerValue) {
                    return true;
                }
                else return false;
        }
        else return false;
    }

    handleChange(field, event) {
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.value = event.value;
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.actionId = this.currentAction.actionId;
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.hasChanged = true;
        this.currentAction["details"].actionDetailsFields?.forEach(field => field.shouldRender = false);
        for (var i = 0; i < this.currentAction["details"].actionDetailsFields.length; i++) {
            this.currentAction["details"].actionDetailsFields[i].shouldRender = this.isShouldRender(this.currentAction["details"].actionDetailsFields[i], this.currentAction["details"].actionDetailsFields);
        }
    }

    fieldValueChanged(field, args) {
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.value = args;
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.actionId = this.currentAction.actionId;
        this.currentAction["details"].actionDetailsFields
            .filter(x => x.actionDetailsFieldId == field.actionDetailsFieldId)[0].actionDetailsValue.hasChanged = true;
    }

    showDialog() {
        this.dateDialog.header = '<label style="color: #0E384D;' +
            'font-family: Rubik;' +
            'font-size: 32px;' +
            'font-style: normal;' +
            'font-weight: 300;">Status: Active</label>';
        this.dateDialog.show();
    }

    showDetails(ac, initialBaselineId) {
        this.actionId = ac.actionId;
        this.subRuleId = ac["details"].subRuleId;
        this.isCustom = ac["details"].isCustom;
        this.initialBaselineId = initialBaselineId;
        for (var i = 0; i < ac.details.actionDetailsFields.length; i++) {
            ac.details.actionDetailsFields[i].shouldRender = this.isShouldRender(ac.details.actionDetailsFields[i], ac.details.actionDetailsFields);
        }
        this.currentAction = ac;
        this.detailDialog.header = '<label style="color: #0E384D;' +
            'font-family: Rubik;' +
            'font-size: 32px;' +
            'font-style: normal;' +
            'font-weight: 300;">Details</label>';
        this.detailDialog.show();
    }

    showCancelDetails(ac) {
        for (var i = 0; i < ac.details.actionDetailsFields.length; i++) {
            if (ac.details.actionDetailsFields[i].title == "Change status") {
                ac.details.actionDetailsFields[i].actionDetailsValue.value = "Yes";
                ac.details.actionDetailsFields[i].actionDetailsValue.isHidden = true;
                ac.details.actionDetailsFields[i].actionDetailsValue.hasChanged = true;
                ac.details.actionDetailsFields[i].actionDetailsValue.actionId = ac.actionId;
            }
            if (ac.details.actionDetailsFields[i].title == "New status") {
                ac.details.actionDetailsFields[i].actionDetailsValue.value = "Cancel";
                ac.details.actionDetailsFields[i].actionDetailsValue.isHidden = true;
                ac.details.actionDetailsFields[i].actionDetailsValue.hasChanged = true;
                ac.details.actionDetailsFields[i].actionDetailsValue.actionId = ac.actionId;
            }
            ac.details.actionDetailsFields[i].shouldRender = this.isShouldRender(ac.details.actionDetailsFields[i], ac.details.actionDetailsFields);
        }
        this.currentAction = ac;
        this.cancelDialog.header = '<label style="color: #0E384D;' +
            'font-family: Rubik;' +
            'font-size: 32px;' +
            'font-style: normal;' +
            'font-weight: 300;">Status: Cancel</label>';
        this.cancelDialog.show();
    }

    showReadonlyDetails(ac) {
        this.currentAction = ac;
        this.detailRedonlyDialog.header = '<label style="color: #0E384D;' +
            'font-family: Rubik;' +
            'font-size: 32px;' +
            'font-style: normal;' +
            'font-weight: 300;">Status: Done</label>';
        this.detailRedonlyDialog.show();
    }

    redirectToPage(ac, args, actionId, subRuleId, isCustom, initialBaselineId) {
        this.actionId = actionId;
        this.subRuleId = subRuleId;
        this.isCustom = isCustom;
        this.initialBaselineId = initialBaselineId;
        if (args.text === this.items[0].text) {
            this.showDialog();
        }
        if (args.text === this.items[1].text) {
            this.showCancelDetails(ac);
        }
    }

    setActive() {
        var details = {
            id: 0,
            actionId: this.actionId,
            subRuleId: this.subRuleId,
            startDate: this.startDate,
            endDate: this.endDate,
            isCustom: this.isCustom
        }
        this.actionPlanService.addActionDetails(details).subscribe(res => {
            if (res !== null) {
                this.actionPlanData
                    .filter(x => x.subRuleId == this.subRuleId && x.isCustom == this.isCustom)[0].initialBaselines
                    .filter(x => x.initialBaselineId == this.initialBaselineId)[0].initialBaselineActions
                    .filter(x => x.actionId == this.actionId)[0].status = res;
            }
        })
        this.dateDialog.hide();
    }

    getInitialActionPlan() {
        const orgId = sessionStorage.getItem("customerId");
        this.actionPlanService.getInitialActionPlan(orgId).subscribe(data => {
            this.actionPlanData = data;
            this.originalActionPlanData = data;
        })
    }

    getActionStatuses() {
        this.actionPlanService.getActionStatuses().subscribe(data => {
            this.actionStatuses = data;
        })
    }

    getDimensions() {
        this.actionPlanService.getDimensions().subscribe(data => {
            this.dimensions = data;
            this.dimensions.push({ id: 0, name: 'All' })
        })
    }

    changeStartDate(args: any) {
        this.startDate = args.value;
    }

    changeEndDate(args: any) {
        this.endDate = args.value;
    }

    submitDetails() {
        const filteredDetails = this.currentAction["details"].actionDetailsFields.filter(field => field.actionDetailsValue.hasChanged);
        this.currentAction["details"].actionDetailsFields = filteredDetails;
        this.actionPlanService.addActionDetailsFields(this.currentAction).subscribe(res => {
            if (res !== null) {
                this.actionPlanData
                    .filter(x => x.subRuleId == this.subRuleId && x.isCustom == this.isCustom)[0].initialBaselines
                    .filter(x => x.initialBaselineId == this.initialBaselineId)[0].initialBaselineActions
                    .filter(x => x.actionId == this.actionId)[0].status = res;
            }
        })
        this.detailDialog.hide();
    }

    cancelDetails() {
        const filteredDetails = this.currentAction["details"].actionDetailsFields.filter(field => field.actionDetailsValue.hasChanged);
        this.currentAction["details"].actionDetailsFields = filteredDetails;
        this.actionPlanService.addActionDetailsFields(this.currentAction).subscribe(res => {
            if (res !== null) {
                this.actionPlanData
                    .filter(x => x.subRuleId == this.subRuleId && x.isCustom == this.isCustom)[0].initialBaselines
                    .filter(x => x.initialBaselineId == this.initialBaselineId)[0].initialBaselineActions
                    .filter(x => x.actionId == this.actionId)[0].status = res;
            }
        })
        this.cancelDialog.hide();
    }

    showGanntChart() {
        const filteredData = this.actionPlanData.map(item => {
            const filteredInitialBaselines = item.initialBaselines.map(init => {
                const filteredActions = init.initialBaselineActions.filter(action =>
                    action.status.statusName === 'Active'
                );

                return {
                    ...init,
                    initialBaselineActions: filteredActions
                };
            }).filter(init => init.initialBaselineActions.length > 0);

            return {
                ...item,
                initialBaselines: filteredInitialBaselines
            };
        }).filter(item => item.initialBaselines.length > 0);

        // Convert the data
        let convertedData = this.convertDataStructure(filteredData);

        console.log(convertedData);

        this.ganttDataService.setGanttData(convertedData);

        this.router.navigate([`/initial-action-plan/gantt`]);
    }

    convertDataStructure(data) {
        return data.map(item => ({
            taskId: item.subRuleId,
            taskName: item.subRuleDisplayId + ' ' + item.subRuleName,
            subRuleDisplayId: item.subRuleDisplayId,
            isCustom: item.isCustom,
            subTasks: item.initialBaselines.map(baseline => ({
                taskId: baseline.initialBaselineId,
                taskName: baseline.initialBaselineName,
                initialBaselineComplianceLevel: baseline.initialBaselineComplianceLevel,
                subTasks: baseline.initialBaselineActions.map(action => ({
                    taskId: action.actionId,
                    registeredDateTime: action.registeredDateTime,
                    complianceEffect: action.complianceEffect,
                    status: action.status,
                    taskName: action.description,
                    completionDateTime: action.completionDateTime,
                    displayId: action.displayId,
                    phases: action.phases,
                    details: action.details
                }))
            })),
            baselineEstablished: item.baselineEstablished
        }));
    }
}
