import { Component, HostBinding, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { BusinessCountData } from 'src/app/interfaces/Dashboard/BusinessCountData';
import { AdministrationService } from '../../services/admin.service';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['dashboard.component.css'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private adminService: AdministrationService) {

  }
  @HostBinding('class.card-details-min-width') shouldApplyMinWidth = true;
  private businessCountData: BusinessCountData;
  ngOnInit(): void {
    this.GetBusinessCountData();
  }

  GetBusinessCountData() {
    if (sessionStorage.getItem("customerId")) {
      this.dashboardService.GetBusinessCountData(sessionStorage.getItem("customerId")).subscribe(item => {
        this.businessCountData = item;
      })
    }
    else {
      this.adminService.getDummyCustomer().subscribe(data => {
        sessionStorage.setItem("customerId", data.customerID);
        this.dashboardService.GetBusinessCountData(data.customerID).subscribe(item => {
          this.businessCountData = item;
        })
      })
    }
  }
}
