import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DataTransfersFormComponent } from "../../data-transfers-form.component";

@Component({
    selector: 'derogations',
    styleUrls: ['derogations.component.css'],
    templateUrl: 'derogations.component.html',
})
export class DerogationsComponent implements OnInit, AfterViewChecked {
    @Input() recipientId: string;
    @Input() model: any;
    public derogations: any[];
    isFirstRun: boolean;
    bpId: string;
    versionNo: any;
    constructor(private service: DataTransferService, private parent: DataTransfersFormComponent) { }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.model !== undefined) {
                if (this.derogations !== undefined) {
                    if (this.model.derogations.length > 0) {
                        for (var i = 0; i < this.model.derogations.length; i++) {
                            document.getElementById('derogationAddBtn_' + this.model.derogations[i].id + '').style.display = "none";
                            document.getElementById('derogationModule_' + this.model.derogations[i].id + '').closest('div').style.background = "#DBEEF7";
                        }
                    }
                    this.isFirstRun = false;
                }
            }
        }
    }
    ngOnInit(): void {
        this.getDerogationsList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
    }

    getDerogationsList() {
        this.service.getDerogations().subscribe(x => {
            this.derogations = x;
        })
    }

    AddDerogation(item) {
        document.getElementById('derogationAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('derogationModule_' + item.id + '').closest('div').style.background = "#DBEEF7";
        this.service.linkDerogation(this.recipientId, item.id, this.versionNo).subscribe();
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        this.parent.derogationChips.add(chip)
    }

    RevertAdd(id) {
        document.getElementById('derogationAddBtn_' + id + '').style.display = "block";
        document.getElementById('derogationModule_' + id + '').closest('div').style.background = "#EDEFF3";
    }
}