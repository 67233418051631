// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.domainContentSeparator{
    margin: 5px 0px 5px 0px;
    height: 1px;
    width: 90%;
    background: white;
    clear: both;
}

.domainContainer{
    padding: 15px;
    background: #EDEFF3;
}

.domainContent{
    font-family: 'Rubik';
    color: #142A56;
    font-weight: 500;
    font-size: 14px;
    float: left;
    width: 60%;
}
.domainHeader{
    color: #8D8EA1;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
}

.domainSpecificationsLink{
    font-family: 'Rubik';
    color: #63A5DA;
    font-weight: 500;
    font-size: 14px;
    float: right;
    width: 30%;
    cursor: pointer;
}

#tab_control .e-toolbar-item.e-active{
    background-color: #EDEFF3;
    }
#tab_control .e-toolbar-item.e-active{
        border-bottom: none;
    }
#tab_control .e-indicator{
        background: none !important;
    }`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/modals/standard-controls.component.css"],"names":[],"mappings":"AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,UAAU;IACV,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,UAAU;AACd;AACA;IACI,cAAc;IACd,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB;AACJ;QACQ,mBAAmB;IACvB;AACJ;QACQ,2BAA2B;IAC/B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.domainContentSeparator{\r\n    margin: 5px 0px 5px 0px;\r\n    height: 1px;\r\n    width: 90%;\r\n    background: white;\r\n    clear: both;\r\n}\r\n\r\n.domainContainer{\r\n    padding: 15px;\r\n    background: #EDEFF3;\r\n}\r\n\r\n.domainContent{\r\n    font-family: 'Rubik';\r\n    color: #142A56;\r\n    font-weight: 500;\r\n    font-size: 14px;\r\n    float: left;\r\n    width: 60%;\r\n}\r\n.domainHeader{\r\n    color: #8D8EA1;\r\n    font-family: 'Rubik';\r\n    font-weight: 500;\r\n    font-size: 16px;\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.domainSpecificationsLink{\r\n    font-family: 'Rubik';\r\n    color: #63A5DA;\r\n    font-weight: 500;\r\n    font-size: 14px;\r\n    float: right;\r\n    width: 30%;\r\n    cursor: pointer;\r\n}\r\n\r\n#tab_control .e-toolbar-item.e-active{\r\n    background-color: #EDEFF3;\r\n    }\r\n#tab_control .e-toolbar-item.e-active{\r\n        border-bottom: none;\r\n    }\r\n#tab_control .e-indicator{\r\n        background: none !important;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
