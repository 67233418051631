import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { RightsOfDataSubjectsComponent } from "../../rights-of-data-subjects.component";
import { RightsOfDataSubjectsService } from "src/app/shared/services/rightsOfDataSubjectsService";

@Component({
    selector: 'restrictions',
    styleUrls: ['restrictions.css'],
    templateUrl: 'restrictions.html',
})
export class RestrictionsComponent implements OnInit, AfterViewChecked {
    @Input() field: any;
    @Input() model: any;
    @Input() versionNo: number;
    restrictions: any[];
    isFirstRun: boolean;
    bpId: string;

    constructor(private service: RightsOfDataSubjectsService, private parent: RightsOfDataSubjectsComponent) { }
    ngOnInit(): void {
        this.getRestrictionsList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId");
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.restrictions !== undefined) {
                if (this.model !== undefined && this.model.length > 0) {
                    for (var i = 0; i < this.model.length; i++) {
                        document.getElementById('restrictionsAddBtn_' + this.model[i].id + '').style.display = "none";
                        document.getElementById('removeRestrictionsBtn_' + this.model[i].id + '').style.display = "block";
                    }
                }
                this.isFirstRun = false;
            }
        }
    }

    getRestrictionsList() {
        this.service.getRestrictionsModels().subscribe(x => {
            this.restrictions = x;
        })
    }
    AddRestrictions(item) {
        document.getElementById('restrictionsAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('removeRestrictionsBtn_' + item.id + '').style.display = "block";
        this.service.enableRestriction(this.field, this.versionNo, item);
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        this.parent.restrictionsChips.add(chip)
    }

    DisableRestrictions(id) {
        this.service.disableRestriction(this.field, this.versionNo, id).subscribe();
        document.getElementById('removeRestrictionsBtn_' + id + '').style.display = "none";
        document.getElementById('restrictionsAddBtn_' + id + '').style.display = "block";
        this.parent.restrictionsModelData
        .splice(this.parent.restrictionsModelData.findIndex(item => item.id === id), 1);
        this.model.splice(this.model.findIndex(x => x.id == id), 1);
    }
}