import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { OrganizationService } from "../../services/organization.service";
import { AdministrationService } from "../../services/admin.service";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { OrganizationIndustries } from "src/app/interfaces/Organization/OrganizationIndustries";
import { OrganizationPublicServices } from "src/app/interfaces/Organization/OrganizationPublicServices";
import { CommandModel, GridComponent, IEditCell, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { Organizations } from "src/app/interfaces/Organization/Organizations";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { EntityTypes, LicensedActivity } from "src/app/interfaces/Organization/EntityTypes";
import { OrganizationFields } from "src/app/interfaces/Organization/OrganizationFields";

@Component({
    selector: 'organization-basic-info',
    templateUrl: './basic-info.html',
    styleUrls: ['basic-info.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    encapsulation: ViewEncapsulation.None,
})
export class BasicInfoComponent implements OnInit {
    fileName: any;
    constructor(
        private organizationService: OrganizationService,
        private adminService: AdministrationService,
        private mainLayout: MainLayoutComponent) { }

    public editSettings: Object;
    private industries = [];
    private countries = [];
    private currencies = [];
    private entityTypes = [];
    private sectors = [];
    private timeCriticalOptions = [];
    private impactOptions = [];
    private publicServices = [];
    private functions = [];
    private initialFunctions = [];
    private newFunctionData = [];
    private organizations;
    private predefinedIndustry;
    private predefinedPublicService;
    private selectedEntityType;
    private licensedActivities;
    public gridProperties;
    public initialRender: Boolean = true;
    currentModel: any;
    currentIndex: number;
    isPublicServices: boolean;
    isIndustries: boolean;
    showFunctions: boolean;
    isFinancialFunction: boolean;
    imageUploaded: boolean;
    public toastPosition: ToastPositionModel = { X: 'Right', Y: 'Bottom' };
    public commands: CommandModel[];
    @ViewChild('IndustryDialog')
    public IndustryDialog: DialogComponent;

    @ViewChild('LADialog')
    public LADialog: DialogComponent;

    @ViewChild('PSDialog')
    public PSDialog: DialogComponent;

    @ViewChild('ETDialog')
    public ETDialog: DialogComponent;

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];

    @ViewChildren(GridComponent) grids: QueryList<GridComponent>;
    url = '';
    ngOnInit(): void {
        this.editSettings = { allowEditing: false, allowAdding: true, allowDeleting: true, newRowPosition: 'Bottom' };
        this.isPublicServices = false;
        this.isIndustries = false;
        this.showFunctions = false;
        this.isFinancialFunction = false;
        this.getEntityTypes();
        this.getOrganizationInfo();
        this.getIndustries();
        this.getSectors();
        this.getPublicServices();
        this.getCountries();
        this.getCurrencies();
        this.getImpactOptions();
        this.getTimeCriticalOptions();
        this.imageUploaded = false;

        this.commands = [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-close', cssClass: 'e-flat colorBronze' } }];
    }

    public dialogHeight = '70%';
    public dialogWidth = '35%';
    showCloseIcon = true;
    dialogResize = true;
    dialogdragging = true;

    public fields: Object = { groupBy: 'category', text: 'displayName', value: 'id' };
    public countryFields: Object = { text: 'name', value: 'id' };
    public impactOptionsFields: Object = { text: 'impactName', value: 'impactId' }
    public timeCriticalOptionsFields: Object = { text: 'timeCriticalName', value: 'timeCriticalId' }


    public impactParams: IEditCell;


    allowFiltering: boolean = true;
    public sort: string = 'Ascending';

    customiseCell(args: QueryCellInfoEventArgs) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (count % 2 !== 0) {
            args.cell.classList.add('background-grey');
        }
    }

    showIndustries() {
        this.IndustryDialog.header = '<label style="font-family: Rubik;font-weight: 300;' +
            'font-size: 32px; color: #0E384D;">Select Industry</label>';
        this.IndustryDialog.show();
    }

    showPublicServices() {
        this.PSDialog.header = '<label style="font-family: Rubik;font-weight: 300;' +
            'font-size: 32px; color: #0E384D;">Select Public service</label>';
        this.PSDialog.show();
    }

    showLicensedActivities(model, ind) {
        this.currentModel = model;
        this.currentIndex = ind;
        this.LADialog.header = '<label style="font-family: Rubik;font-weight: 300;' +
            'font-size: 32px; color: #0E384D;">Select Licensed activities</label>';
        this.LADialog.show();
    }

    showEntityTypes() {
        this.ETDialog.header = '<label style="font-family: Rubik;font-weight: 300;' +
            'font-size: 32px; color: #0E384D;">Type of entity</label>';
        this.ETDialog.show();
    }

    handleChange(field, evt) {
        field.organizationValue.value = evt.value;
        field.organizationValue.hasChanged = true;
        field.organizationValue.organizationId = this.organizations.organizationId;
        field.organizationValue.organizationFieldId = field.organizationFieldId;
        var fieldToRemove = this.organizations.fields
            .filter(x => x.parentOrganizationFieldId == field.parentOrganizationFieldId
                && x.organizationFieldId !== field.organizationFieldId && x.organizationValue.hasChanged === true)[0];
        this.organizations.fields.forEach(x => {
            x.shouldRender = false;
        })
        if (field.parentOrganizationFieldId !== null) {
            this.organizations.fields
                .filter(x => x.parentOrganizationFieldId == field.parentOrganizationFieldId
                    && x.organizationFieldId !== field.organizationFieldId).forEach(x => {
                        x.organizationValue.value = null;
                        x.organizationValue.hasChanged = false;
                    })
        }
        for (var i = 0; i < this.organizations.fields.length; i++) {
            this.organizations.fields[i].shouldRender = this.isShouldRender(this.organizations.fields[i]);
        }
        if (fieldToRemove !== undefined) {
            setTimeout(() => {
                this.removeFieldValue(fieldToRemove);
            },
                5000);
        }
        setTimeout(() => {
            this.saveFieldChanged(field);
        },
            5000);
    }

    saveFieldChanged(field: OrganizationFields) {
        this.organizationService.saveFieldChanged(field.organizationValue).subscribe(data => {
            this.organizations.fields.filter(x => x.organizationFieldId == field.organizationFieldId)[0]
                .organizationValue.organizationValueId = data["item1"];
        });
    }

    removeFieldValue(field: OrganizationFields) {
        this.organizationService.removeFieldValue(field.organizationValue.organizationValueId).subscribe(x => {
            this.organizations.fields.filter(x => x.organizationFieldId == field.organizationFieldId)[0]
                .organizationValue.organizationValueId = "00000000-0000-0000-0000-000000000000";
        });
    }

    isShouldRender(field: OrganizationFields): boolean {
        if (field.parentOrganizationFieldId == null)
            return true;
        if (field.parentOrganizationFieldId != null) {
            var parent = this.organizations.fields.filter(x => x.organizationFieldId == field.parentOrganizationFieldId);
            if (parent[0].shouldRender)
                if (parent[0].type == 2 && parent[0].organizationValue.value == field.parentOrganizationFieldTriggerValue) {
                    return true;
                }
        }
        else return false;
    }

    public add(name: string, id: string): void {
        var gridId = name + id;
        var currentGrid = this.grids.filter(x => x.element.id == gridId)[0];
        currentGrid.editModule.addRecord();
    }

    public delete(functionGroupId, data): void {
        var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
        if (currentGrid.dataSource["length"] == 1) {
            this.toasts[0].content = "Cannot remove last column from function group";
            this.toastObj.show(this.toasts[0]);
        }
        else {
            currentGrid.deleteRecord("functionId", data);
        }
    }

    actionBegin(args: any, functionGroupId): void {
        if (args.requestType === 'add') {
            args.data.functionGroupId = functionGroupId;
            args.data.isCustom = true;
            const allOrganizationFunctions = this.functions.flatMap(func => func.organizationFunctions);
            const maxFunctionId = Math.max(...allOrganizationFunctions.map(func => func.functionId));
            args.data.functionId = maxFunctionId + 1;
        }
        if (args.requestType === 'save' && args.action === 'add') {
            args.data.licensedActivities = [];
        }

        if (args.requestType === 'delete') {
            var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
            if (currentGrid.dataSource["length"] == 1) {
                this.toasts[0].content = "Cannot remove last column from function group";
                this.toastObj.show(this.toasts[0]);
                args.cancel = true;
            }
        }
    }

    getOrganizationInfo() {

        this.organizationService.getOrganizationInfo(sessionStorage.getItem("customerId")).subscribe(item => {
            this.organizations = item;
            this.organizations.fields.sort((a, b) => a.sequence - b.sequence);
            if (this.organizations.organizationSector.id !== null) {
                this.setTypeSelected(this.organizations.organizationSector.id);
            }

            if (this.organizations.entityType) {
                this.licensedActivities = this.entityTypes
                    .filter(x => x.id == this.organizations.entityType.id)[0]
                    .licensedActivities;
            }

            if (this.organizations.organizationIndustry.id !== null) {
                if (this.organizations.organizationIndustry.isPredefined && this.isIndustries) {
                    this.isFinancialFunction = true;
                    if (!this.organizations.functionsSubmit && !this.organizations.functionsPreviouslySubmit) {
                        this.getOrganizationFunctions()
                        this.showFunctions = true;
                    }
                }
            }
            if (this.organizations.functionsSubmit) {
                this.mainLayout.showHideFunction(true);
                this.showFunctions = false;
            }

            for (var i = 0; i < this.organizations.fields.length; i++) {
                this.organizations.fields[i].shouldRender = this.isShouldRender(this.organizations.fields[i]);
            }
        })
    }

    getIndustries() {
        this.organizationService.getOrganizationIndustries().subscribe(item => {
            this.industries = item;
        },
            error => {
                console.error('Error fetching industries:', error);
            })
    }

    getSectors() {
        this.organizationService.getOrganizationSectors().subscribe(item => {
            this.sectors = item;
        },
            error => {
                console.error('Error fetching sectors:', error);
            })
    }

    getCountries() {
        this.organizationService.getCountries().subscribe(item => {
            this.countries = item;
        },
            error => {
                console.error('Error fetching countries:', error);
            })
    }

    getCurrencies() {
        this.organizationService.getCurrencies().subscribe(item => {
            this.currencies = item;
            this.currencies.forEach(x => {
                x.displayName = x.name + ' ' + x.code;
                if (x.isMainGroup) {
                    x.category = 'Base';
                }
                else x.category = 'Other';
            })
        },
            error => {
                console.error('Error fetching currencies:', error);
            })
    }

    getEntityTypes() {
        this.organizationService.getEntityTypes().subscribe(item => {
            this.entityTypes = item;
        },
            error => {
                console.error('Error fetching entity types:', error);
            })
    }

    getPublicServices() {
        this.organizationService.getOrganizationPublicServices().subscribe(item => {
            this.publicServices = item;
        },
            error => {
                console.error('Error fetching public services:', error);
            })
    }

    getOrganizationFunctions() {
        this.organizationService.getOrganizationFunctions().subscribe(item => {
            this.functions = item;
            this.initialFunctions = item;
        },
            error => {
                console.error('Error fetching functions:', error);
            })
    }

    getImpactOptions() {
        this.organizationService.getImpactOptions().subscribe(item => {
            this.impactOptions = item;
            this.impactParams = {
                params: {
                    actionComplete: () => false,
                    allowFiltering: true,
                    dataSource: this.impactOptions,
                    fields: this.impactOptionsFields,
                }
            };
        },
            error => {
                console.error('Error fetching impact options:', error);
            })
    }

    getTimeCriticalOptions() {
        this.organizationService.getTimeCriticalOptions().subscribe(item => {
            this.timeCriticalOptions = item;
        },
            error => {
                console.error('Error fetching time critical options:', error);
            })
    }

    setTypeSelected(type) {
        if (type == 1) {
            this.isPublicServices = false;
            this.isIndustries = true;
            if (this.organizations.organizationIndustry.isPredefined) {
                if (!this.organizations.functionsSubmit) {
                    this.showFunctions = true;
                }
                this.isFinancialFunction = true;
            }
        }
        if (type == 2) {
            this.isIndustries = false;
            this.isPublicServices = true;
            this.showFunctions = false;
            this.isFinancialFunction = true;
        }
    }

    linkIndustry(industry: OrganizationIndustries) {
        if (industry.isPredefined) {
            if (!this.organizations.functionsSubmit && !this.organizations.functionsPreviouslySubmit) {
                this.getOrganizationFunctions();
                this.showFunctions = true;
            }
            if (!this.organizations.functionsSubmit && this.organizations.functionsPreviouslySubmit) {
                this.organizations.functionsSubmit = true;
                this.mainLayout.showHideFunction(true);
            }
            this.isFinancialFunction = true;
        }
        else {
            this.organizations.functionsSubmit = false;
            this.mainLayout.showHideFunction(false);
            this.showFunctions = false;
            this.isFinancialFunction = false;
        }
        this.predefinedIndustry = industry.name;
        this.organizations.organizationIndustry = industry;
        this.organizationService.updateOrganizationData(this.organizations).subscribe();
        this.IndustryDialog.hide();
    }

    linkPublicService(ps: OrganizationPublicServices) {
        this.showFunctions = false;
        this.isFinancialFunction = false;
        this.predefinedPublicService = ps.name;
        this.organizations.organizationPublicService = ps;
        this.organizationService.updateOrganizationData(this.organizations).subscribe();
        this.PSDialog.hide();
    }

    linkEntityType(et: EntityTypes) {
        this.selectedEntityType = et.name;
        this.organizations.entityType = et;
        this.organizationService.updateOrganizationData(this.organizations).subscribe();
        if (this.submitFunctions) {
            this.organizationService.removeFunctionsLicencedActivities(this.organizations.organizationId).subscribe();
        }
        this.licensedActivities = et.licensedActivities;
        this.ETDialog.hide();
        this.grids.forEach(element => {
            element.dataSource = []
            this.getOrganizationFunctions();
        });
    }

    fieldValueChanged(organization: Organizations) {
        this.organizationService.updateOrganizationData(organization).subscribe();
    }

    fieldValueChangedEntityTypeAsset(entityType: EntityTypes) {
        this.organizationService.updateAssetData(entityType, this.organizations.organizationId).subscribe(data => {
            this.organizations.entityType.totalAssets[0].assetId = data;
        });
    }

    fieldValueChangedEntityTypeFinancialIndicator(entityType: EntityTypes) {
        this.organizationService.updateFinancialIndicatorData(entityType, this.organizations.organizationId).subscribe(data => {
            this.organizations.entityType.financialIndicator[0].financialIndicatorId = data;
        });
    }

    fieldValueChangedCurrency(currency) {
        this.organizations.currency = currency.itemData;
        this.organizationService.updateOrganizationData(this.organizations).subscribe();
    }

    fieldValueChangedCountry(country) {
        this.organizations.country = country.itemData;
        this.organizationService.updateOrganizationData(this.organizations).subscribe();
    }

    organizationFieldValueChanged(field, evt) {
        field.organizationValue.value = evt;
        field.organizationValue.organizationId = this.organizations.organizationId;
        field.organizationValue.organizationFieldId = field.organizationFieldId;
        this.saveFieldChanged(field);
    }

    checkLei(organization: Organizations) {
        this.organizationService.getOrganizationByLeiCode(organization.leiCode).subscribe(data => {
            if (data.data.attributes.entity.legalName.name !== organization.organizationName) {
                console.error("Organization " + organization.organizationName + " doesn`t match Organization by LEI-code")
                this.toasts[0].content = "Organization " + organization.organizationName + " doesn`t match Organization by LEI-code";
                this.toastObj.show(this.toasts[0]);
            }
        },
            error => {
                console.error('Error getting by provided Lei code:', error);
                this.toasts[2].content = "Wrong LEI-code";
                this.toastObj.show(this.toasts[2]);
            })
    }

    uploadImage(organizationId: string, url) {
        this.organizationService.updateOrganizationLogo(organizationId, url).subscribe(
            () => {
                // Success case
                console.log('Logo image uploaded successfully');
                this.toasts[1].content = "Logo image uploaded successfully";
                this.toastObj.show(this.toasts[1]);
            },
            error => {
                console.error('Error on image load:', error);
                this.toasts[2].content = "Failed to upload logo image";
                this.toastObj.show(this.toasts[2]);
            });
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            const selectedFile = event.target.files[0];
            if (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg') {
                reader.readAsDataURL(selectedFile);
                this.fileName = selectedFile.name;
                reader.onload = (event) => {
                    this.imageUploaded = true;
                    this.url = event.target.result as string;
                }
            } else {
                this.toasts[0].content = "Image is of wrong type. Allowed formats: .png, .jpg";
                this.toastObj.show(this.toasts[0]);
            }
        }
    }

    saveImportantOrCriticalFunctionData(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.importantOrCritical = args.value;
        data.dateOfLatestAssessment = new Date();
        this.updateFunctionListAndDataSource(ind, index, data);
    }

    linkLA(activity: LicensedActivity, model, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        if (data.licensedActivities) {
            var existingItem = data.licensedActivities.filter(x => x.licensedActivityId == activity.licensedActivityId)[0];
            if (existingItem) {
                const indexToRemove = data.licensedActivities.findIndex(la => la.licensedActivityId === activity.licensedActivityId);
                if (indexToRemove !== -1) {
                    data.licensedActivities.splice(indexToRemove, 1);
                }
                // this.updateFunctionListAndDataSource(ind, index, data);
                return;
            }
        }
        var nextDisplayId = this.generateNewFunctionDisplayId(this.getExistingFunctionDisplayIds(ind));
        var newActivity = activity;
        newActivity.functionDisplayId = nextDisplayId;
        data.licensedActivities.push(newActivity);
    }

    getExistingFunctionDisplayIds(ind): string[] {
        const existingFunctionDisplayIds: string[] = [];
        this.functions[ind].organizationFunctions.forEach(func => {
            if (!func.licensedActivities) {
                func.licensedActivities = [];
            }
            func.licensedActivities.forEach(activity => {
                existingFunctionDisplayIds.push(activity.functionDisplayId);
            });

        });
        return existingFunctionDisplayIds;
    }

    generateNewFunctionDisplayId(existingFunctionDisplayIds: string[]): string {
        let maxNumber = 0;
        existingFunctionDisplayIds.forEach(functionDisplayId => {
            const match = functionDisplayId.match(/F(\d+)/);
            if (match) {
                const number = parseInt(match[1], 10);
                if (!isNaN(number) && number > maxNumber) {
                    maxNumber = number;
                }
            }
        });
        const newNumber = maxNumber + 1;
        const newFunctionDisplayId = 'F' + newNumber;
        return newFunctionDisplayId;
    }

    saveExplain(model, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.explain = model.explain;
        this.updateFunctionListAndDataSource(ind, index, data);
    }

    saveTimeCritical(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.timeCritical = args.itemData;
        this.updateFunctionListAndDataSource(ind, index, data);
    }

    saveImpact(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.impact = args.itemData;
        this.updateFunctionListAndDataSource(ind, index, data);
    }

    updateFunctionListAndDataSource(ind, index, data) {
        this.functions[ind].organizationFunctions[index] = data;
        this.functions = [...this.functions];
        ((this.grids.filter(x => x.element.id == `basic_info_function_grid_${data.functionGroupId}`)[0] as any).dataSource as object[]).splice(index, 1);
        ((this.grids.filter(x => x.element.id == `basic_info_function_grid_${data.functionGroupId}`)[0] as any).dataSource as object[]).unshift(data);
    }

    submitFunctions() {
        this.organizationService.submitFunctions(this.functions, this.organizations.organizationId).subscribe(data => {
            if (data.success == true) {
                this.mainLayout.showHideFunction(true);
                this.showFunctions = false;
            }
        })
    }
}