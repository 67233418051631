import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ButtonComponent } from "@syncfusion/ej2-angular-buttons";
import { MultiSelectComponent, TaggingEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { EditSettingsModel, GridComponent, QueryCellInfoEventArgs, SaveEventArgs } from "@syncfusion/ej2-angular-grids";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { ToastCloseArgs, ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { DialogComponent, TooltipComponent } from "@syncfusion/ej2-angular-popups";
import { AnimationModel, FontModel, ILoadedEventArgs, ProgressTheme } from "@syncfusion/ej2-angular-progressbar";
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import * as _ from "lodash";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DocumentationCategoryField } from "src/app/interfaces/DocumentationCategoryField";
import { ControlEvent } from "src/app/interfaces/Maintenance/ControlEvent";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { ComplianceService } from "src/app/shared/services/complianceService";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";
import { ArchiveMaintenanceComponent } from "../documentation-maintenance/archive-maintenance.component";
import { DiscretionaryMaintenanceComponent } from "../documentation-maintenance/discretionary-maintenance.component";
import { ControlReportComponent } from "../documentation-maintenance/modals/control-report.component";
import { MaintenanceLogComponent } from "../documentation-maintenance/modals/maintenance-log.component";
import { WheelMaintenanceComponent } from "../documentation-maintenance/wheel-maintenance.component";

@Component({
    selector: 'compliance-with-gdpr-principles',
    templateUrl: 'compliance-with-gdpr-principles.html',
    styleUrls: ['../general-info/general-info.component.css', 'compliance-with-gdpr-principles.css'],
    encapsulation: ViewEncapsulation.None
})
export class ComplianceComponent implements OnInit, AfterViewInit {
    @ViewChild(ArchiveMaintenanceComponent, { static: false }) childA: ArchiveMaintenanceComponent;
    @ViewChild(WheelMaintenanceComponent, { static: false }) childW: WheelMaintenanceComponent;
    @ViewChild(DiscretionaryMaintenanceComponent, { static: false }) childD: DiscretionaryMaintenanceComponent;
    @ViewChild(ControlReportComponent, { static: false }) childCR: ControlReportComponent;
    @ViewChild(MaintenanceLogComponent, { static: false }) childML: MaintenanceLogComponent;
    @ViewChildren(TooltipComponent) tooltipTemplates: QueryList<TooltipComponent>;

    @ViewChild('lb_grid') grid: GridComponent;

    @ViewChild('hideTosat')
    private hidebtn: ButtonComponent;

    private tableFields;
    categoryId;
    tabIndex;
    prevIndex;
    lastTabItem
    defaultCategoryData = [];
    filledFields: number;
    wholeFields: number;
    defaultSubCategoryData = [];
    subCategories = [];
    controlId: number;
    categoryField: any;
    showWheel: boolean = false;
    showWheelReport: boolean = false;
    typeSelected: any;
    showDiscretionary: boolean = false;
    currentEvent: ControlEvent;
    generalFrequency: any;
    fieldName: string;
    categoryType: any;
    currentDateTime: any;
    controlStarted: boolean;
    exitMode: boolean;
    exitWheelMode: boolean;
    refreshLog: boolean;
    refreshDiscretionary: boolean;
    contentTypeModel: { text: string; value: number; }[];
    public retentionFields: object = { text: 'name', value: 'retentionPeriodId' };
    public maxSelection: number = 1;
    filterPlaceholder = 'Choose retention period'
    currentType: number;
    bpId: string;
    versionNo: any;
    viewId: string;
    legalBasisId: any;
    lbTypes: any = [];
    tableData: any = [];
    noInfoValues: boolean;
    personalDataInfoValues: any = [];
    retentionPeriods: any;
    public editSettings: EditSettingsModel;
    constructor(
        private generalInfoService: GeneralInfoService,
        private mainService: DocMaintenanceService,
        private lbService: LegalBasisService,
        private route: ActivatedRoute,
        private complianceService: ComplianceService) {
    }

    ngAfterViewInit(): void {
        this.getControlFrequencies();
        this.getCurrentControlEvent();
        // this.SubmitFinalDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Submit as...</label>";
        // this.FrequencyDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Maintenance frequency</label>"
    }
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.categoryId = id;
            }
        })
        this.editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Normal' };
        this.noInfoValues = false;
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.viewId = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].modelViewID;
        this.currentDateTime = new Date().toISOString().slice(0, 10);
        this.controlStarted = false;
        this.exitWheelMode = false;
        this.exitMode = false;
        this.refreshLog = false;
        this.refreshDiscretionary = false;
        this.refresh = false;
        this.showWheel = false;
        this.showDiscretionary = false;
        // this.menu.openClick(true);
        this.ifCategoryCompletedIsFinal();
        this.getCategories();
        this.getLegalBasisPersonalData();
        this.getLegalBasisTableData();
        this.getRetentionPeriods();
        this.contentTypeModel = [
            { text: 'Planning wheel maintenance', value: 1 },
            { text: 'Discretionary maintenance', value: 2 }
        ]
    }
    public defaultMultiselectValue: string[] = ["1"];
    public labelStyle1: FontModel;
    public type: string = 'Linear';
    public width: string = '303px';
    public height: string = '41px';
    public trackThickness: number = 41;
    public progressThickness: number = 41;
    public min: number = 0;
    public max: number = 100;
    public value1: number = 0;
    public showProgressValue: boolean = true;
    public controlFrequency = [];
    resetFormSubject: Subject<boolean> = new Subject<boolean>();
    @ViewChild('customlist')
    public multiselect: MultiSelectComponent;

    @ViewChild('Dialog')
    public Dialog: DialogComponent;

    @ViewChild('FrequencyDialog')
    public FrequencyDialog: DialogComponent;

    @ViewChild('CRDialog')
    public ReportDialog: DialogComponent;

    @ViewChild('ArchiDialog')
    public ArchiDialog: DialogComponent;

    @ViewChild('MDialog')
    public MDialog: DialogComponent;

    @ViewChild('SubmitFinalDialog')
    public SubmitFinalDialog: DialogComponent;

    @ViewChild('CETypeDialog')
    public CEDialog: DialogComponent;
    public dialogHeight = '100%';
    public dialogWidth = '31%';
    public dialogCEHeight = '220';
    public dialogCEWidth = '25%';
    public dialogPosition: { X: "Right", Y: "Top" }
    public dialogCEPosition: { X: "Center", Y: "Top" }

    public a_dialogPosition: { X: "Center", Y: "Center" }
    public cat_dialogPosition: { X: "Bottom", Y: "Right" }

    public a_dialogWidth = '70%';
    public a_dialogCEHeight = '100%';


    public cr_dialogWidth = '50%';
    public cr_dialogHeight = '100%';

    public cat_dialogWidth = '15%';
    public cat_dialogCEHeight = '30%';
    f_dialogWidth = '25%';
    showCloseIcon = true;
    public isFinal: boolean;
    public refresh: boolean = false;
    reportRefresh: boolean = false;

    public ddlfields: Object = { text: 'name', value: 'id' };
    public waterMark: string = 'Select frequency item';
    public value: string = 'GDPR visual models';
    public showReport: boolean;
    public refreshReport: boolean;

    categories = [];
    fields = [];
    public animation: AnimationModel = { duration: 2000, delay: 0 };

    @ViewChild('tabGroup') tabGroup: TabComponent;

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChild('warningToast')
    public position: ToastPositionModel = { X: 'Top', Y: 'Center' };

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];

    setTypeSelected(type) {
        this.typeSelected = type;
    }

    beforeRenderPD(e, field, type, index) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipPD_' + field + '_' + type + '_' + index + '');
        currentTooltip[0].content = e.target.innerText;
    }

    customiseCell(args: QueryCellInfoEventArgs, type) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (index == "0") {
            args.cell.classList.add('background-grey');
        }
        if (count % 2 == 0) {
            args.cell.classList.add('background-grey');
        }
    }

    showArchive() {
        this.childA.refresh = true;
        this.ArchiDialog.header = '<label style="font-family: Rubik;font-weight: 600;' +
            'font-size: 20px; color: #0C2B57;">Maintenance archive</label>';
        this.ArchiDialog.show();
    }

    getCurrentControlEvent() {
        this.mainService.getCategoryMaintenanceType(this.bpId, this.categoryId, this.versionNo).subscribe(x => {
            if (x) {
                if (x === 1) {
                    this.showWheel = true;
                    this.showDiscretionary = false;
                    this.currentType = 1;
                }

                if (x === 2) {
                    this.showWheel = false;
                    this.showDiscretionary = true;
                    this.currentType = 2;
                }
            }
        })
    }

    currentIndex;


    public items: ItemModel[] = [
        {
            text: 'Final'
        }];

    public itemBeforeEvent(args: MenuEventArgs) {
        args.element.getElementsByTagName('a')[0].setAttribute('target', '_blank');
    }

    ifCategoryCompletedIsFinal() {
        this.generalInfoService.ifCategoryIsFinal(this.bpId, this.categoryId, this.versionNo).subscribe(_data => {
            this.isFinal = _data;
        })
    }

    chooseCEType() {
        this.CEDialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 18px;' +
            'color: #0C2B57;">Select type of Control Event:</label>'
        this.CEDialog.show();
    }

    submitTypeSelection() {
        this.controlStarted = false;
        this.CEDialog.hide();
        if (this.typeSelected !== undefined) {
            if (this.typeSelected === 2) {
                this.showDiscretionary = true;
                this.showWheel = false;
                this.mainService.updateCategoryType(this.bpId, this.categoryId, 2, this.versionNo);
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
            if (this.typeSelected === 1) {
                this.showWheel = true;
                this.showDiscretionary = false;
                this.mainService.updateCategoryType(this.bpId, this.categoryId, 1, this.versionNo);
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
        }

        else if (this.typeSelected === null || this.typeSelected === undefined) {
            this.typeSelected = 1;
            this.showWheel = true;
            this.showDiscretionary = false;
            this.mainService.updateCategoryType(this.bpId, this.categoryId, 1, this.versionNo);
        }
    }


    showDialog(data: any, name: string) {
        this.categoryField = data;
        this.fieldName = name;
        this.Dialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 20px;' +
            'color: #0C2B57;">Add observations and actions</label>'
        this.Dialog.show();
    }
    showMDialog(id: number) {
        this.controlId = id;
        this.MDialog.header = '<label style="font-size: 25px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 600; margin-left:5px;' +
            'color: #0C2B57" class="logLbl">Maintenance Log</label>';
        this.childML.refresh = true;
        this.MDialog.show();
    }
    observationActionDialogClose() {
        if (this.showWheel == true) {
            this.childW.refresh = true;
        }
        if (this.showWheel == false) {
            this.childD.refresh = true;
        }
        this.categoryField = undefined;
    }

    isComplianceManager: boolean = false;
    isDataController: boolean = false;
    isAdmin: boolean = false;
    bpHeader;

    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }

    public load(args: ILoadedEventArgs): void {
        let selectedTheme: string = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = <ProgressTheme>(selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark');
        if (args.progressBar.theme === 'Material') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.labelStyle.fontFamily = 'Rubik'
            args.progressBar.labelStyle.fontWeight = '500'
            args.progressBar.progressColor = '#5AA9EF'
            args.progressBar.trackColor = '#0C2B57'
        }
        if (selectedTheme === 'Contrast') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.trackColor = '#0C2B57'
        }
    }

    isShouldRender(field: DocumentationCategoryField, fields: any[]): boolean {
        let parent;
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId).length > 0) {
                    parent = fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId)
                    break;
                }
            }
            if (parent)
                if (parent[0].shouldRender)
                    if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                        return true;
                    }
        }
        else return false;
    }

    getCategories() {
        this.generalInfoService.getCategoryById(this.categoryId, this.bpId, this.versionNo).subscribe(res => {
            this.defaultCategoryData = res;
            let fields = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields);

            for (var i = 0; i < fields.length; i++) {
                for (var j = 0; j < fields[i].length; j++) {
                    if (fields[i][j].type == 0 || fields[i][j].type == 5 || fields[i][j].type == 6) {
                        fields[i][j].shouldRender = false;
                    }
                    else {
                        fields[i][j].shouldRender = this.isShouldRender(fields[i][j], fields);
                    }
                }
            }
        })
    }
    hideNotification() {
        this.noInfoValues = false;
    }
    getLegalBasisTableData() {
        let flag = 0;
        let customerId = sessionStorage.getItem("customerId");
        this.lbService.getLegalBasisTableData(this.legalBasisId, this.bpId, this.versionNo, this.viewId, customerId).subscribe(data => {
            this.tableData = data;
            this.tableData.forEach(element => {
                element.fields.forEach(element2 => {
                        if (element2.personalDataInfoValues.length > 0) {
                            this.personalDataInfoValues.push(element2.personalDataInfoValues);
                            flag += 1;
                        }
                    });
            });
            if (flag == 0) {
                this.noInfoValues = true;
            }
            this.recalculateProgress();
        });
    }

    getRetentionPeriods() {
        this.complianceService.getStandardRetentionPeriods().subscribe(data => {
            this.retentionPeriods = data;
        })
    }

    getLegalBasisPersonalData() {
        let categories;
        let customerId = sessionStorage.getItem("customerId");
        if (sessionStorage.getItem("categories")) {
            categories = JSON.parse(sessionStorage.getItem("categories"));
            this.legalBasisId = categories
                .filter(x => x.description == 'The legal basis for processing')[0]
                .documentationCategoryId;

            this.lbService.getLegalBasisTypeData(this.legalBasisId, this.versionNo, customerId).subscribe(data => {
                this.lbTypes = data;
            });
        }
        else {
            this.generalInfoService.getAllCategoriesListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
                this.legalBasisId = res
                    .filter(x => x.description == 'The legal basis for processing')[0]
                    .documentationCategoryId;

                this.lbService.getLegalBasisTypeData(this.legalBasisId, this.versionNo, customerId).subscribe(data => {
                    this.lbTypes = data;
                });
            });
        }
    }

    recalculateProgress() {
        let fields = this.defaultCategoryData["documentationSubCategories"].map(x => x.documentationCategoryFields);
        let extraFields = 0;
        let tableFieldData = this.tableData.map(x => x.fields).reduce(function (a, b) { return a.concat(b); }, []);
        var _infoValues = tableFieldData.map(x => x.personalDataInfoValues).reduce(function (a, b) { return a.concat(b); }, []);
        if(_infoValues.length > 0){
            extraFields = _infoValues.length - 1
        }
        let wholeRowsToCalculate = 4 * (tableFieldData.length + extraFields);
        let filledCounter = 0;
        let unfilledCounter = 0;
        let fieldsToRenderCounter = 0;
        for (var i = 0; i < fields.length; i++) {
            for (var j = 0; j < fields[i].length; j++) {
                if (fields[i][j].shouldRender === true) {
                    //if yes then check if field filled
                    if (fields[i][j].fieldValue.value != null && fields[i][j].fieldValue.value != '' && fields[i][j].fieldValue.value != 'undefined') {
                        filledCounter = filledCounter + 1;
                    }
                    //for the whole amount of needed fields
                    fieldsToRenderCounter = fieldsToRenderCounter + 1;
                }
            }
        }
        for (var k = 0; k < tableFieldData.length; k++) {
            var fieldData = tableFieldData[k].personalDataInfoValues;
            if (fieldData.length == 0) {
                unfilledCounter += 4;
            }
            for (var n = 0; n < fieldData.length; n++) {
                unfilledCounter += fieldData[n].tableFieldData.retentionPeriod.retentionPeriodId == 0 ? 1 : 0;
                unfilledCounter += (fieldData[n].tableFieldData.explainConsiderations == null ||
                    fieldData[n].tableFieldData.explainConsiderations == '') ? 1 : 0;
                unfilledCounter += (fieldData[n].tableFieldData.explainDeletionsCarriedOut == null ||
                    fieldData[n].tableFieldData.explainDeletionsCarriedOut == '') ? 1 : 0;
                unfilledCounter += (fieldData[n].tableFieldData.explainDeletionsExecuted == null ||
                    fieldData[n].tableFieldData.explainDeletionsExecuted == '') ? 1 : 0;
            }
        }

        this.wholeFields = fieldsToRenderCounter;
        this.filledFields = filledCounter;
        let value1 = (this.filledFields / this.wholeFields) * 100
        let value2 = wholeRowsToCalculate > 0 ? ((wholeRowsToCalculate - unfilledCounter) / wholeRowsToCalculate * 100) : 0;
        this.value1 = (value1 + value2) / 2;
        this.labelStyle1 = { text: 'You have completed ' + Math.round(this.value1) + '% of ' + this.max + '%' };
    }

    onTabSelect(args: any) {
        this.tabIndex = args.selectedIndex;
        this.prevIndex = args.previousIndex;
        this.lastTabItem = this.tabGroup["lastIndex"];
        setTimeout(() => this.getDynamicTemplate(), 500);
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress()
    }

    showPrev() {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [this.tabIndex]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();

            this.recalculateProgress()
            this.updateCategoryProgress()
        }
        this.tabGroup.selectedItem--;
    }

    showNext() {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [this.tabIndex]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();


            this.recalculateProgress()
            this.updateCategoryProgress()
        }
        this.tabGroup.selectedItem++;
    }

    formSave() {
        if (this.defaultCategoryData["progress"].progress === 100 && this.isFinal == false)
            this.SubmitFinalDialog.show();
        else if (this.defaultCategoryData["progress"].progress !== 100 || this.isFinal == true)
            this.submitCategory(0);
        else if (this.defaultCategoryData["progress"].progress == 100 && this.isFinal)
            this.submitCategory(0)
    }


    getControlFrequencies() {
        this.mainService.getControlFrequencyList().subscribe(data => {
            this.controlFrequency = data;
        })
    }

    frequencyChanged(data: any) {
        this.generalFrequency = data;
        var d = new Date();
        var newDate = new Date(d.setMonth(d.getMonth() + data.itemData.monthsToAdd));
        document.getElementById('nextDeadline').textContent = newDate.toISOString().slice(0, 10);
    }

    submitFrequency() {
        var bp = sessionStorage.getItem("bpId");
        this.mainService.changeFrequency(bp, this.categoryId, this.generalFrequency.itemData, this.versionNo)
        this.FrequencyDialog.hide();
    }

    submitCategory(categoryType: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [this.tabIndex]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        this.generalInfoService.saveCategoryWithType(categoryType, this.categoryId, this.bpId, this.versionNo, fields).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
        if (categoryType == 2) this.isFinal = true;
        this.recalculateProgress()
        this.updateCategoryProgress()
    }

    setCategoryTypeSelected(typeid) {
        this.categoryType = typeid;
    }

    categorySbmtChoice() {
        if (this.categoryType === undefined) {
            this.categoryType = 1;
        }
        this.SubmitFinalDialog.hide();
        if (this.categoryType === 2)
            this.FrequencyDialog.show();
        this.submitCategory(this.categoryType)
    }

    updateCategoryProgress() {
        if (this.tabIndex !== 0) {
            var bpId = sessionStorage.getItem("bpId");
            let categoryProgress = this.defaultCategoryData["progress"];
            categoryProgress.documentationCategoryId = this.defaultCategoryData["documentationCategoryId"];
            categoryProgress.businessProcessId = bpId;
            categoryProgress.progress = Math.round(this.value1);
            categoryProgress.versionNo = this.versionNo;

            this.generalInfoService.addUpdateCategory(categoryProgress).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
    }

    fieldValueChanged(field, args, index) {
        field.fieldValue.value = args;
        field.fieldValue.hasChanged = true;
        this.formSaveOnTab(index);
    }

    formSaveOnTab(index: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [index]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
    }

    handleChange(field, evt) {
        // field.fieldValue.value = evt.target.value;
        field.fieldValue.hasChanged = true;
        var target = evt.target;

        let id = target.id;
        for (var i = 0; i < this.fields.length; i++) {
            this.fields[i].shouldRender = false;
        }

        for (var i = 0; i < this.fields.length; i++) {
            this.fields[i].shouldRender = this.isShouldRenderLocal(this.fields[i]);
        }
    }

    isShouldRenderLocal(field: DocumentationCategoryField): boolean {
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            var parent = this.fields.filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId);
            if (parent[0].shouldRender)
                if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                    return true;
                }
        }
        else return false;
    }

    showObservationBtn(id: number) {
        this.controlId = id;
        this.controlStarted = true;
    }

    controlEventRemoved(id) {
        if (this.controlId !== undefined) {
            if (this.controlId == id) {
                this.controlStarted = false;
            }
        }
    }

    previewReport(id: number) {
        this.controlId = id;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        })
        this.ReportDialog.show();
        this.showReport = true;
        this.childCR.refresh = true;
    }

    closePreview(exitControlMode) {
        this.ReportDialog.hide();
        if (exitControlMode === true) {
            this.controlStarted = false;
            if (this.showWheel == true) {
                this.childW.refresh = true;
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
            if (this.showWheel == false) {
                this.childD.refresh = true;
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
        }
    }

    closeAndPreview() {
        this.Dialog.hide();
        this.childCR.refresh = true;
        this.showReport = true;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        });
        if (this.showWheel == true)
            this.refresh = true;
        if (this.showWheel == false)
            this.refreshDiscretionary = true;
        this.ReportDialog.show();
    }

    closeLog() {
        this.MDialog.hide();
    }

    hideObservationBtn() {
        var addObservationActionButtons = document.getElementsByClassName("observationActionAdd") as HTMLCollectionOf<HTMLElement>;;
        for (var i = 0; i < addObservationActionButtons["length"]; i++) {
            addObservationActionButtons[i].style.display = "none";
        }
    }

    getDynamicTemplate() {
        this.formSaveOnTab(this.prevIndex)
        this.subCategories = this.defaultCategoryData["documentationSubCategories"];
        this.defaultSubCategoryData = this.subCategories[this.tabIndex];
        this.fields = this.defaultSubCategoryData["documentationCategoryFields"];
    }

    createFieldData(e: any, fields: any) {
        let model = e["tableFieldData"];
        model.fields = fields;
        model.versionNo = this.versionNo;
        this.complianceService.AddUpdateComplianceFieldData(model, this.legalBasisId, this.versionNo).subscribe();
        this.recalculateProgress();
    }

    public onRetentionTagging = (val: any, e: TaggingEventArgs, fields: any) => {
        let model = val["tableFieldData"];
        model.fields = fields;
        if (e.itemData["retentionPeriodId"] < 1) {
            e.itemData["retentionPeriodId"] = 0;
            e.itemData["isStandard"] = false;
        }
        model.retentionPeriod = e.itemData;
        model.versionNo = this.versionNo;
        this.complianceService.AddUpdateComplianceFieldData(model, this.legalBasisId, this.versionNo).subscribe();
        this.recalculateProgress();
    }

    public enableDDL(v, model: any, field: any) {
        let labelToHide = "rpLbl_" + field.documentationCategoryFieldId + v;
        let iconToHide = "rpImg_" + field.documentationCategoryFieldId + v;
        let ddlToShowReserve = "customlistReserve_" + field.documentationCategoryFieldId + v;
        document.getElementById(labelToHide).style.display = "none";
        document.getElementById(iconToHide).style.display = "none";
        document.getElementById(ddlToShowReserve).style.display = "block";
        var data = {
            fields: field.allFieldIds,
            versionNo: this.versionNo,
            retentionPeriod : model
        }
        this.complianceService.DisableRetentionPeriod(data).subscribe();
        this.recalculateProgress();
    }

    disableRetentionPeriod(model: any, fields: any){
        model.fields = fields;
        model.versionNo = this.versionNo;
        var data = {
            fields: fields,
            versionNo: this.versionNo,
            retentionPeriod : model
        }
        this.complianceService.DisableRetentionPeriod(data).subscribe();
        this.recalculateProgress();
    }
}
