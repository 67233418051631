import { Component, ViewEncapsulation, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from "@angular/core";
import { DropDownList } from "@syncfusion/ej2-angular-dropdowns";
import { CommandModel, DetailDataBoundEventArgs, DetailRowService, EditSettingsModel, GridComponent, GridModel, IEditCell, IGrid, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ToastComponent } from "@syncfusion/ej2-angular-notifications";
import { L10n, closest } from "@syncfusion/ej2-base";
import { RuleBookService } from "src/app/shared/services/rule-book.service";
L10n.load({
    GridModel: {
        'Add': 'Start New'
    }
});
@Component({
    selector: 'admin-rule-book',
    templateUrl: './admin-rule-book.html',
    styleUrls: ['admin-rule-book.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    providers: [DetailRowService],
    encapsulation: ViewEncapsulation.None,
})
export class AdminRuleBookComponent implements OnInit, AfterViewInit {
    ruleDomains: any = [];

    public commands?: CommandModel[] = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
    { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-icons e-close' } },
    { type: 'Save', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-update e-icons' } },
    { type: 'Cancel', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-cancel-icon e-icons' } }];

    public editSettings?: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };;
    public frequencyFields: Object = { text: 'frequencyName', value: 'frequencyId' };

    public childGrid: GridModel;
    public toolbar?: ToolbarItems[];

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChildren(GridComponent) grids: QueryList<IGrid>;

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];
    constructor(
        private ruleBookService: RuleBookService) { }
    ngAfterViewInit(): void {

        this.toolbar = ['Add'];
    }
    ngOnInit(): void {
        this.getRuleBookInfo();
        this.toolbar = ['Add'];
    }

    getRuleBookInfo() {
        this.ruleBookService.getRuleBook(sessionStorage.getItem("customerId"), true).subscribe(data => {
            this.ruleDomains = data;
            this.childGrid = {
                queryString: 'ruleId',
                cssClass: 'rule-book-child-grid',
                locale: "en-US",
                editSettings: this.editSettings,
                toolbar: ['Add'],
                columns: [
                    { field: 'subRuleId', isPrimaryKey: true, visible: false },
                    { field: 'ruleId', visible: false },
                    { field: 'subRuleDisplayId', headerText: 'Sub-rule ID', textAlign: 'Left', width: 90 },
                    { field: 'subRuleName', headerText: 'Name/Description', textAlign: 'Left', width: 400 },
                    { headerText: 'Edit', textAlign: 'Left', width: 120, commands: this.commands }],
                actionBegin: (args) => {
                    if (args.action === 'add') {
                        const form = args.form;
                        const secondChildGridElementId = form.id.split('EditForm')[0];
                        const secondChildGridElement = document.getElementById(secondChildGridElementId);
                        const childGridRowElement = closest(secondChildGridElement, 'tr');
                        const childGridParentRowElement = childGridRowElement.previousElementSibling;
                        const childGridElement = closest(childGridParentRowElement, '.e-grid');
                        const childGridInstance = (childGridElement as any).ej2_instances[0];
                        const uid = childGridParentRowElement.getAttribute('data-uid');
                        const rowObject = childGridInstance.getRowObjectFromUID(uid);
                        const rowData = rowObject.data;
                        args.data.isCustom = false;
                        args.data.ruleId = rowData["ruleId"];
                    }
                    if (args.action === 'add' && args.requestType === 'save') {
                        this.ruleBookService.addPredefinedSubRule(args.data).subscribe(res => {
                            const form = args.form;
                            const secondChildGridElementId = form.id.split('EditForm')[0];
                            const secondChildGridElement = document.getElementById(secondChildGridElementId);
                        }, error => {
                            console.error('Error on sub rule add:', error);
                            this.toasts[2].content = "Failed to add data";
                            this.toastObj.show(this.toasts[2]);
                        })
                    }
                    if (args.action === 'edit') {
                        this.ruleBookService.updatePredefinedSubRule(args.data)
                            .subscribe(
                                () => { },
                                error => {
                                    console.error('Error on sub rule update:', error);
                                    this.toasts[2].content = "Failed to update data";
                                    this.toastObj.show(this.toasts[2]);
                                });
                    }
                    if (args.requestType === 'delete') {
                        this.ruleBookService
                            .deletePredefinedSubRule(args.data[0].subRuleId);
                    }
                }
            };
        },
            error => {
                console.error('Error fetching rule book data:', error);
            })
    }

    detailDataBound({ data, childGrid }: DetailDataBoundEventArgs) {
        var dataSource: Object[] = data["subRules"];
        (childGrid as IGrid).dataSource = dataSource;
    }

    actionRuleBegin(args: any, domainId): void {
        if (args.requestType === 'save' && args.action === 'add') {
            this.ruleBookService.AddRule(args.data, domainId).subscribe(res => {
                args.data["ruleId"] = Number(res);
                var currentGrid = this.grids.filter(x => x.element.id == `rule_book_grid_${domainId}`)[0];
                currentGrid.setCellValue(0, "ruleId", Number(res))
                currentGrid.editModule.updateRow(1, args.data);
            })
        }

        if (args.action === 'edit') {
            this.ruleBookService.updateRule(args.data)
                .subscribe(
                    () => { },
                    error => {
                        console.error('Error on sub rule update:', error);
                        this.toasts[2].content = "Failed to update data";
                        this.toastObj.show(this.toasts[2]);
                    });
        }

        if (args.requestType === 'delete') {
            this.ruleBookService
                .deleteRule(args.data[0].ruleId);
        }
    }
}