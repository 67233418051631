// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dataProcessorsDdl {
    margin-top: -33px;
    margin-left: 470px;
}

#dpLabel{
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Rubik';
    color: #000000;
}

.dpDDlWrapper{
    position: absolute;
    z-index: 1000;
    top: -78px;
    left: 340px;
}

#dpDDl{
    float: left;
    background-color: white;
    border-radius: 9px;
    padding-left: 10px;
    font-size: 14px;
    font-family: 'Rubik';
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-analysis/data-processors.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,oBAAoB;IACpB,cAAc;AAClB","sourcesContent":["#dataProcessorsDdl {\r\n    margin-top: -33px;\r\n    margin-left: 470px;\r\n}\r\n\r\n#dpLabel{\r\n    float: left;\r\n    margin-right: 10px;\r\n    margin-top: 5px;\r\n    font-size: 14px;\r\n    font-family: 'Rubik';\r\n    color: #000000;\r\n}\r\n\r\n.dpDDlWrapper{\r\n    position: absolute;\r\n    z-index: 1000;\r\n    top: -78px;\r\n    left: 340px;\r\n}\r\n\r\n#dpDDl{\r\n    float: left;\r\n    background-color: white;\r\n    border-radius: 9px;\r\n    padding-left: 10px;\r\n    font-size: 14px;\r\n    font-family: 'Rubik';\r\n    color: #000000;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
