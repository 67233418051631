import { Component } from "@angular/core";
import { AdministrationService } from "src/app/shared/services/admin.service";

@Component({
    selector: 'model-import',
    styleUrls: ['model_import.css'],
    templateUrl: 'model_import.html',
})
export class ModelImportComponent {
    private customers = [];
    constructor(private adminService: AdministrationService) {

    }
    getCustomers() {
        this.adminService.getAllCustomers().subscribe(data => {
            this.customers = data;
        });
    }
}