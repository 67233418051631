import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { GridComponent, GridLine, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { RiskService } from "src/app/shared/services/risk-analysis.service";
import { cloneNode } from "@syncfusion/ej2-base";
import { AfterContentChecked } from "@angular/core";
import { ChipListComponent, ClickEventArgs, SwitchComponent } from "@syncfusion/ej2-angular-buttons";
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { DialogComponent, ResizeDirections, Tooltip, TooltipComponent, TooltipEventArgs } from "@syncfusion/ej2-angular-popups";
import { ArcSegment } from "@syncfusion/ej2-diagrams";
@Component({
    selector: 'gdpr-heatmap',
    styleUrls: ['gdpr-assessment-heatmap.component.css'],
    templateUrl: 'gdpr-assessment-heatmap.component.html',
    styles: [` 

    .background-green{ 
      background-color:  #92BB50; 
    } 
    .background-red{ 
      background-color: #DD2122; 
    } 
    .background-yellow{ 
      background-color: #EDDA00; 
    } 
    .background-orange{ 
      background-color: #F3B10D; 
    } 
`]
})
export class GdprAssessmentHeatmapComponent implements OnInit, AfterViewChecked, AfterViewInit, 
OnDestroy{
// @Input() selectedId:number
public type;
@ViewChild('heatmapgrid') grid: GridComponent;
@ViewChild('tooltipBefore')
public beforetooltipControl: TooltipComponent;
@ViewChild('tooltipAfter')
public aftertooltipControl: TooltipComponent;
@ViewChildren(TooltipComponent) templates: QueryList<TooltipComponent>;
@ViewChild('actionChips') 
public actionChipsList: ChipListComponent
public lines: GridLine;
public hiddenItems = [];
public riskBefore = []
public threats = [];
riskProbabilityList = [];
riskConsequenceList = [];
@ViewChild('switch')
public switch: SwitchComponent;
    nextElementSibling: any;
    @ViewChild('Dialog')
    public Dialog: DialogComponent;
    public dialogdragging: Boolean = true;
    public resizeHandleDirection: ResizeDirections[] = ['All'];
    public dialogResize: Boolean = true;
    public showCloseIcon: Boolean = true;
    public width: string = '80%';
    public animationSettings: Object = { effect: 'None' };
    public tooltipContainer:TooltipComponent[];
    public tooltip:Tooltip;
    public choiceSelected = 6;
    bpId: string;
    versionNo: any;

    getColor(risk){
        switch(risk){
            case 1:case 2:
                return '#92BB50';  
            case 3:case 4: 
                return '#EDDA00';
            case 6:case 8:case 9: 
                return'#F3B10D';
            case 12:case 16:   
                return '#DD2122';
            default: return '#92BB50';
        }
    }

    onBeforeRenderBefore(args: TooltipEventArgs) {
        var pureId = Number.parseInt(args.target.id.replace('beforeafterchipcontainer',''));
        var currentThreat = this.threats.filter(x=>x.id === pureId);
        let beforeColor = this.getColor(currentThreat[0].riskBefore);
        let afterColor = this.getColor(currentThreat[0].riskAfter);
        var currentTooltip = this.templates.filter(x=>x.element.id == 'tooltipBefore_'+pureId+'');
        let data = "<div style='padding:15px; padding-bottom:0px;'>"+
        "<h3 style='color: #5AA9EF;font-family:Rubik,sans-serif;font-weight: 700;font-size: 16px;float: left; margin-right: 10px; width:fit-content'>"+currentThreat[0].displayId+"</h3>"+
        "<p style='width:20px; height: 20px; background-color: "+beforeColor+"; text-align:center; float: left; color:rgba(255, 255, 255, 0.5)'>"+currentThreat[0].riskBefore+"</p>"+
        "<img style='float:left; margin-top:8px' src='assets/icons/Security/tooltipArr.png'>"+
        "<p style='width:20px; height: 20px; background-color: "+afterColor+"; text-align:center;  float: left; color:rgba(255, 255, 255, 0.5)'>"+currentThreat[0].riskAfter+"</p>"+
        "<h6 style='color:black; clear:both;float:left;font-family: Rubik, sans-serif;font-weight: 700;font-size: 14px;'>Threat:</h6>"+
        "<p style='float:left; font-family: Rubik, sans-serif;font-weight: 400;font-size: 12px; color:black'>"+currentThreat[0].name+"</p>";
        data+="</div>";
        var deleteIcon = cloneNode(document.getElementById('dltChip'))
        deleteIcon.id = 'dltChip'+pureId;
        deleteIcon.style.display = 'flex';
        args.target.children[0].appendChild(deleteIcon)
        currentTooltip[0].content = data;
    }
    onBeforeRender(args: TooltipEventArgs) {
        var pureId = Number.parseInt(args.target.id.replace('beforeafterchipcontainer',''));
        var currentThreat = this.threats.filter(x=>x.id === pureId);
        let implementedControlsList = "<ul style='padding-left:15px'>";
        for(var i = 0; i< currentThreat[0].controls.length; i++){
            var li = "<li style='clear:both; color: #0C2B57;font-family: Rubik, sans-serif;font-weight: 400;font-size: 12px;text-align: left;'>"+currentThreat[0].controls[i].name+"</li>";
            implementedControlsList+=li;
        }
        for (var i = 0; i < currentThreat[0].standardControls.length; i++) {
            for (var j = 0; j < currentThreat[0].standardControls[i].domains.length; j++) {
                for (var k = 0; k < currentThreat[0].standardControls[i].domains[j].specifications.length; k++) {
                    var li = "<li style='clear:both; color: #0C2B57;font-family: Rubik, sans-serif;font-weight: 400;font-size: 12px;text-align: left;'>" +
                    currentThreat[0].standardControls[i].domains[j].specifications[k].specification + "</li>";
                    implementedControlsList += li;
                }
            }
        }
        var currentTooltip = this.templates.filter(x=>x.element.id == 'tooltipAfter_'+pureId+'');
        implementedControlsList+="</ul>"
        let beforeColor = this.getColor(currentThreat[0].riskBefore);
        let afterColor = this.getColor(currentThreat[0].riskAfter);

        let data = "<div style='padding:15px; padding-bottom:0px;'>"+
        "<h3 style='color: #5AA9EF;font-family: Rubik, sans-serif;font-weight: 700;font-size: 16px;float: left; margin-right: 10px;'>"+currentThreat[0].displayId+"</h3>"+
        "<p style='width:20px; height: 20px; background-color: "+beforeColor+"; text-align:center; float: left; color:rgba(255, 255, 255, 0.5)'>"+currentThreat[0].riskBefore+"</p>"+
        "<img style='float:left; margin-top:8px' src='assets/icons/Security/tooltipArr.png'>"+
        "<p style='width:20px; height: 20px; background-color: "+afterColor+"; text-align:center;  float: left; color:rgba(255, 255, 255, 0.5)'>"+currentThreat[0].riskAfter+"</p>"+
        "<h6 style='clear:both; float:left; color: #0C2B57;font-family: Rubik, sans-serif;font-weight: 700;font-size: 14px;text-align: left;'>Implemented controls:</h6>";
        data += implementedControlsList;
        data+="</div>";
        var deleteIcon = cloneNode(document.getElementById('dltChip'))
        deleteIcon.id = 'dltChip'+pureId;
        deleteIcon.style.display = 'flex';
        args.target.children[0].appendChild(deleteIcon)
        currentTooltip[0].content = data;
    }

    destroyed(args){
        var pureId = Number.parseInt(args.target.id.replace('beforeafterchipcontainer',''));
        var dlt = document.getElementById('dltChip'+pureId);
        dlt.parentElement.removeChild(dlt);
    }

    BtnClick() {
        this.grid.refresh();
        this.Dialog.show();
    }
    dialogClose() {
        document.getElementById('dlgbtn').style.display = 'block';
        }

    dialogOpen() {
        document.getElementById('dlgbtn').style.display = 'none';
    }
    public dlgButtons: Object[] = [
        { click: this.dlgBtnClick.bind(this), buttonModel: { 
            // content: 'Close', isPrimary:'true' 
        }
      }];

    dlgBtnClick() {
    this.Dialog.hide();
    }

    showDialogData(){
        this.Dialog.show();
    }
ngOnInit(): void { 
    this.bpId = sessionStorage.getItem("bpId")
    var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
    this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
    this.menu.openClick(true)
    this.fillRiskConsequenceObject();
    this.fillRiskProbabilityObject();
    this.getThreats();
    this.lines = 'None';
}
constructor(private service: RiskService, private menu:MainLayoutComponent){

}

    ngOnDestroy(): void {
        // this.aftertooltipControl.destroy;
        // this.beforetooltipControl.destroy;
    }
    ngAfterViewChecked(): void {
        var dynamicArrows = document.getElementsByName('arr');
        if(dynamicArrows !==null && dynamicArrows.length > 0 ){
            for(var i = 0; i<dynamicArrows.length; i++){
                if(dynamicArrows[i].id!=="arrow"){
                    if(dynamicArrows[i].parentElement.children.namedItem('arr')){
                        dynamicArrows[i].parentElement.removeChild(dynamicArrows[i]);
                    }
                }
            }
        }
        if(this.threats){
            if(this.threats.length > 0){
                for(var i = 0; i<this.threats.length; i++)
                this.init('start-chip-category-'+this.threats[i].displayId+'','end-chip-category-'+this.threats[i].displayId+'',i);
            }
        }      
    }

    ngAfterViewInit(): void {
        this.Dialog.header = '<button class="editDialogBtn" style = "margin-left:2px;font-family: Rubik;font-size: 14px;font-weight: 400;width: 165px;height: 41px;background-color: #5AA9EF;color: white;border: 1px solid #5AA9EF">Edit</button>'
        this.Dialog.footerTemplate = '<div style="padding-left:12px"><button style="float:left; background: #D5DCEA;width: 170px;'+
        'height: 41px; font-family:Rubik; font-size:12px; font-weight:400; color:#0C2B57; border:none;margin-right:15px;">+ Add consequences</button>'+
        '<button style="float:left; width: 170px;height: 41px;background: #DD5E36; border:none; color:white;'+
        'font-family:Rubik; font-size:12px; font-weight:400;">Save</button></div>';
    }

    public gridDataSource: Object = [
    {'lab':'','id':0, 'xPosition':'Almost certain', 'minorvalueNumber':4, 'modValueNumber':8, 'majorValueNumber':12, 'severeValueNumber':16},
    {'lab':'Probability','id':1, 'xPosition':'Possible', 'minorvalueNumber':3, 'modValueNumber':6, 'majorValueNumber':9, 'severeValueNumber':12},
    {'lab':'','id':2, 'xPosition':'Unlikely', 'minorvalueNumber':2, 'modValueNumber':4, 'majorValueNumber':6, 'severeValueNumber':8},
    {'lab':'','id':3, 'xPosition':'Rare', 'minorvalueNumber':1, 'modValueNumber':2, 'majorValueNumber':3, 'severeValueNumber':4}]

 fillRiskProbabilityObject(){
    var obj = [
      {id:0, text:'Rare'},
      {id:1, text:'Rare'},
      {id:2, text:'Unlikely'},
      {id:3, text:'Possible'},
      {id:4, text:'Almost certain'}
    ]
    this.riskProbabilityList = obj;
  }
  
  fillRiskConsequenceObject(){
    var obj = [
      {id:0, text:'Minor'},
      {id:1, text:'Minor'},
      {id:2, text:'Moderate'},
      {id:3, text:'Major'},
      {id:4, text:'Severe'}
    ]
    this.riskConsequenceList = obj;
  }

  customiseCell(args: QueryCellInfoEventArgs) {

    if(args.column.field === 'minorvalueNumber'){
        if(args.data["minorvalueNumber"] > 0 && args.data["minorvalueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["minorvalueNumber"] > 2 && args.data["minorvalueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["minorvalueNumber"] >= 6 && args.data["minorvalueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["minorvalueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'modValueNumber'){
        if(args.data["modValueNumber"] > 0 && args.data["modValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["modValueNumber"] > 2 && args.data["modValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["modValueNumber"] >= 6 && args.data["modValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["modValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'majorValueNumber'){
        if(args.data["majorValueNumber"] > 0 && args.data["majorValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["majorValueNumber"] > 2 && args.data["majorValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["majorValueNumber"] >= 6 && args.data["majorValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["majorValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'severeValueNumber'){
        if(args.data["severeValueNumber"] > 0 && args.data["severeValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["severeValueNumber"] > 2 && args.data["severeValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["severeValueNumber"] >= 6 && args.data["severeValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["severeValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
  } 

        getPos(el) {
            for (var lx=0, ly=0;
                el.id != "heatmapGrid";
                lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
            return {x: lx, y: ly};
        }

        
        getDistance(a, b) {
            const from = this.getPos(a);
            const to = this.getPos(b);
            return {
            distance: Math.hypot(from.x - to.x, from.y - to.y),
            angle: Math.atan2(to.x - from.x, from.y - to.y) * 180 / Math.PI,
            position: {
                start: from,
                end: to
            }
            }
        }
        
        init(start, end, id:number){

        var _start = document.getElementById(start);
        var _end = document.getElementById(end);

        if(_start!==null && _end!==null){
            if(_start.style.display!=='none' && _end.style.display!=='none'){
        const values = this.getDistance(
            _start, _end
        );


        let chipContainer = document.getElementById('chip-choice-container');
        var divToappend = document.getElementById('grid-section');
        let wrapper = _start.closest('div')
        let arrow = document.getElementById('arrow');

        let dynamicArrow = document.getElementById('arrow['+id+']');

        if(dynamicArrow !==null)
        dynamicArrow.parentNode.removeChild(dynamicArrow);
        let temp_arrow = cloneNode(arrow);
        let bottom = wrapper.offsetHeight - values.position.start.y;

        if(chipContainer.offsetHeight >=52){
        temp_arrow.style.height = values.distance - 10 + "px";
        temp_arrow.id = 'arrow['+id+']';
        temp_arrow.style.transform = `rotate(${values.angle}deg)`;
        temp_arrow.style.bottom = bottom + "px";
        temp_arrow.style.left = values.position.start.x + 25 + "px";
        divToappend.appendChild(temp_arrow)
        }
        else{
            temp_arrow.style.height = values.distance - 10 + "px";
            temp_arrow.id = 'arrow['+id+']';
            temp_arrow.style.transform = `rotate(${values.angle}deg)`;
            temp_arrow.style.bottom = bottom + 30 + "px";
            temp_arrow.style.left = values.position.start.x + 25 + "px";
            divToappend.appendChild(temp_arrow)
        }
        }
    }
}
showHideArrows(){
    var dynamicArrows = document.getElementsByName('arr');
    if(dynamicArrows !==null && dynamicArrows.length > 0 ){
        for(var i = 0; i<dynamicArrows.length; i++){
                if(dynamicArrows[i].style.display === 'none'){
                    dynamicArrows[i].style.display = 'block'
                }
                else if(dynamicArrows[i].style.display === 'block'){
                    dynamicArrows[i].style.display = 'none'
                }
            }
    }
}

revertMapItems(){
    if(this.hiddenItems.length > 0){
        for(var i = 0; i<this.hiddenItems.length; i++){
            this.hiddenItems[i].style.display = 'block';
        }
    }
    var resetBtn = document.getElementById('resetBtn');
    resetBtn.style.display = 'none';
}

chipclick(e: ClickEventArgs) {
    if(e.text){
        this.getThreats();
    }  
   }

   hideChip(e: ClickEventArgs) {
    if(e.text){
        document.getElementById('start-chip-category-'+e.text+'').style.display = 'none';
        document.getElementById('end-chip-category-'+e.text+'').style.display = 'none';

        this.hiddenItems.push(document.getElementById('start-chip-category-'+e.text+''));
        this.hiddenItems.push(document.getElementById('end-chip-category-'+e.text+''))

        var resetBtn = document.getElementById('resetBtn');
        if(resetBtn.style.display === 'none'){
            resetBtn.style.display = 'block'
        }
    }  
   }


   compare( a, b ) {
    if ( a.riskBefore > b.riskBefore ){
      return -1;
    }
    if ( a.riskBefore < b.riskBefore ){
      return 1;
    }
    return 0;
  }

    getThreats() {
        var chips = this.actionChipsList;
        let type;
        if(chips){
            if(chips.selectedChips){
                type = chips.selectedChips;
            }
            else type = 0
        }else type = 0
        if(type===this.type){
            return;
        }
        else{
            this.type = type;
        }
        var probability = this.riskProbabilityList;
        var consequence = this.riskConsequenceList;
        this.service.getFullThreats(this.bpId, this.versionNo).subscribe(res=>{         
            if (res.length > 0){
                if(type !==0 && type !=="0")
            res = res.filter(x=>x.assetSubType == type && x.riskAnalysisType == 0);
            else 
            res = res.filter(x=>x.riskAnalysisType == 0);
            for(var i = 0; i < res.length; i++){
            let calculatedColumn = 0;
            calculatedColumn = res[i].consequenceBefore * res[i].probabilityBefore;
            res[i].riskBefore = calculatedColumn;

            calculatedColumn = 0;
            calculatedColumn = res[i].consequenceAfter * res[i].probabilityAfter;
            res[i].riskAfter = calculatedColumn;

            var _pa = probability.filter(x=>x.id == res[i].probabilityAfter)
            var _pb = probability.filter(x=>x.id == res[i].probabilityBefore)

            var _ca = consequence.filter(x=>x.id == res[i].consequenceAfter)
            var _cb = consequence.filter(x=>x.id == res[i].consequenceBefore)

            res[i].probabilityBeforeText = _pb[0].text;
            res[i].probabilityAfterText =  _pa[0].text;
            res[i].consequenceAfterText =  _ca[0].text;
            res[i].consequenceBeforeText = _cb[0].text;
            }
        }
        this.threats = res.sort(this.compare).slice(0,5);  
        })
    }
}