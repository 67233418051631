import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GridComponent, SelectionSettingsModel } from "@syncfusion/ej2-angular-grids";
import { outputs } from "@syncfusion/ej2-angular-grids/src/grid/grid.component";
import { GridLine, ToolbarItems } from "@syncfusion/ej2-grids";
import { ConsequenceService } from "src/app/shared/services/consequence.service";

@Component({
  selector: 'concequence-types',
  templateUrl: 'concequence-types.component.html',
  styleUrls: ['concequence-types.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConsequenceTypesComponent implements OnInit {
  constructor(private consequenceService: ConsequenceService) { }

  isFirstTimeTrigger: boolean;
  @Input() uniqueCombination: string;
  @Input() type: number;
  @Input() threat: any;
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Output() showItemEvent = new EventEmitter<boolean>();
  @Output() selectedItemEvent = new EventEmitter();
  @Output() unselectedItemEvent = new EventEmitter();
  public customer;
  @ViewChild('ct_grid')
  public grid: GridComponent;
  public selectionOptions: SelectionSettingsModel;
  public selectOptions: { type: 'Multiple', mode: 'Row', allowColumnSelection?: false };
  public lines: GridLine;
  public editSettings: Object;
  public editparams: Object;
  public toolbar: ToolbarItems[] | object;
  public infoText: string;
  public requiredrules: Object;
  c_types = [];
  currentlySelectedData = []
  ngOnInit(): void {
    this.isFirstTimeTrigger = true;
    this.lines = 'None';
    this.GetConsequenceTypes()
    this.selectionOptions = { type: "Multiple", mode: "Cell", allowColumnSelection: false };
    this.infoText = "Choose individual cells for defining consequence profile";
    this.requiredrules = { required: true };
    this.customer = sessionStorage.getItem("customerId")
  }

  GetConsequenceTypes() {
    var tenant = sessionStorage.getItem("customerId");
    this.consequenceService.getConSequenceTypesByCustomer(tenant).subscribe(res => {
      if (res.length > 0) {
        sessionStorage.setItem('consequencetypes', JSON.stringify(res));
        this.c_types = res.filter(x => x.riskAnalysisType == this.type);
      }
    });
  }

  switchEditMode() {
    this.infoText = "";
    this.toolbar = [
      { text: "Edit mode", id: 'editModeTxt' },
      'Cancel', 'Update', 'Delete', 'Add'];
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Batch' };
    this.editparams = { params: { popupHeight: '300px' } };
    this.selectionOptions = {};
    this.selectionOptions = { mode: "Row" }
    document.getElementById("editModeBtn_" + this.uniqueCombination + "").style.display = "none";
    document.getElementById("exitEditModeBtn_" + this.uniqueCombination + "").style.display = "block";
    this.newItemEvent.emit(true);
  }

  exitEditMode() {
    document.getElementById("editModeBtn_" + this.uniqueCombination + "").style.display = "block";
    document.getElementById("exitEditModeBtn_" + this.uniqueCombination + "").style.display = "none";
    this.toolbar = null;
    this.editSettings = { allowEditing: false, allowAdding: false, allowDeleting: false, mode: 'Batch' };
    this.infoText = "Choose individual cells for defining consequence profile";
    this.selectionOptions = { type: "Multiple", mode: "Cell", allowColumnSelection: false };
    this.showItemEvent.emit(false);
    this.consequenceService.GetConsequenceTypesSelected(this.threat["id"],
      this.threat["businesProcessId"], this.threat["isDefault"], this.threat["riskAnalysisType"], this.threat["versionNo"]).subscribe(data => {
        if (data.length !== 0)
          this.grid.selectCells(data);
        else
          this.unselectedItemEvent.emit();
      })
  }


  rowSelected(e) {
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch' };
  }
  rowDeselected(e) {
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Batch' };
  }

  public selectingEvent(e: any): void {
    if (e.cellIndex.cellIndex === 0) {
      e.cancel = true;
    }
  }
  public deselectingEvent(e: any): void {
    var data = this.grid.getSelectedRowCellIndexes();
    if (this.currentlySelectedData.length > 0) {
      var deselectedItem = {
        "rowIndex": e.cellIndexes[0].rowIndex,
        "cellIndex": e.cellIndexes[0].cellIndexes[0]
    }
      if (this.currentlySelectedData[0].rowIndex === deselectedItem.rowIndex 
        && this.currentlySelectedData[0].cellIndex === deselectedItem.cellIndex) {
        this.currentlySelectedData = [];
      }
    }
    if (e === null) return;
    if (data.length !== 0) {
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < e.cellIndexes.length; j++) {
          if (data[i].rowIndex == e.cellIndexes[j].rowIndex) {

            for (var k = 0; k < data[i].cellIndexes.length; k++) {
              for (var t = 0; t < e.cellIndexes[j].cellIndexes.length; t++) {
                if (data[i].cellIndexes[k] == e.cellIndexes[j].cellIndexes[t]) {
                  data.splice(i, 1)
                  if (data.length === 0)
                    this.unselectedItemEvent.emit();
                  if (this.currentlySelectedData.length > 0) {
                      this.selectedItemEvent.emit();
                      this.currentlySelectedData = [];
                  }
                  return;
                }
              }
            }
          }
        }
      }
    }
    else
      this.unselectedItemEvent.emit();
  }
  GetFilteredConsequenceTypes(type: number) {
    this.c_types = JSON.parse(sessionStorage.getItem('consequencetypes')).filter(x => x.riskAnalysisType == type);
  }
  beforeBatchSave(args: any): void {
    if (args.batchChanges.addedRecords.length > 0) {
      args.batchChanges.addedRecords.forEach(element => {
        element.riskAnalysisType = this.type;
      });
      this.consequenceService.AddConsequenceType(args.batchChanges.addedRecords, this.customer);
    }
    if (args.batchChanges.changedRecords.length > 0) {
      this.consequenceService.EditConsequenceType(args.batchChanges.changedRecords);
    }
    if (args.batchChanges.deletedRecords.length > 0) {
      this.consequenceService.DeleteConsequenceType(args.batchChanges.deletedRecords);
    }
    this.GetConsequenceTypes();
  }
}
