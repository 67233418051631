import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { Observation } from "src/app/interfaces/Maintenance/Observation";
import { Action } from "src/app/interfaces/Maintenance/Action";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'observation-action',
    templateUrl: 'observation-action.html',
    styleUrls: ['observation-action.css']
})
export class ObservationActionComponent implements OnInit, AfterViewChecked {
    @Input() control: any;
    @Input() field: any;
    @Input() fieldName:string;

    public prevControl = 0;
    public prevField = null;
    public index = 0;
    public Observations = [];
    actionId: number;

    public firstRun: boolean;
    ngOnInit(): void {
        this.firstRun = true;
    }
    constructor(private service: DocMaintenanceService) { }
    ngAfterViewChecked(): void {
        if (this.control !== undefined && this.field !== undefined) {
        if (this.control !== this.prevControl || this.field !== this.prevField) this.firstRun = true;
        if (this.firstRun == true) {      
                    this.getFieldObservations();
                    this.firstRun = false;
                    this.prevControl = this.control;
                    this.prevField = this.field;
            }
        }
    }

    addObservationAction(id: number) {
        if (id !== 0) {
            let action = {
                id: 0,
                description: "",
                actionType: "",
                priority: 0,
                registeredDate: new Date(),
                observationId: id
            };
            this.Observations.find(x => x.id === id).actions.push(action);
        }
    }

    cloneObservation() {
        let action = {
            id: 0,
            description: "",
            actionType: "",
            priority: 0,
            registeredDate: new Date(),
            observationId: 0
        };

        let observation = {
            id: 0,
            name: "",
            controlId: this.control,
            documantationCategoryFieldId: this.field,
            actions: [action]
        };
        this.Observations.push(observation);
    }

    saveObservation(id: any, data: any, index) {
        if (this.control !== undefined && this.field !== undefined) {
            if (id === 0) {
                let observation = {
                    id: 0,
                    name: data.target.value,
                    controlId: this.control,
                    documentationCategoryFieldId: this.field,
                };
                this.service.addObservation(observation).subscribe(x => {
                    this.Observations[index].id = x;
                });
            }

            if (id !== 0) {
                let observation = {
                    id: id,
                    name: data.target.value,
                    controlId: this.control,
                    documentationCategoryFieldId: this.field,
                };
                this.service.updateObservation(observation);
            }
        }
    }

    getFieldObservations() {
        this.Observations = [];
        this.service.getObservationsActionsForField(this.control, this.field).subscribe(_data => {
            if (_data.length > 0)
                this.Observations = _data;
            else if (_data.length === 0)
                this.cloneObservation();
        })
    }

    saveAction(observationId, actionId, data: any, index) {
        if (observationId == 0) return;
        else if (observationId !== 0 && actionId === 0) {

            let action = {
                id: 0,
                registeredDate: new Date(),
                actionType: "Planned",
                priority: 1,
                description: data.target.value,
                observationId: observationId
            }
            this.service.addAction(action).subscribe(x => {
                this.Observations.filter(x=>x.id == observationId)[0].actions[index].id = x;
                this.Observations.filter(x=>x.id == observationId)[0].actions[index].priority = 1;
            });
        }
        else if (observationId !== 0 && actionId !== 0) {

            let action = {
                id: actionId,
                actionType: "Planned",
                registeredDate: new Date(),
                priority: 1,
                description: data.target.value,
                observationId: observationId
            }
            this.service.updateAction(action).subscribe();
        }
    }

    updateActionWithPriority(observationId, actionId, data: any) {
        if (observationId !== null && observationId !== 0) {
            if (actionId !== null && this.actionId !== 0) {
                let action = {
                    id: actionId,
                    registeredDate: new Date(),
                    priority: data,
                    description: '',
                    observationId: observationId
                }
                this.service.updateAction(action).subscribe();
            }
        }
    }

    deleteObservation(obId: number) {
        this.service.deleteObservation(obId).subscribe();
        var index = this.Observations.findIndex(function(o){
            return o.id === obId;
       })
       if (index !== -1) this.Observations.splice(index, 1);
    }

    deleteAction(obId: number, acId: number, index) {
        this.service.deleteAction(acId).subscribe();
        var observation = this.Observations.filter(x=>x.id === obId)[0];
    //     var index = observation.actions.findIndex(function(o){
    //         return o.id === acId;
    //    })
       if (index !== -1) this.Observations.filter(x=>x.id==obId)[0].actions.splice(index, 1);
    }

}


