import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { PersonalDataType } from 'src/app/interfaces/DataTransfers/PersonalDataType';
import { DocumentationCategory } from 'src/app/interfaces/DocumentationCategory';
import { LegalBasisForProcessing } from 'src/app/interfaces/LegalBasis/LegalBasisForProcessing';
import { LegalBasisTableFieldData } from 'src/app/interfaces/LegalBasis/LegalBasisTableFieldData';
import auth from '../../auth-config.json';
import { GroupedLegalBasisFieldData } from 'src/app/interfaces/ComplianceWithGDPRPrinciples/GroupedLegalBasisTableFieldData';
import { ObjectsDecisionMaker } from 'src/app/interfaces/LegalBasis/ObjectsDecisionMaker';

@Injectable({
    providedIn: 'root'
})
export class LegalBasisService {
    url = auth.resources.PDHubApi.resourceUri;
    localUrl = "https://localhost:44394"
    constructor(private http: HttpClient) { }

    private createHedader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    getCategoryById(categoryId: string, bpId: string, versionNo: number, viewId: string, customerId: string): Observable<DocumentationCategory[]> {
        var Url = this.url + "/api/LegalBasis/Get?documentationCategoryId=" +
            categoryId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "&viewId=" + viewId +
            "&customerId=" + customerId + "";
        return this.http.get<DocumentationCategory[]>(Url);
    }

    getAllLegalBasisForProcessing(): Observable<LegalBasisForProcessing[]> {
        var Url = this.url + "/api/LegalBasis/GetLegalBasisList"
        return this.http.get<LegalBasisForProcessing[]>(Url);
    }
    getLegalBasisForProcessingById(fieldId: string): Observable<LegalBasisForProcessing[]> {
        var Url = this.url + "/api/LegalBasis/GetLegalBasisListByField?fieldId=" + fieldId + "";
        return this.http.get<LegalBasisForProcessing[]>(Url);
    }

    getTableFieldData(fieldId: string) {
        var Url = this.url + "/api/LegalBasis/GetLegalBasisFieldData?fieldId=" + fieldId + "";
        return this.http.get<LegalBasisTableFieldData[]>(Url);
    }

    LinkLegalBasisForProcessingToField(fieldId: string, legalBasisId: number): Observable<LegalBasisForProcessing[]> {
        var Url = this.url + "/api/LegalBasis/InsertLegalBasisLink?fieldId=" + fieldId + "&legalBasisId=" + legalBasisId + "";
        return this.http.get<any>(Url);
    }

    AddLegalBasisFieldData(model: any) {
        var Url = this.url + "/api/LegalBasis/CreateFieldData";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    UpdateLegalBasisFieldData(model: any) {
        var Url = this.url + "/api/LegalBasis/UpdateFieldData";
        return this.http.put(Url, JSON.stringify(model), this.createHedader());
    }

    RemoveLegalBasisForProcessingFieldLink(fieldId: string, legalBasisId: number): Observable<LegalBasisForProcessing[]> {
        var Url = this.url + "/api/LegalBasis/DeleteLegalBasisLink?fieldId=" + fieldId + "&legalBasisId=" + legalBasisId + "";
        return this.http.delete<any>(Url);
    }
    getLegalBasisTypeData(documentationCategoryId: string, versionNo: number, customerId: string) {
        var Url = this.url + "/api/LegalBasis/GetLegalBasisExistingTypes?documentationCategoryId=" + documentationCategoryId
            + "&versionNo=" + versionNo + "&customerId=" + customerId + "";
        return this.http.get<PersonalDataType>(Url);
    }
    getLegalBasisTableData(documentationCategoryId: string, bpId: string, versionNo: number, viewId: string, customerId: string) {
        var Url = this.url + "/api/LegalBasis/GetTableData?documentationCategoryId=" +
            documentationCategoryId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "&viewId=" + viewId +
            "&customerId=" + customerId + "";
        return this.http.get<GroupedLegalBasisFieldData>(Url);
    }

    getObjectsDecisionMaker(versionNo: number, viewId: string, customerId: string) {
        var Url = this.url + "/api/LegalBasis/GetBusinessObjectsWithDecisionMaking?versionNo=" + versionNo
            + "&viewId=" + viewId +
            "&customerId=" + customerId + "";
        return this.http.get<ObjectsDecisionMaker>(Url);
    }

    getDecisionMakingImportanceList() {
        var Url = this.url + "/api/LegalBasis/GetDecisionMakingImportanceList";
        return this.http.get<any[]>(Url);
    }

    getDecisionMakingSpecialCategoriesList() {
        var Url = this.url + "/api/LegalBasis/GetDecisionMakingSpecialCategoryList";
        return this.http.get<any[]>(Url);
    }

    getExistingDecisionMakingImportanceList(fieldId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/GetExistingDecisionMakingImportanceList?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(Url);
    }

    getExistingDecisionMakingSpecialCategoriesList(fieldId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/GetExistingDecisionMakingSpecialCategoryList?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(Url);
    }

    getExistingDecisionMakingImportanceListByBusinessProcess(bpId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/GetExistingDecisionMakingImportanceListByBusinessProcess?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(Url);
    }

    getExistingDecisionMakingImportanceFunctionByBusinessProcess(bpId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/GetExistingDecisionMakingImportanceWithFunctionByBusinessProcess?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(Url);
    }

    getExistingDecisionMakingSpecialCategoriesListByBusinessProcess(bpId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/GetExistingDecisionMakingSpecialCategoryListByBusinessProcess?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(Url);
    }

    EnableDecisionMakingImportance(fieldId: string, bpId: string, versionNo: number, dmImportance: any) {
        var Url = this.url + "/api/LegalBasis/EnableDecisionMakingImportanceLink?fieldId=" + fieldId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
        return this.http.post(Url, JSON.stringify(dmImportance), this.createHedader());
    }

    EnableDecisionMakingSpecialCategory(fieldId: string, bpId: string, versionNo: number, dmSpecialCategory: any) {
        var Url = this.url + "/api/LegalBasis/EnableDecisionMakingSpecialCategoryLink?fieldId=" + fieldId + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
        return this.http.post(Url, JSON.stringify(dmSpecialCategory), this.createHedader());
    }

    DisableDecisionMakingImportance(fieldId: string, versionNo: number, dmImportance: number) {
        var Url = this.url + "/api/LegalBasis/DisableDecisionMakingImportanceLink?fieldId=" + fieldId
            + "&versionNo=" + versionNo + "&decisionMakingImportanceId=" + dmImportance + "";
        return this.http.delete(Url);
    }

    DisableDecisionMakingSpecialCategory(fieldId: string, versionNo: number, dmSpecialCategory: number) {
        var Url = this.url + "/api/LegalBasis/DisableDecisionMakingSpecialCategoryLink?fieldId="
            + fieldId + "&versionNo=" + versionNo + "&decisionMakingSpecialCategoryId=" + dmSpecialCategory + "";
        return this.http.delete(Url);
    }

    coveredByTheRightNotToBeSubjectToAutomatedDecisionMaking(fieldId: string, versionNo: number) {
        var Url = this.url + "/api/LegalBasis/CoveredByTheRightNotToBeSubjectToAutomatedDecisionMaking?fieldId=" + fieldId + "&versionNo=" + versionNo + "";
        return this.http.get<string>(Url);
    }
}