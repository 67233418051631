import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConsequenceTypes } from 'src/app/interfaces/ConsequenceTypes';
import { FullThreat } from 'src/app/interfaces/FullThreat';
import auth from '../../auth-config.json';

@Injectable({
    providedIn: 'root'
})
export class ConsequenceService {
    url = auth.resources.PDHubApi.resourceUri;

    constructor(private http: HttpClient) { }


    private createHedader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }
    getConSequenceTypesByCustomer(customerId: string): Observable<ConsequenceTypes[]> {
        var fullUrl = this.url + "/api/SecurityOfProcessing/GetConsequenceTypesByCustomerId?customerId=" + customerId + "";
        return this.http.get<ConsequenceTypes[]>(fullUrl);
    }

    setConSequenceTypesSelected(consequenceTypes: any[], threat: FullThreat) {
        //var fullUrl = "https://localhost:44394/api/SecurityOfProcessing/SetConsequenceTypeSelected";
        var fullUrl = this.url + "/api/SecurityOfProcessing/SetConsequenceTypeSelected";
        let data = {
            consequenceIndexes: consequenceTypes,
            threat: threat
        }
        return this.http.post(fullUrl, JSON.stringify(data), this.createHedader())
            .subscribe();
    }

    getConSequenceTypes(): Observable<ConsequenceTypes[]> {
        var fullUrl = this.url + "/api/SecurityOfProcessing/GetConsequenceTypesList";
        return this.http.get<ConsequenceTypes[]>(fullUrl);
    }

    GetConsequenceTypesSelected(threatId: number, bpId: string, isSelected: boolean, riskAnalysisType: number, versionNo: number): Observable<any[]> {
        var fullUrl = this.url + "/api/SecurityOfProcessing/GetConsequenceTypeSelected?" +
            "threatId=" + threatId + "&bpId=" + bpId + "&isSelected=" + isSelected + "&riskAnalysisType=" + riskAnalysisType + "&versionNo=" + versionNo + "";
        return this.http.get<any[]>(fullUrl);
    }

    AddConsequenceType(ct: ConsequenceTypes, customer: string) {
        var fullUrl = this.url + "/api/SecurityOfProcessing/AddConsequenceType?customerId=" + customer + "";
        return this.http.post(fullUrl, JSON.stringify(ct), this.createHedader())
            .subscribe();
    }

    EditConsequenceType(ct: ConsequenceTypes) {
        var fullUrl = this.url + "/api/SecurityOfProcessing/UpdateConsequenceType";
        return this.http.put(fullUrl, JSON.stringify(ct), this.createHedader())
            .subscribe();
    }

    DeleteConsequenceType(ct: ConsequenceTypes) {
        var fullUrl = this.url + "/api/SecurityOfProcessing/DeleteConsequenceType";
        return this.http.post(fullUrl, JSON.stringify(ct), this.createHedader())
            .subscribe();
    }
}