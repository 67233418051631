import { Component, ViewEncapsulation, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SliderModule } from "@syncfusion/ej2-angular-inputs";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { L10n } from "@syncfusion/ej2-base";
import { RuleBookService } from "src/app/shared/services/rule-book.service";
import { NewWheelMaintenanceComponent } from "../../../general/documentation-maintenance/wheel-maintenance.component";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { InitialBaselineTrackingLogComponent } from "../../../general/documentation-maintenance/modals/initial-baseline-tracking-log.component";
import { EstimatedCosts, EstimatedCostTypes } from "src/app/interfaces/Maintenance/InitialBaseline/EstimatedCostTypes";
import { Observable } from "rxjs";

L10n.load({
    GridModel: {
        'Add': 'Start New'
    }
});
@Component({
    selector: 'establish-baseline',
    templateUrl: './establish-baseline.html',
    styleUrls: ['establish-baseline.css'],
    encapsulation: ViewEncapsulation.None,
    imports: [SliderModule]
})
export class EstablishBaselineComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private ruleBookService: RuleBookService,
        private mainService: DocMaintenanceService) { }
    private subRule: any;
    controlStarted: boolean;
    public value: number = 30;
    public min: number = 0;
    public max: number = 100;
    public gradient_value: number = 50;
    public range: string = 'MinRange';
    public showSubmit: boolean = false;
    public showReport: boolean = false;
    enabled: boolean = false;
    public slider_ticks: Object = { placement: 'After', largeStep: 50, smallStep: 1 };
    showWheel: boolean = false;
    controlId: number;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const id = params['subRuleId'];
            this.getRuleBookInfo(Number(id));
        });
        this.controlStarted = false;
    }
    public tooltip: Object = {
        placement: 'Before',
        isVisible: true,
        // showOn: 'Always',
        cssClass: 'sliderTltp',
        // format: 'number'
    };
    @ViewChild('MDialog')
    public MDialog: DialogComponent;
    @ViewChild('CRDialog')
    public ReportDialog: DialogComponent;
    @ViewChild(InitialBaselineTrackingLogComponent, { static: false }) childML: InitialBaselineTrackingLogComponent;
    @ViewChild(NewWheelMaintenanceComponent, { static: false }) childW: NewWheelMaintenanceComponent;
    @ViewChild('toasttype')
    private toastObj: ToastComponent;
    public toastPosition: ToastPositionModel = { X: 'Right', Y: 'Bottom' };
    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];
    @ViewChild('Dialog')
    public Dialog: DialogComponent;

    @ViewChild('CostDialog')
    public CostDialog: DialogComponent;
    public dialogHeight = '100%';
    public dialogWidth = '40%';
    public m_dialogWidth = '55%'
    public c_dialogWidth = '900px';
    public dialogPosition: { X: "Right", Y: "Top" }
    visibilityMap: { [key: number]: boolean } = {};
    public currentCosts: any[] = [];
    public currentAction: number;

    getRuleBookInfo(id) {
        this.ruleBookService.getMyRuleBook(sessionStorage.getItem("customerId")).subscribe(data => {
            if (data) {
                this.subRule = data.flatMap(x => x.subRules).filter(x => x.subRuleId === id);
                this.showSubmit = !this.subRule[0].baselineEstablished === true;
            }
        })
    }

    submitBaseline(model: NgForm) {
        model["actualControlEvent"].employeeExecutingTheControl = sessionStorage.getItem("role");
        this.ruleBookService.establishBaseline(model).subscribe(
            (data) => {
                // Success case
                console.log('Baseline stablished successfully');
                this.toasts[1].content = "Baseline stablished successfully";
                this.toastObj.show(this.toasts[1]);
                this.subRule = data;
            },
            error => {
                console.error('Error on establish baseline:', error);
                this.toasts[2].content = "Failed to establish baseline";
                this.toastObj.show(this.toasts[2]);
            });
    }

    showObservationBtn(id: number) {
        this.controlStarted = true;
        this.showDialog();
    }

    showDialog() {
        this.Dialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 32px;' +
            'color: #0E384D;">Add observations and actions</label>'
        this.Dialog.show();
    }
    showMDialog(id: number) {
        this.controlId = id;
        this.MDialog.header = '<label style="font-size: 30px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 50; margin-left:5px;' +
            'color: #0E384D" class="logLbl">Maintenance Log</label>';
        this.childML.refresh = true;
        this.MDialog.show();
    }

    showCostDialog(costs, actionId) {
        this.currentCosts = costs;
        this.currentAction = actionId;
        this.CostDialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 32px;' +
            'color: #0E384D;">Estimated Costs</label>'
        this.CostDialog.show();
    }

    observationActionDialogClose() {
        this.childW.showBtn();
    }

    addObservationAction(id: number, initialBaselineId) {
        if (id !== 0) {
            let action = {
                actionId: 0,
                description: "",
                complianceEffect: 0,
                registeredDateTime: new Date(),
                observationId: id
            };
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].initialBaselineObservations
                .find(x => x.observationId === id).initialBaselineActions
                .push(action);
        }
    }

    cloneObservation(initialBaselineId) {
        let action = {
            actionId: 0,
            description: "",
            complianceEffect: 0,
            registeredDateTime: new Date(),
            observationId: 0
        };

        let observation = {
            observationId: 0,
            observationName: "",
            initialBaseline: initialBaselineId,
            subRule: this.subRule,
            initialBaselineActions: [action]
        };
        this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0]
            .initialBaselineObservations
            .push(observation);
    }

    saveObservation(id: any, data: any, index, initialBaselineId) {
        if (initialBaselineId !== undefined && this.subRule !== undefined) {
            if (id === 0) {
                let observation = {
                    observationId: 0,
                    observationName: data.target.value,
                    initialBaseline: initialBaselineId,
                    subRule: {
                        subRuleId: this.subRule[0].subRuleId,
                        subRulename: this.subRule[0].subRuleName,
                        isCustom: this.subRule[0].isCustom
                    },
                };
                this.mainService.addInitialObservation(observation).subscribe(x => {
                    this.subRule[0].initialBaselines
                        .filter(x => x.initialBaselineId == initialBaselineId)[0]
                        .initialBaselineObservations[index].observationId = x;
                });
            }

            if (id !== 0) {
                let observation = {
                    observationId: id,
                    observationName: data.target.value,
                    initialBaseline: initialBaselineId,
                    subRule: this.subRule[0],
                };
                this.mainService.updateInitialObservation(observation);
            }
        }
    }

    saveAction(observationId, actionModel, data: any, index, initialBaselineId) {
        if (observationId == 0) return;
        else if (observationId !== 0 && (actionModel.actionId === 0 || actionModel.actionId === undefined)) {
            let action = {
                actionId: 0,
                registeredDateTime: new Date(),
                complianceEffect: actionModel.comlianceEffect,
                description: data.target.value,
                observationId: observationId,
                status: { statusId: 1 }
            }
            this.mainService.addInitialAction(action).subscribe(x => {
                this.subRule[0].initialBaselines
                    .filter(x => x.initialBaselineId == initialBaselineId)[0]
                    .initialBaselineObservations.filter(x => x.observationId == observationId)[0]
                    .initialBaselineActions[index].actionId = x;
                this.subRule[0].initialBaselines
                    .filter(x => x.initialBaselineId == initialBaselineId)[0]
                    .initialBaselineObservations.filter(x => x.observationId == observationId)[0]
                    .initialBaselineActions[index].complianceEffect = actionModel.comlianceEffect;
            });
        }
        else if (observationId !== 0 && actionModel.actionId !== 0 && actionModel.actionId !== undefined) {
            let action = {
                actionId: actionModel.actionId,
                registeredDateTime: new Date(),
                complianceEffect: actionModel.comlianceEffect,
                description: data.target.value,
                observationId: observationId,
                status: actionModel.status
            }
            this.mainService.updateInitialAction(action).subscribe();
        }
    }
    deleteObservation(obId: number, initialBaselineId) {
        if (obId !== 0) {
            this.mainService.deleteInitialObservation(obId).subscribe();
        }
        var index = this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0]
            .initialBaselineObservations.findIndex(function (o) {
                return o.observationId === obId;
            })
        if (index !== -1) this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0]
            .initialBaselineObservations.splice(index, 1);
    }

    deleteAction(obId: number, acId: number, index, initialBaselineId) {
        if (acId !== 0) {
            this.mainService.deleteInitialAction(acId).subscribe();
        }
        if (index !== -1) this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0]
            .initialBaselineObservations.filter(x => x.observationId == obId)[0].initialBaselineActions.splice(index, 1);
    }

    isVisible(baselineId: number): boolean {
        return this.visibilityMap[baselineId] || false;
    }

    showHide(baselineId: number): void {
        var container = document.getElementById(`initialObservationsActionsWrapper${baselineId}`);
        if (container.style.display == 'block') {
            container.style.display = 'none';
            this.visibilityMap[baselineId] = false;
        } else {
            container.style.display = 'block';
            this.visibilityMap[baselineId] = true;
        }
    }

    complianceEffectChanged(args: any, initialBaselineId, action) {
        if (action.complianceEffect == -1 || action.complianceEffect == 1) {
            return;
        }
        var targetLevel = this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel;
        if (targetLevel == 100 && args.value > action.complianceEffect) {
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel = 100;
            return;
        }
        if (this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel !== null) {
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel = 
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].initialBaselineComplianceLevel + args.value;
        }

        if (action.actionId !== 0 && action.actionId !== undefined) {
            this.mainService.updateInitialAction(action).subscribe();
        }
    }

    onSliderChange(event: any, initialBaselineId): void {
        if (this.subRule[0].initialBaselines
            .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel === null) {
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel = event.value;
        }

        else {
            let allActions = this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].initialBaselineObservations
                .flatMap(observation => observation.initialBaselineActions);
            const complianceEffectSum = allActions.reduce((sum, action) => sum + action.complianceEffect, 0);
            this.subRule[0].initialBaselines
                .filter(x => x.initialBaselineId == initialBaselineId)[0].targetComplianceLevel = event.value + complianceEffectSum;
        }
    }

    addCost(c_type: EstimatedCostTypes) {
        var cost: EstimatedCosts = {
            estimatedCostId: 0,
            actionId: this.currentAction,
            estimatedCostTypeId: c_type.estimatedCostTypeId,
            amount: 0,
            estimatedCostName: '',
            IsStandard: false
        }
        this.ruleBookService.addCost(cost).subscribe(id => {
            cost.estimatedCostId = id;
            this.currentCosts.filter(x => x.estimatedCostTypeId == c_type.estimatedCostTypeId)[0].estimatedCosts.push(cost);
        })
    }

    updateCost(cost: EstimatedCosts, c_type: EstimatedCostTypes) {
        this.ruleBookService.updateCost(cost).subscribe(data => {
            this.currentCosts.forEach(element => {
                element.totalAmount = this.calculateTotalAmount(element.estimatedCosts);
            });
        });
    }

    deleteCost(estimatedCostId: number, estimatedCostTypeId, isStandard: boolean) {
        this.ruleBookService.deleteCost(estimatedCostId, isStandard, this.currentAction)
            .subscribe(x => {
                this.currentCosts.filter(x => x.estimatedCostTypeId == estimatedCostTypeId)[0].estimatedCosts
                    .splice(this.currentCosts
                        .filter(x => x.estimatedCostTypeId == estimatedCostTypeId)[0].estimatedCosts
                        .findIndex(x => x.estimatedCostId == estimatedCostId), 1);
            });
    }

    calculateTotalAmount(costs: EstimatedCosts[]): number {
        return costs.reduce((sum, cost) => sum + Number(cost.amount), 0);
    }
}