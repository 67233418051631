import { AfterViewChecked, AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { MultiSelectComponent, TaggingEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { DialogComponent, TooltipComponent } from "@syncfusion/ej2-angular-popups";
import { DataTransfersModelData } from "src/app/interfaces/DataTransfers/DataTransfersModelData";
import { LegalBasis } from "src/app/interfaces/DataTransfers/LegalBasis";
import { Prerequisites } from "src/app/interfaces/DataTransfers/Prerequisites";
import { RecipientCategory } from "src/app/interfaces/DataTransfers/RecipientCategory";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { TransferCategoriesComponent } from "./modals/categories/categories.component";
import { DerogationsComponent } from "./modals/derogations/derogations.component";
import { PersonalDataComponent } from "./modals/personal-data/personal-data.component";
import { SafeguardsComponent } from "./modals/safeguards/safeguards.component";

@Component({
    selector: 'data-transfers-form',
    styleUrls: ['data-transfers-form.css'],
    templateUrl: 'data-transfers-form.html',
})
export class DataTransfersFormComponent implements OnInit {

    public firstRun: boolean;
    public recipientId: string;
    public personalData = [];
    public emptycustomPersonalData = [];
    public filteredCustomPersonalData = [];
    public CategoryDataMultiselect = [];
    @ViewChild('SafeguardsDialog')
    public SafeguardsDialog: DialogComponent;

    @ViewChild('customCat')
    public customCat: MultiSelectComponent;

    @ViewChild(PersonalDataComponent) pdComponent: PersonalDataComponent;
    @ViewChild(SafeguardsComponent) safeguards: SafeguardsComponent;
    @ViewChild(DerogationsComponent) derogations: DerogationsComponent;
    @ViewChild(TransferCategoriesComponent) categoryComponent: TransferCategoriesComponent;

    @ViewChild('safeguardChips')
    public safeguardChips: ChipListComponent

    @ViewChildren(TooltipComponent) tooltipTemplates: QueryList<TooltipComponent>;
    @ViewChildren(ChipListComponent) templates: QueryList<ChipListComponent>;
    @ViewChildren(MultiSelectComponent) multitemplates: QueryList<MultiSelectComponent>;

    @ViewChild('derogationChips')
    public derogationChips: ChipListComponent

    @ViewChild('catChips')
    public catChips: ChipListComponent

    @ViewChild('DerogationsDialog')
    public DerogationsDialog: DialogComponent;

    @ViewChild('PersonalDataDialog')
    public PersonalDataDialog: DialogComponent;

    @ViewChild('TransferCategoryDialog')
    public TransferCategoryDialog: DialogComponent;

    showCloseIcon = true;
    public s_dialogPosition: { X: "Center", Y: "Center" }
    safeguardsHeight = '100%';
    safeguardsWidth = '50%';
    pdType: number;
    isTiaVisisble: boolean;
    isSafeguardVisisble: boolean;
    isDerogationVisisble: boolean;
    isExplainVisisble: boolean;
    modelData: DataTransfersModelData;
    pdWidth = '60%';
    public box: string = 'Box';
    public fields: Object = { text: 'name', value: 'id' };
    customPersonalData = [];
    legalBasisList = [];
    prerequisites = [];
    legalBasis: LegalBasis;
    categoryList = [];
    category: RecipientCategory;
    public placeholder: string = 'Start typing here...';
    explainContainerVisisble: boolean;
    bpId: string;
    versionNo: any;
    constructor(private service: DataTransferService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.firstRun = true;
        this.isTiaVisisble = false;
        this.isDerogationVisisble = false;
        this.isSafeguardVisisble = false;
        this.explainContainerVisisble = false;
        this.route.params.subscribe(params => {
            const id = params['recipientId'];
            if (id !== 'recipientId') {
                this.recipientId = id;
                this.getCustomPersonalData();
            }
        })

        this.getPersonalDataInfo();
        this.getCustomPersonalData();
        this.getDisabledCustomPersonalData();
        this.getLegalBasisByRecipient();
        this.getLegalBasisList();
        this.getRecipientCategoryByRecipient();
        this.getRecipientCategoryList();
        this.getPrerequisites();
        this.getDataTransfersModelData()
    }
    getCustomPersonalData() {
        this.service.getCustomPersonalDataList(this.recipientId, this.versionNo).subscribe(data => {
            this.customPersonalData = data;
        })
    }

    showCategoriesInput() {
        document.getElementById("customCat").style.display = "block";
    }

    getDataTransfersModelData() {
        this.service.getDTModelData(this.recipientId, this.versionNo).subscribe(data => {
            this.modelData = data;
        })
    }

    getPrerequisites() {
        this.service.getPrerequisitesList(this.recipientId, this.versionNo).subscribe(data => {
            this.prerequisites = data;
        })
    }

    getDisabledCustomPersonalData() {
        this.service.getDisabledCustomPersonalDataList(this.recipientId, this.versionNo).subscribe(data => {
            this.emptycustomPersonalData = data;
        })
    }

    filterDisabledPersonalData(typeId) {
        this.filteredCustomPersonalData = this.emptycustomPersonalData.filter(x => x.personalDataType == typeId);
    }

    getPersonalDataInfo() {
        this.service.getPersonalDataListByRecipient(this.recipientId, this.versionNo).subscribe(_data => {
            this.personalData = _data;       
        })
    }

    getLegalBasisList() {
        this.service.getLegalBasisList().subscribe(_data => {
            this.legalBasisList = _data;
        })
    }

    linkPrerequisiteDate(args, pre: Prerequisites) {
        if (pre !== undefined) {
            pre.explainDate = args.value;
            this.service.addPrerequisiteLink(this.recipientId, pre, this.versionNo).subscribe();
            var dateField = document.getElementById("transferDatePicker" + pre.id);
            this.prerequisites.filter(x => x.id == pre.id)[0].explainDate = args.value;
            this.getPrerequisites();
        }
    }

    linkExplainDate(args, id) {
        let explainModel = {
            id: 0,
            recipientId: this.recipientId,
            explainDate: args.value
        }
        if (id === 0 || id === null || id === undefined) {
            this.service.addExplainDate(explainModel).subscribe(data => {

            });
        }

        else {
            explainModel.id = id;
            this.service.updateExplainDate(explainModel).subscribe();
        }
    }

    getLegalBasisByRecipient() {
        this.service.getLegalBasisByRecipient(this.recipientId, this.versionNo).subscribe(_data => {
            this.legalBasis = _data;
            if (this.legalBasis !== null) {
                switch (this.legalBasis.id) {
                    case 1: {
                        this.isDerogationVisisble = false;
                        this.isSafeguardVisisble = false;
                        this.isTiaVisisble = false;
                        this.explainContainerVisisble = false;
                        break;
                    }
                    case 2: {
                        this.isDerogationVisisble = false;
                        this.isSafeguardVisisble = true;
                        this.isTiaVisisble = true;
                        this.explainContainerVisisble = false;
                        break;
                    }
                    case 3: {
                        this.isDerogationVisisble = true;
                        this.isSafeguardVisisble = false;
                        this.isTiaVisisble = false;
                        this.explainContainerVisisble = false;
                        break;
                    }
                    case 4: {
                        this.isDerogationVisisble = false;
                        this.isSafeguardVisisble = false;
                        this.isTiaVisisble = false;
                        this.explainContainerVisisble = true;
                        break;
                    }
                    default: {
                        this.isDerogationVisisble = false;
                        this.isSafeguardVisisble = false;
                        this.isTiaVisisble = false;
                        this.explainContainerVisisble = false;
                        break;
                    }
                }
            }
        })
    }

    getRecipientCategoryList() {
        this.service.getRecipientCategoryList(this.recipientId, this.versionNo).subscribe(_data => {
            this.categoryList = _data;
        })
    }

    getRecipientCategoryByRecipient() {
        this.service.getRecipientCategoryByRecipient(this.recipientId, this.versionNo).subscribe(_data => {
            this.category = _data;
        })
    }

    showSafeguards() {
        this.SafeguardsDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select safeguards</h3>'
        this.SafeguardsDialog.show();
    }

    showTransferCategories() {
        this.TransferCategoryDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select Recipient Category</h3>'
        this.TransferCategoryDialog.show();
    }

    showPersonalData(id) {
        this.pdType = id;
        this.PersonalDataDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select personal data type</h3>'
        this.PersonalDataDialog.show();
    }

    PDDialogClose(){
        this.pdComponent.hideMultiselect();
    }
    CatDialogClose(){
        this.categoryComponent.hideMultiselect();
    }

    showPersonalDataInput(id) {
        document.getElementById("customPD_" + id).style.display = "block";
    }

    showDerogations() {
        this.DerogationsDialog.header = '<h3 style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Select derogations</h3>'
        this.DerogationsDialog.show();
    }

    deletePersonalDataType(e: ClickEventArgs, type, isStandard) {      
        if (e.text) {
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {              
                let index = e.data["value"];
                if (index) {
                    var dataToReturn = {
                        id: Number(index),
                        name: e.data["text"].replace("+ ", ""),
                        isStandard: isStandard,
                        personalDataType: type,
                        isEnabled: true,
                        recipientId: this.recipientId,
                        isCurrentlySelected: false
                    }
                    if(isStandard){
                        this.service.deleteLinkPersonalDataType(this.recipientId, Number(index), type, this.versionNo);
                    }
                    if (!isStandard) {
                        this.service.deselectPersonalDataType(Number(index));
                    }
                    this.pdComponent.type = type;
                    if (isStandard) {              
                        if (this.pdComponent.personalData !== undefined) {
                            this.pdComponent.personalData.filter(x => x.typeId == type)[0].personalDataInfoValues.push(dataToReturn);
                        }
                    }
                    if (!isStandard) {
                        if (this.pdComponent.deselectedEnabledData !== undefined) {
                            this.pdComponent.deselectedEnabledData.push(dataToReturn);
                        }
                    }
                }
            }
        }
    }

    deleteCategory(e: ClickEventArgs, isCustom: boolean) {
        if (e.text) {
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn') {
                let index = e.data["value"];
                if (index) {
                    var val = {
                        id: Number(index),
                        name: e.data["text"]
                    }
                    this.service.deleteCategoryLink(this.recipientId, Number(index), this.versionNo);
                    if (!isCustom) {
                        this.categoryComponent.categories.push(val);
                    }
                    if (isCustom) {
                        this.categoryComponent.customCategories.push(val);
                    }
                    this.category = null;
                }
            }
        }
    }

    categoryChanged(id, isCustom) {
        this.service.linkRecipientCategory(this.recipientId, id, isCustom, this.versionNo).subscribe();
    }

    lbChanged(id) {
        this.service.linkLegalBasis(this.recipientId, id, this.versionNo).subscribe();
        switch (Number(id)) {
            case 1: {
                this.isDerogationVisisble = false;
                this.isSafeguardVisisble = false;
                this.isTiaVisisble = false;
                this.explainContainerVisisble = false;
                break;
            }
            case 2: {
                this.isDerogationVisisble = false;
                this.isSafeguardVisisble = true;
                this.isTiaVisisble = true;
                this.explainContainerVisisble = false;
                break;
            }
            case 3: {
                this.isDerogationVisisble = true;
                this.isSafeguardVisisble = false;
                this.isTiaVisisble = false;
                this.explainContainerVisisble = false;
                break;
            }
            case 4: {
                this.isDerogationVisisble = false;
                this.isSafeguardVisisble = false;
                this.isTiaVisisble = false;
                this.explainContainerVisisble = true;
                break;
            }
            default: {
                this.isDerogationVisisble = false;
                this.isSafeguardVisisble = false;
                this.isTiaVisisble = false;
                this.explainContainerVisisble = false;
                break;
            }
        }
    }

    deleteSafeguard(e: ClickEventArgs) {
        if (e.text) {
            this.service.deleteSafeguardLink(this.recipientId, e.data["value"], this.versionNo)
            this.safeguards.RevertAdd(e.data["value"])
        }
    }

    deleteDerogation(e: ClickEventArgs) {
        if (e.text) {
            this.service.deleteDerogationLink(this.recipientId, e.data["value"], this.versionNo)
            this.derogations.RevertAdd(e.data["value"])
        }
    }

    disablePersonalDataType(e: ClickEventArgs, type) {
        if (e.text) {
            let index = e.data["value"];
            if (index) {
                this.service.disableLinkPersonalDataType(Number(index));
                var item = {
                    id: index,
                    name: e.text,
                    isStandard: false,
                    personalDataType: type,
                    isEnabled: false,
                    recipientId: this.recipientId
                }
                this.emptycustomPersonalData.push(item);
            }
        }
    }

    disablePersonalDataTypeFromMultiselect(e, type) {
        if (e.itemData["id"]) {
            this.service.disableLinkPersonalDataType(Number(e.itemData["id"]));
            var item = {
                id: e.itemData["id"],
                name: e.itemData["name"],
                isStandard: false,
                personalDataType: type,
                isEnabled: false,
                recipientId: this.recipientId
            }
            this.emptycustomPersonalData.push(item);
        }
    }

    disableCustomCategory(e, type) {
        if (e.itemData["id"]) {
            this.service.disableLinkPersonalDataType(Number(e.itemData["id"]));
            var item = {
                id: e.itemData["id"],
                name: e.itemData["name"],
                isStandard: false,
                personalDataType: type,
                isEnabled: false,
                recipientId: this.recipientId
            }
            this.emptycustomPersonalData.push(item);
        }
    }


    RemoveElementFromArray(element: number, arrayElements: any) {
        arrayElements.forEach((value, index) => {
            if (value.id == element) arrayElements.splice(index, 1);
        });
    }

    beforeRenderSF(e) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipsf');
        currentTooltip[0].content = e.target.innerText;
    }

    beforeRenderSFExisting(e) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipsfex');
        currentTooltip[0].content = e.target.innerText;
    }

    beforeRenderDG(e) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipdg');
        currentTooltip[0].content = e.target.innerText;
    }

    beforeRenderDGExisting(e) {
        var currentTooltip = this.tooltipTemplates.filter(x => x.element.id == 'tooltipdgex');
        currentTooltip[0].content = e.target.innerText;
    }

    public onTagging = (e: TaggingEventArgs, type: number) => {
        if (e.itemData["id"] > 1) {
            this.service.enableLinkPersonalDataType(e.itemData["id"]);
            var chip = {
                text: e.itemData["name"],
                value: e.itemData["id"],
                cssClass: 'transfer-chips-choice'
            }

            var chipListName = 'pdChips_' + type + '';
            var currentChip = this.templates.filter(x => x.element.id == chipListName);
            currentChip[currentChip.length - 1].add(chip);
            this.RemoveElementFromArray(chip.value, this.filteredCustomPersonalData);
            this.getDisabledCustomPersonalData();
        }

        else {
            let model = {
                id: 0,
                name: e.itemData["name"],
                isStandard: false,
                personalDataType: type,
                isEnabled: true,
                recipientId: this.recipientId,
                versionNo: this.versionNo
            }
            this.service.addCustomPersonalData(model).subscribe(data => {
                e.itemData["id"] = data;
                var chip = {
                    text: e.itemData["name"],
                    value: Number(data),
                    cssClass: 'transfer-chips-choice'
                }

                var chipListName = 'pdChips_' + type + '';
                var currentChip = this.templates.filter(x => x.element.id == chipListName);
                currentChip[currentChip.length - 1].add(chip)
            });
        }
        var multiSelectName = "customPD_" + type;
        var currentSelect = this.multitemplates.filter(x => x.element.id == multiSelectName);
        currentSelect[0].value = [];
        e.setClass("transfer-chips-choice")
        document.getElementById("customPD_" + type).style.display = "none";
    }

    public BeforeOpen(e: TaggingEventArgs, typeId) {
        this.filterDisabledPersonalData(typeId)
    }
}