
export enum GroupData {
  //DEV
  // DataControllerGroupId = "6f193a4b-b200-4fa0-b313-f32261a59835",
  // DPOGroupId = "0f142f44-197f-46ec-a53f-a1514e887a56",
  // ComplianceManagerGroupId = "f2a9317c-b928-4ea2-8a70-488fc0c01f59",
  // AdminGroupId = "63bef5ff-1e36-488c-91de-ec9f878e3b26",
  // IncidentManagerGroupId = "0e235eae-794e-4740-9b66-649f01491581",
  // RequestManagerGroupId = "9ecc428c-28cf-4411-b231-aed54a1e4df0",
  // ProcessOwnerGroupId = "a461fd3a-3f7a-4000-b766-3950bb250b76",
  // ProcessOwnerITGroupId = "2fe34263-cf0f-43c9-b9a9-bd28278ef20d",
  // ProcessOwnerHRGroupId = "59a282a7-03cf-4217-ac33-5cff0c9940fd",
  // OrganizationAdminGroupId = "63cadd41-6c7d-493b-be7e-121889225f5e"


  //PROD
  DataControllerGroupId = "8c705ef4-a9e7-4876-87c5-29af96a9441c",
  ComplianceManagerGroupId = "a6f9d639-39ab-4aa0-b5ba-d74dd9a20fef",
  AdminGroupId = "857f24d2-00f5-4d39-9100-826ef3dc328e",
  DPOGroupId = '8cdc54ae-8a9c-443f-ad5c-ce964bc1c705',
  IncidentManagerGroupId = '8415a471-1485-4818-8803-052f37497c27',
  RequestManagerGroupId = 'a1f3cfba-49f9-45a2-bd00-e4b68c3ae978',
  ProcessOwnerGroupId = "86ba2c80-a984-48bb-a35a-4a1453d97d60",
  ProcessOwnerITGroupId = "74b2bf05-df61-4e6b-8202-998200e6afba",
  ProcessOwnerHRGroupId = "<mock>",
  OrganizationAdminGroupId = "41588998-014f-42a0-86f8-a268fb5e96d8"
}
