import { AfterViewChecked, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { OneDriveLargeFileUploadTask } from "@microsoft/microsoft-graph-client";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { MultiSelectComponent, TaggingEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { LegalBasisComponent } from "../../legal-basis.component";


@Component({
    selector: 'personal-data-lb',
    styleUrls: ['personal-data.component.css'],
    templateUrl: 'personal-data.component.html',
})
export class LegalBasisPersonalDataComponent implements OnInit, AfterViewChecked {
    @Input() recipientId;
    @Input() type;
    @Input() existingValues: any;
    public currentType: number;
    public currentRecipient;
    public personalData: any[];
    public emptycustomPersonalData = [];
    customPersonalData = [];
    public filteredCustomPersonalData = [];
    public fields: Object = { text: 'name', value: 'id' };
    public placeholder: string = 'Start typing here...';
    @ViewChild('pdChipsList')
    public pdChipsList: ChipListComponent
    @ViewChild('customchipsList')
    public customchipsList: ChipListComponent
    @ViewChild('multiselectelementPd')
    public multiselectelementPd: MultiSelectComponent
    deselectedEnabledData = [];
    bpId: string;
    versionNo: any;
    ngOnInit(): void {
        this.currentType = 0;
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
    }

    constructor(private service: DataTransferService, private parent: LegalBasisComponent,
        private authService: MsalService) { }
    ngAfterViewChecked(): void {
        if (this.type !== undefined && this.recipientId !== undefined) {
            if (this.type !== this.currentType || this.recipientId !== this.currentRecipient) {
                this.getPersonalData();
                this.getCustomPersonalData();
                this.getDeselectedCustomPersonalData();
                this.currentType = this.type;
                this.currentRecipient = this.recipientId;
            }
        }
    }

    RemoveElementFromArray(element: number, arrayElements: any) {
        arrayElements.forEach((value, index) => {
            if (value.id == element) arrayElements.splice(index, 1);
        });
    }

    getCustomPersonalData() {
        this.service.getCustomPersonalDataList(this.recipientId, this.versionNo).subscribe(data => {
            this.customPersonalData = data;
        })
    }

    getPersonalData() {
        this.service.getPersonalDataList().subscribe(data => {
            this.personalData = data.filter(x => x.typeId == this.type);
            if (this.existingValues !== undefined) {
                if (this.existingValues.length > 0) {
                    for (var i = 0; i < this.existingValues.length; i++) {
                        this.RemoveElementFromArray(this.existingValues[i].id, this.personalData[0].personalDataInfoValues);
                    }
                }
            }
        })
    }

    chipclick(e: ClickEventArgs, isCustom) {
        if (e.text) {
            if (e.event.target["className"] !== 'e-chip-delete e-dlt-btn') {
                if (this.recipientId !== undefined && this.type !== undefined) {
                    if (isCustom == false) {
                        this.service.linkPersonalDataType(this.recipientId, e.data["value"], this.type, this.versionNo);
                    }
                    if (isCustom == true) {
                        this.service.enableLinkPersonalDataType(e.data["value"]);
                    }
                }
                if (isCustom == false) {
                    this.RemoveElementFromArray(e.data["value"], this.personalData.filter(x => x.typeId == this.type)[0].personalDataInfoValues);
                }
                if (isCustom == true) {
                    this.RemoveElementFromArray(e.data["value"], this.deselectedEnabledData);
                }
                // var chip = {
                //     text: e.text,
                //     value: e.data["value"],
                //     cssClass: 'transfer-chips-choice'
                // }

                // var chipListName = 'pdChips_' + this.recipientId + '_' + this.type + '';
                // var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
                // currentChip[0].add(chip)

                this.parent.defaultCategoryData["documentationSubCategories"]
                    .filter(x => x.tabLevel === 3)
                    .map(x => x.documentationCategoryFields)
                    .reduce(function (a, b) { return a.concat(b); }, [])
                    .filter(x=>x.documentationCategoryFieldId == this.recipientId)[0].personalDataTypes
                    .filter(x=>x.typeId == this.type)[0].personalDataInfoValues.push({ id: e.data["value"], name: e.text })
                this.parent.recalculateProgress();
            }
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn' && isCustom === true) {
                this.service.disableLinkPersonalDataType(e.data["value"]);
                this.RemoveElementFromArray(e.data["value"], this.deselectedEnabledData);
            }
        }
    }

    close() {
        this.parent.PersonalDataDialog.hide();
    }

    disablePersonalDataTypeFromMultiselect(e, type) {
        if (e.itemData["id"]) {
            this.service.disableLinkPersonalDataType(Number(e.itemData["id"]));
            var item = {
                id: e.itemData["id"],
                name: e.itemData["name"],
                isStandard: false,
                personalDataType: type,
                isEnabled: false,
                recipientId: this.recipientId
            }
            this.emptycustomPersonalData.push(item);
        }
    }
    filterDisabledPersonalData(typeId) {
        this.filteredCustomPersonalData = this.emptycustomPersonalData.filter(x => x.personalDataType == typeId);
    }
    public BeforeOpen(e: TaggingEventArgs, typeId) {
        this.filterDisabledPersonalData(typeId)
    }
    getDisabledCustomPersonalData() {
        this.service.getDisabledCustomPersonalDataList(this.recipientId, this.versionNo).subscribe(data => {
            this.emptycustomPersonalData = data;
        })
    }
    getDeselectedCustomPersonalData() {
        this.service.getDeselectedEnabledCustomPersonalDataList(this.recipientId, this.type, this.versionNo).subscribe(data => {
            this.deselectedEnabledData = data;
        })
    }
    public onTagging = (e: TaggingEventArgs) => {
        if (e.itemData["id"] > 1) {
            this.service.enableLinkPersonalDataType(e.itemData["id"]);
            var chip = {
                text: e.itemData["name"],
                value: e.itemData["id"],
                cssClass: 'transfer-chips-choice'
            }

            var chipListName = 'pdChips_' + this.recipientId + '_' + this.type + '';
            var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
            currentChip[currentChip.length - 1].add(chip);
            this.RemoveElementFromArray(chip.value, this.filteredCustomPersonalData);
            this.getDisabledCustomPersonalData();
        }

        else {
            let model = {
                id: 0,
                name: e.itemData["name"],
                isStandard: false,
                personalDataType: this.type,
                isEnabled: true,
                recipientId: this.recipientId,
                customerId: this.authService.instance.getAllAccounts()[0].tenantId,
                isCurrentlySelected: false,
                versionNo: this.versionNo
            }
            this.service.addCustomPersonalData(model).subscribe(data => {
                e.itemData["id"] = data;
                model.id = Number(data);
                var chip = {
                    text: e.itemData["name"],
                    value: Number(data),
                    cssClass: 'e-chip pd-transfer-chips-choice'
                }

                this.customchipsList.add(chip);
                this.filteredCustomPersonalData = null;
                this.multiselectelementPd.value = [];
                this.deselectedEnabledData.push(model)
                document.getElementById("multiselectelementPd").style.display = "none";
            });
        }
        //var multiSelectName = "customPD_" + type;
        //var currentSelect = this.multitemplates.filter(x => x.element.id == multiSelectName);
        //currentSelect[0].value = [];
        e.setClass("transfer-chips-choice")
        // document.getElementById("customPD_" + type).style.display = "none";
    }
    showPersonalDataInput() {
        document.getElementById("multiselectelementPd").style.display = "block";
    }
    hideMultiselect() {
        document.getElementById("multiselectelementPd").style.display = "none";
    }
    titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }
}