import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TabComponent, ItemModel, MenuEventArgs } from "@syncfusion/ej2-angular-navigations";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { DialogComponent, createSpinner, hideSpinner, showSpinner } from "@syncfusion/ej2-angular-popups";
import { FontModel, ILoadedEventArgs, ProgressTheme } from "@syncfusion/ej2-angular-progressbar";
import { AnimationModel } from "@syncfusion/ej2-base";
import { DocumentationCategoryField } from "src/app/interfaces/DocumentationCategoryField";
import { ControlEvent } from "src/app/interfaces/Maintenance/ControlEvent";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";
import { ArchiveMaintenanceComponent } from "../documentation-maintenance/archive-maintenance.component";
import { DiscretionaryMaintenanceComponent } from "../documentation-maintenance/discretionary-maintenance.component";
import { ControlReportComponent } from "../documentation-maintenance/modals/control-report.component";
import { MaintenanceLogComponent } from "../documentation-maintenance/modals/maintenance-log.component";
import { WheelMaintenanceComponent } from "../documentation-maintenance/wheel-maintenance.component";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RightsOfDataSubjectsService } from "src/app/shared/services/rightsOfDataSubjectsService";
import { ErasureNegativeComponent } from "./modals/erasure/erasure-negative.component";
import { FormControl, Validators } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import { RestrictionsComponent } from "./modals/restrictions/restrictions.component";
import { ChipListComponent } from "@syncfusion/ej2-angular-buttons";
import { RestrictionsTotheRightsComponent } from "./modals/restrictions_to_the_rights/restrictions_to_the_rights.component";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";
@Component({
    selector: 'rights-of-data-subjects',
    styleUrls: ['../general-info/general-info.component.css', 'rights-of-data-subjects.css'],
    templateUrl: 'rights-of-data-subjects.html',
    encapsulation: ViewEncapsulation.None
})
export class RightsOfDataSubjectsComponent implements OnInit, AfterViewInit {
    @ViewChild(ArchiveMaintenanceComponent, { static: false }) childA: ArchiveMaintenanceComponent;
    @ViewChild(WheelMaintenanceComponent, { static: false }) childW: WheelMaintenanceComponent;
    @ViewChild(DiscretionaryMaintenanceComponent, { static: false }) childD: DiscretionaryMaintenanceComponent;
    @ViewChild(ControlReportComponent, { static: false }) childCR: ControlReportComponent;
    @ViewChild(MaintenanceLogComponent, { static: false }) childML: MaintenanceLogComponent;
    @ViewChild(ErasureNegativeComponent) erasures: ErasureNegativeComponent;
    @ViewChild(RestrictionsComponent) restrictions: RestrictionsComponent;
    @ViewChild(RestrictionsTotheRightsComponent) restrictionsToTheRights: RestrictionsTotheRightsComponent;

    @ViewChild('tabGroup', null) private matTabGroup: MatTabGroup;
    @ViewChild('ErasureNegativeDialog')
    public NegativeErasureDialog: DialogComponent;
    @ViewChild('RestrictionsDialog')
    public RestrictionsDialog: DialogComponent;
    @ViewChild('RestrictionsToTheRightsDialog')
    public RestrictionsToTheRightsDialog: DialogComponent;
    categoryId;
    legalBasisId: any;
    tabIndex;
    prevIndex = 0;
    lastTabItem
    defaultCategoryData = [];
    filledFields: number;
    wholeFields: number;
    defaultSubCategoryData = [];
    subCategories = [];
    controlId: number;
    categoryField: any;
    showWheel: boolean = false;
    showWheelReport: boolean = false;
    typeSelected: any;
    showDiscretionary: boolean = false;
    currentEvent: ControlEvent;
    generalFrequency: any;
    fieldName: string;
    categoryType: any;
    currentDateTime: any;
    controlStarted: boolean;
    exitMode: boolean;
    exitWheelMode: boolean;
    refreshLog: boolean;
    refreshDiscretionary: boolean;
    contentTypeModel: { text: string; value: number; }[];
    currentType: number;
    bpId: string;
    versionNo: any;
    viewId: any;
    filterargs = { tabLevel: 1 };
    filterargsSecond = { tabLevel: 2 };
    applications: any[];
    erasureModelData: any[];
    fieldId: string;
    arr: any = [];
    erasureType: number = 0;
    restrictionsModelData: any[];
    restrictionsToTheRightsModelData: any[];
    restrictionsCalled: boolean;
    erasuresCalled: boolean;
    restrictionsFieldId: string;
    restrictionsToTheRightsFieldId: string;
    @ViewChild('tab_rds') mainTabs: TabComponent;
    mainIndex: any;
    tableData: any = [];
    personalDataInfoValues: any = [];
    existingBpImportanceModelData: any[];

    constructor(private generalInfoService: GeneralInfoService, private mainService: DocMaintenanceService,
        private route: ActivatedRoute, private rightsService: RightsOfDataSubjectsService,
        private lbService: LegalBasisService) { }

    onMainTabSelect(args: any) {
        this.mainIndex = args.selectedIndex;
        var mainSubCategoryId = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == 1)[args.selectedIndex].documentationSubCategoryId;
        this.getErasureRestrictionsModels(mainSubCategoryId);
    }

    ngAfterViewInit(): void {
        this.getControlFrequencies();
        this.getCurrentControlEvent();
        this.SubmitFinalDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Submit as...</label>";
        this.FrequencyDialog.header = "<label style='font-family:Rubik; font-size:20px; font-weight:500; color:#0C2B57'>Maintenance frequency</label>"
    }
    ngOnInit(): void {
        createSpinner({
            target: document.getElementById('loadingContainer') as any
        });

        showSpinner((document as any).getElementById('loadingContainer'));
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.categoryId = id;
            }
        })
        this.arr.push('a', 'b', 'c');
        this.restrictionsCalled = false;
        this.erasuresCalled = false;
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.viewId = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].modelViewID;
        this.currentDateTime = new Date().toISOString().slice(0, 10);
        this.controlStarted = false;
        this.exitWheelMode = false;
        this.exitMode = false;
        this.refreshLog = false;
        this.refreshDiscretionary = false;
        this.refresh = false;
        this.showWheel = false;
        this.showDiscretionary = false;
        this.ifCategoryCompletedIsFinal();
        this.getCategories();
        this.getApplications();
        this.getLegalBasisTableData();
        this.GetExistingDmImportanceListsByBusinessProcess();
        this.contentTypeModel = [
            { text: 'Planning wheel maintenance', value: 1 },
            { text: 'Discretionary maintenance', value: 2 }
        ]
    }
    public labelStyle1: FontModel;
    public type: string = 'Linear';
    public width: string = '303px';
    public height: string = '41px';
    public trackThickness: number = 41;
    public progressThickness: number = 41;
    public min: number = 0;
    public max: number = 100;
    public value1: number = 0;
    public showProgressValue: boolean = true;
    public controlFrequency = [];
    resetFormSubject: Subject<boolean> = new Subject<boolean>();
    public dialogResize: Boolean = true;
    public dialogdragging: Boolean = true;

    @ViewChild('erasureChips')
    public erasureChips: ChipListComponent

    @ViewChild('restrictionsToTheRightsChips')
    public restrictionsToTheRightsChips: ChipListComponent

    @ViewChild('restrictionsChips')
    public restrictionsChips: ChipListComponent

    @ViewChild('Dialog')
    public Dialog: DialogComponent;

    @ViewChild('FrequencyDialog')
    public FrequencyDialog: DialogComponent;

    @ViewChild('CRDialog')
    public ReportDialog: DialogComponent;

    @ViewChild('ArchiDialog')
    public ArchiDialog: DialogComponent;

    @ViewChild('MDialog')
    public MDialog: DialogComponent;

    @ViewChild('SubmitFinalDialog')
    public SubmitFinalDialog: DialogComponent;

    @ViewChild('CETypeDialog')
    public CEDialog: DialogComponent;
    public dialogHeight = '100%';
    public dialogWidth = '31%';
    public dialogCEHeight = '220';
    public dialogCEWidth = '25%';
    public dialogPosition: { X: "Right", Y: "Top" }
    public dialogCEPosition: { X: "Center", Y: "Top" }

    public a_dialogPosition: { X: "Center", Y: "Center" }
    public cat_dialogPosition: { X: "Bottom", Y: "Right" }

    public a_dialogWidth = '70%';
    public a_dialogCEHeight = '100%';

    public erasureHeight = '80%';


    public cr_dialogWidth = '50%';
    public cr_dialogHeight = '100%';

    public cat_dialogWidth = '15%';
    public cat_dialogCEHeight = '30%';
    f_dialogWidth = '25%';
    showCloseIcon = true;
    public isFinal: boolean;
    public refresh: boolean = false;
    reportRefresh: boolean = false;

    public ddlfields: Object = { text: 'name', value: 'id' };
    public waterMark: string = 'Select frequency item';
    public value: string = 'GDPR visual models';
    public showReport: boolean;
    public refreshReport: boolean;

    categories = [];
    public animation: AnimationModel = { duration: 2000, delay: 0 };

    @ViewChild('tabGroup') tabGroup: TabComponent;

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChild('warningToast')
    public position: ToastPositionModel = { X: 'Right', Y: 'Bottom' };

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];

    showNegativeErasure() {
        this.erasures.isFirstRun = true;
        if (this.erasureType == 2)
            this.NegativeErasureDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
                'color: #0C2B57; line-height:0px; margin-top:10px;' +
                'font-weight: 600;' +
                'font-size: 19px;' +
                'text-align: left;">Explain why the data subject does not have the right to' +
                '​</h3></br><h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif; line-height:0px;' +
                'color: #0C2B57;' +
                'font-weight: 600;' +
                'font-size: 19px; text-align: left;"' +
                '>have his/her personal data erased</h3>';
        else
            this.NegativeErasureDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
                'color: #0C2B57;' +
                'font-weight: 600;' +
                'font-size: 19px;' +
                'text-align: left;">Explain on which grounds' +
                '​</h3>';
        this.NegativeErasureDialog.show();
    }

    showRestrictions() {
        this.restrictions.isFirstRun = true;
        this.RestrictionsDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Explain why the data subject does not have the right to have his/her personal data erased' +
            '​</h3>';
        this.RestrictionsDialog.show();
    }

    showRestrictionsToTheRights() {
        this.restrictionsToTheRights.isFirstRun = true;
        this.RestrictionsToTheRightsDialog.header = '<h3 class="safeguardsHeader" style="font-family: Rubik, sans-serif;' +
            'color: #0C2B57;' +
            'font-weight: 600;' +
            'font-size: 19px;' +
            'text-align: left;">Explain why the data subject does not have the right to have his/her personal data erased' +
            '​</h3>';
        this.RestrictionsToTheRightsDialog.show();
    }

    setTypeSelected(type) {
        this.typeSelected = type;
    }

    showArchive() {
        this.childA.refresh = true;
        this.ArchiDialog.header = '<label style="font-family: Rubik;font-weight: 600;' +
            'font-size: 20px; color: #0C2B57;">Maintenance archive</label>';
        this.ArchiDialog.show();
    }

    getCurrentControlEvent() {
        this.mainService.getCategoryMaintenanceType(this.bpId, this.categoryId, this.versionNo).subscribe(x => {
            if (x) {
                if (x === 1) {
                    this.showWheel = true;
                    this.showDiscretionary = false;
                    this.currentType = 1;
                }

                if (x === 2) {
                    this.showWheel = false;
                    this.showDiscretionary = true;
                    this.currentType = 2;
                }
            }
        })
    }

    currentIndex;


    public items: ItemModel[] = [
        {
            text: 'Final'
        }];

    public itemBeforeEvent(args: MenuEventArgs) {
        args.element.getElementsByTagName('a')[0].setAttribute('target', '_blank');
    }

    ifCategoryCompletedIsFinal() {
        this.generalInfoService.ifCategoryIsFinal(this.bpId, this.categoryId, this.versionNo).subscribe(_data => {
            this.isFinal = _data;
        })
    }

    chooseCEType() {
        this.CEDialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 18px;' +
            'color: #0C2B57;">Select type of Control Event:</label>'
        this.CEDialog.show();
    }

    submitTypeSelection() {
        this.controlStarted = false;
        this.CEDialog.hide();
        if (this.typeSelected !== undefined) {
            if (this.typeSelected === 2) {
                this.showDiscretionary = true;
                this.showWheel = false;
                this.mainService.updateCategoryType(this.bpId, this.categoryId, 2, this.versionNo);
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
            if (this.typeSelected === 1) {
                this.showWheel = true;
                this.showDiscretionary = false;
                this.mainService.updateCategoryType(this.bpId, this.categoryId, 1, this.versionNo);
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
        }

        else if (this.typeSelected === null || this.typeSelected === undefined) {
            this.typeSelected = 1;
            this.showWheel = true;
            this.showDiscretionary = false;
            this.mainService.updateCategoryType(this.bpId, this.categoryId, 1, this.versionNo);
        }
    }


    showDialog(data: any, name: string) {
        this.categoryField = data;
        this.fieldName = name;
        this.Dialog.header = '<label style="font-family: Rubik;' +
            'font-weight: 500;' +
            'font-size: 20px;' +
            'color: #0C2B57;">Add observations and actions</label>'
        this.Dialog.show();
    }
    showMDialog(id: number) {
        this.controlId = id;
        this.MDialog.header = '<label style="font-size: 25px;' +
            'font-family: Rubik, sans-serif;' +
            'font-weight: 600; margin-left:5px;' +
            'color: #0C2B57" class="logLbl">Maintenance Log</label>';
        this.childML.refresh = true;
        this.MDialog.show();
    }
    observationActionDialogClose() {
        if (this.showWheel == true) {
            this.childW.refresh = true;
        }
        if (this.showWheel == false) {
            this.childD.refresh = true;
        }
        this.categoryField = undefined;
    }

    isComplianceManager: boolean = false;
    isDataController: boolean = false;
    isAdmin: boolean = false;
    bpHeader;

    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }

    public load(args: ILoadedEventArgs): void {
        let selectedTheme: string = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = <ProgressTheme>(selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark');
        if (args.progressBar.theme === 'Material') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.labelStyle.fontFamily = 'Rubik'
            args.progressBar.labelStyle.fontWeight = '500'
            args.progressBar.progressColor = '#5AA9EF'
            args.progressBar.trackColor = '#0C2B57'
        }
        if (selectedTheme === 'Contrast') {
            args.progressBar.labelStyle.color = 'white';
            args.progressBar.trackColor = '#0C2B57'
        }
    }

    isShouldRender(field: DocumentationCategoryField, fields: any[]): boolean {
        let parent;
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId).length > 0) {
                    parent = fields[i].filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId)
                    break;
                }
            }
            if (parent)
                if (parent[0].shouldRender)
                    if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                        return true;
                    }
        }
        else return false;
    }

    getIfCoveredByDecisionMaking(fieldId: string) {
        this.lbService.coveredByTheRightNotToBeSubjectToAutomatedDecisionMaking(fieldId, this.versionNo).subscribe(res => {

        });
    }

    GetExistingDmImportanceListsByBusinessProcess() {
        this.lbService.getExistingDecisionMakingImportanceFunctionByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.existingBpImportanceModelData = res;
            }
        })
    }

    getCategories() {
        let customerId = sessionStorage.getItem("customerId");
        this.rightsService.getCategoryById(this.categoryId, this.bpId, this.versionNo, this.viewId, customerId).subscribe(res => {
            this.defaultCategoryData = res;
            let fields = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields);

            for (var i = 0; i < fields.length; i++) {
                for (var j = 0; j < fields[i].length; j++) {
                    fields[i][j].shouldRender = this.isShouldRender(fields[i][j], fields);
                }
            }
            this.recalculateProgress()
        })
    }

    fieldValueChanged(field, args) {
        field.fieldValue.value = args;
        field.fieldValue.hasChanged = true;
    }

    getLegalBasisTableData() {
        let categories;
        if (sessionStorage.getItem("categories")) {
            categories = JSON.parse(sessionStorage.getItem("categories"));
            this.legalBasisId = categories
                .filter(x => x.description == 'The legal basis for processing')[0]
                .documentationCategoryId;
        }
        else {
            this.generalInfoService.getAllCategoriesListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
                this.legalBasisId = res
                    .filter(x => x.description == 'The legal basis for processing')[0]
                    .documentationCategoryId;
            });
        }
        let customerId = sessionStorage.getItem("customerId");
        this.lbService.getLegalBasisTableData(this.legalBasisId, this.bpId, this.versionNo, this.viewId, customerId).subscribe(data => {
            this.tableData = data;
            this.tableData.forEach(element => {
                element.fields.forEach(element2 => {
                    if (element2.personalDataInfoValues.length > 0) {
                        this.personalDataInfoValues.push(element2.personalDataInfoValues);
                    }
                });
            });
            this.recalculateProgress();
        });
    }

    getApplications() {
        // if (sessionStorage.getItem("legalBasisApps")) {
        //     this.applications = JSON.parse(sessionStorage.getItem("legalBasisApps"));
        // }
        // else {
            let categories;
            if (sessionStorage.getItem("categories")) {
                categories = JSON.parse(sessionStorage.getItem("categories"));
                this.legalBasisId = categories
                    .filter(x => x.description == 'The legal basis for processing')[0]
                    .documentationCategoryId;
            }
            else {
                this.generalInfoService.getAllCategoriesListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
                    this.legalBasisId = res
                        .filter(x => x.description == 'The legal basis for processing')[0]
                        .documentationCategoryId;
                });
            }
            let customerId = sessionStorage.getItem("customerId");
            this.rightsService.getApplications(this.legalBasisId, this.bpId, this.viewId, customerId, this.versionNo).subscribe(data => {
                this.applications = data;
                sessionStorage.setItem("legalBasisApps", JSON.stringify(data));
            })
        //}
    }

    GetErasureLists(type: number, showDialog: boolean) {
        this.rightsService.getExistingErasureModels(this.fieldId, this.versionNo).subscribe(res => {
            if (res !== null) {
                if (type > 0) {
                    this.erasureModelData = res.filter(x => x.responseType == type);
                    if (showDialog) {
                        this.showNegativeErasure();
                    }
                }
            }
        })
    }

    GetRestrictionsLists() {
        this.rightsService.getExistingRestrictionsModels(this.restrictionsFieldId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.restrictionsModelData = res;
            }
        })
    }

    GetRestrictionsToTheRightsLists() {
        this.rightsService.getExistingRestrictionsToTheRightsList(this.restrictionsToTheRightsFieldId, this.versionNo).subscribe(res => {
            if (res !== null) {
                this.restrictionsToTheRightsModelData = res;
            }
            this.restrictionsCalled = true;
        })
    }

    getErasureRestrictionsModels(mainSubactegory) {
        let restrictionFieldId = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.order === 4 && x.parentDocumentationSubCategoryId == mainSubactegory)[0].documentationCategoryFields
            .filter(x => x.sequence == 1)[0].documentationCategoryFieldId;

        this.restrictionsFieldId = restrictionFieldId;
        this.GetRestrictionsLists();

        this.restrictionsToTheRightsFieldId = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.order === 8 && x.parentDocumentationSubCategoryId == mainSubactegory)[0].documentationCategoryFields
            .filter(x => x.sequence == 1)[0].documentationCategoryFieldId;

        this.GetRestrictionsToTheRightsLists();


        let erasureField = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.order === 3 && x.parentDocumentationSubCategoryId == mainSubactegory)[0].documentationCategoryFields
            .filter(y => y.sequence == 1 && y.dataSourceType == 1)[0];

        if (erasureField.fieldValue.value) {
            if (erasureField.fieldValue.value == "Yes") {
                this.erasureType = 1;
            }
            else {
                this.erasureType = 2;
            }
        }
        let erasureFieldId = erasureField.documentationCategoryFieldId;
        this.fieldId = erasureFieldId;
        this.GetErasureLists(this.erasureType, false);
    }

    recalculateProgress() {
        let subCats = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == 2);
        let fields = subCats.map(x => x.documentationCategoryFields);
        let filledCounter = 0;
        var missingValues = [];
        let fieldsToRenderCounter = 0;

        for (var i = 0; i < fields.length; i++) {
            for (var j = 0; j < fields[i].length; j++) {
                if (fields[i][j].shouldRender === true) {
                    if (fields[i][j].sequence !== 0 && fields[i][j].type !== 7 && fields[i][j].type !== 6) {
                        if (fields[i][j].fieldValue.value != null && fields[i][j].fieldValue.value != '' && fields[i][j].fieldValue.value != 'undefined') {
                            filledCounter = filledCounter + 1;
                        }
                        else {
                            missingValues.push(fields[i][j])
                        }
                        //for the whole amount of needed fields
                        fieldsToRenderCounter = fieldsToRenderCounter + 1;
                    }
                }
            }
        }
        this.wholeFields = fieldsToRenderCounter;
        this.filledFields = filledCounter;
        this.value1 = (this.filledFields / this.wholeFields) * 100
        this.labelStyle1 = { text: 'You have completed ' + Math.round(this.value1) + '% of ' + this.max + '%' };
        var xxx = missingValues;
        hideSpinner((document as any).getElementById('loadingContainer'));
    }

    getParentTabs(parent: string) {
        var tabs = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.tabLevel == 2 && x.parentDocumentationSubCategoryId == parent);

        return tabs;
    }

    getThirdLevelParentTabs(parent: string) {
        var tabs = this.defaultCategoryData["documentationSubCategories"]
            .filter(x => x.parentDocumentationSubCategoryId == parent)
        return tabs;
    }

    getThirdLevelFields(subcategory: string) {
        if (subcategory) {
            var fields = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields)
                .filter(x => x.documentationSubCategoryId == subcategory);
            return fields;
        }
        return null;
    }

    onTabSelect(args: any) {
        this.tabIndex = args.index;
        setTimeout(() => this.getDynamicTemplate(), 500);
        setTimeout(() => this.recalculateProgress(), 500);
        this.updateCategoryProgress();
    }

    formSave() {
        this.tabIndex = 1;
        if (this.defaultCategoryData["progress"].progress === 100 && this.isFinal == false)
            this.SubmitFinalDialog.show();
        else if (this.defaultCategoryData["progress"].progress !== 100 || this.isFinal == true)
            this.submitCategory(0);
        else if (this.defaultCategoryData["progress"].progress == 100 && this.isFinal)
            this.submitCategory(0)
    }


    getControlFrequencies() {
        this.mainService.getControlFrequencyList().subscribe(data => {
            this.controlFrequency = data;
        })
    }

    frequencyChanged(data: any) {
        this.generalFrequency = data;
        var d = new Date();
        var newDate = new Date(d.setMonth(d.getMonth() + data.itemData.monthsToAdd));
        document.getElementById('nextDeadline').textContent = newDate.toISOString().slice(0, 10);
    }

    submitFrequency() {
        var bp = sessionStorage.getItem("bpId");
        this.mainService.changeFrequency(bp, this.categoryId, this.generalFrequency.itemData, this.versionNo)
        this.FrequencyDialog.hide();
    }

    submitCategory(categoryType: number) {
        let fields = this.defaultCategoryData["documentationSubCategories"]
        [this.tabIndex]["documentationCategoryFields"]
            .map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        this.generalInfoService.saveCategoryWithType(categoryType, this.categoryId, this.bpId, this.versionNo, fields).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
        if (categoryType == 2) this.isFinal = true;
        this.recalculateProgress()
        this.updateCategoryProgress()
    }

    setCategoryTypeSelected(typeid) {
        this.categoryType = typeid;
    }

    categorySbmtChoice() {
        if (this.categoryType === undefined) {
            this.categoryType = 1;
        }
        this.SubmitFinalDialog.hide();
        if (this.categoryType === 2)
            this.FrequencyDialog.show();
        this.submitCategory(this.categoryType)
    }

    updateCategoryProgress() {
        var bpId = sessionStorage.getItem("bpId");
        let categoryProgress = this.defaultCategoryData["progress"];
        categoryProgress.documentationCategoryId = this.defaultCategoryData["documentationCategoryId"];
        categoryProgress.businessProcessId = bpId;
        categoryProgress.progress = Math.round(this.value1);

        this.generalInfoService.addUpdateCategory(categoryProgress).pipe(catchError(err => {
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(err);
        })).subscribe();
        this.prevIndex = this.tabIndex;
    }

    checkInputValue(value: string, fieldId: string) {
        var patt = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
        var res = patt.test(value);
        var hiddenControlId = 'hidden_' + fieldId + '';
        if (!res) {
            document.getElementById(hiddenControlId).style.display = "block";
        }
        if (res) {
            document.getElementById(hiddenControlId).style.display = "none";
        }
    }

    formSaveOnTab() {
        let fields = this.defaultCategoryData["documentationSubCategories"]
            .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, []).map(x => x.fieldValue)
            .filter(x => x.hasChanged == true);
        if (fields.length > 0) {
            this.generalInfoService.saveCategory(fields).pipe(catchError(err => {
                this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
                this.toastObj.show(this.toasts[0]);
                return throwError(err);
            })).subscribe();
        }
    }

    handleChange(field, evt) {
        let fields = this.defaultCategoryData["documentationSubCategories"].map(x => x.documentationCategoryFields)
            .reduce(function (a, b) { return a.concat(b); }, []);
        field.fieldValue.hasChanged = true;
        var target = evt.target;

        for (var i = 0; i < fields.length; i++) {
            fields[i].shouldRender = false;
        }

        for (var i = 0; i < fields.length; i++) {
            fields[i].shouldRender = this.isShouldRenderLocal(fields[i], fields);
        }
    }

    handleErasureRestrictionsChange(fieldId: string, evt) {
        let fields = this.defaultCategoryData["documentationSubCategories"].map(x => x.documentationCategoryFields)
            .reduce(function (a, b) { return a.concat(b); }, []);
        fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].fieldValue.hasChanged = true;
        fields.filter(x => x.documentationCategoryFieldId == fieldId)[0].fieldValue.value = evt;

        for (var i = 0; i < fields.length; i++) {
            fields[i].shouldRender = false;
        }

        for (var i = 0; i < fields.length; i++) {
            fields[i].shouldRender = this.isShouldRenderLocal(fields[i], fields);
        }
    }

    callErasure(val) {
        if (val !== 'Yes' && val !== 'No') {
            val = this.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields)
                .reduce(function (a, b) { return a.concat(b); }, [])
                .filter(x => x.documentationCategoryFieldId == this.fieldId)[0].fieldValue.value;
        }
        this.handleErasureRestrictionsChange(this.fieldId, val);
        if (val == 'Yes') {
            this.erasureType = 1;
        }
        else {
            this.erasureType = 2;
        }
        this.GetErasureLists(this.erasureType, true);
    }

    callRestrictions(val) {
        this.handleErasureRestrictionsChange(this.restrictionsFieldId, val);
        if (val == 'Yes') {
            this.GetRestrictionsLists();
            this.showRestrictions();
        }
    }

    callRestrictionsToTheRights(val) {
        this.handleErasureRestrictionsChange(this.restrictionsToTheRightsFieldId, val);
        if (val == 'Yes') {
            this.GetRestrictionsToTheRightsLists();
            this.showRestrictionsToTheRights();
        }
    }

    isShouldRenderLocal(field: DocumentationCategoryField, fields: any): boolean {
        if (field.parentDocumentationCategoryFieldId == null)
            return true;
        if (field.parentDocumentationCategoryFieldId != null) {
            var parent = fields.filter(x => x.documentationCategoryFieldId == field.parentDocumentationCategoryFieldId);
            if (parent[0].shouldRender)
                if (parent[0].type == 2 && parent[0].fieldValue.value == field.parentDocumentationCategoryFieldTriggerValue) {
                    return true;
                }
        }
        else return false;
    }

    showObservationBtn(id: number) {
        this.controlId = id;
        this.controlStarted = true;
    }

    disableRestrictions(id: number, documentationCategoryFieldId: string) {
        this.rightsService.disableRestriction(documentationCategoryFieldId, this.versionNo, id).subscribe();
    }

    disableErasure(id: number, documentationCategoryFieldId: string) {
        this.rightsService.disableErasure(documentationCategoryFieldId, this.versionNo, id).subscribe();
    }

    controlEventRemoved(id) {
        if (this.controlId !== undefined) {
            if (this.controlId == id) {
                this.controlStarted = false;
            }
        }
    }

    previewReport(id: number) {
        this.controlId = id;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        })
        this.ReportDialog.show();
        this.showReport = true;
        this.childCR.refresh = true;
    }

    closePreview(exitControlMode) {
        this.ReportDialog.hide();
        if (exitControlMode === true) {
            this.controlStarted = false;
            if (this.showWheel == true) {
                this.childW.refresh = true;
                this.childW.prevId = 0;
                this.childW.selectedIndex = null;
            }
            if (this.showWheel == false) {
                this.childD.refresh = true;
                this.childD.prevId = 0;
                this.childD.selectedIndex = null;
            }
        }
    }

    closeAndPreview() {
        this.Dialog.hide();
        this.childCR.refresh = true;
        this.showReport = true;
        this.mainService.getControlEventById(this.controlId).subscribe(_data => {
            this.ReportDialog.header = '<label style="font-family: Rubik, sans-serif;' +
                'font-style: normal;' +
                'font-weight: 600;' +
                'font-size: 20px;' +
                'color: #0C2B57;">' + _data.displayId + ' - Control Report</label>'
        });
        if (this.showWheel == true)
            this.refresh = true;
        if (this.showWheel == false)
            this.refreshDiscretionary = true;
        this.ReportDialog.show();
    }

    closeLog() {
        this.MDialog.hide();
    }

    hideObservationBtn() {
        var addObservationActionButtons = document.getElementsByClassName("observationActionAdd") as HTMLCollectionOf<HTMLElement>;;
        for (var i = 0; i < addObservationActionButtons["length"]; i++) {
            addObservationActionButtons[i].style.display = "none";
        }
    }

    getDynamicTemplate() {
        this.formSaveOnTab();
        this.subCategories = this.defaultCategoryData["documentationSubCategories"];
        this.defaultSubCategoryData = this.subCategories[this.tabIndex];
    }

}
