// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.laTxt{
    float: left;
    clear: both;
    width: 80%;
}

.spanLa{
    color:green;
    margin-left: 15px;
    float: left;
    display: none;
}

.la_select_separator{
    height: 1px;
    background: #F0F1F6;
    margin-bottom: 15px;
    float: left;
    width: 90%;
    clear: both;
}

.laWrapper{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/direct-menu-pages/modals/licensed-activities.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.laTxt{\r\n    float: left;\r\n    clear: both;\r\n    width: 80%;\r\n}\r\n\r\n.spanLa{\r\n    color:green;\r\n    margin-left: 15px;\r\n    float: left;\r\n    display: none;\r\n}\r\n\r\n.la_select_separator{\r\n    height: 1px;\r\n    background: #F0F1F6;\r\n    margin-bottom: 15px;\r\n    float: left;\r\n    width: 90%;\r\n    clear: both;\r\n}\r\n\r\n.laWrapper{\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
