import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { LegalBasisComponent } from "../../legal-basis.component";
import { LegalBasisService } from "src/app/shared/services/legal-basis.service";

@Component({
    selector: 'decision-making-importance',
    styleUrls: ['decision-making-importance.css'],
    templateUrl: 'decision-making-importance.html',
})
export class DecisionMakingImportanceComponent implements OnInit, AfterViewChecked {
    @Input() field: any;
    @Input() model: any;
    @Input() versionNo: number;
    @Input() index: number;
    @Input() subCategory: any;

    dmImportance: any[];
    isFirstRun: boolean;
    bpId: string;

    constructor(private service: LegalBasisService, private parent: LegalBasisComponent) { }
    ngOnInit(): void {
        this.getDmImportanceList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId");
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.dmImportance !== undefined) {
                var removeItems = document.getElementsByClassName("removeDmImportanceBtn");
                for (var i = 0; i < removeItems.length; i++) {
                    removeItems[i].setAttribute('style', 'display:none');
                }
                var addItems = document.getElementsByClassName("dmImportanceAddBtn");
                for (var i = 0; i < addItems.length; i++) {
                    addItems[i].setAttribute('style', 'display:block');
                }
                if (this.model !== undefined && this.model.length > 0) {
                    for (var i = 0; i < this.model.length; i++) {
                        document.getElementById('dmImportanceAddBtn_' + this.model[i].id + this.index + '').style.display = "none";
                        document.getElementById('removeDmImportanceBtn_' + this.model[i].id + this.index + '').style.display = "block";
                    }
                }
                this.isFirstRun = false;
            }
        }
    }

    getDmImportanceList() {
        this.service.getDecisionMakingImportanceList().subscribe(x => {
            this.dmImportance = x;
        })
    }
    AddDmImportance(item) {
        document.getElementById('dmImportanceAddBtn_' + item.id + this.index + '').style.display = "none";
        document.getElementById('removeDmImportanceBtn_' + item.id + this.index + '').style.display = "block";
        this.service.EnableDecisionMakingImportance(this.field, this.bpId, this.versionNo, item).subscribe();
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        var chipListName = 'importanceChips_' + this.field + this.index + '';
        var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
        currentChip[0].add(chip)

        this.model.push(item);
        let b = this.model.some(x => x.selectType == 'a' || x.selectType == 'c');
        if (b) {
            var subcategoryId = this.parent.defaultCategoryData["documentationSubCategories"]
                .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
                .filter(x => x.documentationCategoryFieldId == this.field)[0].documentationSubCategoryId;
            this.parent.defaultCategoryData["documentationSubCategories"]
                .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = true;
        }
    }

    DisableDmImportance(id) {
        this.service.DisableDecisionMakingImportance(this.field, this.versionNo, id).subscribe();
        document.getElementById('removeDmImportanceBtn_' + id + this.index + '').style.display = "none";
        document.getElementById('dmImportanceAddBtn_' + id + this.index + '').style.display = "block";
        this.model.splice(this.model.findIndex(x => x.id == id), 1);
        let b = this.model.some(x => x.selectType == 'a' || x.selectType == 'c');
        var subcategoryId = this.parent.defaultCategoryData["documentationSubCategories"]
            .map(x => x.documentationCategoryFields).reduce(function (a, b) { return a.concat(b); }, [])
            .filter(x => x.documentationCategoryFieldId == this.field)[0].documentationSubCategoryId;
        if (b) {
            this.parent.defaultCategoryData["documentationSubCategories"]
                .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = true;
        }
        if (!b) {
            this.parent.defaultCategoryData["documentationSubCategories"]
                .filter(x => x.documentationSubCategoryId == subcategoryId)[0].documentationCategoryFields.filter(x => x.sequence == 6)[0].shouldRender = false;
        }
    }
}