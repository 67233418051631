import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { OrganizationService } from "../../../services/organization.service";
import { CommandModel, GridComponent, IEditCell, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { OrganizationFunctions } from "src/app/interfaces/Organization/OrganizationFunctions";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { LicensedActivity } from "src/app/interfaces/Organization/EntityTypes";

@Component({
    selector: 'business-functions',
    templateUrl: './business-functions.component.html',
    styleUrls: ['business-functions.component.css', '../basic-info.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    encapsulation: ViewEncapsulation.None,
})
export class BusinessFunctionsComponent implements OnInit {
    fileName: any;
    constructor(
        private organizationService: OrganizationService) { }
    public editSettings: Object;
    private timeCriticalOptions = [];
    private impactOptions = [];
    private functions = [];
    isPublicServices: boolean;
    isIndustries: boolean;
    showFunctions: boolean;
    imageUploaded: boolean;
    currentModel: any;
    currentIndex:number;
    public toastPosition: ToastPositionModel = { X: 'Right', Y: 'Bottom' };
    public commands: CommandModel[];
    public licensedActivities = [];
    public dialogHeight = '70%';
    public dialogWidth = '35%';
    showCloseIcon = true;
    dialogResize = true;
    dialogdragging = true;
    @ViewChildren(GridComponent) grids: QueryList<GridComponent>;
    url = '';
    ngOnInit(): void {
        this.editSettings = { allowEditing: false, allowAdding: true, allowDeleting: true, newRowPosition: 'Bottom' };
        this.isPublicServices = false;
        this.isIndustries = false;
        this.showFunctions = false;
        this.getOrganizationFunctions();
        this.getImpactOptions();
        this.getTimeCriticalOptions();
        this.getLicensedActivities()
        this.imageUploaded = false;

        this.commands = [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-close', cssClass: 'e-flat colorBronze' } }];
    }

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChild('FunctionsLADialog')
    public FunctionsLADialog: DialogComponent;

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];

    public impactOptionsFields: Object = { text: 'impactName', value: 'impactId' }
    public timeCriticalOptionsFields: Object = { text: 'timeCriticalName', value: 'timeCriticalId' }


    public impactParams: IEditCell;
    getOrganizationFunctions() {
        this.organizationService.getExistingOrganizationFunctions('7CCD8F33-73D1-4398-AEED-36F8ED82F931').subscribe(item => {
            this.functions = item;
        },
            error => {
                console.error('Error fetching functions:', error);
            })
    }

    getImpactOptions() {
        this.organizationService.getImpactOptions().subscribe(item => {
            this.impactOptions = item;
            this.impactParams = {
                params: {
                    actionComplete: () => false,
                    allowFiltering: true,
                    dataSource: this.impactOptions,
                    fields: this.impactOptionsFields,
                }
            };
        },
            error => {
                console.error('Error fetching impact options:', error);
            })
    }

    getTimeCriticalOptions() {
        this.organizationService.getTimeCriticalOptions().subscribe(item => {
            this.timeCriticalOptions = item;
        },
            error => {
                console.error('Error fetching time critical options:', error);
            })
    }

    getLicensedActivities() {
        this.organizationService.getLicensedActivity(sessionStorage.getItem("customerId")).subscribe(item => {
            this.licensedActivities = item;
        },
            error => {
                console.error('Error fetching licensedActivities:', error);
            })
    }

    showFunctionsLicensedActivities(model, ind) {
        this.currentModel = model;
        this.currentIndex = ind;
        this.FunctionsLADialog.header = '<label style="font-family: Rubik;font-weight: 300;' +
            'font-size: 32px; color: #0E384D;">Select Licensed activities</label>';
        this.FunctionsLADialog.show();
    }

    public add(name: string, id: string): void {
        var gridId = name + id;
        var currentGrid = this.grids.filter(x => x.element.id == gridId)[0];
        currentGrid.editModule.addRecord();
    }

    public delete(functionGroupId, data): void {
        var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
        if (currentGrid.dataSource["length"] == 1) {
            this.toasts[0].content = "Cannot remove last column from function group";
            this.toastObj.show(this.toasts[0]);
        }
        else {
            currentGrid.deleteRecord("functionId", data);
        }
    }

    actionBegin(args: any, functionGroupId): void {
        if (args.requestType === 'add') {
            var lastId = this.functions
                .filter(x => x.id == functionGroupId)[0].organizationFunctions
                .map(x => x.functionId).sort().reverse()[0];
            args.data.functionGroupId = functionGroupId;
            args.data.isCustom = true;
            args.data.functionId = lastId + 1;
            args.data.timeCritical = {
                timeCriticalId: null,
                timeCriticalName: null
            };
            args.data.impact = {
                impactId: null,
                impactName: null
            };
        }
        if (args.requestType === 'save' && args.action === 'add') {
            args.data.timeCritical = {
                timeCriticalId: null,
                timeCriticalName: null
            };
            args.data.impact = {
                impactId: null,
                impactName: null
            };
            this.organizationService.addFunction(args.data, sessionStorage.getItem("customerId")).subscribe(res => {
                var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
                currentGrid.setCellValue(0, "functionId", Number(res))
            })
        }

        if (args.requestType === 'delete') {
            var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
            if (currentGrid.dataSource["length"] == 1) {
                this.toasts[0].content = "Cannot remove last column from function group";
                this.toastObj.show(this.toasts[0]);
                args.cancel = true;
            }
            this.organizationService
                .deleteFunction(args.data[0].functionId,
                    sessionStorage.getItem("customerId"), args.data[0].isCustom);
        }
    }

    customiseCell(args: QueryCellInfoEventArgs) {
        var index = args.cell.attributes.getNamedItem("index").nodeValue;
        const count = parseInt(index, 10)
        if (count % 2 !== 0) {
            args.cell.classList.add('background-grey');
        }
    }

    fieldValueChanged(model: OrganizationFunctions, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.explain = model.explain;
        this.organizationService.updateFunction(data).subscribe(
            () => {
                this.updateFunctionListAndDataSource(ind, index, data);
            },
            error => {
                console.error('Error on data save:', error);
                this.toasts[2].content = "Failed to save data";
                this.toastObj.show(this.toasts[2]);
            });
    }

    saveImportantOrCriticalFunctionData(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.importantOrCritical = args.value;
        data.dateOfLatestAssessment = new Date();
        this.organizationService.updateFunction(data).subscribe(
            () => {
                this.updateFunctionListAndDataSource(ind, index, data);
            },
            error => {
                console.error('Error on data save:', error);
                this.toasts[2].content = "Failed to save data";
                this.toastObj.show(this.toasts[2]);
            });
    }

    saveTimeCritical(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.timeCritical = args.itemData;
        this.organizationService.updateFunction(data).subscribe(
            () => {
                this.updateFunctionListAndDataSource(ind, index, data);
            },
            error => {
                console.error('Error on data save:', error);
                this.toasts[2].content = "Failed to save data";
                this.toastObj.show(this.toasts[2]);
            });
    }

    saveImpact(model, args, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        data.impact = args.itemData;
        this.organizationService.updateFunction(data).subscribe(
            () => {
                this.updateFunctionListAndDataSource(ind, index, data);
            },
            error => {
                console.error('Error on data save:', error);
                this.toasts[2].content = "Failed to save data";
                this.toastObj.show(this.toasts[2]);
            });
    }

    updateFunctionListAndDataSource(ind, index, data) {
        this.functions[ind].organizationFunctions[index] = data;
        this.functions = [...this.functions];
    }

    linkLA(activity: LicensedActivity, model, ind) {
        var index: number = model["index"];
        var data = this.functions[ind].organizationFunctions[index];
        if (data.licensedActivities) {
            var existingItem = data.licensedActivities.filter(x => x.licensedActivityId == activity.licensedActivityId)[0];
            if (existingItem) {
                const indexToRemove = data.licensedActivities.findIndex(la => la.licensedActivityId === activity.licensedActivityId);
                if (indexToRemove !== -1) {
                    data.licensedActivities.splice(indexToRemove, 1);
                }
                this.organizationService.removeLicensedActivity(existingItem.licensedActivityId, model.functionId, model.isCustom).subscribe(
                    () => {
                        this.updateFunctionListAndDataSource(ind, index, data);
                    },
                    error => {
                        console.error('Error on data save:', error);
                        this.toasts[2].content = "Failed to save data";
                        this.toastObj.show(this.toasts[2]);
                    });
                return;
            }
        }
        var nextDisplayId = this.generateNewFunctionDisplayId(this.getExistingFunctionDisplayIds(ind));
        activity.functionDisplayId = nextDisplayId;
        this.functions[ind].organizationFunctions[index].licensedActivities.push(activity);
        this.organizationService.linkLicensedActivity(activity, model).subscribe(
            () => {
                this.updateFunctionListAndDataSource(ind, index, data);
            },
            error => {
                console.error('Error on data save:', error);
                this.toasts[2].content = "Failed to save data";
                this.toastObj.show(this.toasts[2]);
            });
    }

    getExistingFunctionDisplayIds(ind): string[] {
        const existingFunctionDisplayIds: string[] = [];
        this.functions[ind].organizationFunctions.forEach(func => {
            func.licensedActivities.forEach(activity => {
                existingFunctionDisplayIds.push(activity.functionDisplayId);
            });
        });
        return existingFunctionDisplayIds;
    }

    generateNewFunctionDisplayId(existingFunctionDisplayIds: string[]): string {
        let maxNumber = 0;
        existingFunctionDisplayIds.forEach(functionDisplayId => {
            const match = functionDisplayId.match(/F(\d+)/);
            if (match) {
                const number = parseInt(match[1], 10);
                if (!isNaN(number) && number > maxNumber) {
                    maxNumber = number;
                }
            }
        });
        const newNumber = maxNumber + 1;
        const newFunctionDisplayId = 'F' + newNumber;
        return newFunctionDisplayId;
    }
}