import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'data-transfers-layout',
    styleUrls: ['data-transfers-layout.css'],
    templateUrl: 'data-transfers-layout.html',
})
export class DataTransfersLayoutComponent implements OnInit {
    public categoryId: string;
    constructor(private route: ActivatedRoute) { }
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.categoryId = id;
            }
        })
    }

    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }
}