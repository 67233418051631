import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { StandardControlSpecificationModel } from "src/app/interfaces/RiskAnalysis/StandardControlSpecificationModel";
import { RiskService } from "src/app/shared/services/risk-analysis.service";
import { FullThreatComponent } from "../full-threat.component";
import { StandardControlComponent } from "./standard-controls.component";

@Component({
    selector: 'specifications',
    templateUrl: 'standard-control-specification.html',
    styleUrls: ['standard-control-specification.css']
})


export class StandardControlSpecificationComponent implements OnInit, AfterViewChecked {
    @Input() domain: any;
    public oldDomainId;
    public firstTime: boolean;
    @Input() control: number;
    @Input() threat: any;
    public existingSpecifications: Observable<StandardControlSpecificationModel>;
    constructor(
        private domainComponent: StandardControlComponent,
        private threatComponent: FullThreatComponent,
        private riskService: RiskService) { }
    ngOnInit(): void {
        this.firstTime = true;
        this.oldDomainId = 0;
    }
    ngAfterViewChecked(): void {
        if (this.threat !== undefined && this.domain !== undefined) {
            if (this.firstTime == true && this.domain.domainId !== this.oldDomainId) {
                if (this.threat.standardControls !== null) {
                    if (this.threat.standardControls.length > 0) {
                        let controlData = this.threat.standardControls.
                            filter(x => x.standardControlId == this.control);
                        if (controlData["length"] == 0) {
                            this.firstTime = false;
                            this.oldDomainId = this.domain.domainId;
                        }
                        if (controlData["length"] > 0)
                            this.existingSpecifications = controlData[0].domains.
                                filter(x => x.domainId == this.domain.domainId);


                        if (this.existingSpecifications["length"] > 0) {
                            this.existingSpecifications[0].specifications.forEach(x => {
                                if (document.getElementById('addControlBtn_' + this.control + x.controlSpecificationId + '')
                                    && document.getElementById('specRow_' + this.control + x.controlSpecificationId + '')) {
                                    document.getElementById('addControlBtn_' + this.control + x.controlSpecificationId + '').style.display = "none";
                                    document.getElementById('specRow_' + this.control + x.controlSpecificationId + '').closest('div').style.background = "#DBEEF7";
                                    document.getElementById('specContent_' + x.controlSpecificationId + '').style.width = "100%";
                                    this.firstTime = false;
                                    this.oldDomainId = this.domain.domainId;
                                }
                            })
                        }
                    }
                }
          }
       }
    }

    backToDomains() {
        this.firstTime = true;
        this.oldDomainId = 0;
        this.domainComponent.showdomainsList(this.control);
    }

    saveControl(item: StandardControlSpecificationModel) {
        if (this.threat !== undefined) {
            this.riskService.AddStandardControl(this.threat, item.controlSpecificationId);
        }

        document.getElementById('addControlBtn_' + this.control + item.controlSpecificationId + '').style.display = "none";
        document.getElementById('specRow_' + this.control + item.controlSpecificationId + '').closest('div').style.background = "#DBEEF7";
        document.getElementById('specContent_' + item.controlSpecificationId + '').style.width = "100%";
        var chip = {
            text: item.specification,
            value: item.controlSpecificationId,
            cssClass: 'custom-chips',
            leadingIconCss: 'iso'
        }
        this.threatComponent.standardConChipsList.add(chip)
    }

    deleteControl(item: StandardControlSpecificationModel) {
        if (this.threat !== undefined) {
            this.riskService.DeleteStandardControl(this.threat, item.controlSpecificationId);
        }
        document.getElementById('removeControlBtn_' + this.control + item.controlSpecificationId + '').style.display = "none";
        document.getElementById('addControlBtn_' + this.control + item.controlSpecificationId + '').style.display = "block";
        document.getElementById('specRow_' + this.control + item.controlSpecificationId + '').closest('div').style.background = "#EDEFF3";

        var chip = {
            text: item.specification,
            value: item.controlSpecificationId
        }
        this.threatComponent.standardConChipsList.remove(item.controlSpecificationId);
    }
}



