import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ModelData } from "src/app/interfaces/Administration/import/ModelData";
import auth from '../../auth-config.json';
import { forkJoin, Observable } from "rxjs";
import { Customer } from "src/app/interfaces/Administration/Customer";
import { ActionPlan } from "src/app/interfaces/Maintenance/ActionPlan";
import { ActionStatus } from "src/app/interfaces/ActionPlan/actionStatus";
@Injectable({
    providedIn: 'root'
})
export class ActionPlanService {
    url = auth.resources.PDHubApi.resourceUri;
    localUrl = "https://localhost:44394"
    constructor(private http: HttpClient, private authService: MsalService) { }
    private createHeader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    getActionPlans(): Observable<ActionPlan[]> {
        var Url = this.url + "/api/ActionPlan/GetAll";
        return this.http.get<ActionPlan[]>(Url);
    }
    getDefaultObservationId(): Observable<number> {
        var Url = this.url + "/api/ActionPlan/GetDefaultObservationId";
        return this.http.get<number>(Url);
    }
    getActionStatuses(): Observable<ActionStatus[]> {
        var Url = this.url + "/api/ActionPlan/GetStatuses";
        return this.http.get<ActionStatus[]>(Url);
    }

    getInitialActionPlan(organizationId: string) {
        var Url = this.url + `/api/ActionPlan/initial-action-plan?organizationId=${organizationId}`;
        return this.http.get<any>(Url);
    }

    getDimensions() {
        var Url = this.url + `/api/ActionPlan/dimensions`;
        return this.http.get<any>(Url);
    }

    addActionDetails(actionDetails: any) {
        var Url = this.url + `/api/ActionPlan/action-details`;
        var data = JSON.stringify(actionDetails);
        return this.http.post(Url, data, this.createHeader());
    }

    addActionDetailsFields(action: any) {
        var Url = this.url + `/api/ActionPlan/action-details-fields`;
        var data = JSON.stringify(action);
        return this.http.post(Url, data, this.createHeader());
    }
}