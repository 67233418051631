import { AfterViewChecked, Component, Input, OnInit } from "@angular/core";
import { RightsOfDataSubjectsComponent } from "../../rights-of-data-subjects.component";
import { RightsOfDataSubjectsService } from "src/app/shared/services/rightsOfDataSubjectsService";

@Component({
    selector: 'restrictions_to_the_rights',
    styleUrls: ['restrictions_to_the_rights.css'],
    templateUrl: 'restrictions_to_the_rights.html',
})
export class RestrictionsTotheRightsComponent implements OnInit, AfterViewChecked {
    @Input() field: any;
    @Input() model: any;
    @Input() versionNo: number;
    restrictionsTotheRights: any[];
    isFirstRun: boolean;
    bpId: string;

    constructor(
        private service: RightsOfDataSubjectsService,
        private parent: RightsOfDataSubjectsComponent) { }
    ngOnInit(): void {
        this.getRestrictionsTotheRightsList();
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId");
    }
    ngAfterViewChecked(): void {
        if (this.isFirstRun) {
            if (this.restrictionsTotheRights !== undefined) {
                if (this.model !== undefined && this.model.length > 0) {
                    for (var i = 0; i < this.model.length; i++) {
                        document.getElementById('restrictionsTotheRightsAddBtn_' + this.model[i].id + '').style.display = "none";
                        document.getElementById('removeRestrictionsTotheRightsBtn_' + this.model[i].id + '').style.display = "block";
                    }
                }
                this.isFirstRun = false;
            }
        }
    }

    getRestrictionsTotheRightsList() {
        this.service.getRestrictionsToTheRightsExplainList().subscribe(x => {
            this.restrictionsTotheRights = x;
        })
    }
    AddRestrictionsTotheRights(item) {
        document.getElementById('restrictionsTotheRightsAddBtn_' + item.id + '').style.display = "none";
        document.getElementById('removeRestrictionsTotheRightsBtn_' + item.id + '').style.display = "block";
        this.service.enableRestrictionToTheRights(this.field, this.versionNo, item);
        var chip = {
            text: item.name,
            value: item.id,
            cssClass: 'transfer-chips-choice'
        }
        this.parent.restrictionsToTheRightsChips.add(chip)
    }

    DisableRestrictionsTotheRights(id) {
        this.service.disableRestrictionToTheRights(this.field, this.versionNo, id).subscribe();
        document.getElementById('removeRestrictionsTotheRightsBtn_' + id + '').style.display = "none";
        document.getElementById('restrictionsTotheRightsAddBtn_' + id + '').style.display = "block";
        this.parent.restrictionsToTheRightsModelData
        .splice(this.parent.restrictionsToTheRightsModelData.findIndex(item => item.id === id), 1);
        this.model.splice(this.model.findIndex(x => x.id == id), 1);
    }
}