// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 

  .background-green{ 
    background-color:  #92BB50; 
    color:white !important;
  } 
  .background-red{ 
    background-color: #DD2122; 
    color:white !important;
  } 
  .background-yellow{ 
    background-color: #EDDA00; 
    color:white !important;
  } 
  .background-orange{ 
    background-color: #F3B10D; 
    color:white !important;
  } 
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dashboard/heatmap.component.ts"],"names":[],"mappings":";;EAEE;IACE,0BAA0B;IAC1B,sBAAsB;EACxB;EACA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;EACA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;EACA;IACE,yBAAyB;IACzB,sBAAsB;EACxB","sourcesContent":[" \n\n  .background-green{ \n    background-color:  #92BB50; \n    color:white !important;\n  } \n  .background-red{ \n    background-color: #DD2122; \n    color:white !important;\n  } \n  .background-yellow{ \n    background-color: #EDDA00; \n    color:white !important;\n  } \n  .background-orange{ \n    background-color: #F3B10D; \n    color:white !important;\n  } \n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
