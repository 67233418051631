import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BPService } from '../../../services/bp.service'
import { FilterService, GridComponent, GridLine, QueryCellInfoEventArgs, VirtualScrollService } from '@syncfusion/ej2-angular-grids';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { L10n } from '@syncfusion/ej2-base';
import { GroupData } from 'src/app/enums/groups';
import { MainLayoutComponent } from 'src/app/layouts/main/main-layout.component';
import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';
import { Router } from '@angular/router';
import { GeneralInfoService } from 'src/app/shared/services/general-info.service';
L10n.load({
  'en-US': {
    grid: {
      EmptyRecord: "",
    }
  }
});
@Component({
  selector: 'in-operation',
  templateUrl: 'bp-in-operation.component.html',
  styleUrls: ['bp-in-operation.component.css'],
  styles: [` 
    .background-grey{ 
      background-color: #F9F9F9; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
  providers: [FilterService, VirtualScrollService]
})
export class InOperationComponent implements OnInit {
  public lines: GridLine;
  public isDataBound: boolean = false;
  public isDataChanged: boolean = true;
  public dropSlectedIndex: number = null;
  public filter: Object;
  public isInitialLoad: boolean = false;
  public filterSettings: Object;
  @ViewChild('sample')
  public listObj: DropDownListComponent;
  @ViewChild('overviewgrid')
  @ViewChild('grid') grid: GridComponent;
  public stTime: any;
  public gridInstance: GridComponent;
  public fields: Object = { text: 'text', value: 'value' };
  public item: number[] = [1, 2, 3, 4, 5];
  bpList = [];
  categories = [];
  _baseUrl;
  customerId;
  maxVersion;
  bpHeader;
  isComplianceManager: boolean = false;
  isDataController: boolean = false;
  isAdmin: boolean = false;
  public ngOnInit(): void {
    this.lines = 'None';
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" };
    this.getAndStoreToken();
    this.getCategories();
  }

  constructor(private bpService: BPService,
    private msalService: MsalService,
    private generalInfo: GeneralInfoService,
    private http: HttpClient,
    private router: Router,
    private mainMenuParent: MainLayoutComponent,
    private broadcastService: MsalBroadcastService, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  customiseCell(args: QueryCellInfoEventArgs) {
    var index = args.cell.attributes.getNamedItem("index").nodeValue;
    const count = parseInt(index, 10)
    if (index == "0") {
      args.cell.classList.add('background-grey');
    }
    if (count % 2 == 0) {
      args.cell.classList.add('background-grey');
    }
  }

  MenuToggle() {
    this.mainMenuParent.openClick(true);
  }

  public load(args: any): void {
    this.isInitialLoad = true;
  }
  public beforeDataBound(e): void {
    let fn = () => {
      this.grid.showSpinner();
      this.grid.off('toolbar-refresh', fn);
      if (!this.isInitialLoad && e.result.length == 0) {
        this.grid.hideSpinner();            //hide spinner 
      }
      this.isInitialLoad = false;
    };
    this.grid.on('toolbar-refresh', fn);
  }

  getAndStoreToken() {
    let dataFromSession = sessionStorage.getItem("bpListData");
    if (dataFromSession) {
      this.bpList = JSON.parse(dataFromSession);
    }
    else {
      this.getBps();
    }
  }

  public items: ItemModel[] = [
    {
      text: 'General information',
      iconCss: 'e-ddb-icons e-dashboard',
      url: "/access-denied"
    },
    {
      text: 'Processed data',
      iconCss: 'e-ddb-icons e-notifications',
      url: "/access-denied"
    },
    {
      text: 'Supply chain',
      iconCss: 'e-ddb-icons e-settings',
      url: "/home"
    }];


  public field: Object = { text: 'text', id: 'id' };

  redirectToPage(args, bpId) {
    if (args.text === this.items[0].text) {
      var url = '/bp-details/' + bpId + '/general-info/'
        + this.categories.filter(x => x.name === args.text)[0].documentationCategoryId + '';
      sessionStorage.setItem("bpId", bpId)
      this.router.navigate([url]);
    }
    else if (args.text === this.items[1].text) {
      var url = '/bp-details/' + bpId + '/legal-basis/'
        + this.categories.filter(x => x.name === 'The legal basis for processing')[0].documentationCategoryId + '';
      sessionStorage.setItem("bpId", bpId)
      this.router.navigate([url]);
    }
    else if (args.text === this.items[2].text) {
      var url = '/bp-details/' + bpId + '/data-transfers-layout/'
        + this.categories.filter(x => x.name === 'Data transfers')[0].documentationCategoryId + '/data-transfers-grid';
      sessionStorage.setItem("bpId", bpId)
      this.router.navigate([url]);
    }
    else this.router.navigate(['/entry/' + bpId + ''])
  }

  getCategories() {
    if (sessionStorage.getItem("categoriesAll")) {
      this.categories = JSON.parse(sessionStorage.getItem("categoriesAll"));
    }
    else {
      this.generalInfo.getAllCategoriesList().subscribe(res => {
        if (res !== null && res.length > 0) {
          this.categories = res;
        }
      });
    }
  }

  getHeader() {
    if (this.bpList !== null) {
      if (this.isComplianceManager)
        return "Business Processes";
    }
    if (this.bpList != null && this.bpList.length > 0) {
      let bpPosinionName = this.bpList[0].internalDataManagerName;
      return "Business Processes - " + bpPosinionName + "";
    }
    else
      return "Business Processes";
  }

  getBps() {
    var tenant = this.msalService.instance.getAllAccounts()[0].tenantId;
    var group;
    var groups;
    if (sessionStorage.getItem("current_group")) {
      group = JSON.parse(sessionStorage.getItem("current_group"));
    }

    else {
      groups = this.msalService.instance.getAllAccounts()[0].idTokenClaims["groups"];
    }

    if (groups) {
      if (groups.indexOf(GroupData.ComplianceManagerGroupId) > -1) {
        group = GroupData.ComplianceManagerGroupId;
      }
      else if (groups.indexOf(GroupData.DataControllerGroupId) > -1) {
        group = GroupData.DataControllerGroupId;
      }

      else if (groups.indexOf(GroupData.AdminGroupId) > -1) {
        group = GroupData.AdminGroupId;
      }
    }

    if (sessionStorage.getItem("bpListData")) {
      this.bpList = JSON.parse(sessionStorage.getItem("bpListData"))
      this.grid.hideSpinner();
    }
    else {
      this.bpService.getAllBusinessProcesses(tenant).subscribe(res => {

        if (res.length > 0) {
          sessionStorage.removeItem("bpListData");
          sessionStorage.setItem("bpListData", JSON.stringify(res));
          this.grid.hideSpinner();
        }
        else res = null;
        this.bpList = res;
        this.grid.hideSpinner();
      })
    }
  }
}
