// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.importIcon {
    float: left;
    margin-right: 1%;
}

.importWrapper {
    overflow:auto;
    margin-left: 20px;
}

.importHeader {
    float: left;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    color: #1F3056;
}
.customerData{
    clear: both;
}
.customerDataGrid{
    /* width: 50%; */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/admin/sections/import/model_import.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.importIcon {\r\n    float: left;\r\n    margin-right: 1%;\r\n}\r\n\r\n.importWrapper {\r\n    overflow:auto;\r\n    margin-left: 20px;\r\n}\r\n\r\n.importHeader {\r\n    float: left;\r\n    font-family: 'Rubik';\r\n    font-style: normal;\r\n    font-weight: 500;\r\n    font-size: 38px;\r\n    color: #1F3056;\r\n}\r\n.customerData{\r\n    clear: both;\r\n}\r\n.customerDataGrid{\r\n    /* width: 50%; */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
