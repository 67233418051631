
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RetentionPeriod } from 'src/app/interfaces/ComplianceWithGDPRPrinciples/ComplianceLegalBasisTableFieldData';
import auth from '../../auth-config.json';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394"
  constructor(private http: HttpClient) { }
  private createHeader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getStandardRetentionPeriods() {
    var customerUrl = this.url + "/api/complianceWithGdprPrinciples/GetRetentionPeriodList";
    return this.http.get<RetentionPeriod[]>(customerUrl);
  }

  AddUpdateComplianceFieldData(model: any, documentationCategoryId: string, versionNo: number) {
    var Url = this.url + "/api/complianceWithGdprPrinciples/AddUpdateFieldData?documentationCategoryId="
      + documentationCategoryId + "&versionNo=" + versionNo + "";
    return this.http.post(Url, JSON.stringify(model), this.createHeader());
  }
  DisableRetentionPeriod(model: any) {
    var Url = this.url + "/api/complianceWithGdprPrinciples/DisableRetentionPeriod";
    return this.http.post(Url, JSON.stringify(model), this.createHeader());
  }
}