import { Component, HostListener, OnInit } from "@angular/core";
import { BPService } from "src/app/shared/services/bp.service";

@Component({
    selector: 'security-of-processing',
    styleUrls: ['security-of-processing.css'],
    templateUrl: 'security-of-processing.html'
})
export class SecurityOfProcessingComponent implements OnInit{
ngOnInit(): void {
    this.sopType="";
}
constructor(private bpService: BPService){

}
@HostListener('window:popstate', ['$event'])
onPopState(event) {
    this.sopType="";
    var menu = document.getElementById('menu-secondary');
    if(menu.style.display == 'none')
    menu.style.display = 'block'
}
sopType="";

getProcessId(){
    var name;
    if (sessionStorage.getItem("bpListData")){
        let data = JSON.parse(sessionStorage.getItem("bpListData"));
        name = data.filter(x=>x.id==sessionStorage.getItem("bpId"));
        return name[0].displayId;
    }
    else return "undefined";
}
getProcessName(){
    var name;
    if (sessionStorage.getItem("bpListData")){
        let data = JSON.parse(sessionStorage.getItem("bpListData"));
        name = data.filter(x=>x.id==sessionStorage.getItem("bpId"));
        return name[0].businessProcessName;
    }
    else return "undefined";
}
}