import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { GridComponent, GridLine } from "@syncfusion/ej2-angular-grids";
import { DialogComponent, ResizeDirections } from "@syncfusion/ej2-angular-popups";
import { EmitType } from "@syncfusion/ej2-base";
import { ConsequenceTypes } from "src/app/interfaces/ConsequenceTypes";
import { RiskService } from "src/app/shared/services/risk-analysis.service";
import { ConsequenceTypesComponent } from "./concequence-types.component";

@Component({
    selector: 'entry-security',
    styleUrls: ['entry-security.component.css'],
    templateUrl: 'entry-security.component.html'
})
export class EntrySecurityOfProcessingComponent implements OnInit{
    ngOnInit(): void {
        this.lines = 'None';
    }
    constructor(private modalComponent: ConsequenceTypesComponent, private riskService: RiskService){}

    @ViewChild('ct_grid') 
    public grid: GridComponent;
    public lines:GridLine;
    c_types = [];
    // @ViewChild('Dialog')
    // public Dialog: DialogComponent;
    public dialogdragging: Boolean = true;
    public resizeHandleDirection: ResizeDirections[] = ['All'];
    public dialogResize: Boolean = true;
    public showCloseIcon: Boolean = true;
    public width: string = '80%';
    public animationSettings: Object = { effect: 'None' };
    BtnClick(type:number) {
        // this.GetConsequenceTypes(type);
        this.grid.refresh();
    }
} 