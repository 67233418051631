import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { L10n } from "@syncfusion/ej2-base";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";
L10n.load({
    'en-US': {
        'grid': {
            'Add': 'Start New'
        }
    }
});
@Component({
    selector: 'discretionary-maintenance',
    templateUrl: 'discretionary-maintenance.html',
    styleUrls: ['discretionary-maintenance.css'],
    encapsulation: ViewEncapsulation.None
})
export class DiscretionaryMaintenanceComponent implements OnInit, AfterViewChecked, OnChanges {
    public controlsEvents = [];
    public controlFrequency = [];
    public controlReason = [];
    public selectedIndex;
    public dataManager: any;
    public isFirstRun: boolean;
    public editSettings: Object;
    @ViewChild('discret_grid') grid: GridComponent;
    @Output() controlStarted = new EventEmitter<number>();
    @Output() showReportStarted = new EventEmitter<number>();
    @Output() controlEventRemoved = new EventEmitter<number>();
    @Input() show: boolean;
    @Input() refresh: boolean;
    @Input() category: string;
    @Input() controlEvent: any;
    prevId: number;
    bpId: string;
    versionNo: any;
    constructor(private service: DocMaintenanceService, private authService: MsalService) { }
    ngOnChanges(changes: SimpleChanges): void {
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = bpList.filter(x=>x.id == this.bpId)[0].versionNo;
        this.getControlEvents();
    }
    ngOnInit(): void {
        this.isFirstRun = true;
        this.prevId = 0;
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
        this.getInternalDataManager();
        this.editSettings = { allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal', showConfirmDialog: false };
        this.toolbar = [
            { text: 'Discretionary maintenance', id: 'assetHeader' },
            'Add', {
                text: '<div>' +
                    '<p class="targetInfo targetDiscretInfo">Click target icon</p>' +
                    '<img class="targetInfoImg targetInfoImgDC" src="assets/icons/maintenance/darts.svg">' +
                    '<p class="targetInfo targetDiscretInfo"> for select documentation field</p>' +
                    '</div>', id: 'targetInfoWrapper'
            }
        ]
    }
    public toolbar: ToolbarItems[] | object;

    ngAfterViewChecked(): void {
        if (this.refresh === true) {
            this.bpId = sessionStorage.getItem("bpId")
            this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
            this.getControlEvents();
            this.refresh = false;
            if (this.prevId === 0) {
                let info = document.getElementById("targetInfoWrapper");
                info.style.display = "none";
            }
        }
        if (this.isFirstRun === true) {
            if (this.show !== undefined) {
                if (this.show === true) {
                    if (this.category !== undefined) {
                        if (this.controlEvent !== undefined) {
                            this.controlEvent.dueDate = new Date(this.controlEvent.dueDate).toISOString().slice(0, 10)
                            this.controlsEvents.push(this.controlEvent);
                            this.isFirstRun = false;
                        }
                        else {
                            this.getControlEvents();
                            this.isFirstRun = false;
                        }
                    }
                }
            }
        }
    }

    getInternalDataManager() {
        this.dataManager = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].internalDataManagerName;
    }

    getControlEvents() {
        this.service.getDiscretionaryEvents(this.bpId, this.category, this.versionNo).subscribe(data => {
            this.controlsEvents = data;
            if (this.controlsEvents.length > 0) {
                this.controlsEvents.forEach(x => {
                    x.dateCreated = new Date(x.dateCreated).toISOString().slice(0, 10)
                })
            }
        })
    }

    dataBound(args) {
        if (this.selectedIndex !== null && this.selectedIndex !== undefined) {
            this.grid.selectRow(this.selectedIndex);
        }
        if (this.prevId !== 0)
            document.getElementById("dcStartControlBtn_" + this.prevId)
                .style.display = "none";
    }

    getControlReasons() {
        this.service.getControlEventReasonList().subscribe(data => {
            this.controlReason = data;
        })
    }

    getControlTriggers() {
        this.service.getControlEventTriggerList().subscribe(data => {
            this.controlReason = data;
        })
    }

    actionBegin(args: any): void {
        if (args.requestType === 'add') {
            args.requestType = 'save';
            args.action = 'add';
        }
        if (args.requestType === 'save' && args.action === 'add') {

            args.cancel = true;
            let controlEvent = {
                id: 0,
                dateCreated: new Date(),
                documentationCategoryId: this.category,
                businesProcessId: sessionStorage.getItem("bpId"),
                employeeExecutingTheControl: 'Lemmy Killmister',
                isPlanned: false,
                IsEnabled: true,
                controlEventStatus: 1,
                versionNo: this.versionNo
            };

            this.service.addDiscretionaryControlEvent(controlEvent).subscribe(x => {
                x["dateCreated"] = new Date(x["dateCreated"]).toISOString().slice(0, 10);
                this.controlsEvents.push(x);
                this.grid.dataSource = [];
                this.grid.dataSource = this.controlsEvents;
                this.grid.refresh();
            })
        }
    }
    showReport(id) {
        this.showReportStarted.emit(id);
    }

    beforeBatchSave(args: any): void {
        let controlEvent = {
            id: 0,
            dateCreated: new Date(),
            documentationCategoryId: this.category,
            businesProcessId: sessionStorage.getItem("bpId"),
            employeeExecutingTheControl: 'Lemmy Killmister',
            isPlanned: false,
            IsEnabled: true,
            controlEventStatus: 1
        };

        this.service.addDiscretionaryControlEvent(controlEvent).subscribe(x => {
            args.data.displayId = x["displayId"];
            args.data.dateCreated = new Date(x["dateCreated"]).toISOString().slice(0, 10);
            this.controlsEvents.push(x);
        })
    }

    reasonChanged(id: number) {
        this.service.changeControlReason(this.controlsEvents[0].id, { id: id })
    }

    startControl(id: number) {
        if (this.prevId !== 0) {
            document.getElementById("dcStartControlBtn_" + this.prevId).style.display = "block";
        }
        document.getElementById("dcStartControlBtn_" + id).style.display = "none";
        this.controlStarted.emit(id);
        this.prevId = id;
        this.selectedIndex = this.grid.currentViewData.findIndex(item => item["id"] === id);
        let info = document.getElementById("targetInfoWrapper");
        info.style.display = "block";
    }

    dismissControlEvent(id, displayId) {
        this.service.setControlEventDisabled(id);
        this.grid.deleteRecord('displayId', displayId)
        if (this.prevId == id) {
            this.prevId = 0;
            this.controlEventRemoved.emit(id)
        }
    }
}


