import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FontModel, ILoadedEventArgs, ProgressBar, ProgressTheme } from "@syncfusion/ej2-angular-progressbar";
import { AnimationModel } from "@syncfusion/ej2-base";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { GeneralInfoService } from "src/app/shared/services/general-info.service";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
    selector: 'bp-details-entry',
    styleUrls: ['entry-page-processes.css'],
    templateUrl: 'entry-page-processes.html',
    encapsulation: ViewEncapsulation.None
})
export class EntryPageComponent implements OnInit {

    public type: string = 'Linear';
    // public width: string = '80%';
    public height: string = '41px';
    public trackThickness: number = 24;
    public progressThickness: number = 24;
    public min: number = 0;
    public max: number = 100;
    public value1: number = 40;

    public showProgressValue: boolean = true;
    public isStriped: boolean = false;

    public role2: string = 'Info';
    categories = [];
    public animation: AnimationModel = { duration: 2000, delay: 0 };
    bpId: string;
    versionNo: any;

    constructor(private route: ActivatedRoute, private generalInfo: GeneralInfoService, private riskService: RiskService,
        private router: Router, private mainMenuParent: MainLayoutComponent) { }
    processId;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const id = params['id'];
            if (id !== 'id') {
                this.processId = id;
                var storedData;
                if (sessionStorage.getItem("bpId")) {
                    storedData = sessionStorage.getItem("bpId");
                    if (storedData !== id) {
                        sessionStorage.removeItem("categories")
                        sessionStorage.removeItem("threatData")
                        sessionStorage.removeItem("appData")
                        sessionStorage.removeItem("bpId")
                        sessionStorage.removeItem("dpia")
                        sessionStorage.removeItem("legalBasisApps")
                        sessionStorage.setItem("bpId", id);
                    }
                }
                else {
                    sessionStorage.removeItem("categories")
                    sessionStorage.removeItem("threatData")
                    sessionStorage.removeItem("appData")
                    sessionStorage.removeItem("bpId")
                    sessionStorage.removeItem("dpia")
                    sessionStorage.removeItem("legalBasisApps")
                    sessionStorage.setItem("bpId", id);
                }
            }
        })
        this.getCategoriesList()
        // this.GetThreatData();
    }

    MenuToggle() {
        this.mainMenuParent.openClick(true);
    }

    GetThreatData() {
        this.riskService.getFullThreats(sessionStorage.getItem("bpId"), this.versionNo).subscribe(res => {
            if (res != null && res.length > 0) {
                sessionStorage.setItem("threatData", JSON.stringify(res))
            }
        })
    }

    getProcessId() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].displayId;
        }
        else return "undefined";
    }
    getProcessName() {
        var name;
        if (sessionStorage.getItem("bpListData")) {
            let data = JSON.parse(sessionStorage.getItem("bpListData"));
            name = data.filter(x => x.id == sessionStorage.getItem("bpId"));
            return name[0].businessProcessName;
        }
        else return "undefined";
    }


    public load(args: ILoadedEventArgs): void {
        args.progressBar.theme = 'Material'
        args.progressBar.labelStyle.color = 'white';
        args.progressBar.labelStyle.fontFamily = 'Rubik'
        args.progressBar.labelStyle.fontWeight = '500'
        args.progressBar.progressColor = '#5AA9EF'
        args.progressBar.trackColor = '#0C2B57'
    }

    getCategoriesList() {
        // if (sessionStorage.getItem("categories")) {
        //     this.categories = JSON.parse(sessionStorage.getItem("categories"));
        // }
        // else {
            this.bpId = sessionStorage.getItem("bpId")
            this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
            this.generalInfo.getAllCategoriesListByBusinessProcess(this.bpId, this.versionNo).subscribe(res => {
                if (res !== null && res.length > 0) {
                    sessionStorage.setItem("categories", JSON.stringify(res));
                    for (var i = 0; i < res.length; i++) {
                        res[i].labelStyle = { text: res[i].progress.progress + '% complete', textAlignment: 'Near' };
                        this.max = 100
                        switch (res[i].name) {
                            case 'General information': {
                                res[i].routerLink = "/bp-details/id/general-info/" + res[i].documentationCategoryId + "";
                                break;
                            }
                            case 'The legal basis for processing': {
                                res[i].routerLink = "/bp-details/id/legal-basis/" + res[i].documentationCategoryId + "";
                                break;
                            }
                            case 'Data transfers': {
                                res[i].routerLink = "/bp-details/id/data-transfers-layout/" + res[i].documentationCategoryId + "/data-transfers-grid";
                                break;
                            }
                            case 'Security of processing': {
                                res[i].routerLink = "/bp-details/id/security/entry-security";
                                break;
                            }
                            case 'Compliance with GDPR principles': {
                                res[i].routerLink = "/bp-details/id/compliance-with-gdpr-principles/" + res[i].documentationCategoryId + "";
                                break;
                            }
                            case 'The rights of data subjects': {
                                res[i].routerLink = "/bp-details/id/rights-of-data-subjects/" + res[i].documentationCategoryId + "";
                                break;
                            }
                            default: {
                                res[i].routerLink = "/home"
                                break;
                            }
                        }
                    }
                    this.categories = res;
                }
            });
        // }
    }
}