// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.it_header{
    text-align: left;
    color: #5AA9EF;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    /* margin-left: 8px; */
}

.e-tab-text{
    font-family: 'Rubik';
    color: #0C2B57 !important;
    font-size: 14px;
    font-weight: 400;
}

.e-tab .e-tab-header .e-indicator {
    background: white;
}
.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
    background-color: white;
}

#tab_it .e-toolbar-items{
    float: left;
    /* margin-left: 8px; */
}

#tab_it .e-tab-header{
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.e-toolbar-item.e-active .e-tab-text {
    color: #5AA9EF !important;
}
#e-item-tab_it_2{
    background: #5AA9EF;

}

#e-item-tab_it_2 .e-tab-text{
    color:white !important;
}

.e-chip-list.e-chip, .e-chip-list .e-chip{
    border-radius: 0px;
}

.custom-chips{
    background-color: #5AA9EF;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 12px;
    height: 20px;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/security-of-processing/risk-assessment/it-risk-assessment.component.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,kCAA6B;IAA7B,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.it_header{\r\n    text-align: left;\r\n    color: #5AA9EF;\r\n    font-size: 22px;\r\n    font-weight: 600;\r\n    font-family: 'Rubik', sans-serif;\r\n    /* margin-left: 8px; */\r\n}\r\n\r\n.e-tab-text{\r\n    font-family: 'Rubik';\r\n    color: #0C2B57 !important;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n}\r\n\r\n.e-tab .e-tab-header .e-indicator {\r\n    background: white;\r\n}\r\n.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {\r\n    background-color: white;\r\n}\r\n\r\n#tab_it .e-toolbar-items{\r\n    float: left;\r\n    /* margin-left: 8px; */\r\n}\r\n\r\n#tab_it .e-tab-header{\r\n    width: fit-content !important;\r\n}\r\n\r\n.e-toolbar-item.e-active .e-tab-text {\r\n    color: #5AA9EF !important;\r\n}\r\n#e-item-tab_it_2{\r\n    background: #5AA9EF;\r\n\r\n}\r\n\r\n#e-item-tab_it_2 .e-tab-text{\r\n    color:white !important;\r\n}\r\n\r\n.e-chip-list.e-chip, .e-chip-list .e-chip{\r\n    border-radius: 0px;\r\n}\r\n\r\n.custom-chips{\r\n    background-color: #5AA9EF;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-weight: 500;\r\n    font-size: 12px;\r\n    height: 20px;\r\n    color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
