import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GroupData } from "src/app/enums/groups";
import { RiskService } from "src/app/shared/services/risk-analysis.service";

@Component({
    selector: 'applications',
    templateUrl: 'applications.component.html',
    styleUrls: ['applications.component.css'],
    styles: [` 
    .background-white{ 
      color: white; 
    } 
`],
    encapsulation: ViewEncapsulation.None
})
export class ApplicationsComponent implements OnInit{
    applications = [];
    @Input() assetId:number;
    bpId: string;
    versionNo: any;
    constructor(private riskService: RiskService, private msalService: MsalService){}
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
        this.GetApplications();
    }
    GetApplications() {

        var tenant = sessionStorage.getItem("customerId");
        if(sessionStorage.getItem("appData")){
            this.applications = JSON.parse(sessionStorage.getItem("appData"));
        }
        else{
        this.riskService.getApplicationsModel(this.bpId, this.versionNo, tenant).subscribe(res=>{         
            if (res.length > 0){
            sessionStorage.removeItem("appData");
            sessionStorage.setItem("appData", JSON.stringify(res));
            }
            else res = null; 
            this.applications = res;
          })
       }
    }

    
    handleChange(field, evt) {
        let items = document.getElementsByClassName('appContainer');
        for(var i = 0; i<items.length;i++)
            document.getElementById(items[i].id).style.background = "white"
        let labelItems = document.getElementsByClassName('radioLbl');
        for(var i = 0; i<labelItems.length;i++)
            labelItems[i].setAttribute('style','color:#142A56');
        evt.target.closest('div').style.background = "#142A56";
        var currentId = evt.target.closest('div').id;
        let lbl = document.getElementById(currentId).children[1].setAttribute('style','color:white');
    }
}