import { Component, ViewEncapsulation, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from "@angular/core";
import { DropDownList } from "@syncfusion/ej2-angular-dropdowns";
import { CommandModel, DetailDataBoundEventArgs, DetailRowService, EditSettingsModel, GridComponent, GridModel, IEditCell, IGrid, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ToastComponent } from "@syncfusion/ej2-angular-notifications";
import { L10n, closest } from "@syncfusion/ej2-base";
import { RuleBookService } from "src/app/shared/services/rule-book.service";
L10n.load({
    GridModel: {
        'Add': 'Start New'
    }
});
@Component({
    selector: 'is-rule-book',
    templateUrl: './rule-book.html',
    styleUrls: ['rule-book.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    providers: [DetailRowService],
    encapsulation: ViewEncapsulation.None,
})
export class IsRuleBookComponent implements OnInit, AfterViewInit {
    ruleDomains: any = [];
    complianceResponsible: any = [];
    frequencies: any = [];
    frequenciesParams: IEditCell;
    complianceResponsibleParams: IEditCell;
    isRuleSubmit: boolean;

    public frequencyElem: HTMLElement;
    public frequencyObj: DropDownList;

    public complianceResponsibleElem: HTMLElement;
    public complianceResponsibleObj: DropDownList;

    public commands?: CommandModel[] = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
    { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-icons e-close' } },
    { type: 'Save', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-update e-icons' } },
    { type: 'Cancel', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-cancel-icon e-icons' } }];

    public editSettings?: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };;
    public frequencyFields: Object = { text: 'frequencyName', value: 'frequencyId' };

    public childGrid: GridModel;
    public toolbar?: ToolbarItems[];

    @ViewChild('toasttype')
    private toastObj: ToastComponent;

    @ViewChildren(GridComponent) grids: QueryList<IGrid>;

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];
    constructor(
        private ruleBookService: RuleBookService) { }
    ngAfterViewInit(): void {
        this.getRuleBookInfo();
        this.toolbar = ['Add'];
    }
    ngOnInit(): void {
        this.isRuleSubmit = true;
        this.getRuleSubmit();
        this.getComplianceResponsible();
        this.getValidationFrequencies();
        this.toolbar = ['Add'];
        this.frequenciesParams = {
            create: () => {
                this.frequencyElem = document.createElement('input');
                return this.frequencyElem;
            },
            read: () => {
                return this.frequencyObj.text;
            },
            destroy: () => {
                this.frequencyObj.destroy();
            },
            write: (args) => {
                this.frequencyObj = new DropDownList({
                    dataSource: this.frequencies,
                    fields: { value: 'frequencyId', text: 'frequencyName' },
                    value: args.requestType === 'add' ?
                        this.frequencies[0].frequencyId : args.rowData.frequency == null ? this.frequencies[0].frequencyId
                            : (args.rowData as any).frequency.frequencyId,
                    floatLabelType: 'Never'
                });
                this.frequencyObj.appendTo(this.frequencyElem);
            }
        };

        this.complianceResponsibleParams = {
            create: () => {
                this.complianceResponsibleElem = document.createElement('input');
                return this.complianceResponsibleElem;
            },
            read: () => {
                return this.complianceResponsibleObj.text;
            },
            destroy: () => {
                this.complianceResponsibleObj.destroy();
            },
            write: (args) => {
                this.complianceResponsibleObj = new DropDownList({
                    dataSource: this.complianceResponsible,
                    fields: { value: 'complianceResponsibleId', text: 'complianceResponsibleName' },
                    value: args.requestType === 'add' ?
                        this.complianceResponsible[0].complianceResponsibleId
                        : args.rowData.complianceResponsible == null
                            ? this.complianceResponsible[0].complianceResponsibleId
                            : (args.rowData as any).complianceResponsible.complianceResponsibleId,
                    floatLabelType: 'Never'
                });
                this.complianceResponsibleObj.appendTo(this.complianceResponsibleElem);
            }
        };
    }
    getRuleSubmit() {
        this.ruleBookService.isRuleSubmitted(sessionStorage.getItem("customerId")).subscribe(data => {
            this.isRuleSubmit = data;
        })
    }

    getRuleBookInfo() {
        this.ruleBookService.getRuleBook(sessionStorage.getItem("customerId"), false).subscribe(data => {
            this.ruleDomains = data;
            this.childGrid = {
                queryString: 'ruleId',
                cssClass: 'rule-book-child-grid',
                locale: "en-US",
                // id: 'rule-book-child-grid',
                editSettings: this.editSettings,
                toolbar: ['Add'],
                columns: [
                    { field: 'subRuleId', isPrimaryKey: true, visible: false },
                    { field: 'ruleId', visible: false },
                    { field: 'subRuleDisplayId', headerText: 'Sub-rule ID', textAlign: 'Left', width: 90 },
                    { field: 'subRuleName', headerText: 'Name/Description', textAlign: 'Left', width: 400 },
                    {
                        field: 'complianceResponsible.complianceResponsibleName', headerText: 'Compliance responsible', textAlign: 'Left', width: 150,
                        editType: 'dropdownedit', edit: this.complianceResponsibleParams
                    },
                    {
                        field: 'frequency.frequencyName', headerText: 'Validation frequency', textAlign: 'Left', width: 150,
                        editType: 'dropdownedit', edit: this.frequenciesParams
                    },
                    { headerText: 'Current compliance level', textAlign: 'Left', width: 120 },
                    { headerText: 'Compliance check indicator', textAlign: 'Left', width: 120 },
                    { headerText: 'Edit', textAlign: 'Left', width: 120, commands: this.commands }],
                actionBegin: (args) => {
                    if (args.action === 'add') {
                        const form = args.form;
                        const secondChildGridElementId = form.id.split('EditForm')[0];
                        const secondChildGridElement = document.getElementById(secondChildGridElementId);
                        const childGridRowElement = closest(secondChildGridElement, 'tr');
                        const childGridParentRowElement = childGridRowElement.previousElementSibling;
                        const childGridElement = closest(childGridParentRowElement, '.e-grid');
                        const childGridInstance = (childGridElement as any).ej2_instances[0];
                        const uid = childGridParentRowElement.getAttribute('data-uid');
                        const rowObject = childGridInstance.getRowObjectFromUID(uid);
                        const rowData = rowObject.data;
                        args.data.isCustom = true;
                        args.data.ruleId = rowData["ruleId"];
                        args.data.frequency.frequencyId = this.frequencyObj.value;
                        args.data.complianceResponsible.complianceResponsibleId = this.complianceResponsibleObj.value;
                    }
                    if (args.action === 'add' && args.requestType === 'save') {
                        if (this.isRuleSubmit) {
                            this.ruleBookService.addSubRule(args.data, sessionStorage.getItem("customerId")).subscribe(res => {
                                const form = args.form;
                                const secondChildGridElementId = form.id.split('EditForm')[0];
                                const secondChildGridElement = document.getElementById(secondChildGridElementId);
                                // var currentGrid = this.grids.filter(x => x.element.id == `basic_info_function_grid_${functionGroupId}`)[0];
                                // currentGrid.setCellValue(0, "functionId", Number(res))
                            }, error => {
                                console.error('Error on sub rule add:', error);
                                this.toasts[2].content = "Failed to add data";
                                this.toastObj.show(this.toasts[2]);
                            })
                        }
                    }
                    if (args.action === 'edit') {
                        args.data.frequency.frequencyId = this.frequencyObj.value;
                        args.data.complianceResponsible.complianceResponsibleId = this.complianceResponsibleObj.value;
                        if (this.isRuleSubmit) {
                            this.ruleBookService.updateSubRule(args.data)
                                .subscribe(
                                    () => { },
                                    error => {
                                        console.error('Error on sub rule update:', error);
                                        this.toasts[2].content = "Failed to update data";
                                        this.toastObj.show(this.toasts[2]);
                                    });
                        }
                    }
                    if (args.requestType === 'delete') {
                        if (this.isRuleSubmit) {
                            this.ruleBookService
                                .deleteSubRule(args.data[0].subRuleId,
                                    sessionStorage.getItem("customerId"), args.data[0].isCustom);
                        }
                    }
                }
            };
        },
            error => {
                console.error('Error fetching rule book data:', error);
            })
    }

    detailDataBound({ data, childGrid }: DetailDataBoundEventArgs) {
        var dataSource: Object[] = data["subRules"];
        (childGrid as IGrid).dataSource = dataSource;
    }

    getComplianceResponsible() {
        this.ruleBookService.getComplianceResponsible().subscribe(data => {
            this.complianceResponsible = data;
        },
            error => {
                console.error('Error fetching compliance responsible data:', error);
            })
    }

    getValidationFrequencies() {
        this.ruleBookService.getFrequencies().subscribe(data => {
            this.frequencies = data;
        },
            error => {
                console.error('Error fetching validation frequency data:', error);
            })
    }

    submitSubRules() {
        this.ruleBookService.submitRules(this.ruleDomains, sessionStorage.getItem("customerId")).subscribe(
            () => {
                // Success case
                this.toasts[1].content = "Rules submitted";
                this.toastObj.show(this.toasts[1]);
                this.isRuleSubmit = true;
            },
            error => {
                console.error('Error on rule submit:', error);
                this.toasts[2].content = "Failed to submit rules";
                this.toastObj.show(this.toasts[2]);
            });
    }
}