import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { DocMaintenanceService } from "src/app/shared/services/documentation-maintenance.serviсe";

@Component({
    selector: 'wheel-maintenance',
    templateUrl: 'wheel-maintenance.html',
    styleUrls: ['wheel-maintenance.css'],
    encapsulation: ViewEncapsulation.None
})
export class WheelMaintenanceComponent implements OnInit, AfterViewChecked,
    AfterViewInit, OnChanges {
    @Output() controlStarted = new EventEmitter<number>();
    @Output() showReportStarted = new EventEmitter<number>();
    @Output() controlEventRemoved = new EventEmitter<number>();
    @Input() show: boolean;
    @Input() category: string;
    @Input() controlEvent: any;
    @Input() refresh: boolean;

    public editSettings: Object;
    public isFirstRun: boolean;
    public controlsEvents = [];
    public controlFrequency = [];
    public dataManager: any;
    public reportPreview: any;
    @ViewChild('Dialog')
    public Dialog: DialogComponent;
    public dialogHeight = '100';
    public dialogWidth = '20';
    showCloseIcon = true;
    public prevId: any;
    public ddlfields: Object = { text: 'name', value: 'id' };
    public selectedIndex;
    public bpId;
    public versionNo;
    @ViewChild('wheel_grid') grid: GridComponent;
    constructor(private service: DocMaintenanceService, private authService: MsalService) { }
    ngAfterViewInit(): void {
        this.editSettings = { allowEditing: false, allowAdding: false, allowDeleting: true, mode: 'Normal', showConfirmDialog: false };
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x=>x.id == this.bpId)[0].versionNo;
        this.getControlEvents();
    }
    ngAfterViewChecked(): void {
        if (this.refresh === true) {
            this.bpId = sessionStorage.getItem("bpId")
            var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
            this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x=>x.id == this.bpId)[0].versionNo;
            this.getControlEvents();
            this.refresh = false;
            if (this.prevId === 0) {
                let info = document.getElementById("targetInfoWrapper");
                info.style.display = "none";
            }
        }
        if (this.isFirstRun === true) {
            if (this.show !== undefined) {
                if (this.show === true) {
                    if (this.category !== undefined) {
                        if (this.controlEvent !== undefined) {
                            this.controlEvent.dueDate = new Date(this.controlEvent.dueDate).toISOString().slice(0, 10)
                            this.controlsEvents.push(this.controlEvent);
                            this.isFirstRun = false;
                        }
                        else {
                            this.getControlEvents();
                            this.isFirstRun = false;
                        }
                    }
                }
            }
        }
    }
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x=>x.id == this.bpId)[0].versionNo;
        this.prevId = 0;
        this.isFirstRun = true;
        this.getControlFrequencies();
        this.getInternalDataManager();
    }

    dataBound(args) {
        if (this.selectedIndex !== null && this.selectedIndex !== undefined) {
            this.grid.selectRow(this.selectedIndex);
        }
        if (this.prevId !== 0)
            document.getElementById("dcStartControlBtn_" + this.prevId)
                .style.display = "none";
    }

    getControlEvents() {
        this.service.getPlannedControlEvents(this.bpId, this.category, 1, this.versionNo).subscribe(data => {
            this.controlsEvents = data;
            if (this.controlsEvents.length > 0) {
                this.controlsEvents.forEach(x => {
                    x.dueDate = new Date(x.dueDate).toISOString().slice(0, 10)
                })
            }
        })
    }

    dismissControlEvent(id, displayId) {
        this.service.setControlEventDisabled(id);
        this.grid.deleteRecord('displayId', displayId);
        if (this.prevId == id) {
            this.prevId = 0;
            this.controlEventRemoved.emit(id)
        }
    }

    getInternalDataManager() {
        this.dataManager = JSON.parse(sessionStorage.getItem("bpListData")).filter(x=>x.id == this.bpId)[0].internalDataManagerName;
    }

    getControlFrequencies() {
        this.service.getControlFrequencyList().subscribe(data => {
            this.controlFrequency = data;
        })
    }

    frequencyChanged(id: number) {
        var category = this.category;
        var currentFrequency = this.controlFrequency.filter(x => x.id == id)[0];

        this.service.changeFrequency(this.bpId, category, currentFrequency, this.versionNo)
        this.getControlEvents();
    }

    startControl(id) {
        if (this.prevId !== 0) {
            document.getElementById("dcStartControlBtn_" + this.prevId).style.display = "block";
        }
        document.getElementById("dcStartControlBtn_" + id).style.display = "none";
        this.controlStarted.emit(id);
        this.prevId = id;
        let info = document.getElementById("targetInfoWrapper");
        info.style.display = "block";
        this.selectedIndex = this.grid.currentViewData.findIndex(item => item["id"] === id);
    }

    showReport(id) {
        this.showReportStarted.emit(id);
    }

    dialogShow() {
        this.Dialog.show;
    }
}


