import { Component, ViewEncapsulation, OnInit, ViewChild, ViewChildren, QueryList, Inject, ViewContainerRef, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DetailDataBoundEventArgs, DetailRowService, GridComponent, GridModel, IGrid, RowDataBoundEventArgs, ToolbarItems, parentsUntil } from "@syncfusion/ej2-angular-grids";
import { ToastComponent } from "@syncfusion/ej2-angular-notifications";
import { ItemModel } from "@syncfusion/ej2-angular-splitbuttons";
import { L10n } from "@syncfusion/ej2-base";
import { DataManager } from "@syncfusion/ej2/data";
import { RuleBookService } from "src/app/shared/services/rule-book.service";
L10n.load({
    GridModel: {
        'Add': 'Start New'
    }
});
@Component({
    selector: 'my-rule-book',
    templateUrl: './my-rule-book.html',
    styleUrls: ['my-rule-book.css'],
    styles: [` 
    .background-grey{ 
      background-color: #F0F1F6; 
    } 
    .highlight{
      padding:0;
      width:4px;
    }
`],
    providers: [DetailRowService],
    encapsulation: ViewEncapsulation.None,
})
export class MyRuleBookComponent implements OnInit, AfterViewInit {
    ruleDomains: any = [];

    public childGrid: GridModel;
    public toolbar?: ToolbarItems[];

    @ViewChild('toasttype')
    private toastObj: ToastComponent;
    @ViewChild('childtemplate') public childtemplate: any;
    @ViewChild('statustemplate') public statustemplate: any;
    @ViewChild('nexttemplate') public nexttemplate: any;
    @ViewChild('initialbaselinetemplate') public initialbaselinetemplate: any;

    @ViewChildren(GridComponent) grids: QueryList<IGrid>;

    public value: number = 30;
    public min: number = 0;
    public max: number = 100;
    public gradient_value: number = 50;
    public range: string = 'MinRange';
    public slider_ticks: Object = { placement: 'After', largeStep: 50, smallStep: 1 };

    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        },

        {
            title: 'Error!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-danger', icon: 'e-danger toast-icons'
        }
    ];
    public items: ItemModel[] = [
        {
          text: 'Planning wheel',
          iconCss: 'e-ddb-icons e-dashboard',
          url: "/access-denied",
          id: "planningWheel"
        },
        {
          text: 'Operational observations',
          iconCss: 'e-ddb-icons e-notifications',
          url: "/access-denied",
          id: "operationalObservations"
        }];
    constructor(
        private ruleBookService: RuleBookService,
        private router: Router, @Inject(ViewContainerRef) private viewContainerRef?: ViewContainerRef) { }

    ngOnInit(): void {
        this.getRuleBookInfo();
    }

    ngAfterViewInit() {
        this.childtemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.childtemplate.elementRef.nativeElement.propName = 'template';

        this.statustemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.statustemplate.elementRef.nativeElement.propName = 'template';

        this.nexttemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.nexttemplate.elementRef.nativeElement.propName = 'template';

        this.initialbaselinetemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.initialbaselinetemplate.elementRef.nativeElement.propName = 'template';
    }

    getRuleBookInfo() {
        this.ruleBookService.getMyRuleBook(sessionStorage.getItem("customerId")).subscribe(data => {
            this.ruleDomains = data;
            this.childGrid = {
                queryString: 'ruleId',
                cssClass: 'rule-book-child-grid',
                locale: "en-US",
                load() {
                    this.registeredTemplate = {};
                },
                columns: [
                    { field: 'subRuleId', isPrimaryKey: true, visible: false },
                    { field: 'baselineEstablished', visible: false },
                    { field: 'ruleId', visible: false },
                    { field: 'subRuleDisplayId', headerText: 'Sub-rule ID', textAlign: 'Left', width: 90 },
                    { field: 'subRuleName', headerText: 'Name/Description', textAlign: 'Left', width: 400 },
                    {
                        headerText: 'Initial baseline compliance level', textAlign: 'Left', width: 200,
                        template: this.initialbaselinetemplate
                    },
                    {
                        headerText: 'Next planning wheel validation', textAlign: 'Left', width: 150,
                        template: this.nexttemplate
                    },
                    {
                        headerText: 'Compliance check indicator', textAlign: 'Left', width: 100,
                        template: this.statustemplate
                    },
                    {
                        headerText: 'Action', textAlign: 'Left', width: 200,
                        template: this.childtemplate
                    }
                ]
            };
        },
            error => {
                console.error('Error fetching rule book data:', error);
            })
    }

    detailDataBound({ data, childGrid }: DetailDataBoundEventArgs) {
        var dataSource: Object[] = data["subRules"];
        (childGrid as IGrid).dataSource = dataSource;
    }

    establishBaseLine(e) {
        if (e.target.classList.contains('establishBtn') || e.target.classList.contains('establishedBtn')) {
            let subRuleId;
            let gridEle = parentsUntil(e.target, 'e-grid');
            let gobj = gridEle['ej2_instances'][0];
            let rowInfo = gobj.getRowInfo(e.target);
            subRuleId = rowInfo.rowData["subRuleId"];
            this.router.navigate([`/my-rule-book/establish-baseline/${subRuleId}`])
        }
    }

    parseDate(dateString: string): Date {
        return new Date(dateString);
    }

    calculateAverage(list: any[], property: string): number {
        const total = list.reduce((acc, item) => acc + item[property], 0);
        return total / list.length;
    }
}