import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ColumnModel, CommandModel, EditSettings, EditSettingsModel, GridComponent, QueryCellInfoEventArgs, RowDataBoundEventArgs } from "@syncfusion/ej2-angular-grids";
import { cssClass } from "@syncfusion/ej2-angular-lists";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { AdministrationService } from "src/app/shared/services/admin.service";

const localede = {
    "de": {
        grid: {
            Add: 'Add threat'
        }
    }
};

setCulture("de");
L10n.load(localede);

@Component({
    selector: 'admin-threats',
    styleUrls: ['threats.css'],
    templateUrl: 'threats.html',
    encapsulation: ViewEncapsulation.None
})
export class ThreatsComponent implements OnInit {
    constructor(private adminService: AdministrationService) { }
    private data: any;
    public assetsColumns: any[];
    public effectColumns: any[];
    public customAttributes;
    public threatLevels;
    public categories;
    public selectedCategories: string[] = [];
    public selectedThreatLevels: string[] = [];
    public selectedThreatLevel: string = '';
    public ddlfields: Object = { text: 'levelName', value: 'levelId' };
    public catfields: Object = { text: 'categoryName', value: 'categoryId' };
    public riskfields: Object = { text: 'riskTypeName', value: 'riskTypeId' };
    public actorfields: Object = { text: 'actorName', value: 'actorId' };
    public intentionfields: Object = { text: 'intentionName', value: 'intentionId' };
    public toolbar: string[];
    public editSettings: EditSettingsModel;
    public adminThreats: any[] = [];
    public actors: any[] = [];
    public intentions: any[] = [];
    public riskTypes: any[] = [];

    @ViewChild('integritytemplate', { static: true }) public integrityTemplate!: TemplateRef<any>;
    @ViewChild('confidentialitytemplate', { static: true }) public confidentialitytemplate!: TemplateRef<any>;
    @ViewChild('availabilitytemplate', { static: true }) public availabilitytemplate!: TemplateRef<any>;

    @ViewChild('internalOrganisationTemplate', { static: true }) public internalOrganisationTemplate!: TemplateRef<any>;
    @ViewChild('internalEmployeesTemplate', { static: true }) public internalEmployeesTemplate!: TemplateRef<any>;
    @ViewChild('internallyHostedApplicationsTemplate', { static: true }) public internallyHostedApplicationsTemplate!: TemplateRef<any>;
    @ViewChild('internallyHostedITInfrastructureTemplate', { static: true }) public internallyHostedITInfrastructureTemplate!: TemplateRef<any>;
    @ViewChild('iCTServiceProvidersOrganisationTemplate', { static: true }) public iCTServiceProvidersOrganisationTemplate!: TemplateRef<any>;
    @ViewChild('iCTServiceProvidersEmployeesTemplate', { static: true }) public iCTServiceProvidersEmployeesTemplate!: TemplateRef<any>;
    @ViewChild('externallyHostedApplicationsTemplate', { static: true }) public externallyHostedApplicationsTemplate!: TemplateRef<any>;
    @ViewChild('externallyHostedITInfrastructureTemplate', { static: true }) public externallyHostedITInfrastructureTemplate!: TemplateRef<any>;

    @ViewChild('gridAdminThreats')
    public grid?: GridComponent;
    public commands?: CommandModel[]
    public pageSettings: Object;
    public pageSizes: number[] = [3, 5, 10];

    ngOnInit(): void {
        this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };
        this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-delete e-icons' } }];
        this.getAdminThreats();
        this.getCategories();
        this.getRiskTypes();
        this.getActors();
        this.getThreatLevels();
        this.getIntentions();
        this.customAttributes = { class: 'orientationcss' };

        this.assetsColumns = [
            {
                field: 'internalOrganisation',
                headerText: 'Internal organisation',
                width: 100,
                customAttributes: this.customAttributes,
                template: this.internalOrganisationTemplate,
                allowEditing: true,
                editType: 'booleanedit',
            },
            {
                field: 'internalEmployees',
                width: 100,
                headerText: 'Internal employees',
                customAttributes: this.customAttributes,
                template: this.internalEmployeesTemplate,
                allowEditing: true,
                editType: 'booleanedit',
            },
            {
                field: 'internallyHostedApplications',
                width: 100,
                headerText: 'Internally hosted applications',
                customAttributes: this.customAttributes,
                template: this.internallyHostedApplicationsTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'internallyHostedITInfrastructure',
                width: 100,
                headerText: 'Internally hosted IT infrastructure',
                customAttributes: this.customAttributes,
                template: this.internallyHostedITInfrastructureTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'iCTServiceProvidersOrganisation',
                width: 100,
                headerText: 'ICT service providers organisation',
                customAttributes: this.customAttributes,
                template: this.iCTServiceProvidersOrganisationTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'iCTServiceProvidersEmployees',
                width: 100,
                headerText: 'ICT service providers employees',
                customAttributes: this.customAttributes,
                template: this.iCTServiceProvidersEmployeesTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'externallyHostedApplications',
                width: 100,
                headerText: 'Externally hosted applications',
                customAttributes: this.customAttributes,
                template: this.externallyHostedApplicationsTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'externallyHostedITInfrastructure',
                width: 100,
                headerText: 'Externally hosted IT infrastructure',
                customAttributes: this.customAttributes,
                template: this.externallyHostedITInfrastructureTemplate,
                editType: 'booleanedit'
            }
        ];

        this.effectColumns = [
            {
                field: 'confidentiality',
                width: 100,
                headerText: 'Confidentiality',
                customAttributes: this.customAttributes,
                template: this.confidentialitytemplate,
                editType: 'booleanedit',
            },
            {
                field: 'integrity',
                width: 100,
                headerText: 'Integrity',
                customAttributes: this.customAttributes,
                template: this.integrityTemplate,
                editType: 'booleanedit',
            },
            {
                field: 'availability',
                width: 100,
                headerText: 'Availability',
                customAttributes: this.customAttributes,
                template: this.availabilitytemplate,
                editType: 'booleanedit',
            }
        ];

        this.toolbar = ['Add'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Bottom' };
    }

    created() {
        let toolbar = ((this.grid as GridComponent).element as HTMLElement).querySelector('.e-toolbar');
        (this.grid as GridComponent).element.appendChild(toolbar as HTMLElement);
    }

    public rowDataBound(args: RowDataBoundEventArgs) {
            args.rowHeight = 40;
    }

    actionBegin(args: any): void {
        if (args.requestType === 'save' && (args.action === 'add' || args.action === 'edit')) {
            var categoryId = args.data.category.categoryId;
            var relatedCategory = this.categories.filter(x => x.categoryId === categoryId)[0];
            args.data.category = relatedCategory;
            this.adminService.AddUpdateThreats(args.data).subscribe(resp => {
                args.data.threatId = resp;
            })
        }

        if (args.requestType === 'delete') {
            this.adminService.deleteAdminThreat(args.data[0].threatId).subscribe();
        }
    }

    public setHeaderHeight() {
        // const textWidth: number = (document.querySelector(".orientationcss > div") as HTMLElement).scrollWidth;
        // const headerCell: NodeList = document.querySelectorAll(".e-headercell");
        // for (let i: number = 0; i < headerCell.length; i++) {
        //     ((headerCell as any).item(i)).style.height = 50 + 'px';
        //}
    }
    previousCategory: string = '';
    categoryStartIndex: number = 0;

    previousColumn2: string = '';
    column2StartIndex: number = 0;

    filterCategory(category: string, event: any): void {
        if (event.checked) {
            this.selectedCategories.push(category);
        } else {
            const index = this.selectedCategories.indexOf(category);
            if (index > -1) {
                this.selectedCategories.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    filterThreatLevel(event: any): void {
        let tl = event.itemData.name;
        if (event.name === "tagging") {
            this.selectedThreatLevels.push(tl);
        }
        if (event.name === "removing") {
            const index = this.selectedThreatLevels.indexOf(tl);
            if (index > -1) {
                this.selectedThreatLevels.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    // Assuming this.selectedCategories and this.selectedThreatLevels are arrays of selected values.
    applyFilters() {
        const selectedCategories = this.selectedCategories;
        const selectedThreatLevels = this.selectedThreatLevels.map(level => level);

        const filteredData = this.data.filter(item => {
            const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(item.Category);
            const threatLevelMatch = selectedThreatLevels.length === 0 || selectedThreatLevels.includes(item.Column2);
            return categoryMatch && threatLevelMatch;
        });

        this.grid.dataSource = filteredData;
        this.grid.refresh();
    }


    getAdminThreats() {
        this.adminService.getAdminThreats().subscribe(item => {
            this.adminThreats = item;
        },
            error => {
                console.error('Error fetching threats:', error);
            })
    }

    getCategories() {
        this.adminService.getCategories().subscribe(item => {
            this.categories = item;
        },
            error => {
                console.error('Error fetching categories:', error);
            })
    }


    getRiskTypes() {
        this.adminService.getRiskTypes().subscribe(item => {
            this.riskTypes = item;
        },
            error => {
                console.error('Error fetching risk types:', error);
            })
    }

    getActors() {
        this.adminService.getActors().subscribe(item => {
            this.actors = item;
        },
            error => {
                console.error('Error fetching actors:', error);
            })
    }

    getThreatLevels() {
        this.adminService.getThreatLevels().subscribe(item => {
            this.threatLevels = item;
        },
            error => {
                console.error('Error fetching threat levels:', error);
            })
    }

    getIntentions() {
        this.adminService.getIntentions().subscribe(item => {
            this.intentions = item;
        },
            error => {
                console.error('Error fetching intentions:', error);
            })
    }
}


