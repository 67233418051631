// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiaStartBtnForm {
    width: 100%;
    height: 30px;
    background: #ED5424;
    border: 1px solid #ED5424;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: white;
    float: left;
    clear: both;
    width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bp/details/detail-items/data-transfers/tia/tia.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,gCAAgC;IAChC,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,500;1,600&display=swap');\r\n\r\n.tiaStartBtnForm {\r\n    width: 100%;\r\n    height: 30px;\r\n    background: #ED5424;\r\n    border: 1px solid #ED5424;\r\n    font-size: 14px;\r\n    font-family: 'Rubik', sans-serif;\r\n    font-weight: 400;\r\n    color: white;\r\n    float: left;\r\n    clear: both;\r\n    width: 10%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
