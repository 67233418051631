import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { MultiSelectComponent, TaggingEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { RecipientCategory } from "src/app/interfaces/DataTransfers/RecipientCategory";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DataTransfersFormComponent } from "../../data-transfers-form.component";

@Component({
    selector: 'transfer-categories',
    styleUrls: ['categories.component.css'],
    templateUrl: 'categories.component.html',
})
export class TransferCategoriesComponent implements OnInit, AfterViewChecked {
    @Input() recipientId;
    @Input() category: RecipientCategory;
    public categories: any[];
    public customCategories: any[];
    public CategoryDataMultiselect = [];
    public isFirstRun: boolean;
    @ViewChild('customCat')
    public customCat: MultiSelectComponent;
    public placeholder: string = 'Start typing here...';
    @ViewChild('pdChipsList')
    public pdChipsList: ChipListComponent

    @ViewChild('chipsList')
    public categoryChips: ChipListComponent
    @ViewChild('customchipsList')
    public customchipsList: ChipListComponent
    versionNo: any;
    bpId: string;
    ngOnInit(): void {
        this.isFirstRun = true;
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
    }

    constructor(private service: DataTransferService, private parent: DataTransfersFormComponent, private authService: MsalService) { }
    ngAfterViewChecked(): void {
        if (this.isFirstRun == true && this.recipientId !== undefined) {
            this.getcategories();
            this.isFirstRun = false;
        }
    }

    RemoveElementFromArray(element: number, arrayElements: any) {
        arrayElements.forEach((value, index) => {
            if (value.id == element) arrayElements.splice(index, 1);
        });
    }

    getcategories() {
        this.service.getRecipientCategoryList(this.recipientId, this.versionNo).subscribe(_data => {
            this.categories = _data.filter(x => x.isCustom == false);
            this.customCategories = _data.filter(x => x.isCustom == true);
            if (this.category !== null && this.category !== undefined) {
                this.RemoveElementFromArray(this.category.id, this.categories);
                this.RemoveElementFromArray(this.category.id, this.customCategories);
            }
        })
    }
    showCategoriesInput() {
        document.getElementById("customCat").style.display = "block";
    }
    hideMultiselect() {
        document.getElementById("customCat").style.display = "none";
    }
    chipclick(e: ClickEventArgs, isCustom: boolean) {
        if (e.text) {
            if (e.event.target["className"] !== 'e-chip-delete e-dlt-btn') {
                if (this.recipientId !== undefined) {
                    this.service.linkRecipientCategory(this.recipientId, e.data["value"], isCustom, this.versionNo).subscribe();
                    if (isCustom == false) {
                        this.RemoveElementFromArray(e.data["value"], this.categories);
                    }
                    if (isCustom == true) {
                        this.RemoveElementFromArray(e.data["value"], this.customCategories);
                    }
                    var chip = {
                        text: e.text,
                        value: e.data["value"],
                        cssClass: 'transfer-chips-choice'
                    }
                    var chipListName = 'catChips';
                    var currentChip = this.parent.templates.filter(x => x.element.id == chipListName);
                    this.getcategories();
                    this.category = {
                        id: e.data["value"],
                        name: e.text,
                        isCustom: isCustom
                    }
                    this.parent.category = this.category;
                    currentChip[0].add(chip);
                }
            }
            if (e.event.target["className"] === 'e-chip-delete e-dlt-btn' && isCustom === true) {
                this.service.deleteCustomCategoryLink(this.recipientId, e.data["value"], this.versionNo);
                this.RemoveElementFromArray(e.data["value"], this.customCategories);
            }
        }
    }

    public onCategoryTagging = (e: TaggingEventArgs) => {
        let model = {
            id: 0,
            name: e.itemData["text"],
            isCustom: true
        }
        var tenant = this.authService.instance.getAllAccounts()[0].tenantId;
        this.service.linkCustomCategory(this.recipientId, tenant, model, this.versionNo).subscribe(x => {
            var chip = {
                text: model.name,
                value: Number(x),
                cssClass: 'e-chip pd-transfer-chips-choice'
            }
            model.id = Number(x);
            this.customCategories.push(model);
            this.customchipsList.add(chip);
            this.category = null;
            this.customCat.value = [];
            document.getElementById("customCat").style.display = "none";
        });
    }

    getRecipientCategoryByRecipient() {
        this.service.getRecipientCategoryByRecipient(this.recipientId, this.versionNo).subscribe(_data => {
            this.category = _data;
            var chip = {
                text: _data.name,
                value: _data.id,
                cssClass: 'transfer-chips-choice'
            }
            this.customchipsList.add(chip);
        })
    }
}