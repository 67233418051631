
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { ChipListComponent, ClickEventArgs } from "@syncfusion/ej2-angular-buttons";
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-angular-dropdowns";
import { GridLine, GridComponent, QueryCellInfoEventArgs, EditService, ToolbarService, ToolbarItems, PageService, IEditCell, CommandModel, NumericEditCell, SelectionSettingsModel } from "@syncfusion/ej2-angular-grids";
import { createSpinner, DialogComponent, ResizeDirections, Tooltip, TooltipComponent } from "@syncfusion/ej2-angular-popups";
import { ToolbarClickEventArgs } from "@syncfusion/ej2-filemanager";
import { FullThreat } from "src/app/interfaces/FullThreat";
import { SubmittedInfo } from "src/app/interfaces/SubmittedInfo";
import { ThreatEffect } from "src/app/interfaces/ThreatEffect";
import { MainLayoutComponent } from "src/app/layouts/main/main-layout.component";
import { ConsequenceService } from "src/app/shared/services/consequence.service";
import { RiskService } from "src/app/shared/services/risk-analysis.service";
import { ConsequenceTypesComponent } from "../../concequence-types.component";
import { SecurityOfProcessingComponent } from "../../security-of-processing.component";
import { RiskAnylysisComponent } from "../risk-analysis.component";

@Component({
  selector: 'full-threat',
  templateUrl: 'full-threat.component.html',
  styleUrls: ['full-threat.component.css'],
  styles: [` 
    .background-grey{ 
      background-color: #F9F9F9; 
    } 
    .background-green{ 
      background-color:  #A1CD63; 
      color:white;
    } 
    .background-red{ 
      background-color: #DE2F3A; 
      color:white;
    } 
    .background-yellow{ 
      background-color: #FFFD54; 
      color:white;
    } 
    .background-orange{ 
      background-color: #F4C142; 
      color:white;
    } 
    .background-white{ 
      background-color: #ffffff; 
    }
    .background-light-green{
      background-color:  #d6f5d6;
    }
    .background-light-red{
      background-color:#ffcccc;
      opacity:1;
    }

    .text-color-submitted{
      font-color:#5AA9EF;
    }
`],
  encapsulation: ViewEncapsulation.None,
  providers: [ToolbarService, EditService, PageService]
})

export class FullThreatComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(ConsequenceTypesComponent) ctypes: ConsequenceTypesComponent;

  @ViewChild('ct_grid')
  public gridModal: GridComponent;
  public editedThreat: any;
  c_types = [];
  public mode: string;

  @ViewChild('Dialog')
  public Dialog: DialogComponent;

  @ViewChild('ConstrolsDialog')
  public ConstrolsDialog: DialogComponent;

  @ViewChild('Dialog2')
  public Dialog2: DialogComponent;

  @ViewChild('Dialog3')
  public Dialog3: DialogComponent;

  @ViewChild('confirmDialog')
  public confirmAddDialog: DialogComponent;

  @ViewChild('confirmDialog2')
  public confirmEditDialog: DialogComponent;

  @ViewChild('ctNonSelectedAlert')
  public ctNonSelectedAlert: DialogComponent;

  @ViewChild('submitctNonSelectedAlert')
  public submitctNonSelectedAlert: DialogComponent;


  public dialogdragging: Boolean = true;
  public resizeHandleDirection: ResizeDirections[] = ['All'];
  public dialogResize: Boolean = true;
  public showCloseIcon: Boolean = true;
  public width: string = '80%';
  public dialogSubmitWidth: string = '40%';
  applications = []
  public animationSettings: Object = { effect: 'None' };
  public uniquecombination: string;
  @Input() selectedId: number
  @Input() subtype: number
  @Input() raType: boolean
  @Input() selectedSubId: number
  public popHeight: string = '350px';
  public lines: GridLine;
  @ViewChild('editChipsVul')
  public vulChipsList: ChipListComponent
  @ViewChild('editChipsTE')
  public teChipsList: ChipListComponent
  @ViewChild('editChipsCon')
  public conChipsList: ChipListComponent
  @ViewChild('editChipsStandardCon')
  public standardConChipsList: ChipListComponent
  @ViewChild('sampleConMS')
  public mulConObj: MultiSelectComponent;
  @ViewChild('sampleMS')
  public mulObj: MultiSelectComponent;
  public dropSlectedIndex: number = null;
  @ViewChild('sample')
  public listObj: DropDownListComponent;

  @ViewChild('probabilityBefore')
  public probabilitylistObj: DropDownListComponent;
  @ViewChild('probabilityAfter')
  public probabilityAfterlistObj: DropDownListComponent;
  @ViewChild('consequenceBefore')
  public consequencelistObj: DropDownListComponent;
  @ViewChild('consequenceAfter')
  public consequenceAfterlistObj: DropDownListComponent;
  @ViewChild('checkbox')
  public teListObj: MultiSelectComponent;
  @ViewChild('ra_grid') grid: GridComponent;

  @ViewChild('submittedThreats_grid') submittedgrid: GridComponent;

  @ViewChild('existingThreats_grid') existingGrid: GridComponent;

  @ViewChild('deletedThreats_grid') deletedgrid: GridComponent;

  @ViewChildren(TooltipComponent) templates: QueryList<TooltipComponent>;

  public fullInfo = [];
  public submittedInfo: SubmittedInfo;
  public tooltip: string;
  public pageSizes: number[] = [3, 5, 10];
  public commands: CommandModel[];
  public editSettings: Object;
  public toolbar: ToolbarItems[] | object;
  public numberRules: IEditCell;
  public editparams: Object;
  public pageSettings: Object;
  public requiredrules: Object;
  public limitrules: Object;
  public displayIddrules: Object;
  public requiredNumberrules: Object;
  public placeholder: string = 'Start typing here...';
  public fields: Object = { text: 'name', value: 'id' };
  public exNumberDdl: Object = { text: 'text', value: 'id' };
  public confirmHeader = "ADD THREAT"
  public confirmHeader2 = "ATTENTION"
  public setFocus: any;
  public probabilityValue: 'Rare';
  public consequenceValue: 'Minor';
  public confirmWidth = '40%'
  public ctWidth = '27%'
  public submittedInfoText;
  threats = [];
  existingStandardThreats: any[] = [];
  vulnerabilities = [];
  controls = [];
  tabIndex;
  threatEffects: ThreatEffect[] = [];
  riskProbabilityList = [];
  riskConsequenceList = [];
  selectedThreat: any;
  riskAnalysisType;
  public numericParams: IEditCell = {
    params: {
      decimals: 0,
      format: "N",
      validateDecimalOnType: true
    }
  }
  public assetText;
  public selectionOptions: SelectionSettingsModel;
  submittedThreats: any[];
  deletedThreats: any[];
  firstTimeTrigger: number;
  bpId: string;
  versionNo: any;
  public isInitialLoad: boolean = false;
  currentuniquecombination: any;

  constructor(private riskService: RiskService, private consequenceServece: ConsequenceService, private cdRef: ChangeDetectorRef,
    private menu: MainLayoutComponent,
    private riskAnanlysisParent: RiskAnylysisComponent, private SopParent: SecurityOfProcessingComponent) {
    this.riskAnalysisType = riskAnanlysisParent.riskAnalysisType;
  }

  ngAfterViewChecked(): void {
    if (this.firstTimeTrigger === 0) {
      if (document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "")) {
        document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "").closest('div').classList.add('e-overlay');
        this.firstTimeTrigger = 1;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.fullInfo.length > 0) this.filterSubmittedInfo(this.riskAnalysisType, this.raType == true ? 2 : 1, this.selectedId, this.selectedSubId);
    this.Dialog2.header = '<h6 style="font-family:Rubik; font-size:16px; font-weight:600; color:#0C2B57; opacity:0.8">SUBMIT RISK ANALYSIS THREATS</h6>' +
      '<hr>'

    this.Dialog3.header = '<h6 style="font-family:Rubik; font-size:16px; font-weight:600; color:#0C2B57; opacity:0.8">ADD STANDARD THREAT</h6>';
    var wrapper = document.getElementById('raGridWrapper');
    if (wrapper !== null) wrapper.id = 'raGridWrapper_' + this.raType + this.tabIndex + '';

    this.confirmAddDialog.header = '<h6 style="font-family:Rubik; font-size:16px; font-weight:600; color:#0C2B57; opacity:0.8">ADD THREAT</h6>' +
      '<hr>';

    this.confirmEditDialog.header = '<h6 style="font-family:Rubik; font-size:16px; font-weight:600; color:#0C2B57; opacity:0.8">ATTENTION</h6>' +
      '<hr>';

    this.toolbar = [
      { text: this.assetText, id: 'assetHeader' },
      'Add', 'Delete',
      {
        text: 'Show consequence types <img id="dlgbtn" class="cTypes_img" src="assets/icons/Security/info.svg">', tooltipText: 'consequence types',
        id: 'consequenceBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + ''
      },
      { id: 'expandBtn', text: '<img id="resizeIcon' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '" class="cTypes_img" src="assets/icons/Security/open_in_full.svg">' },
      { text: 'SUBMIT', id: 'submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '' },
      {
        text: this.submittedInfoText,
        id: 'infoText' + this.riskAnalysisType + this.selectedId + this.selectedSubId + ''
      }
    ]
    this.uniquecombination = this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId;
  }
  ngOnInit(): void {
    this.currentuniquecombination = this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId;
    if (this.currentuniquecombination !== this.uniquecombination) {
      this.bpId = sessionStorage.getItem("bpId")
      var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
      this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
      this.firstTimeTrigger = 0;
      this.selectionOptions = { type: "Multiple", mode: "Cell", allowColumnSelection: false };
      this.menu.openClick(true);
      this.requiredNumberrules = { number: true, editMode: NumericEditCell };
      this.requiredrules = { required: true };
      this.limitrules = { required: false, maxLength: 10 }
      this.displayIddrules = { required: true, maxLength: 5 };
      this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };

      this.numberRules = {
        params: {
          decimals: 0,
          format: "N",
          min: 0,
          max: 4,
          validateDecimalOnType: true,
        },
      };
      this.editparams = { params: { popupHeight: '300px' } };
      this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };
      this.commands = [

        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];
      this.GetThreatEffects();
      this.fillRiskConsequenceObject();
      this.fillRiskProbabilityObject();
      this.GetVulnerabilities(0, false);
      this.GetControls(0, false);
      this.getThreats();
      this.GetApplications();
      this.GetExistingStandardThreats();
    }
  }

  onDoubleClick(args: any): void {
    this.setFocus = args.column;  // Get the column from Double click event 
  }

  beforeRenderCis(e) {
    var uniqueId = (e.target.className.replace('e-chip e-chip-icon-wrap custom-chips threat-chip-standard-control-cis', ''));
    var pureId = uniqueId.substring(0, uniqueId.indexOf('_'));
    var currentTooltip = this.templates.filter(x => x.element.id == 'tooltipcis_' + pureId + '');
    currentTooltip[0].content = e.target.innerText;
  }

  beforeRenderCT(e) {
    var uniqueId = (e.target.className.replace('e-chip custom-chips-ct threat-chip-category', ''));
    var pureId = uniqueId.substring(0, uniqueId.indexOf('_'));
    var currentTooltip = this.templates.filter(x => x.element.id == 'tooltipct_' + pureId + '');
    currentTooltip[0].content = e.target.innerText;
  }

  beforeRenderIso(e) {
    var uniqueId = (e.target.className.replace('e-chip e-chip-icon-wrap custom-chips threat-chip-standard-control-iso', ''));
    var pureId = uniqueId.substring(0, uniqueId.indexOf('_'));
    var currentTooltip = this.templates.filter(x => x.element.id == 'tooltipiso_' + pureId + '');
    currentTooltip[0].content = e.target.innerText;
  }

  setSelectedCt() {
    const selected = this.ctypes.grid.getSelectedRowCellIndexes();
    this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceTypes = [];
    let indexes = [];
    for (var i = 0; i < selected.length; i++) {
      var index = selected[i].rowIndex;
      var ctId = this.ctypes.c_types[index].rowId;
      selected[i].rowIndex = ctId;
      for (var j = 0; j < selected[i].cellIndexes.length; j++) {
        var consequenceRow = this.ctypes.c_types[index];
        switch (selected[i].cellIndexes[j]) {
          case 1: {
            var data = {
              id: ctId,
              name: consequenceRow.minor
            }
            this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceTypes.push(data);
            indexes.push({ index: 1, name: "1 - Minor" });
            break;
          }
          case 2: {
            var data = {
              id: ctId,
              name: consequenceRow.moderate
            }
            this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceTypes.push(data);
            indexes.push({ index: 2, name: "2 - Moderate" });
            break;
          }
          case 3: {
            var data = {
              id: ctId,
              name: consequenceRow.major
            }
            this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceTypes.push(data);
            indexes.push({ index: 3, name: "3 - Major" });
            break;
          }
          case 4: {
            var data = {
              id: ctId,
              name: consequenceRow.severe
            }
            this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceTypes.push(data);
            indexes.push({ index: 4, name: "4 - Severe" });
            break;
          }
        }
      }
    };

    if (selected.length !== 0) {
      this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceBeforeText = indexes.sort(this.sorterCT)[0].name;
      this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceBefore = indexes.sort(this.sorterCT)[0].index;
    }
    this.consequenceServece.setConSequenceTypesSelected(selected, this.selectedThreat);
    if (selected.length == 0) {
      this.threats.filter(x => x.id == this.selectedThreat.id)[0].consequenceBeforeText = "";
    }
    this.grid.dataSource = [];
    this.grid.dataSource = this.threats;
    if (selected.length !== 0)
      this.Dialog.hide();
    if (selected.length === 0)
      this.submitctNonSelectedAlert.show();
  }

  setAssetHeaderText(id: number) {
    let sHeader = [
      { id: 1, text: 'Process related risks' },
      { id: 2, text: 'Applications related risks' },
      { id: 3, text: 'Infrastructure related risks' },
      { id: 5, text: 'Lines of Communication related risks' },
      { id: 4, text: 'People related risks' },
    ]
    this.assetText = sHeader.filter(x => x.id == id)[0].text;
  }

  rowSelected(e) {
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "").closest('div').classList.remove('e-overlay');
    this.Dialog.header = "<div><label style='font-family:Rubik; font-size:16; font-weight:700'>"
      + e.data.displayId + " Manage consequence types</label></div>";
    this.selectedThreat = e.data;
  }
  rowDeselected(e) {
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "").closest('div').classList.add('e-overlay');
    this.selectedThreat = null;

    if (e.isInteracted === false) this.selectedThreat = e.data;
  }
  submitThreats() {

    var bpId = sessionStorage.getItem("bpId");
    var addedThreats = this.submittedgrid.getSelectedRecords();
    var deletedThreats = this.deletedgrid.getSelectedRecords();

    if (this.raType == false) this.riskService.submitThreats(Number(this.riskAnalysisType), 1, bpId, this.selectedId, this.selectedSubId, addedThreats, deletedThreats)
    else this.riskService.submitThreats(Number(this.riskAnalysisType), 2, bpId, this.selectedId, this.selectedSubId, addedThreats, deletedThreats)
    this.dlgBtnClick2();

    if (this.submittedInfo !== undefined) {
      this.submittedInfo.submittedDateUtc = new Date();
      this.submittedInfo.submittedBy = this.menu.getName();
    }

    else if (this.submittedInfo === undefined) {
      this.submittedInfo = {
        id: 0,
        riskAnalysisSubTypeId: this.raType == true ? 2 : 1,
        assetId: this.selectedId,
        assetTypeId: this.selectedSubId,
        riskAnalysisTypeId: this.riskAnalysisType,
        isSubmitted: true,
        submittedDateUtc: new Date(),
        submittedBy: this.menu.getName(),
        businessProcessId: this.bpId,
        versionNo: this.versionNo
      };
    }

    this.riskService.AddSubmitInfo(this.submittedInfo);
    sessionStorage.removeItem("sbmtInfo");
    this.GetAllSubmittedInfo();

    document.getElementById('infoText' + this.riskAnalysisType + this.selectedId + this.selectedSubId + '').innerHTML =
      '<span class="e-tbar-btn-text">Submitted at: ' +
      this.submittedInfo.submittedDateUtc.toISOString().slice(0, 10) + ' by '
      + this.submittedInfo.submittedBy + '</span>';

    this.riskAnanlysisParent.totalText = 'Submitted at: ' +
      this.submittedInfo.submittedDateUtc.toISOString().slice(0, 10)
      + ' by '
      + this.submittedInfo.submittedBy + '';

    var filteredDeleted = this.deletedThreats.filter(x => x.isDefault === true);
    for (var i = 0; i < filteredDeleted.length; i++)
      this.existingGrid.currentViewData.push(filteredDeleted[i]);
    this.existingGrid.dataSource = [];
    this.existingGrid.dataSource = this.existingGrid.currentViewData;
    this.existingStandardThreats = this.existingGrid.currentViewData;
    document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '').closest('div').style.display = 'none';

    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch' };
    for (var j = 0; j < this.deletedThreats.length; j++) {
      this.grid.deleteRecord('id', this.deletedThreats[j].id)
      this.grid.currentViewData.splice(this.grid.currentViewData.findIndex(item => item["id"] === this.deletedThreats[j].id), 1)
    }

    for (var i = 0; i < this.grid.currentViewData.length; i++) {
      for (var j = 0; j < addedThreats.length; j++) {
        if (this.grid.currentViewData[i]["id"] == addedThreats[j]["id"]) {
          var standardDisplayId = this.grid.currentViewData[i]["displayId"].replace('*', '');
          this.grid.currentViewData[i]["displayId"] = standardDisplayId;
        }
      }
    }

    for (var i = 0; i < this.grid.currentViewData.length; i++) {
      if (this.grid.currentViewData[i]["isSubmitted"] === false) {
        this.grid.currentViewData[i]["isSubmitted"] = true;
      }
      if (this.grid.currentViewData[i]["isCandidadeForDelete"] === true) {
        this.grid.currentViewData[i]["isCandidadeForDelete"] = false
      }
    }

    this.grid.dataSource = [];
    this.grid.dataSource = this.grid.currentViewData;

    for (var i = 0; i < this.grid.getAllDataRows().length; i++) {
      for (var j = 0; j < this.grid.getAllDataRows()[i]["cells"].length; j++) {
        if (this.grid.getAllDataRows()[i]["cells"][j].classList.contains('background-light-green')) {
          this.grid.getAllDataRows()[i]["cells"][j].classList.remove('background-light-green');
        }
      }
    }
    this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };
  }
  addExistingThreats() {
    var bpId = sessionStorage.getItem("bpId");
    var addedThreats = this.existingGrid.getSelectedRecords();

    this.riskService.submitExistingThreats(bpId, addedThreats)
    this.dlgBtnClick3();

    for (var i = 0; i < addedThreats.length; i++) {
      this.grid.currentViewData.push(addedThreats[i]);
      this.existingGrid.currentViewData
        .splice(this.existingGrid.currentViewData.findIndex(item => item["id"] === addedThreats[i]["id"]), 1);
    }
    this.getThreats();
    this.existingGrid.dataSource = [];
    this.existingGrid.dataSource = this.existingGrid.currentViewData;
    this.existingStandardThreats = this.existingGrid.currentViewData;

    this.grid.dataSource = [];
    this.grid.dataSource = this.threats
  }

  makeFullScreen() {
    var wrapper = document.getElementById('raGridWrapper_' + this.raType + this.tabIndex + '');
    var icon = document.getElementById("resizeIcon" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId) as HTMLImageElement;
    var menu = document.getElementById('menu-secondary');

    if (wrapper.style.position == "" || wrapper.style.position == "inherit") {
      wrapper.style.position = 'fixed';
      wrapper.style.top = '-30px';
      wrapper.style.right = '0';
      wrapper.style.bottom = '0';
      wrapper.style.left = '0';
      wrapper.style.zIndex = '999';
      this.menu.openClick(true)
      this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[2], pageSizes: this.pageSizes };
      icon.src = "assets/icons/Security/normal_mode.svg";

      menu.style.display = "none"
    }

    else if (wrapper.style.position == "fixed") {
      wrapper.style.position = 'inherit';
      wrapper.style.top = '0px';
      wrapper.style.right = '0';
      wrapper.style.bottom = '0';
      wrapper.style.left = '0';
      wrapper.style.zIndex = '0';
      menu.style.display = "block"
      this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };

      icon.src = "assets/icons/Security/open_in_full.svg"
    }
  }

  filterTE(data: any): ThreatEffect[] {
    if (data.name === undefined) {
      return this.threatEffects;
    }
    else {
      var filtered = this.threatEffects.filter(ar => !data.find(rm => (rm.id === ar.id)))
      return filtered;
    }
  }

  actionComplete(e: any): void {
    if (e.requestType === 'add') {
      (e.form.elements.namedItem('displayId') as HTMLInputElement).disabled = true;
      e.form.elements[1].focus();
    }
    else if (e.requestType === 'beginEdit') {
      (e.form.elements.namedItem('displayId') as HTMLInputElement).disabled = true;
      if (e.form.elements.namedItem('riskAfter') !== null)
        (e.form.elements.namedItem('riskAfter') as HTMLInputElement).disabled = true;
      if (e.form.elements.namedItem('riskBefore') !== null)
        (e.form.elements.namedItem('riskBefore') as HTMLInputElement).disabled = true;
      if (e.requestType === 'beginEdit') {
        if (this.setFocus.field === 'threatEffects.name') {
          setTimeout(function (e) {
            if (e.form.elements[5] !== undefined)
              e.form.elements[5].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'vulnerabilities') {
          setTimeout(function (e) {
            if (e.form.elements[2] !== undefined)
              e.form.elements[2].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'probabilityBeforeText') {
          setTimeout(function (e) {
            if (e.form.elements[7] !== undefined)
              e.form.elements[7].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'consequenceBeforeText') {
          setTimeout(function (e) {
            if (e.form.elements[9] !== undefined)
              e.form.elements[9].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'controls') {
          setTimeout(function (e) {
            if (e.form.elements[11] !== undefined)
              e.form.elements[11].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'probabilityAfterText') {
          setTimeout(function (e) {
            if (e.form.elements[14] !== undefined)
              e.form.elements[14].focus();
          }.bind(this), 0, e);
        }
        if (this.setFocus.field === 'consequenceAfterText') {
          setTimeout(function (e) {
            if (e.form.elements[16] !== undefined)
              e.form.elements[16].focus();
          }.bind(this), 0, e);
        }
        else {
          e.form.querySelector('#' + this.grid.element.id + this.setFocus.field).focus();
        }
      }
    }
  }
  public load(): void {
    this.isInitialLoad = true;
  }
  public beforeDataBound(e): void {
    let fn = () => {
      this.grid.showSpinner();
      this.grid.off('toolbar-refresh', fn);
      if (!this.isInitialLoad && e.result.length == 0) {
        this.grid.hideSpinner();            //hide spinner 
      }
      this.isInitialLoad = false;
    };
    this.grid.on('toolbar-refresh', fn);
  }


  customiseSubmittedCell(args: QueryCellInfoEventArgs) {
    if (args.column.field == 'displayId') {
      args.cell.setAttribute('style', 'color:#5AA9EF')
    }
    if (args.column.field == 'name') {
      args.cell.setAttribute('style', 'color:#0C2B57;font-weight:600')
    }
  }
  customiseDeletedCell(args: QueryCellInfoEventArgs) {
    if (args.column.field == 'displayId') {
      args.cell.setAttribute('style', 'color:#ffcccc')
    }
    if (args.column.field == 'name') {
      args.cell.setAttribute('style', 'color:#0C2B57;font-weight:600')
    }
  }
  customiseCell(args: QueryCellInfoEventArgs) {
    var index = args.cell.attributes.getNamedItem("index").nodeValue;
    const count = parseInt(index, 10)
    if (index == "0") {
      args.cell.classList.add('background-grey');
    }
    if (count % 2 == 0) {
      args.cell.classList.add('background-grey');
    }

    if (args.data["isSubmitted"] === false) {
      args.cell.classList.add('background-light-green');
    }
    if (args.data["isCandidadeForDelete"] === true) {
      args.cell.classList.add('background-light-red');
    }
    if (args.column.field == 'riskBefore') {
      if (args.data["riskBefore"] <= 2 && args.data["riskBefore"] > 0)
        args.cell.classList.add('background-green');

      if (args.data["riskBefore"] > 2 && args.data["riskBefore"] <= 4)
        args.cell.classList.add('background-yellow');

      if (args.data["riskBefore"] >= 6 && args.data["riskBefore"] <= 9)
        args.cell.classList.add('background-orange');

      if (args.data["riskBefore"] > 9)
        args.cell.classList.add('background-red');
    }

    if (args.column.field == 'riskAfter') {
      if (args.data["riskAfter"] <= 2 && args.data["riskAfter"] > 0)
        args.cell.classList.add('background-green');
      if (args.data["riskAfter"] > 2 && args.data["riskAfter"] <= 4)
        args.cell.classList.add('background-yellow');
      if (args.data["riskAfter"] >= 6 && args.data["riskAfter"] <= 9)
        args.cell.classList.add('background-orange');
      if (args.data["riskAfter"] > 9)
        args.cell.classList.add('background-red');
    }

    if (args.column.field == 'riskBefore') {
      if (args.data[args.column.field] !== undefined) {
      }
    }

    if (args.column.field == 'riskAfter') {
      if (args.data[args.column.field] !== undefined) {
      }
    }
  }

  actionBegin(args: any): void {
    if (args.requestType === 'beginEdit') {
      var threatId = args.rowData.id;
      this.GetVulnerabilities(threatId, args.rowData.isDefault);
      this.GetControls(threatId, args.rowData.isDefault);
      this.editedThreat = args.rowData;
    }

    if (this.existingStandardThreats.length > 0) {
      if (args.requestType === 'add') {
        this.confirmAddDialog.show();
        let btn = document.getElementById("confirmAddExisting" + this.uniquecombination);
        btn.addEventListener("click", () => {
          this.confirmAddDialog.hide();
          document.getElementById("approveSbmtBtn").style.display = "block";
          document.getElementById("discardSbmtBtn").style.display = "block";
          this.Dialog3.show()
          this.grid.closeEdit()
        });

        let btnNew = document.getElementById("confirmAddNew" + this.uniquecombination);
        btnNew.addEventListener("click", () => {
          this.confirmAddDialog.hide();
        });
      }
    }

    if (args.requestType === 'save' && args.action === 'add') {
      this.confirmAddDialog.hide();
      args.data.isDefault = false;
      args.data.isSubmitted = false;
      args.data.isCandidadeForDelete = false;
      var threatEffect = this.teListObj;
      var vulnerabilities = this.mulObj;
      var controls = this.mulConObj;
      var standardControls;
      var probabilityBefore = this.probabilitylistObj;
      var probabilityAfter = this.probabilityAfterlistObj;
      var consequenceAfter = this.consequenceAfterlistObj;

      let _probabilityBefore = probabilityBefore !== undefined && (typeof probabilityBefore.value === 'string' || typeof probabilityBefore.value === 'number' || typeof probabilityBefore.value === 'boolean') 
      ? probabilityBefore.getDataByValue(probabilityBefore.value)
      : null;
    
    let _probabilityAfter = probabilityAfter !== undefined && (typeof probabilityAfter.value === 'string' || typeof probabilityAfter.value === 'number' || typeof probabilityAfter.value === 'boolean') 
      ? probabilityAfter.getDataByValue(probabilityAfter.value)
      : null;
    
    let _consequenceAfter = consequenceAfter !== undefined && (typeof consequenceAfter.value === 'string' || typeof consequenceAfter.value === 'number' || typeof consequenceAfter.value === 'boolean') 
      ? consequenceAfter.getDataByValue(consequenceAfter.value)
      : null;
    

      if (this.standardConChipsList !== undefined)
        standardControls = this.standardConChipsList.element.childNodes;

      let standardControlList = [];
      if (standardControls) {
        for (var k = 0; k < standardControls.length; k++) {
          let sc = {
            standardControlId: standardControls[k].dataset.value,
            standardControlName: standardControls[k].textContent
          }
          standardControlList.push(sc);
        }
        args.data.standardControls = standardControlList;
      }
      var _vulnerabilities = [];
      var _controls = [];
      var _threatEffects = []
      if (threatEffect.value !== null)
        if (threatEffect.value.length > 0) {
          var teTextData = [];
          teTextData = threatEffect.text.split(',');
          for (var i = 0; i < threatEffect.value.length; i++) {
            let vul = {
              id: threatEffect.value[i],
              name: teTextData[i]
            }
            _threatEffects.push(vul);
          }
        }
      if (vulnerabilities.value !== null)
        if (vulnerabilities.value.length > 0) {
          for (var i = 0; i < vulnerabilities.value.length; i++) {
            let vul = {
              name: vulnerabilities.value[i],
              isSelected: true
            }
            _vulnerabilities.push(vul);
          }
        }
      if (controls.value !== null)
        if (controls.value.length > 0) {
          for (var i = 0; i < controls.value.length; i++) {
            let control = {
              name: controls.value[i],
              isSelected: true
            }
            _controls.push(control)
          }
        }


      //GENERATE DISPLAY ID
      var arr = [];
      let currentraType = this.raType == true ? 2 : 1;
      let tabsModel = [];
      tabsModel = JSON.parse(sessionStorage.getItem("tabs")).filter(
        x => x.id == currentraType)[0];
      let displayIdText = '';

      let tabModelCurrentAsset = tabsModel["assets"]
        .filter(x => x.assetId === this.selectedId)[0];

      let tabModelCurrentSubAsset = tabModelCurrentAsset["subTypes"].
        filter(y => y.subAssetId === this.selectedSubId)[0];

      displayIdText += tabsModel["prefix"] += tabModelCurrentAsset["prefix"] += tabModelCurrentSubAsset["prefix"] + ".";

      if (this.threats !== undefined) {
        if (this.threats.length > 0) {
          var nextSortOrder = this.threats[this.threats.length - 1].sortOrder + 1;
          args.data.sortOrder = nextSortOrder;
          const selectedIds = this.threats
            .filter(x => x.riskAnalysisSubType == currentraType);


          if (selectedIds.length > 0) {
            // selectedIds.map(({ displayId }) => displayId);
            for (var i = 0; i < selectedIds.length; i++) {
              let id = selectedIds[i].displayId.replace(/^\D+/g, '').replace('*', '');
              arr.push(Number(id));
            }
            arr.sort(this.sorter);
            let lastIndex = arr[arr.length - 1];
            let newIndex = ++lastIndex;
            args.data.displayId = displayIdText + newIndex + "*";
          }
          else {
            args.data.displayId = displayIdText + 1 + "*";
            args.data.sortOrder = 1;
          }
        }
        else {
          args.data.displayId = displayIdText + 1 + "*";
          args.data.sortOrder = 1;
        }
      }
      else {
        args.data.displayId = displayIdText + 1 + "*";
        args.data.sortOrder = 1;
      }
      //GENERATE DISPLAY ID

      args.data.vulnerabilities = _vulnerabilities;
      args.data.controls = _controls;
      args.data.threatEffects = _threatEffects;
      args.data.assetType = this.selectedSubId;
      args.data.businesProcessId = sessionStorage.getItem("bpId")
      args.data.isDefault = false
      args.data.isFullAnalysisThreat = false
      args.data.riskAnalysisType = this.riskAnalysisType
      args.data.id = 0
      args.data.assetId = this.selectedId;
      args.data.assetType = this.selectedId;
      args.data.assetSubType = this.selectedSubId;
      args.data.riskAnalysisSubType = this.raType == false ? 1 : 2

      if (args.data.vulnerabilities === 'undefined' || args.data.vulnerabilities.length == 0)
        args.data.vulnerabilities = null

      if (args.data.controls === 'undefined' || args.data.controls.length == 0)
        args.data.controls = null

      if (_probabilityAfter === null || args.data.probabilityAfter === 'undefined')
        args.data.probabilityAfter = 0
      else
        args.data.probabilityAfter = _probabilityAfter["id"]

      if (_probabilityBefore === null || args.data.probabilityBefore === 'undefined')
        args.data.probabilityBefore = 0
      else
        args.data.probabilityBefore = _probabilityBefore["id"]

      if (_consequenceAfter === null || args.data.consequenceAfter === 'undefined')
        args.data.consequenceAfter = 0
      else
        args.data.consequenceAfter = _consequenceAfter["id"]

      args.data.riskBefore = args.data.consequenceBefore * args.data.probabilityBefore
      args.data.riskAfter = args.data.consequenceAfter * args.data.probabilityAfter

      args.data.probabilityBeforeText = this.riskProbabilityList.filter(x => x.id == args.data.probabilityBefore)[0].text
      args.data.probabilityAfterText = this.riskProbabilityList.filter(x => x.id == args.data.probabilityAfter)[0].text
      args.data.consequenceAfterText = this.riskConsequenceList.filter(x => x.id == args.data.consequenceAfter)[0].text

      args.data.isFullAnalysisThreat = this.raType;
      args.data.versionNo = this.versionNo;
      this.riskService.addFullThreat(args.data).subscribe(data => {
        this.threats.push(data);
        args.data.id = data.id;
      });
      document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '').closest('div').style.display = 'contents';
    }
    if (args.requestType === 'save' && args.action === 'edit') {
      if (args.data.isDefault === true) {
        if (args.data.name !== args.rowData.name) {
          this.confirmEditDialog.show();
          let btn = document.getElementById("confirmEdit" + this.raType + this.selectedId + this.selectedSubId);
          btn.addEventListener("click", () => {
            this.confirmEditDialog.hide();
            if (args.data.businessProcessId == '00000000-0000-0000-0000-000000000000')
              args.data.businessProcessId = sessionStorage.getItem("bpId");
            this.riskService.updateStandardThreat(args.data);
            this.grid.refresh();
          });

          let btnNew = document.getElementById("discardEdit" + this.raType + this.selectedId + this.selectedSubId);
          btnNew.addEventListener("click", () => {
            this.getThreats();
            this.confirmEditDialog.hide();
            this.grid.dataSource = [];
            this.grid.dataSource = this.threats;
            this.grid.editModule.closeEdit();
          });
        }
      }

      var threatEffect = this.teListObj;
      var vulnerabilities = this.mulObj;
      var controls = this.mulConObj;
      var probabilityBefore = this.probabilitylistObj;
      var probabilityAfter = this.probabilityAfterlistObj;
      var consequenceAfter = this.consequenceAfterlistObj;

      let _probabilityBefore = probabilityBefore.getDataByValue(
        typeof probabilityBefore.value === 'string' || typeof probabilityBefore.value === 'number' || typeof probabilityBefore.value === 'boolean' ? probabilityBefore.value : ''
      );
      let _probabilityAfter = probabilityAfter.getDataByValue(
        typeof probabilityAfter.value === 'string' || typeof probabilityAfter.value === 'number' || typeof probabilityAfter.value === 'boolean' ? probabilityAfter.value : ''
      );
      let _consequenceAfter = consequenceAfter.getDataByValue(
        typeof consequenceAfter.value === 'string' || typeof consequenceAfter.value === 'number' || typeof consequenceAfter.value === 'boolean' ? consequenceAfter.value : ''
      );
      


      // REGION REMOVED ITEMS FROM CHIPSLIST
      var teOld = null;
      if (this.teChipsList !== undefined)
        teOld = this.teChipsList.element.childNodes;
      var vullOnEdit;
      if (this.vulChipsList !== undefined)
        vullOnEdit = this.vulChipsList.element.childNodes;
      else vullOnEdit = null;
      var connOnEdit;
      if (this.conChipsList !== undefined)
        connOnEdit = this.conChipsList.element.childNodes;
      else connOnEdit = null;

      let vulnExistingChips = [];
      let conExistingChips = [];
      args.data.threatEffects = [];
      if (teOld !== null && teOld.length > 0) {
        for (var i = 0; i < teOld.length; i++) {
          let te = {
            id: teOld[i].dataset.value,
            name: teOld[i].textContent
          }
          args.data.threatEffects.push(te);
        }
      }

      if (vullOnEdit !== null && vullOnEdit.length > 0) {
        for (var i = 0; i < vullOnEdit.length; i++) {
          let text = vullOnEdit[i].textContent;
          vulnExistingChips.push(text);
        }
      }
      if (connOnEdit !== null && connOnEdit.length > 0) {
        for (var i = 0; i < connOnEdit.length; i++) {
          let text = connOnEdit[i].textContent;
          conExistingChips.push(text);
        }
      }

      // DELETE CHIPS
      if (args.data.vulnerabilities !== null && args.data.vulnerabilities.length > 0) {
        let missing = args.data.vulnerabilities.filter((i => a => a.name !== vulnExistingChips[i] || !++i)(0));
        if (missing.length > 0) {
          for (var i = 0; i < missing.length; i++) {
            this.riskService.setVulnerabilitySelected(missing[i].id, false);
            args.data.vulnerabilities.splice(args.data.vulnerabilities.findIndex(item => item.id === missing[i].id), 1)
          }
        }
      }

      if (args.data.controls !== null && args.data.controls.length > 0) {
        let missing = args.data.controls.filter((i => a => a.name !== conExistingChips[i] || !++i)(0));
        if (missing.length > 0) {
          for (var i = 0; i < missing.length; i++) {
            this.riskService.setControlSelected(missing[i].id, false);
            args.data.controls.splice(args.data.controls.findIndex(item => item.id === missing[i].id), 1)
          }

        }
      }
      //ENDREGION
      //ENDREGION

      var _threatEffects = []
      if (threatEffect.value !== null)
        if (threatEffect.value.length > 0) {
          var teTextData = [];
          teTextData = threatEffect.text.split(',');
          for (var i = 0; i < threatEffect.value.length; i++) {
            let vul = {
              id: threatEffect.value[i],
              name: teTextData[i]
            }
            args.data.threatEffects.push(vul);
          }
        }
      if (args.data.threatEffects == null)
        args.data.threatEffects = [];

      // REGION NEWLY CREATE ITEMS

      if (vulnerabilities.value !== null)
        if (vulnerabilities.value.length > 0) {
          for (var i = 0; i < vulnerabilities.value.length; i++) {
            var x = vulnerabilities.value[i];
            if (Number.isInteger(x)) {
              if (Number(x) % 1 == 0) {
                let el = vulnerabilities.getDataByValue(vulnerabilities.value[i])
                this.riskService.setVulnerabilitySelected((Number(x)), true);
                if (args.data.vulnerabilities == null) args.data.vulnerabilities = [];
                args.data.vulnerabilities.push(el);
              }
            }
            else if (typeof (x) !== 'string') {
              let el: Object;
              el = vulnerabilities.getDataByValue(vulnerabilities.value[i])["name"];
              let vul = {
                name: el,
                isSelected: true
              }
              if (args.data.vulnerabilities == null) args.data.vulnerabilities = [];
              args.data.vulnerabilities.push(vul);
            }
            else if (typeof (x) === 'string') {
              let vul = {
                name: vulnerabilities.value[i],
                isSelected: true
              }
              if (args.data.vulnerabilities == null) args.data.vulnerabilities = [];
              args.data.vulnerabilities.push(vul);
            }
          }
        }

      if (controls.value !== null)
        if (controls.value.length > 0) {
          for (var i = 0; i < controls.value.length; i++) {
            var x = controls.value[i];
            if (Number.isInteger(x)) {
              if (Number(x) % 1 == 0) {
                var el = controls.getDataByValue(controls.value[i]);
                this.riskService.setControlSelected((Number(x)), true);
                if (args.data.controls == null) args.data.controls = [];
                args.data.controls.push(el);
              }
            }
            else if (typeof (x) !== 'string') {
              var el = controls.getDataByValue(controls.value[i])["name"];
              let control = {
                name: el,
                isSelected: true
              }
              if (args.data.controls == null) args.data.controls = [];
              args.data.controls.push(control);
            }

            else if (typeof (x) === 'string') {
              let control = {
                name: controls.value[i],
                isSelected: true
              }
              if (args.data.controls == null) args.data.controls = [];
              args.data.controls.push(control);
            }
          }
        }

      //ENDREGION


      if (_probabilityAfter === null || args.data.probabilityAfter === 'undefined')
        args.data.probabilityAfter = 0

      else {
        args.data.probabilityAfter = _probabilityAfter["id"]
        args.data.probabilityAfterText = _probabilityAfter["text"]
      }

      if (_probabilityBefore === null || args.data.probabilityBefore === 'undefined')
        args.data.probabilityBefore = 0
      else {
        args.data.probabilityBefore = _probabilityBefore["id"]
        args.data.probabilityBeforeText = _probabilityBefore["text"]
      }

      if (_consequenceAfter === null || args.data.consequenceAfter === 'undefined')
        args.data.consequenceAfter = 0
      else {
        args.data.consequenceAfter = _consequenceAfter["id"]
        args.data.consequenceAfterText = _consequenceAfter["text"]
      }


      args.data.riskBefore = args.data.consequenceBefore * args.data.probabilityBefore
      args.data.riskAfter = args.data.consequenceAfter * args.data.probabilityAfter

      if (args.data.isDefault === false)
        this.riskService.updateThreat(args.data);
      if (args.data.isDefault === true && args.data.name === args.rowData.name)
        this.riskService.updateStandardThreat(args.data);
    }
    if (args.requestType === 'delete') {
      args.data.isCandidadeForDelete = true;
      args.data.isSubmitted = false;
      var bp = sessionStorage.getItem("bpId");
      var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
      this.riskService.setCandidadeForDelete(args.data[0].id,
        args.data[0].isDefault, bp, this.versionNo);
      args.cancel = true;
      args.isDeleted = false;
      document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '').closest('div').style.display = 'contents';
      for (var i = 0; i < args.tr[0].cells.length; i++) {
        args.tr[0].cells[i].classList.add('background-light-red');
      }
    }
  }

  sorter(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  sorter2(a, b) {
    if (Number(a.displayId) < Number(b.displayId)) return -1;
    if (Number(a.displayId) > Number(b.displayId)) return 1;
    return 0;
  }



  sorterCT(a, b) {
    if (Number(a.index) > Number(b.index)) return -1;
    if (Number(a.index) < Number(b.index)) return 1;
    return 0;
  }

  sorter3(a, b) {
    if (a.sortOrder < b.sortOrder) return -1;
    if (a.sortOrder > b.sortOrder) return 1;
    return 0;
  }

  fillRiskProbabilityObject() {
    var obj = [
      { id: 0, text: '' },
      { id: 1, text: '1 - Rare' },
      { id: 2, text: '2 - Unlikely' },
      { id: 3, text: '3 - Possible' },
      { id: 4, text: '4 - Almost certain' }
    ]
    this.riskProbabilityList = obj;
  }

  fillRiskConsequenceObject() {
    var obj = [
      { id: 0, text: '' },
      { id: 1, text: '1 - Minor' },
      { id: 2, text: '2 - Moderate' },
      { id: 3, text: '3 - Major' },
      { id: 4, text: '4 - Severe' }
    ]
    this.riskConsequenceList = obj;
  }

  GetThreatEffects() {
    this.riskService.getThreatEffects().subscribe(res => {
      if (res !== null && res.length > 0) {
        this.threatEffects = res
      }
    })
  }

  GetVulnerabilities(threatId: number, isDefault: boolean) {
    var bpId = sessionStorage.getItem("bpId");
    this.riskService.getVulnerabilities(bpId, threatId, isDefault).subscribe(res => {
      if (res.length > 0) {
        this.vulnerabilities = res;
        return this.vulnerabilities;
      }
    });
  };
  GetControls(threatId: number, isDefault: boolean) {
    var bpId = sessionStorage.getItem("bpId");
    this.riskService.getControls(bpId, threatId, isDefault).subscribe(res => {
      if (res.length > 0) {
        this.controls = res;
        return this.controls;
      }
    });
  };

  GetApplications() {
    var tenant = sessionStorage.getItem("customerId");
    this.riskService.getApplicationsModel(this.bpId, this.versionNo, tenant).subscribe(res => {
      if (res.length > 0) {
        sessionStorage.removeItem("appData");
        sessionStorage.setItem("appData", JSON.stringify(res));
      }
      else res = null;
      this.applications = res;
    })
  }


  handleChange(field) {
    this.threats = this.threats.filter(x => x.id == field.threatId)
  }

  getThreats() {

    var assetType = this.selectedId;
    var riskAnalysisSubType = this.raType === false ? 1 : 2;
    var tabId = this.selectedSubId;

    this.setAssetHeaderText(this.selectedSubId);
    //var wrapper = document.getElementById('raGridWrapper').id = 'raGridWrapper_'+this.raType+this.tabIndex+'';
    switch (this.riskAnalysisType) {
      case "0": {
        this.SopParent.sopType = ": GDPR Analysis";
        this.cdRef.detectChanges();

        break;
      }
      case "1": {
        this.SopParent.sopType = ": IT Analysis"
        this.cdRef.detectChanges();
        break;
      }
      default: {
        this.SopParent.sopType = "";
      }

    }
    this.GetAllSubmittedInfo();
    var probability = this.riskProbabilityList;
    var consequence = this.riskConsequenceList;
    let res: FullThreat[];

    if (sessionStorage.getItem("threatData")) {
      var s = sessionStorage.getItem("threatData").replace(/[\u0000-\u0019]+/g, "");
      res = JSON.parse(s);
    }

    if (!sessionStorage.getItem("threatData")) {
      this.riskService.getFullThreats(this.bpId, this.versionNo).subscribe(res => {
        res = res;
        if (res.length > 0) {
          sessionStorage.setItem("threatData", JSON.stringify(res));
        }
      });
    }
    if (res !== undefined)
      if (res.length > 0) {

        res = res.filter(x => x.assetType == assetType
          && x.assetSubType == tabId
          && x.riskAnalysisType == this.riskAnalysisType && x.riskAnalysisSubType == riskAnalysisSubType);

        for (var i = 0; i < res.length; i++) {
          let calculatedColumn = 0;
          calculatedColumn = res[i].consequenceBefore * res[i].probabilityBefore;
          res[i].riskBefore = calculatedColumn;

          calculatedColumn = 0;
          calculatedColumn = res[i].consequenceAfter * res[i].probabilityAfter;
          res[i].riskAfter = calculatedColumn;

          var _pa = probability.filter(x => x.id == res[i].probabilityAfter)
          var _pb = probability.filter(x => x.id == res[i].probabilityBefore)

          var _ca = consequence.filter(x => x.id == res[i].consequenceAfter)
          var _cb = consequence.filter(x => x.id == res[i].consequenceBefore)

          res[i].probabilityBeforeText = _pb[0].text;
          res[i].probabilityAfterText = _pa[0].text;
          res[i].consequenceAfterText = _ca[0].text;
          res[i].consequenceBeforeText = _cb[0].text;

          if (res[i].standardControls["length"] === 0)
            res[i].standardControls = null;
        }
      }
    if (res !== undefined) {
      res = res.sort(this.sorter3);
    }
    this.FilterThreats();
    var btn = document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '');
    if (this.submittedThreats !== undefined) {
      if (this.submittedThreats.length == 0 && this.deletedThreats.length == 0) {
        if (btn !== null)
          btn.closest('div').style.display = 'none';
      }
      if (this.submittedThreats.length > 0 || this.deletedThreats.length > 0) {
        if (btn !== null)
          btn.closest('div').style.display = 'contents';
      }
    }
    this.threats = res;
  }

  hideDialogButtons() {
    document.getElementById("submitSelectedCtBtn_" + this.uniquecombination + "").style.display = "none";
    document.getElementById("ctDialogClose_" + this.uniquecombination + "").style.display = "none";
    this.Dialog.header = "<div><label style='font-family:Rubik; "
      + "font-size:16; font-weight:700'>Edit consequence types</label></div>";
  }

  showDialogButtons() {
    document.getElementById("submitSelectedCtBtn_" + this.uniquecombination + "").style.display = "block";
    document.getElementById("ctDialogClose_" + this.uniquecombination + "").style.display = "block";
    this.Dialog.header = "<div><label style='font-family:Rubik; font-size:16; font-weight:700'>"
      + this.selectedThreat.displayId + " Manage consequence types</label></div>";
    this.Dialog.header + '<button class="editDialogBtn"' +
      'style = "margin-left:2px;font-family: Rubik;font-size: 14px;' +
      'font-weight: 400;width: 165px;height: 41px;background-color: #5AA9EF;' +
      'color: white;border: 1px solid #5AA9EF">Edit</button>';
  }

  clickHandler(args: ToolbarClickEventArgs): void {
    if (args.item.id === 'consequenceBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '') {
      this.ctypes.grid.clearSelection();
      this.consequenceServece.GetConsequenceTypesSelected(this.selectedThreat["id"],
        this.selectedThreat["businesProcessId"], this.selectedThreat["isDefault"], this.selectedThreat["riskAnalysisType"], this.selectedThreat["versionNo"]).subscribe(data => {
          if (data.length !== 0)
            this.ctypes.grid.selectCells(data);
        })
      this.Dialog.show();
    }

    else if (args.item.id === 'submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '') {
      this.getThreats();
      this.pageSettings = { pageCount: 10, pageSize: 100, pageSizes: this.pageSizes };
      this.Dialog2.show();
      document.getElementById("approveSbmtBtn").style.display = "block";
      document.getElementById("discardSbmtBtn").style.display = "block";
    }
    else if (args.item.id === 'addExisting') {
      this.Dialog3.show();
    }
    else if (args.item.id === 'approveSbmtBtn') {
      this.submitThreats();
    }
    else if (args.item.id === 'expandBtn') {
      this.makeFullScreen();
    }
  }
  FilterThreats() {
    if (this.threats.length > 0) {
      this.submittedThreats = this.threats.filter(x => x.isSubmitted == false);
      this.deletedThreats = this.threats.filter(x => x.isCandidadeForDelete == true);
    }
  }

  showDialogData(data: FullThreat) {
    this.ConstrolsDialog.header = "<h3 style='color:#142A56; " +
      "font-family:Rubik;font-size:16px;font-weight:600'>" + data.displayId + " Select Standard Controls</h3>"
    this.ConstrolsDialog.show();
  }

  dialogClose2() {
    document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '').closest('div').style.display = 'contents';
    this.pageSettings = { pageCount: 10, pageSize: this.pageSizes[0], pageSizes: this.pageSizes };
  }

  closeCt() {
    const selected = this.ctypes.grid.getSelectedRowCellIndexes();
    if (selected)
      if (selected.length == 0)
        this.ctNonSelectedAlert.show();
      else this.Dialog.hide();
    document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "").closest('div').classList.remove('e-overlay');
  }

  dialogCloseCtNonSelected() {
    this.Dialog.hide();
    this.ctNonSelectedAlert.hide();
  }

  dialogClose3() {
    document.getElementById('addExisting').style.display = 'block';
  }

  dialogOpen() {
    document.getElementById("consequenceBtn" + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + "").closest('div').classList.add('e-overlay');
  }
  dialogOpenOnAlert() {
    this.ctNonSelectedAlert.hide();
  }
  dialogOpen2() {
    document.getElementById('submitBtn' + this.riskAnalysisType + this.raType + this.selectedId + this.selectedSubId + '').closest('div').style.display = 'none';
  }
  dialogOpen3() {
    document.getElementById('addExisting').style.display = 'none';
  }
  public dlgButtons: Object[] = [
    {
      click: this.dlgBtnClick.bind(this), buttonModel: {
        // content: 'Close', isPrimary:'true' 
      }
    }];

  public dlgButtons2: Object[] = [
    {
      click: this.dlgBtnClick.bind(this), buttonModel: {
        // content: 'Close', isPrimary:'true' 
      }
    }];

  public confirmDlgButtons: Object[] = [
    {
      click: this.dlgBtnClick.bind(this), buttonModel: {
        // content: 'Close', isPrimary:'true' 
      }
    }];

  public confirmDlgButtons2: Object[] = [
    {
      click: this.dlgBtnClick.bind(this), buttonModel: {
        // content: 'Close', isPrimary:'true' 
      }
    }];

  public dlgButtons3: Object[] = [
    {
      click: this.dlgBtnClick.bind(this), buttonModel: {
        // content: 'Close', isPrimary:'true' 
      }
    }];

  dlgBtnClick() {
    this.Dialog.hide();
  }

  dlgBtnClick2() {
    this.Dialog2.hide();
  }
  dlgBtnClick3() {
    this.Dialog3.hide();
    this.getThreats();
  }

  GetExistingStandardThreats() {
    var riskAnalysisSubType;
    if (this.raType == true) riskAnalysisSubType = 2
    else riskAnalysisSubType = 1;
    this.riskService.getExistingStandardThreats(this.riskAnalysisType, riskAnalysisSubType,
      this.selectedId, this.selectedSubId, sessionStorage.getItem("bpId"))
      .subscribe(data => {
        this.existingStandardThreats = data.sort(this.sorter3);
      })
  }

  GetAllSubmittedInfo() {
    this.riskService.getAllBpSubmitInfo(this.bpId, this.versionNo)
      .subscribe(data => {
        sessionStorage.setItem("sbmtInfo", JSON.stringify(data));
        this.fullInfo = data;
        this.filterSubmittedInfo(this.riskAnalysisType, this.raType == true ? 2 : 1, this.selectedId, this.selectedSubId);
      })
  }

  filterSubmittedInfo(riskAnalysisType: number, riskAnalysisSubType: number, assetId: number, assetSubType: number) {
    this.submittedInfo = this.fullInfo.filter(x => x.riskAnalysisTypeId == riskAnalysisType && x.riskAnalysisSubTypeId == riskAnalysisSubType
      && x.assetId == assetId && x.assetTypeId == assetSubType)[0];

    if (this.submittedInfo !== undefined) {
      this.submittedInfoText = "Submitted at: " +
        new Date(this.submittedInfo.submittedDateUtc).toISOString().slice(0, 10)
        + " by " + this.submittedInfo.submittedBy;

      let infoTextContainer = document.getElementById('infoText' + this.riskAnalysisType + this.selectedId + this.selectedSubId + '');

      if (infoTextContainer !== null)
        infoTextContainer.innerHTML =
          '<span class="e-tbar-btn-text">Submitted at: ' +
          new Date(this.submittedInfo.submittedDateUtc).toISOString().slice(0, 10)
          + ' by '
          + this.submittedInfo.submittedBy +
          '</span>';
    }

    else {
      this.submittedInfoText = ""
    }
  }
  deleteStandardControl(e: ClickEventArgs, threat: FullThreat) {
    if (e.text) {
      let index = e.data["value"];
      if (index) {
        this.riskService.DeleteStandardControl(threat, index);
      }
    }
  }
}