import { Component, ViewEncapsulation, OnInit, Input, AfterViewChecked } from "@angular/core";
import { BusinessFunctionsComponent } from "../business-functions.component";

@Component({
    selector: 'function-licensed-activities',
    templateUrl: './function-licensed-activities.html',
    styleUrls: ['function-licensed-activities.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FunctionLicensedActivityComponent implements OnInit, AfterViewChecked {
    constructor(private parent: BusinessFunctionsComponent) { }

    @Input() ind: number;
    @Input() model: any;
    @Input() activities: any;

    isFirstCall: boolean;
    prevFuncId: number;

    licensedActivities: any;
    ngOnInit(): void {
        this.isFirstCall = true;
        this.licensedActivities = this.activities;
    }

    ngAfterViewChecked(): void {
        if ((this.isFirstCall || this.model.functionId !== this.prevFuncId)
            && this.activities !== undefined && this.model !== undefined) {
            this.isFirstCall = false;
            this.licensedActivities = [];
            var newActivities = this.activities.map(activity => {
                return { ...activity, functionDisplayId: null };
            });
            this.licensedActivities = newActivities;
            this.prevFuncId = this.model.functionId;
            if (this.licensedActivities.length == 0) {
                this.licensedActivities = this.activities;
            }
            this.activities.forEach(element => {
                document.getElementById(`chevron_${element.licensedActivityId}`).style.display = "none"
            });
            if (this.model.licensedActivities.length > 0) {
                this.model.licensedActivities.forEach(element => {
                    document.getElementById(`chevron_${element.licensedActivityId}`).style.display = "block"
                });
            }
        }
    }

    linkLA(la) {
        if (document.getElementById(`chevron_${la.licensedActivityId}`).style.display == "block") {
            document.getElementById(`chevron_${la.licensedActivityId}`).style.display = "none";
        }
        else {
            document.getElementById(`chevron_${la.licensedActivityId}`).style.display = "block";
        }
        this.parent.linkLA(la, this.model, this.ind);
    }
}