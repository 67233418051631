import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-heatmap',
  styleUrls: ['heatmap.component.css'],
  templateUrl: './heatmap.component.html',
  styles: [` 

  .background-green{ 
    background-color:  #92BB50; 
    color:white !important;
  } 
  .background-red{ 
    background-color: #DD2122; 
    color:white !important;
  } 
  .background-yellow{ 
    background-color: #EDDA00; 
    color:white !important;
  } 
  .background-orange{ 
    background-color: #F3B10D; 
    color:white !important;
  } 
`]
})
export class HeatmapComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {

  }
  private lines;
  ngOnInit(): void {
    this.lines = 'None';
  }
  getColor(risk) {
    switch (risk) {
      case 1: case 2:
        return '#92BB50';
      case 3: case 4:
        return '#EDDA00';
      case 6: case 8: case 9:
        return '#F3B10D';
      case 12: case 16:
        return '#DD2122';
      default: return '#92BB50';
    }
  }

  customiseCell(args: QueryCellInfoEventArgs) {

    if(args.column.field === 'minorvalueNumber'){
        if(args.data["minorvalueNumber"] > 0 && args.data["minorvalueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["minorvalueNumber"] > 2 && args.data["minorvalueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["minorvalueNumber"] >= 6 && args.data["minorvalueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["minorvalueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'modValueNumber'){
        if(args.data["modValueNumber"] > 0 && args.data["modValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["modValueNumber"] > 2 && args.data["modValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["modValueNumber"] >= 6 && args.data["modValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["modValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'majorValueNumber'){
        if(args.data["majorValueNumber"] > 0 && args.data["majorValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["majorValueNumber"] > 2 && args.data["majorValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["majorValueNumber"] >= 6 && args.data["majorValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["majorValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
    if(args.column.field === 'severeValueNumber'){
        if(args.data["severeValueNumber"] > 0 && args.data["severeValueNumber"] <=2){
            args.cell.classList.add('background-green');
        }
        if(args.data["severeValueNumber"] > 2 && args.data["severeValueNumber"] <=4){
            args.cell.classList.add('background-yellow');
        }
        if(args.data["severeValueNumber"] >= 6 && args.data["severeValueNumber"] <=9){
            args.cell.classList.add('background-orange');
        }
        if(args.data["severeValueNumber"] > 9){
            args.cell.classList.add('background-red');
        }
    }
  } 

  public gridDataSource: Object = [
    { 'lab': '', 'id': 0, 'xPosition': 'Almost certain', 'minorvalueNumber': 4, 'modValueNumber': 8, 'majorValueNumber': 12, 'severeValueNumber': 16 },
    { 'lab': 'Probability', 'id': 1, 'xPosition': 'Possible', 'minorvalueNumber': 3, 'modValueNumber': 6, 'majorValueNumber': 9, 'severeValueNumber': 12 },
    { 'lab': '', 'id': 2, 'xPosition': 'Unlikely', 'minorvalueNumber': 2, 'modValueNumber': 4, 'majorValueNumber': 6, 'severeValueNumber': 8 },
    { 'lab': '', 'id': 3, 'xPosition': 'Rare', 'minorvalueNumber': 1, 'modValueNumber': 2, 'majorValueNumber': 3, 'severeValueNumber': 4 }]
}
