import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { Application } from "src/app/interfaces/Application";
import { Control } from "src/app/interfaces/Control";
import { FullThreat } from "src/app/interfaces/FullThreat";
import { StandardControlModel } from "src/app/interfaces/RiskAnalysis/StandardControlModel";
import { RiskAnalysisSubTypes } from "src/app/interfaces/RiskAnalysisSubTypes";
import { SubmittedInfo } from "src/app/interfaces/SubmittedInfo";
import { ThreatEffect } from "src/app/interfaces/ThreatEffect";
import { Vulnerability } from "src/app/interfaces/Vulnerability";

import auth from '../../auth-config.json';

@Injectable({
  providedIn: 'root'
})
export class RiskService {
  url = auth.resources.PDHubApi.resourceUri;
  localUrl = "https://localhost:44394";
  constructor(private http: HttpClient) { }

  private createHedader() {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getTabStructure() {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetTabs";
    return this.http.get<RiskAnalysisSubTypes[]>(fullUrl);
  }

  getFullThreats(bpId: string, versionNo: number): Observable<FullThreat[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetFullThreats?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get<FullThreat[]>(fullUrl);
  }

  getVulnerabilities(bpId: string, threatId: number, isDefault: boolean): Observable<Vulnerability[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/FullVulnerabilityList?businessProcessId="
      + bpId + "&threatId=" + threatId + "&isDefault=" + isDefault + "";
    return this.http.get<Vulnerability[]>(fullUrl);
  }

  deleteVulnerabilities(data: Vulnerability[]) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/DeleteFullVulnerabilities";
    return this.http.post(fullUrl, JSON.stringify(data), this.createHedader()).subscribe();
  }

  setVulnerabilitySelected(id: number, isSelected: boolean) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/SetFullVulnerabilitySelected?vulnerabilityId=" + id + "&isSelected=" + isSelected + "";
    return this.http.get(fullUrl).subscribe();
  }
  setControlSelected(id: number, isSelected: boolean) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/SetFullControlSelected?controlId=" + id + "&isSelected=" + isSelected + "";
    return this.http.get(fullUrl).subscribe();
  }

  deleteControls(data: Control[]) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/DeleteFullControls";
    return this.http.post(fullUrl, JSON.stringify(data), this.createHedader()).subscribe();
  }

  getControls(bpId: string, threatId: number, isDefault: boolean): Observable<Control[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/FullControlList?businessProcessId="
      + bpId + "&threatId=" + threatId + "&isDefault=" + isDefault + "";
    return this.http.get<Control[]>(fullUrl);
  }

  getThreatEffects(): Observable<ThreatEffect[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetThreatEffects";
    return this.http.get<ThreatEffect[]>(fullUrl);
  }


  updateThreat(data: FullThreat) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/AddEditFullThreat";
    this.http.post(fullUrl, JSON.stringify(data), this.createHedader())
      .subscribe();
  }
  updateStandardThreat(data: FullThreat) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/UpdateStandardThreat";
    this.http.put(fullUrl, JSON.stringify(data), this.createHedader())
      .subscribe();
  }

  addFullThreat(data: FullThreat): Observable<FullThreat> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/AddEditFullThreat";
    return this.http.post<FullThreat>(fullUrl, JSON.stringify(data), this.createHedader());
  }

  generateDisplayId(bpId: string, assetType: number) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/generatenextdisplayid?businessProcessId=" + bpId + "&assetType=" + assetType + "";
    return this.http.get(fullUrl, this.createHedader()).pipe(delay(2000));
  }

  deleteThreat(id: number, bpId: string, versionNo: number) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/DeleteFullThreat?threatId=" + id + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get(fullUrl).subscribe();
  }

  setCandidadeForDelete(id: number, isDefault: boolean, bpId: string, versionNo: number) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/FullThreatSetCandidadeForDelete?threatId=" +
      id + "&isDefault=" + isDefault + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get(fullUrl).subscribe();
  }
  getApplications(bpId: string, versionNo: number, customerId: string) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetApplicationsList?" +
      "businessProcessId=" + bpId + "&customerId=" + customerId + "&versionNo=" + versionNo + "";
    return this.http.get<string[]>(fullUrl);
  };
  getApplicationsModel(bpId: string, versionNo: number, customerId: string): Observable<Application[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetApplicationsThreatModel?" +
      "businessProcessId=" + bpId + "&customerId=" + customerId + "&versionNo=" + versionNo + "";
    return this.http.get<Application[]>(fullUrl);
  };
  getDataProcessorsList(bpId: string, versionNo: number, customerId: string): Observable<string[]> {
    var bpUrl = this.url + "/api/SecurityOfProcessing/GetDataProcessorsList?businessProcessId=" + bpId + "&customerId=" +
      customerId + "&versionNo=" + versionNo + "";
    return this.http.get<string[]>(bpUrl);
  };
  getExistingStandardThreats(riskAnalysisType: number, riskAnalysisSubType: number,
    asset: number,
    assetSubType: number, bpId: string): Observable<FullThreat[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetExistingStandardThreats?" +
      "riskAnalysisType=" + riskAnalysisType + "&riskAnalysisSubType=" + riskAnalysisSubType + "&asset=" + asset + "" +
      "&assetSubType=" + assetSubType + "&businessProcessId=" + bpId + "";
    return this.http.get<FullThreat[]>(fullUrl);
  }
  submitThreats(riskAnalysisType: number, riskAnalysisSubType: number,
    bpId: string,
    asset: number,
    assetSubType: number,
    addedThreats: Object[],
    deletedThreats: Object[]) {
    let data = {
      riskAnalysisType: riskAnalysisType,
      riskAnalysisSubType: riskAnalysisSubType,
      asset: asset,
      assetSubType: assetSubType,
      bpId: bpId,
      addedThreats: addedThreats,
      deletedThreats: deletedThreats
    }
    var fullUrl = this.url + "/api/SecurityOfProcessing/SubmitAsset";
    return this.http.post(fullUrl, JSON.stringify(data), this.createHedader()).subscribe();
  }

  submitExistingThreats(
    bpId: string,
    addedThreats: Object[]) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/AddExistingThreats?businessProcessId=" + bpId + "";
    return this.http.post(fullUrl, JSON.stringify(addedThreats), this.createHedader()).subscribe();
  }


  getSubmitInfo(riskAnalysisType: number, riskAnalysisSubType: number,
    asset: number,
    assetSubType: number, bpId: string, versionNo: number): Observable<SubmittedInfo> {

    var fullUrl = this.url + "/api/SecurityOfProcessing/GetSubmittedInfo?" +
      "riskAnalysisType=" + riskAnalysisType + "&riskAnalysisSubType=" + riskAnalysisSubType + "&asset=" + asset + "" +
      "&assetSubType=" + assetSubType + "&businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get<SubmittedInfo>(fullUrl);
  }

  getAllBpSubmitInfo(bpId: string, versionNo: number): Observable<SubmittedInfo[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetSubmittedInfoByBusinessProcess?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get<SubmittedInfo[]>(fullUrl);
  }
  AddSubmitInfo(data: SubmittedInfo) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/AddUpdateSubmittedInfo";
    return this.http.post(fullUrl, JSON.stringify(data), this.createHedader()).subscribe();
  }

  getSubmittedInfoTotalText(bpId: string, versionNo: number): Observable<SubmittedInfo> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetSubmittedInfoText?businessProcessId=" + bpId + "&versionNo=" + versionNo + "";
    return this.http.get<SubmittedInfo>(fullUrl);
  }
  getStandardControlsList(): Observable<StandardControlModel[]> {
    var fullUrl = this.url + "/api/SecurityOfProcessing/GetStandardControlsList";
    return this.http.get<StandardControlModel[]>(fullUrl);
  }
  AddStandardControl(threat: FullThreat, control: number) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/AddStandardControl?controlId=" + control + "";
    return this.http.post(fullUrl, JSON.stringify(threat), this.createHedader()).subscribe();
  }
  DeleteStandardControl(threat: FullThreat, control: number) {
    var fullUrl = this.url + "/api/SecurityOfProcessing/DeleteStandardControl?controlId=" + control + "";
    return this.http.post(fullUrl, JSON.stringify(threat), this.createHedader()).subscribe();
  }
}
