import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CommandModel, EditSettingsModel } from "@syncfusion/ej2-angular-grids";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { AdministrationService } from "src/app/shared/services/admin.service";
const localexs = {
    "xs": {
        grid: {
            Add: 'Add Technical vulnerability'
        }
    }
};

setCulture("xs");
L10n.load(localexs);
@Component({
    selector: 'technical-vulnerabilities',
    styleUrls: ['technical-vulnerabilities.css'],
    templateUrl: 'technical-vulnerabilities.html',
    encapsulation: ViewEncapsulation.None
})
export class TechnicalVulnerabilitiesComponent implements OnInit {
    constructor(private adminService: AdministrationService) { }
    public vulnerabilities: any = [];
    public answers: any = [];
    public selectionSettings: Object;
    public commands?: CommandModel[]
    public ddlfields: Object = { text: 'answerId', value: 'answerName' };
    public toolbar: string[];
    public filterSettings: Object;
    public editSettings: EditSettingsModel;
    ngOnInit(): void {
        this.filterSettings = { type: "Menu" };
        this.selectionSettings = { persistSelection: true, type: "Multiple", checkboxOnly: true };
        this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-delete e-icons' } },
        { type: 'Save', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-update e-icons' } }];
        this.getVulnerabilitiesList();
        this.getAnswersList();
        this.toolbar = ['Add', 'Delete'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    }

    rowSelected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    }
    rowDeselected(e) {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Normal' };
    }

    getVulnerabilitiesList() {
        this.adminService.getTechnicalVulnerabilities().subscribe(data => {
            this.vulnerabilities = data;
        },
            error => {
                console.error('Error fetching technical vulnerabilities:', error);
            })
    }

    getAnswersList() {
        this.adminService.getTechnicalVulnerabilityAnswers().subscribe(data => {
            this.answers = data;
        },
            error => {
                console.error('Error fetching answers:', error);
            })
    }

    actionBegin(args: any): void {
        if (args.requestType === 'save' && args.action === 'add') {
            this.adminService.addTechnicalVulnerability(args.data).subscribe(resp => {
                args.data.threatId = resp;
            })
        }
        if (args.requestType === 'save' && args.action === 'edit') {
            this.adminService.updateTechnicalVulnerability(args.data).subscribe();
        }
        if (args.requestType === 'delete') {
            this.adminService.deleteTechnicalVulnerability(args.data[0].vulnerabilityId).subscribe();
        }
    }
}