import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ModelData } from "src/app/interfaces/Administration/import/ModelData";
import auth from '../../auth-config.json';
import { forkJoin, Observable } from "rxjs";
import { Customer } from "src/app/interfaces/Administration/Customer";
import { Role } from "src/app/interfaces/Administration/permissionManagement/Role";
import { Scope } from "src/app/interfaces/Administration/permissionManagement/Scope";
import { ParametersTypes } from "src/app/interfaces/Administration/permissionManagement/ParametersTypes";
import { Actors, AdminThreats, Intentions, RiskTypes, ThreatCategories, ThreatLevels } from "src/app/interfaces/Administration/threats/AdminThreats";
@Injectable({
    providedIn: 'root'
})
export class AdministrationService {
    url = auth.resources.PDHubApi.resourceUri;
    localUrl = "https://localhost:44394"
    constructor(private http: HttpClient, private authService: MsalService) { }
    private createHedader() {
        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    getModelData(customerId: string, versionNo: number): Observable<ModelData[]> {
        var Url = this.url + "/api/Xml/modeldata/" + customerId + "/" + versionNo + "";
        return this.http.get<ModelData[]>(Url);
    }
    getAllCustomers(): Observable<Customer[]> {
        var Url = this.url + "/api/Customer/all";
        return this.http.get<Customer[]>(Url);
    }
    getDummyCustomer(): Observable<Customer> {
        var Url = this.url + "/api/Customer/dummycustomer";
        return this.http.get<Customer>(Url)
    }

    uploadXml(formData: FormData, customerId: any) {
        var Url = this.url + "/api/Xml/Save";
        return this.http.post(Url, formData);
    }
    updateXml(model: any) {
        var Url = this.url + "/api/Xml/update";
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    deleteXml(customerId: string, versionNo: number) {
        var Url = this.url + "/api/Xml/delete/" + customerId + "/" + versionNo + "";
        return this.http.delete(Url);
    }

    // Permissions

    getAllRoles() {
        var Url = this.url + "/api/Security/GetRoles";
        return this.http.get<Role[]>(Url);
    }

    getScopesByRole(roleId: number) {
        var Url = this.url + "/api/Security/GetAllUserScopes?roleId=" + roleId + "";
        return this.http.get<Scope[]>(Url);
    }

    getNonIncludedScopesByRole(roleId: number) {
        var Url = this.url + "/api/Security/GetNotLinkedExistingScopes?roleId=" + roleId + "";
        return this.http.get<Scope[]>(Url);
    }

    linkScopesToRole(scopeId: number, roleId: number) {
        var Url = this.url + "/api/Security/AssignScopeToRole?scopeId=" + scopeId + "&roleId=" + roleId + "";
        return this.http.get<number>(Url);
    }

    deleteScopesToRoleLink(roleId: number, scopeId: number) {
        var Url = this.url + "/api/Security/DeleteRoleToScopeLink?scopeId=" + scopeId + "&roleId=" + roleId + "";
        return this.http.delete<number>(Url);
    }

    getRoleParametersWithPermissions(roleId: number) {
        var Url = this.url + "/api/Security/GetParametersWithPermissions?roleId=" + roleId + "";
        return this.http.get<ParametersTypes[]>(Url);
    }

    getNonIncludedRoleParametersWithPermissions(roleId: number) {
        var Url = this.url + "/api/Security/GetNonIncludedParametersWithPermissions?roleId=" + roleId + "";
        return this.http.get<ParametersTypes[]>(Url);
    }

    linkPermissionToRole(val: string, roleId: number, permissionTypeId: number) {
        var Url = this.url + "/api/Security/AssignPermissionToRole?permissionValue=" + val + "&roleId="
            + roleId + "&permissionTypeId=" + permissionTypeId + "";
        return this.http.get<number>(Url);
    }

    deletePermissionToRoleLink(roleId: number, permissionValue: string, permissionTypeId: number) {
        var Url = this.url + "/api/Security/DeleteRoleToPermissionLink?permissionValue="
            + permissionValue + "&roleId=" + roleId + "&permissionTypeId=" + permissionTypeId + "";
        return this.http.delete<number>(Url);
    }

    // Permissions


    // Threats

    getIntentions() {
        var Url = this.url + "/api/Security/intentions";
        return this.http.get<Intentions[]>(Url);
    }

    getCategories() {
        var Url = this.url + "/api/Security/threat-categories";
        return this.http.get<ThreatCategories[]>(Url);
    }

    getActors() {
        var Url = this.url + "/api/Security/actors";
        return this.http.get<Actors[]>(Url);
    }

    getRiskTypes() {
        var Url = this.url + "/api/Security/risk-types";
        return this.http.get<RiskTypes[]>(Url);
    }

    getThreatLevels() {
        var Url = this.url + "/api/Security/threat-levels";
        return this.http.get<ThreatLevels[]>(Url);
    }

    getAdminThreats() {
        var Url = this.url + "/api/Security/admin-threats";
        return this.http.get<AdminThreats[]>(Url);
    }

    getOrganizationAdminThreats(org: string) {
        var Url = this.url + `/api/Security/organization-admin-threats?organizationId=${org}`;
        return this.http.get<AdminThreats[]>(Url);
    }

    AddOrganizationAdminThreats(model: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-threats?organizationId=${org}`;
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    SubmitOrganizationAdminVulnerabilities(model: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-vulnerabilities?organizationId=${org}`;
        return this.http.post(Url, JSON.stringify(model), this.createHedader());
    }

    UpdateOrganizationAdminThreat(model: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-threats?organizationId=${org}`;
        return this.http.put(Url, JSON.stringify(model), this.createHedader());
    }

    AddCustomOrganizationAdminThreat(model: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-custom-threat?organizationId=${org}`;
        return this.http.post<number>(Url, JSON.stringify(model), this.createHedader());
    }

    AddUpdateThreats(model: any) {
        var Url = this.url + "/api/Security/admin-threats";
        return this.http.post<number>(Url, JSON.stringify(model), this.createHedader());
    }

    LinkThreatRule(model: any) {
        var Url = this.url + "/api/Security/threat-rules";
        return this.http.post<number>(Url, JSON.stringify(model), this.createHedader());
    }

    deleteAdminThreat(threatId: number) {
        var Url = this.url + `/api/Security/admin-threats?threatId=${threatId}`;
        return this.http.delete(Url);
    }

    deleteOrganizationAdminThreat(threatId: number, org: string, isCustom: boolean) {
        var Url = this.url + `/api/Security/organization-admin-threats?threatId=${threatId}&organizationId=${org}&isCustom=${isCustom}`;
        return this.http.delete(Url);
    }

    deleteThreatRule(threatRuleId: number) {
        var Url = this.url + `/api/Security/threat-rule?threatRuleId=${threatRuleId}`;
        return this.http.delete(Url);
    }

    threatSubmitInfo(org: string) {
        var Url = this.url + `/api/Security/submit-threats?organizationId=${org}`;
        return this.http.get<boolean>(Url);
    }

    vulnerabilitySubmitInfo(org: string) {
        var Url = this.url + `/api/Security/vulnerabilities-submit-info?organizationId=${org}`;
        return this.http.get<boolean>(Url);
    }

    resetVulnerabilities(org: string) {
        var Url = this.url + `/api/Security/organization-admin-linked-vulnerability?organizationId=${org}`;
        return this.http.delete(Url);
    }

    getRules(org: string, threatId: number, isCustom: boolean) {
        var Url = this.url + `/api/Security/rule-groups?organizationId=${org}&threatId=${threatId}&isCustom=${isCustom}`;
        return this.http.get<any[]>(Url);
    }

    getWeights() {
        var Url = this.url + `/api/Security/weights`;
        return this.http.get<any[]>(Url);
    }

    getTechnicalVulnerabilities() {
        var Url = this.url + `/api/Security/technical-vulnerabilities`;
        return this.http.get<any[]>(Url);
    }

    getTechnicalVulnerabilitiesByOrganization(org: string) {
        var Url = this.url + `/api/Security/organization-technical-vulnerabilities?organizationId=${org}`;
        return this.http.get<any[]>(Url);
    }

    getTechnicalVulnerabilityAnswers() {
        var Url = this.url + `/api/Security/answers`;
        return this.http.get<any[]>(Url);
    }
    addTechnicalVulnerability(vulnerability: any) {
        var Url = this.url + `/api/Security/technical-vulnerability`;
        return this.http.post<number>(Url, JSON.stringify(vulnerability), this.createHedader());
    }
    addOrganizationTechnicalVulnerability(vulnerability: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-custom-vulnerability?organizationId=${org}`;
        return this.http.post<number>(Url, JSON.stringify(vulnerability), this.createHedader());
    }
    updateTechnicalVulnerability(vulnerability: any) {
        var Url = this.url + `/api/Security/technical-vulnerability`;
        return this.http.put(Url, JSON.stringify(vulnerability), this.createHedader());
    }

    updateOrganizationVulnerability(vulnerability: any, org: string) {
        var Url = this.url + `/api/Security/organization-admin-vulnerability?organizationId=${org}`;
        return this.http.put(Url, JSON.stringify(vulnerability), this.createHedader());
    }
    deleteTechnicalVulnerability(vulnerabilityId: number) {
        var Url = this.url + `/api/Security/technical-vulnerability?vulnerabilityId=${vulnerabilityId}`;
        return this.http.delete(Url);
    }

    deleteOrganizationTechnicalVulnerability(vulnerabilityId: number, isCustom: boolean, orgId: string) {
        var Url = this.url + `/api/Security/organization-admin-vulnerability?vulnerabilityId=${vulnerabilityId}&isCustom=${isCustom}&organizationId=${orgId}`;
        return this.http.delete(Url);
    }
    // Threats

}

function mergeMap(arg0: (_data: any) => any): import("rxjs").OperatorFunction<Customer[], Customer[]> {
    throw new Error("Function not implemented.");
}
