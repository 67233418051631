import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TooltipComponent } from "@syncfusion/ej2-angular-popups";
import { BPService } from "src/app/shared/services/bp.service";
import { RiskService } from "src/app/shared/services/risk-analysis.service";
import { SecurityOfProcessingComponent } from "../security-of-processing.component";
import { AssetsComponent } from "./assets.component";

@Component({
    selector: 'risk-analysis',
    templateUrl: 'risk-analysis.html',
    styleUrls: ['risk-analysis.css'],
    encapsulation: ViewEncapsulation.None
})
export class RiskAnylysisComponent implements OnInit, AfterViewInit {
    bpId: string;
    versionNo: any;
    constructor(private route: ActivatedRoute, private router: Router,
        private bpService: BPService, private SopParent: SecurityOfProcessingComponent,
        private riskService: RiskService) {

    }
    ngAfterViewInit(): void {
        this.getTabs();
    }
    @ViewChild(AssetsComponent) assets: AssetsComponent;
    public previousUrl: string;
    public totalText: string;
    @ViewChild('ra_tooltip')
    public tooltipComponent: TooltipComponent
    riskAnalysisType;
    raType;
    public tabsModel = [];
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        var bpList = JSON.parse(sessionStorage.getItem("bpListData"));
        this.versionNo = bpList.filter(x => x.id == this.bpId)[0].versionNo;
        this.previousUrl = "/bp-details/id/security/entry-security";
        this.route.params.subscribe(params => {
            const id = params['id'];
            this.riskAnalysisType = id;
            this.raType = id;
        });
        this.isDPIARequired();
        this.GetSubmittedInfoTotalText();
    }
    public headerText: Object = [];

    navigateBack() {
        this.SopParent.sopType = "";
        this.router.navigate(['/bp-details/id/security/entry-security'])
    }

    beforeRender(index: number) {
        var content;
        if (index == 2)
            content = "<div style='padding: 10px 10px 0px 10px;width: 300px;'><h3 style='color: #5AA9EF;font-family:Rubik,sans-serif;font-weight: 700;" +
                "font-size: 16px;float: left;margin-left:0px'>DPIA required?</h3><br>" +
                "<p style='clear:both; color:black'>A DPIA should be carried out. A full risk analysis is recommended</p></div>"
        else if (index == 1)
            content = "<div style='padding: 10px 10px 0px 10px;width: 200px;'><h3 style='color: #5AA9EF;font-family:Rubik,sans-serif;font-weight: 700;" +
                "font-size: 16px;float: left;margin-left:0px'>DPIA required?</h3><br>" +
                "<p style='clear:both; color:black'>A light risk analysis is recommended</p></div>"
        this.tooltipComponent.content = content;
    }


    getTabs() {
        if (sessionStorage.getItem("tabs"))
            this.tabsModel = JSON.parse(sessionStorage.getItem("tabs"));
        else {
            this.riskService.getTabStructure().subscribe(res => {
                this.tabsModel = res;
                sessionStorage.setItem("tabs", JSON.stringify(res))
            });
        }
    }

    IsDPIARequired: Boolean;
    isDPIARequired() {
        if (sessionStorage.getItem("dpia"))
            this.IsDPIARequired = JSON.parse(sessionStorage.getItem("dpia"));
        else {
            var bpId = sessionStorage.getItem("bpId");
            this.bpService.getDPIARequired(bpId).subscribe(res => {
                this.IsDPIARequired = res;

                sessionStorage.setItem("dpia", JSON.stringify(res))
            })
        }
    }
    GetSubmittedInfoTotalText() {
        this.riskService.getSubmittedInfoTotalText(this.bpId, this.versionNo)
            .subscribe(data => {
                if (data !== null) {
                    this.totalText = "Submitted at: " +
                        new Date(data.submittedDateUtc).toISOString().slice(0, 10)
                        + " by " + data.submittedBy;
                }
            })
    }

    onTabSelect(args: any) {
        this.raType = args.selectedIndex;
    }
}
